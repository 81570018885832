import { useTranslation } from 'react-i18next'

import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'

export const MerchantAccounts = ({ accountNameCC, accountNameACH }) => {
  const { t } = useTranslation()

  return (
    <FieldGroupContainer title={t('common.merchant-accounts')}>
      <FieldGroupRow>
        {accountNameCC && (
          <FieldGroupViewItem
            title={t('common.merchant-account-cc')}
            value={accountNameCC}
            valueTestID="quick-invoice-merchant-account-cc-value"
          />
        )}

        {accountNameACH && (
          <FieldGroupViewItem
            title={t('common.merchant-account-ach')}
            value={accountNameACH}
            valueTestID="quick-invoice-merchant-account-ach-value"
          />
        )}
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Typography } from '@mui/material'

import { api, Recurring } from '@shared/api'
import {
  HasPermission,
  Loading,
  UserNotAllowed,
  Confirmation,
} from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useLocations,
  useEnforceLogin,
} from '@shared/hooks'

import RecurringPaymentDetails from '../components/recurring-payment-details/RecurringPaymentDetails'

const useStyles = tss.withName('RecurringBillingConfirmation').create({
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
})

export default function RecurringBillingConfirmation() {
  const { classes } = useStyles()
  const { id } = useParams()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const navigate = useNavigate()
  const { user } = useEnforceLogin()
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const { t } = useTranslation()

  const recurringBillingPrivs = ['v2.recurrings.post']

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.action.recurring-billing.add'),
      null,
      [t('common.gateway'), t('common.recurring-billing')],
      `/merchant/gateway/recurring-billing/add`
    )
  }, [])

  useEffect(() => {
    fetchRecurring()
  }, [])

  const fetchRecurring = async () => {
    setIsLoading(true)
    const data = await api.service('recurring-billings').get(id)

    const transaction_amount = data.transaction_amount / 100

    setRecurring({ ...data, transaction_amount })
    setIsLoading(false)
  }

  const [recurring, setRecurring] = useState<Recurring>(null)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <HasPermission
      allPermissions={recurringBillingPrivs}
      unauthorizedComponent={<UserNotAllowed />}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Confirmation
            title={t('mfe-gateway.toast.recurring-billing.created-success')}
            details={
              <>
                <Typography
                  component="span"
                  color="primary"
                  onClick={() => setShowDetails(true)}
                  className={classes.link}
                  data-guiding-id="recurringbilling-confirmation-details"
                >
                  {t('common.click-here')}{' '}
                </Typography>
                {t(
                  'mfe-gateway.recurring-billing.to-view-recurring-payment-details'
                ) + ' '}
              </>
            }
            fullHeight={true}
            buttons={[
              {
                label: t('mfe-gateway.action.print-receipt'),
                onClick: () => {
                  const printUrl = `/print/recurring/${id}`
                  window.open(printUrl, '_blank')
                },
                show: true,
                guidingId: 'recurringbilling-confirmation-printreceipt',
              },
            ]}
          />
          {showDetails && (
            <RecurringPaymentDetails
              recurring={recurring}
              productTransaction={recurring?.product_transaction}
              isModalOpen={showDetails}
              tz={user?.tz}
              onClose={() => setShowDetails(false)}
            />
          )}
        </>
      )}
    </HasPermission>
  )
}

import {
  LocationInfo,
  MerchantAccount,
  Token,
  TransactionType,
  User,
} from '../../../api'
import { ProcessMethod } from '../../../types'
import { canCreateWallet, canViewWallet } from '../../permissions/permissions'
import { getValidTerminals } from '../get-valid-terminals/getValidTerminals'

type GetProcessMethodOptionsData = {
  user: User
  location: LocationInfo
  merchantAccount: MerchantAccount | undefined
  transactionType: TransactionType
  walletItems?: Token[]
  allowCustomer?: boolean
}

export const getProcessMethodOptions = ({
  user,
  location,
  merchantAccount,
  transactionType,
  walletItems = [],
}: GetProcessMethodOptionsData): ProcessMethod[] => {
  const includeTerminalOption =
    merchantAccount?.payment_method !== 'cash' &&
    transactionType !== 'force' &&
    getValidTerminals(location, merchantAccount, user).length > 0

  const allowedValues: ProcessMethod[] = ['manual']

  if (includeTerminalOption) allowedValues.push('terminal')

  if (
    canViewWallet(user) &&
    (canCreateWallet(user) || walletItems.length > 0) &&
    Boolean(location.product_token?.active)
  ) {
    allowedValues.push('wallet')
  }

  return allowedValues
}

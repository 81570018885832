import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Recurring } from '@shared/api'
import { valueToNumber } from '@shared/api/src/utils/valueToNumber'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { formatDate, currency } from '@shared/utils'

import { mapRecurringTypeId } from '../../../recurring-billing-grid/utils/RecurringMapping'

const useStyles = tss.withName('RecurrenceSettings').create(({ theme }) => ({
  root: {},
}))

export type RecurrenceSettingsProps = {
  recurringData: Recurring | null
}

function RecurrenceSettings({ recurringData }: RecurrenceSettingsProps) {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const interval =
    recurringData?.interval_type === 'm'
      ? t('common.month-s')
      : recurringData?.interval_type === 'w'
      ? t('common.week-s')
      : t('common.day-s')

  const value_recurring_type = mapRecurringTypeId(
    recurringData?.recurring_type_id
  )
  const value_installment_total_count =
    recurringData?.installment_total_count?.toString()
  const value_transaction_amount =
    currency(valueToNumber(recurringData?.transaction_amount)) + ' USD'
  const value_interval = recurringData?.interval + ' ' + interval
  const value_start_date = formatDate(recurringData?.start_date, 'UTC')
  const value_end_date =
    recurringData?.end_date !== '00-00-0000'
      ? formatDate(recurringData?.end_date, 'UTC')
      : ''
  const value_next_run_date =
    recurringData?.end_date !== '00-00-0000'
      ? formatDate(recurringData?.next_run_date, 'UTC')
      : ''
  const value_send_payment_reminder_days =
    recurringData?.notification_days > 0
      ? `${recurringData?.notification_days} ${t(
          'mfe-gateway.recurring-billing.days-before-due-date'
        )}`
      : ''

  return (
    <FieldGroupContainer
      title={t('mfe-gateway.recurring-billing.recurrence-settings')}
    >
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.recurring-billing.type')}
          value={value_recurring_type}
          valueTestID="recurring-billing-type-value"
        />
        {recurringData?.recurring_type_id === 'i' && (
          <FieldGroupViewItem
            title={t('common.recurring-billing.number-of-payments')}
            value={value_installment_total_count}
            valueTestID="recurring-billing-installment-total-count-value"
          />
        )}
      </FieldGroupRow>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('mfe-gateway.recurring-billing.charge-the-amount-of')}
          value={value_transaction_amount}
          valueTestID="recurring-billing-transaction-amount-value"
        />
        <FieldGroupViewItem
          title={t('common.every')}
          value={value_interval}
          valueTestID="recurring-billing-interval-value"
        />
      </FieldGroupRow>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('mfe-gateway.recurring-billing.starting-on')}
          value={value_start_date}
          valueTestID="recurring-billing-start-date-value"
        />
        <FieldGroupViewItem
          title={t('mfe-gateway.recurring-billing.ends-on')}
          value={value_end_date}
          valueTestID="recurring-billing-end-date-value"
        />
      </FieldGroupRow>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.date-next-run')}
          value={value_next_run_date}
          valueTestID="recurring-billing-next-run-date-value"
        />
        <FieldGroupViewItem
          title={t('mfe-gateway.recurring-billing.send-payment-reminder')}
          value={value_send_payment_reminder_days}
          valueTestID="recurring-billing-send-payment-reminder-value"
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}

export default RecurrenceSettings

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { AlertColor } from '@mui/material'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import MultiSelectSearchFilter from '@shared/ag-grid/pagination-table/filters/MultiSelectSearchFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { Tags, Recurring, User } from '@shared/api/src/schemas/types'
import {
  ActiveStatus,
  HasPermission,
  RecurringStatus,
  UserNotAllowed,
  ThreeDotMenu,
  Notification,
  TagList,
} from '@shared/components'
import AppTheme from '@shared/design'
import {
  useEnforceLogin,
  useFilterModel,
  useFtpPortalHubCommunication,
  useLocations,
  useAuthorization,
  useNotification,
} from '@shared/hooks'
import { EnumServiceName } from '@shared/types'
import {
  formatDate,
  currency,
  checkPermission,
  DataSource,
  formatDatetime,
  sortMerchantAccountsOnGridFilter,
} from '@shared/utils'

import {
  filterMerchantAccount,
  mapRecurringTypeId,
  filterRecurringTypeValues,
  filterRecurringStatusValues,
} from './utils/RecurringMapping'
import DeferPayment from '../components/modals/defer-payment/DeferPayment'
import EndPayment from '../components/modals/end-payment/EndPayment'
import PausePayment from '../components/modals/pause-payment/PausePayment'
import ResumePayment from '../components/modals/resume-payment/ResumePayment'
import SkipPayment from '../components/modals/skip-payment/SkipPayment'
import RecurringPaymentDetails from '../components/recurring-payment-details/RecurringPaymentDetails'

const useStyles = tss.withName('RecurringBilling').create(({ theme }) => ({
  inactive: {
    marginRight: '5px',
  },
}))

export default function RecurringBilling() {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { classes } = useStyles(AppTheme)
  const { allMerchantAccounts, locationTags, selectedLocation } = useLocations()
  const hasProductRecurringService = !!selectedLocation.product_recurring
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()
  const { setNotification } = useNotification()
  const { userPermissionSet } = useAuthorization()

  const RecurringPrivs = ['v2.recurrings.get']
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])

  const gridRef = useRef<AgGridReact>(null)
  const [recurringData, setRecurringData] = useState<Recurring>()
  const [showRecurringDefer, setShowRecurringDefer] = useState<boolean>(false)
  const [showRecurringEnd, setShowRecurringEnd] = useState<boolean>(false)
  const [showRecurringPause, setShowRecurringPause] = useState<boolean>(false)
  const [showRecurringResume, setShowRecurringResume] = useState<boolean>(false)
  const [showRecurringSkip, setShowRecurringSkip] = useState<boolean>(false)
  const [showDetails, setShowDetails] = useState<boolean>(false)

  useEffect(() => {
    setAppBarTitle(t('common.recurring-billing'), null, t('common.gateway'))
  }, [])

  const datasource = useMemo(() => {
    return new DataSource('recurring-billings', {
      filterVariant: 'filter',
      fixedFilters: {
        location_id: selectedLocation?.id,
        active: ['0', '1'],
      },
    })
  }, [selectedLocation?.id])

  const columnDefs: ColDef<Recurring>[] = useMemo(() => {
    return [
      {
        headerName: t('common.description'),
        field: 'description',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.name-first'),
        field: 'contact.first_name',
      },
      {
        headerName: t('common.name-last'),
        field: 'contact.last_name',
      },
      {
        headerName: t('common.date-next-run'),
        field: 'next_run_date',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.next_run_date
          return timestamp !== '0000-00-00' ? formatDate(timestamp, 'UTC') : ''
        },
        sort: 'asc',
        sortIndex: 0,
        filterParams: {
          type: 'future',
          customFormat: 'yyyy-MM-dd',
        },
        sortable: true,
      },
      {
        headerName: t('common.type'),
        field: 'recurring_type_id',
        valueGetter: (params) => {
          return mapRecurringTypeId(params.data?.recurring_type_id)
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterRecurringTypeValues.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterRecurringTypeValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        floatingFilter: true,
        sortable: true,
      },
      {
        headerName: t('common.amount-transaction'),
        field: 'transaction_amount',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) =>
          currency(params.data?.transaction_amount / 100),
      },
      {
        headerName: t('mfe-gateway.total-amount-collect'),
        field: 'installment_amount_total',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) =>
          currency(params.data?.installment_amount_total / 100),
      },
      {
        headerName: t('common.status'),
        field: 'status',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterRecurringStatusValues.map((option) => option.value), // Send values to API
          valueFormatter: (params) => {
            const option = filterRecurringStatusValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        cellRenderer: (data) => <RecurringStatus statusCode={data.value} />,
      },
      {
        headerName: t('common.merchant-account'),
        field: 'product_transaction_id',
        filter: 'agSetColumnFilter',
        filterParams: {
          suppressSorting: true,
          values: filterMerchantAccount(allMerchantAccounts).map(
            (option) => option.value
          ),
          comparator: (a, b) =>
            sortMerchantAccountsOnGridFilter(a, b, allMerchantAccounts),
          valueFormatter: (params) => {
            const option = filterMerchantAccount(allMerchantAccounts).find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        floatingFilter: true,
        valueGetter: (params) => {
          return params.data?.product_transaction.title
        },
        cellRenderer: (data) => {
          const merchantTitle = data.data?.product_transaction?.title
          const active = data.data?.product_transaction?.active
          return (
            <>
              <span className={classes.inactive}>{merchantTitle}</span>

              {!active && <ActiveStatus active={false} />}
            </>
          )
        },
        sortable: true,
      },
      {
        headerName: t('common.date-created'),
        field: 'created_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.created_ts
          return timestamp ? formatDatetime(timestamp, user?.tz) : ''
        },
        filterParams: {
          type: 'past',
          showTimePicker: true,
        },
        sortable: true,
      },
      {
        headerName: t('common.created-by'),
        field: 'created_user.id',
        floatingFilter: true,
        filter: MultiSelectSearchFilter,
        filterParams: {
          typeOfSearch: 'contains',
          filterType: 'text',
          placeholder: t('common.email-search'),
          service: 'users',
          filterPropName: 'email',
          primaryDisplayField: 'email',
          value: selectedUsers,
          multiple: true,
          onChange: (event) => {
            setSelectedUsers(event)
          },
        },
        valueGetter: (params) => {
          return params.data?.created_user?.email
        },
      },
      {
        headerName: t('common.tags'),
        field: 'tags',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values:
            locationTags && locationTags.length > 0
              ? locationTags.map((tag) => tag.title)
              : [],
          comparator: (a, b) => {
            return a.trim().localeCompare(b.trim())
          },
        },
        cellRenderer: (data) => {
          return (
            <TagList
              items={(data.value as Tags[]) ?? []}
              nameSelector={(tag: Tags) => tag.title}
              wrapWords={false}
            />
          )
        },
      },
    ]
  }, [
    allMerchantAccounts,
    locationTags,
    hasProductRecurringService,
    userPermissionSet,
  ])

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const showOrWaitTags = () => {
    if (
      selectedLocation?.tags &&
      selectedLocation?.tags.length > 0 &&
      !!locationTags &&
      locationTags.length > 0
    ) {
      return true
    } else if (selectedLocation?.tags?.length === 0) {
      return true
    } else {
      return false
    }
  }

  const handleRowClick = useCallback((event) => {
    const rowData = event.data
    const id = rowData.id
    navigate(`/merchant/gateway/recurring-billing/${id}/view`)
  }, [])

  const defaultFilters = {
    status: {
      type: 'agSetColumnFilter',
      values: ['active', 'on hold'],
    },
  }

  const paramKeys = [
    {
      queryKey: 'filter[status]',
      filterKey: 'status',
      filterType: 'agSetColumnFilter',
      includeTime: false,
    },
  ]

  const initialFilterModel = useFilterModel(defaultFilters, paramKeys)

  const refreshGrid = () => {
    gridRef.current!.api.refreshServerSide({ purge: true })
  }

  const displayToastAndRefreshGrid = (message: string, isError?: boolean) => {
    setNotification({
      label: message,
      type: isError ? 'error' : 'success',
    })
    refreshGrid()
  }

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  const threeDotsOptions = useCallback(
    (data: Recurring) => [
      {
        label: t('common.view'),
        action: () =>
          navigate(`/merchant/gateway/recurring-billing/${data.id}/view`),
        enabled: true,
      },
      {
        label: t('common.edit'),
        action: async () => {
          data.transaction_amount = data.transaction_amount / 100
          navigate(`/merchant/gateway/recurring-billing/${data.id}/edit`, {
            state: {
              recurringData: data,
              backUrl: '/merchant/gateway/recurring-billing',
            },
          })
        },
        enabled:
          checkPermission(userPermissionSet, 'v2.recurrings.put') &&
          hasProductRecurringService &&
          data?.status !== 'ended',
      },
      {
        label: t('common.pause'),
        action: () => {
          setRecurringData(data)
          setShowRecurringPause(true)
        },
        enabled:
          data?.active &&
          data?.status === 'active' &&
          data?.recurring_type_id === 'o' &&
          hasProductRecurringService,
      },
      {
        label: t('common.resume'),
        action: () => {
          setRecurringData(data)
          setShowRecurringResume(true)
        },
        enabled:
          hasProductRecurringService &&
          checkPermission(userPermissionSet, 'v2.recurrings.post') &&
          data.active &&
          data.recurring_type_id === 'o' &&
          data.status === 'on hold',
      },
      {
        label: t('common.skip'),
        action: () => {
          setRecurringData(data)
          setShowRecurringSkip(true)
        },
        enabled:
          data?.active &&
          data?.status === 'active' &&
          hasProductRecurringService,
      },
      {
        label: t('mfe-gateway.action.recurring-billing.defer-uppercase'),
        action: () => {
          setRecurringData(data)
          setShowRecurringDefer(true)
        },
        enabled:
          data?.active &&
          data?.recurring_type_id === 'i' &&
          data?.status === 'active',
      },
      {
        label: t('common.end'),
        action: () => {
          setRecurringData(data)
          setShowRecurringEnd(true)
        },
        enabled:
          data?.active &&
          checkPermission(userPermissionSet, 'v2.recurrings.delete') &&
          data?.status !== 'ended',
      },
      {
        label: t('mfe-gateway.authorization-agreement'),
        action: () => {
          setRecurringData(data)
          setShowDetails(true)
        },
        enabled: true,
      },
    ],
    [selectedLocation, userPermissionSet, hasProductRecurringService]
  )

  return (
    <section>
      <HasPermission
        allPermissions={RecurringPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <>
          {allMerchantAccounts.length > 0 && showOrWaitTags() && (
            <PaginationTable<Recurring>
              getRowId={(data) => data.data.id}
              getGridRef={getGridRef}
              columnDefs={columnDefs}
              rowModelType={'serverSide'}
              serverSideDatasource={datasource}
              defaultColDef={defaultColDef}
              onRowClicked={handleRowClick}
              showExportButton={true}
              showClearFiltersButton={true}
              serviceName={EnumServiceName.RecurringBillings}
              initialFilterModel={initialFilterModel}
              primaryButtonData={
                checkPermission(userPermissionSet, 'v2.recurrings.post')
                  ? {
                      text: t('mfe-gateway.action.recurring-billing.add'),
                      action: () =>
                        navigate('/merchant/gateway/recurring-billing/add'),
                    }
                  : undefined
              }
              guidingId="recurringbilling"
              threeDotsOptions={threeDotsOptions}
            />
          )}
          <PausePayment
            recurring={recurringData}
            open={showRecurringPause}
            onClose={() => setShowRecurringPause(false)}
            onCompleted={displayToastAndRefreshGrid}
          />
          <ResumePayment
            recurring={recurringData}
            open={showRecurringResume}
            onClose={() => setShowRecurringResume(false)}
            onCompleted={displayToastAndRefreshGrid}
          />
          <SkipPayment
            recurring={recurringData}
            open={showRecurringSkip}
            onClose={() => setShowRecurringSkip(false)}
            onCompleted={displayToastAndRefreshGrid}
          />
          <DeferPayment
            recurring={recurringData}
            open={showRecurringDefer}
            onClose={() => setShowRecurringDefer(false)}
            onCompleted={displayToastAndRefreshGrid}
          />
          <EndPayment
            recurring={recurringData}
            open={showRecurringEnd}
            onClose={() => setShowRecurringEnd(false)}
            onCompleted={displayToastAndRefreshGrid}
          />
          {showDetails && (
            <RecurringPaymentDetails
              recurring={recurringData}
              productTransaction={recurringData?.product_transaction}
              isModalOpen={showDetails}
              tz={user?.tz}
              onClose={() => setShowDetails(false)}
            />
          )}
        </>
      </HasPermission>
    </section>
  )
}

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Close } from '@mui/icons-material'
import { Box, Button, Popper, Typography } from '@mui/material'

import { Token } from '@shared/api'
import { Typeahead, TypeaheadProps } from '@shared/components'
import { useSub } from '@shared/hooks'
import { FolderIcon } from '@shared/icons'
import { formatCcExpDate, accountTypeToString } from '@shared/utils'

import { CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT } from '../customer-details/CustomerDetails'

export interface WalletSelectorProps {
  wallets: Token[]
  selectedWallet: Token | null
  getWallets?: TypeaheadProps<Token>['getItems']
  onChangeWallets: (wallets: Token[]) => void
  onChangeSelectedWallet: (wallet: Token | null) => void
  getWalletsOnMount?: boolean
  loading?: boolean
  showHint?: boolean
  required?: boolean
  disabled?: boolean
  guidingId?: string
}

export const WalletSelector: FC<WalletSelectorProps> = ({
  wallets,
  selectedWallet,
  getWallets,
  onChangeWallets,
  onChangeSelectedWallet,
  getWalletsOnMount,
  loading,
  showHint,
  required,
  disabled,
  guidingId,
}) => {
  const { t } = useTranslation()
  const rootRef = useRef<HTMLElement>()

  const {
    formState: { errors },
  } = useFormContext()

  const [displayHint, setDisplayHint] = useState(!!showHint)

  useEffect(() => {
    if (selectedWallet) {
      setDisplayHint(false)
    }
  }, [])

  const getWalletLabel = useCallback(
    (wallet: Token) =>
      [
        wallet?.last_four,
        formatCcExpDate(wallet?.exp_date) ??
          accountTypeToString(wallet?.account_type),
        wallet?.title,
      ]
        .filter(Boolean)
        .join(' • '),
    []
  )

  const handleChangeSelectedWallet = useCallback(
    (wallet: Token) => {
      onChangeSelectedWallet(wallet)
      if (!!showHint) setDisplayHint(!wallet)
    },
    [onChangeSelectedWallet, showHint]
  )

  const onWalletHintClose = useCallback(() => {
    setDisplayHint(false)
  }, [])

  useSub<typeof CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT>(
    CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT.type,
    ({ data: customer }) => {
      if (!!showHint) setDisplayHint(!!customer)
    },
    [showHint]
  )

  useEffect(() => {
    setDisplayHint(!!showHint)
  }, [showHint])

  wallets = wallets.filter((wallet) => {
    if (wallet?.ach_sec_code !== 'POP') {
      return wallet
    }
  })

  return (
    <>
      {!selectedWallet && (
        <SelectWalletHint
          anchorEl={rootRef.current!}
          onClose={onWalletHintClose}
          show={!disabled && displayHint}
        />
      )}
      <Typeahead<Token>
        id="wallet-selector"
        testId="wallet-selector-input"
        autoCompleteTestId="wallet-selector-autocomplete"
        ref={rootRef}
        label={t('mfe-gateway.customer-wallet-select')}
        noOptionsText={t('mfe-gateway.customer-wallet-select-no-options')}
        value={selectedWallet}
        items={wallets}
        matchItemAndValueProperty={'id'}
        getItemLabel={getWalletLabel}
        getItems={getWallets}
        onChangeItems={onChangeWallets}
        onChangeValue={handleChangeSelectedWallet}
        getItemsOnMount={!!getWalletsOnMount}
        localFiltering={!getWallets}
        loading={!!loading}
        disabled={!!disabled}
        required={!!required}
        error={!!errors.token_id}
        helperText={errors.token_id?.message as string}
        guidingId={`${guidingId}-walletselector`}
      />
    </>
  )
}

// TODO:
// RGB colors being used in there were retrieved from tailwind colors at
// web/src/components/VirtualTerminalCustomerDetails,
// need to check all the places where colors are being used like this,
// it means those colors need to be added to the App Theme
const useWalletHintStyles = tss.withName('SelectWalletHint').create(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    top: '35px',
    right: '15px',
    maxWidth: '240px',
    padding: '.8em',
    backgroundColor: 'rgb(240 249 255 / 1)',
    border: '2px solid rgb(220 240 255 / 1)',
    borderRadius: '.3em',
    clipPath: 'polygon(0% 0%, 0 100%, 97% 100%, 97% 22%, 100% 0, 100% 0, 0 0)',
  },
  description: {
    maxWidth: '70%',
    cursor: 'default',
    color: 'rgb(107 114 128 / 1)',
  },
  closeButton: {
    alignSelf: 'flex-start',
    padding: 0,
    margin: '0 -1em',
    background: 'unset',
    ':hover': {
      background: 'unset',
    },
  },
  closeIcon: {
    width: '20px',
    color: 'rgb(156 163 175 / 1)',
    ':hover': {
      borderRadius: '100%',
      background: 'rgba(156, 163, 175, .25)',
    },
  },
  walletIconContainer: {
    display: 'grid',
    placeContent: 'center',
    padding: '.6em',
    marginRight: '1em',
    background: 'rgb(220 240 255 / 1)',
    borderRadius: '100%',
  },
}))

interface SelectWalletHintProps {
  /**
   * The element from which the component will take reference
   * to position correctly.
   */
  anchorEl: HTMLElement

  /**
   * If `true`, the component is shown.
   */
  show?: boolean

  /**
   * Callback fired when the Popper closes.
   */
  onClose?: () => void
}

const SelectWalletHint: FC<SelectWalletHintProps> = ({
  anchorEl,
  show,
  onClose,
}) => {
  const { classes } = useWalletHintStyles()
  const { t } = useTranslation()
  const [isShowing, setIsShowing] = useState(!!show)

  const handleClose = () => {
    setIsShowing(false)
    onClose?.()
  }

  useEffect(() => {
    if (isShowing !== show) setIsShowing(!!show)
  }, [show])

  return (
    <Popper
      open={isShowing}
      anchorEl={anchorEl}
      placement="left-end"
      sx={{ zIndex: 1000 }}
    >
      <Box className={classes.root}>
        <Box className={classes.walletIconContainer}>
          <FolderIcon height={14} width={14} alt="wallet-icon" />
        </Box>

        <Typography className={classes.description} variant="body2">
          {t('mfe-gateway.customer-wallet-select-here')}
        </Typography>

        <Button className={classes.closeButton} onClick={handleClose}>
          <Close className={classes.closeIcon} />
        </Button>
      </Box>
      {/* <Fade in={isShowing}></Fade> */}
    </Popper>
  )
}

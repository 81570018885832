import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const Calendar = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_14687_668)">
          <path
            d="M10.625 2.37494H13.125C13.2908 2.37494 13.4497 2.44079 13.5669 2.558C13.6842 2.67521 13.75 2.83418 13.75 2.99994V12.9999C13.75 13.1657 13.6842 13.3247 13.5669 13.4419C13.4497 13.5591 13.2908 13.6249 13.125 13.6249H1.875C1.70924 13.6249 1.55027 13.5591 1.43306 13.4419C1.31585 13.3247 1.25 13.1657 1.25 12.9999V2.99994C1.25 2.83418 1.31585 2.67521 1.43306 2.558C1.55027 2.44079 1.70924 2.37494 1.875 2.37494H4.375V1.12494H5.625V2.37494H9.375V1.12494H10.625V2.37494ZM2.5 6.12494V12.3749H12.5V6.12494H2.5ZM3.75 7.37494H5V8.62494H3.75V7.37494ZM6.875 7.37494H8.125V8.62494H6.875V7.37494ZM10 7.37494H11.25V8.62494H10V7.37494Z"
            fill="#6B7280"
          />
        </g>
        <defs>
          <clipPath id="clip0_14687_668">
            <rect
              width="15"
              height="15"
              fill="white"
              transform="translate(0 0.499939)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default Calendar

// This was done using the following repo as a reference:
// https://github.com/braintree/credit-card-type

type CreditCardTypeCardBrandId =
  | 'amex'
  | 'diners'
  | 'disc'
  | 'jcb'
  | 'maestro'
  | 'mc'
  | 'mir'
  | 'visa'

type CreditCardTypeCardBrandNiceType =
  | 'American Express'
  | 'Diners Club'
  | 'Discover'
  | 'JCB'
  | 'Maestro'
  | 'Mastercard'
  | 'Mir'
  | 'Visa'

type CreditCardTypeSecurityCodeLabel =
  | 'CVV'
  | 'CVC'
  | 'CID'
  | 'CVN'
  | 'CVE'
  | 'CVP2'

export type CreditCardType = {
  niceType: string
  type: string
  patterns: number[] | [number[]]
  gaps: number[]
  lengths: number[]
  code: {
    size: number
    name: string
  }
  matchStrength?: number
}

interface BuiltInCreditCardType extends CreditCardType {
  niceType: CreditCardTypeCardBrandNiceType
  type: CreditCardTypeCardBrandId
  code: {
    size: 3 | 4
    name: CreditCardTypeSecurityCodeLabel
  }
}

interface CardCollection {
  [propName: string]: CreditCardType
}

const cardTypes: CardCollection = {
  visa: {
    niceType: 'Visa',
    type: 'visa',
    patterns: [4],
    gaps: [4, 8, 12],
    lengths: [16, 18, 19],
    code: {
      name: 'CVV',
      size: 3,
    },
  } as BuiltInCreditCardType,
  mc: {
    niceType: 'Mastercard',
    type: 'mc',
    patterns: [[51, 55], [2221, 2229], [223, 229], [23, 26], [270, 271], 2720],
    gaps: [4, 8, 12],
    lengths: [16],
    code: {
      name: 'CVC',
      size: 3,
    },
  } as BuiltInCreditCardType,
  amex: {
    niceType: 'American Express',
    type: 'amex',
    patterns: [34, 37],
    gaps: [4, 10],
    lengths: [15],
    code: {
      name: 'CID',
      size: 4,
    },
  } as BuiltInCreditCardType,
  diners: {
    niceType: 'Diners Club',
    type: 'diners',
    patterns: [[300, 305], 36, 38, 39],
    gaps: [4, 10],
    lengths: [14, 16, 19],
    code: {
      name: 'CVV',
      size: 3,
    },
  } as BuiltInCreditCardType,
  disc: {
    niceType: 'Discover',
    type: 'disc',
    patterns: [6011, [644, 649], 65],
    gaps: [4, 8, 12],
    lengths: [16, 19],
    code: {
      name: 'CID',
      size: 3,
    },
  } as BuiltInCreditCardType,
  jcb: {
    niceType: 'JCB',
    type: 'jcb',
    patterns: [2131, 1800, [3528, 3589]],
    gaps: [4, 8, 12],
    lengths: [16, 17, 18, 19],
    code: {
      name: 'CVV',
      size: 3,
    },
  } as BuiltInCreditCardType,
  maestro: {
    niceType: 'Maestro',
    type: 'maestro',
    patterns: [
      493698,
      [500000, 504174],
      [504176, 506698],
      [506779, 508999],
      [56, 59],
      63,
      67,
      6,
    ],
    gaps: [4, 8, 12],
    lengths: [12, 13, 14, 15, 16, 17, 18, 19],
    code: {
      name: 'CVC',
      size: 3,
    },
  } as BuiltInCreditCardType,
  mir: {
    niceType: 'Mir',
    type: 'mir',
    patterns: [[2200, 2204]],
    gaps: [4, 8, 12],
    lengths: [16, 17, 18, 19],
    code: {
      name: 'CVP2',
      size: 3,
    },
  } as BuiltInCreditCardType,
}

function matchesRange(
  cardNumber: string,
  min: number | string,
  max: number | string
): boolean {
  const maxLengthToCheck = String(min).length
  const substr = cardNumber.slice(0, maxLengthToCheck)
  const integerRepresentationOfCardNumber = parseInt(substr, 10)

  min = parseInt(String(min).slice(0, substr.length), 10)
  max = parseInt(String(max).slice(0, substr.length), 10)

  return (
    integerRepresentationOfCardNumber >= min &&
    integerRepresentationOfCardNumber <= max
  )
}

function matchesPattern(cardNumber: string, pattern: string | number): boolean {
  pattern = String(pattern)

  return (
    pattern.substring(0, cardNumber.length) ===
    cardNumber.substring(0, pattern.length)
  )
}

function matches(
  cardNumber: string,
  pattern: string | number | string[] | number[]
): boolean {
  if (Array.isArray(pattern)) {
    return matchesRange(cardNumber, pattern[0], pattern[1])
  }

  return matchesPattern(cardNumber, pattern)
}

function hasEnoughResultsToDetermineBestMatch(
  results: CreditCardType[]
): boolean {
  const numberOfResultsWithMaxStrengthProperty = results.filter(
    (result) => result.matchStrength
  ).length

  /*
   * if all possible results have a maxStrength property that means the card
   * number is sufficiently long enough to determine conclusively what the card
   * type is
   * */
  return (
    numberOfResultsWithMaxStrengthProperty > 0 &&
    numberOfResultsWithMaxStrengthProperty === results.length
  )
}

function findBestMatch(results: CreditCardType[]): CreditCardType | null {
  if (!hasEnoughResultsToDetermineBestMatch(results)) {
    return null
  }

  return results.reduce((bestMatch, result) => {
    if (!bestMatch) {
      return result
    }

    /*
     * If the current best match pattern is less specific than this result, set
     * the result as the new best match
     * */
    if (Number(bestMatch.matchStrength) < Number(result.matchStrength)) {
      return result
    }

    return bestMatch
  })
}

function clone<T>(originalObject: T): T | null {
  if (!originalObject) {
    return null
  }

  return JSON.parse(JSON.stringify(originalObject))
}

function addMatchingCardsToResults(
  cardNumber: string,
  cardConfiguration: CreditCardType,
  results: Array<CreditCardType>
): void {
  let i, patternLength

  for (i = 0; i < cardConfiguration.patterns.length; i++) {
    const pattern = cardConfiguration.patterns[i]

    if (!matches(cardNumber, pattern)) {
      continue
    }

    const clonedCardConfiguration = clone(cardConfiguration) as CreditCardType

    if (Array.isArray(pattern)) {
      patternLength = String(pattern[0]).length
    } else {
      patternLength = String(pattern).length
    }

    if (cardNumber.length >= patternLength) {
      clonedCardConfiguration.matchStrength = patternLength
    }

    results.push(clonedCardConfiguration)
    break
  }
}

function isValidInputType<T>(cardNumber: T): boolean {
  return typeof cardNumber === 'string' || cardNumber instanceof String
}

const cardNames: Record<string, CreditCardTypeCardBrandId> = {
  VISA: 'visa',
  MC: 'mc',
  AMEX: 'amex',
  DINERS: 'diners',
  DISC: 'disc',
  JCB: 'jcb',
  MAESTRO: 'maestro',
  MIR: 'mir',
}

const ORIGINAL_TEST_ORDER = [
  cardNames.VISA,
  cardNames.MC,
  cardNames.AMEX,
  cardNames.DINERS,
  cardNames.DISC,
  cardNames.JCB,
  cardNames.MAESTRO,
  cardNames.MIR,
]

const testOrder = clone(ORIGINAL_TEST_ORDER) as string[]

function findType(cardType: string | number): CreditCardType {
  return cardTypes[cardType]
}

export function getCreditCardType(cardNumber: string): Array<CreditCardType> {
  const results = [] as CreditCardType[]

  if (!isValidInputType(cardNumber)) {
    return results
  }

  if (cardNumber.length === 0) {
    return results
  }

  testOrder.forEach((cardType) => {
    const cardConfiguration = findType(cardType)

    addMatchingCardsToResults(cardNumber, cardConfiguration, results)
  })

  const bestMatch = findBestMatch(results) as CreditCardType

  if (bestMatch) {
    return [bestMatch]
  }

  return results
}

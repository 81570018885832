import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { FieldGroupContainer, Input, DatePicker } from '@shared/components'

interface FormData {
  first_name: string
  last_name: string
  account_number: string
  date_of_birth: string
}

const CustomerDetails = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>()

  return (
    <FieldGroupContainer title={t('mfe-gateway.customer-details')}>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item sm={12} md={6}>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="first-name-input"
                label={t('common.name-first')}
                placeholder={t('common.name-first-placeholder')}
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
                guidingId={'customers-firstname'}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="last-name-input"
                label={t('common.name-last')}
                placeholder={t('common.name-last-placeholder')}
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
                required
                guidingId={'customers-lastname'}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Controller
            name="account_number"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="customer-number-input"
                label={t('mfe-gateway.customer-number')}
                placeholder={t('mfe-gateway.customer-number-placeholder')}
                error={!!errors.account_number}
                helperText={errors.account_number?.message}
                guidingId={'customers-accountnumber'}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Controller
            name="date_of_birth"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                testId="date-of-birth-input"
                label={t('mfe-gateway.date-of-birth')}
                error={!!errors.date_of_birth}
                helperText={errors.date_of_birth?.message}
                guidingId={'customers-dateofbirth'}
              />
            )}
          />
        </Grid>
      </Grid>
    </FieldGroupContainer>
  )
}

export default CustomerDetails

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Link, Typography } from '@mui/material'

import { Transaction, api } from '@shared/api'
import {
  Confirmation,
  Loading,
  VoidTransactionModal,
  TransactionDetailsModal,
  EmailReceiptModal,
} from '@shared/components'
import appTheme from '@shared/design'
import {
  useAuthorization,
  useEnforceLogin,
  useNotification,
} from '@shared/hooks'
import { checkPermission } from '@shared/utils'

const useStyles = tss
  .withName('TransactionConfirmation')
  .create(({ theme }) => ({
    link: {
      color: theme.palette['neutral-700'],
      fontWeight: 600,
      textDecorationColor: theme.palette['neutral-700'],
      ':hover': {
        cursor: 'pointer',
      },
    },
  }))

const TransactionConfirmation = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { user } = useEnforceLogin()
  const { userPermissionSet } = useAuthorization()
  const { setNotification } = useNotification()
  const navigate = useNavigate()

  const { transactionId } = useParams<{ transactionId: string }>()

  const [loading, setLoading] = useState(true)
  const [transaction, setTransaction] = useState<Transaction>()
  const [openDetails, setOpenDetails] = useState(false)
  const [openEmailReceipt, setOpenEmailReceipt] = useState(false)
  const [openVoidTransaction, setOpenVoidTransaction] = useState(false)

  const hasError =
    transaction?.status_code &&
    transaction?.status_code >= 300 &&
    transaction?.status_code < 400

  const backToRunTransaction = () => {
    const backUrl = window.location.pathname.split('/confirmation')[0]
    navigate(backUrl)
  }
  const handlePrintReceipt = () => {
    const printUrl = `/print/transaction/${transactionId}`
    window.open(printUrl, '_blank')
  }

  const getTransaction = async () => {
    if (!transactionId) return

    setLoading(true)
    try {
      const transaction = await api.service('transactions').get(transactionId)
      setTransaction(transaction)
    } catch (e) {
      setNotification({
        type: 'error',
        label: t('common.toast.transaction.not-found'),
      })
    } finally {
      setLoading(false)
    }
  }

  const onReprintTerminal = async (transactionId: string) => {
    if (!transactionId) return

    await api.service('transactions').reprintReceipt(transactionId)

    setNotification({
      type: 'success',
      label: t('mfe-gateway.toast.transaction.reprint-on-terminal'),
    })
  }

  const onVoidSuccess = () => {
    setNotification({
      type: 'success',
      label: t('common.toast.transaction.void-success'),
    })
    getTransaction()
  }

  const onVoidError = () => {
    setNotification({
      type: 'error',
      label: t('common.toast.transaction.could-not-be-voided'),
    })
  }

  useEffect(() => {
    getTransaction()
  }, [transactionId])

  const details = (
    <Typography>
      {`${t('common.you-can-view')} `}
      <Link
        component="span"
        onClick={() => setOpenDetails(true)}
        className={classes.link}
      >
        {t('common.transaction-details')}
      </Link>
      {` ${t('common.or')} `}
      <Link
        component="span"
        onClick={() => backToRunTransaction()}
        className={classes.link}
      >
        {t('common.click-here')}
      </Link>
      {` ${t('mfe-gateway.transaction.to-process-another-transaction')}.`}
    </Typography>
  )

  const verbiage = !!transaction?.auth_code
    ? `${t('common.auth-code')} ${transaction.auth_code}`
    : undefined

  const buttons = [
    {
      label: t('common.action.transaction.void'),
      onClick: () => setOpenVoidTransaction(true),
      show:
        !!transaction?.is_voidable &&
        checkPermission(userPermissionSet, 'v2.transactions.put.void'),
    },
    {
      label: t('mfe-gateway.action.print-receipt'),
      onClick: handlePrintReceipt,
      show: true,
    },
    {
      label: t('common.action.transaction.email-receipt'),
      onClick: () => setOpenEmailReceipt(true),
      show: true,
    },
    {
      label: t('common.action.transaction.reprint-on-terminal'),
      onClick: () => onReprintTerminal(transactionId),
      show:
        transaction?.terminal?.print_enable &&
        transaction?.terminal?.terminal_manufacturer_code.toString() !== '1',
    },
  ]

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <TransactionDetailsModal
        isModalOpen={openDetails}
        onClose={() => setOpenDetails(false)}
        transactionId={transactionId}
        tz={user.tz}
        onReprintTerminal={onReprintTerminal}
      />
      <VoidTransactionModal
        transaction={transaction}
        open={openVoidTransaction}
        onClose={() => setOpenVoidTransaction(false)}
        onSuccess={onVoidSuccess}
        onError={onVoidError}
      />
      <EmailReceiptModal
        transaction={transaction}
        open={openEmailReceipt}
        onClose={() => setOpenEmailReceipt(false)}
      />
      <Confirmation
        title={
          hasError
            ? transaction.status.title
            : t('mfe-gateway.transaction.payment-successfully-processed')
        }
        verbiage={verbiage}
        details={details}
        buttons={buttons}
        icon={
          hasError ? (
            <ErrorOutlineIcon
              style={{
                color: appTheme.palette.negative,
              }}
            />
          ) : undefined
        }
      />
    </>
  )
}

export default TransactionConfirmation

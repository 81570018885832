import { Location, MerchantAccount } from '../../../api'
import { PaymentMethodEbt } from '../../../types'
import { getVirtualTerminalMerchantAccounts } from '../../virtual-terminal/get-virtual-terminal-merchant-accounts/getVirtualTerminalMerchantAccounts'

/**
 *
 * Get the Default Merchant Account for the specified Location.
 *
 * If Payment Method is provided, the Default Merchant Account of that respective
 * Payment Method will be returned.
 *
 * If Payment Method isn't provided, the returned Default Merchant Account will be the first
 * to be available of one of the following: Location Default CC, or Location Default ACH,
 * or the first match to be active and vt_enable, in that same order.
 *
 * @param location Location data.
 * @param paymentMethod Payment Method of the Default Merchant Account.
 */
export const getDefaultMerchantAccount = (
  location: Location,
  paymentMethod?: MerchantAccount['payment_method'] | PaymentMethodEbt
): MerchantAccount | null => {
  if (!location?.product_transactions) return null

  const merchantAccountsRecord = getVirtualTerminalMerchantAccounts(
    location
  ).reduce<{
    [id: string]: MerchantAccount
  }>((record, account) => ({ ...record, [account.id as string]: account }), {})

  if (
    (!paymentMethod || paymentMethod === 'cc') &&
    !!location.default_cc &&
    merchantAccountsRecord[location.default_cc]?.vt_enable
  ) {
    return merchantAccountsRecord[location.default_cc]
  }

  if (
    paymentMethod === 'ebt' &&
    !!location.default_cc &&
    merchantAccountsRecord[location.default_cc]?.vt_enable &&
    merchantAccountsRecord[location.default_cc]?.card_type_ebt
  ) {
    return merchantAccountsRecord[location.default_cc]
  }

  if ((!paymentMethod || paymentMethod === 'ach') && !!location.default_ach) {
    const defaultACHAccount = merchantAccountsRecord[location.default_ach]

    if (
      defaultACHAccount?.vt_enable &&
      (defaultACHAccount?.ach_allow_debit ||
        defaultACHAccount?.ach_allow_credit)
    ) {
      return merchantAccountsRecord[location.default_ach]
    }
  }

  return (
    location.product_transactions?.find((merchantAccount) => {
      if (
        !merchantAccount.active ||
        (merchantAccount.payment_method === 'cc' && !merchantAccount.vt_enable)
      ) {
        return false
      }

      const isValidPaymentMethod =
        !paymentMethod || merchantAccount.payment_method === paymentMethod

      if (merchantAccount.payment_method === 'ach') {
        return (
          isValidPaymentMethod &&
          (merchantAccount.ach_allow_debit || merchantAccount.ach_allow_credit)
        )
      }

      if (paymentMethod === 'ebt') {
        if (merchantAccount.card_type_ebt) {
          if (
            merchantAccount.allow_ebt_cash_benefit ||
            merchantAccount.allow_ebt_food_stamp
          ) {
            return true
          }
        }
      }

      return isValidPaymentMethod
    }) ?? null
  )
}

import React, { forwardRef } from 'react'

import { SlideProps } from '@mui/material'
import Slide from '@mui/material/Slide'

export const Transition = forwardRef(function Transition(
  props: SlideProps,
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />
})

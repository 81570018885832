import { useTranslation } from 'react-i18next'

import { QuickInvoice } from '@shared/api/src'
import { StatusProps, Status } from '@shared/components'
import { PaymentStatusCodesType, paymentStatusCodes } from '@shared/types'

interface PaymentStatusProps {
  statusCode: QuickInvoice['payment_status_id']
  statusMapping?: Record<string, PaymentStatusCodesType>
}

const PaymentStatus = ({
  statusCode,
  statusMapping = paymentStatusCodes,
}: PaymentStatusProps) => {
  const { t } = useTranslation()

  const getStatus = (status: PaymentStatusCodesType): StatusProps => {
    let statusData: StatusProps = {
      text: undefined,
      backgroundColor: undefined,
    }

    switch (status) {
      case PaymentStatusCodesType.PARTIALLY:
        statusData.text = t('common.partial')
        statusData.backgroundColor = 'yellow'
        break
      case PaymentStatusCodesType.PAID:
        statusData.text = t('common.paid')
        statusData.backgroundColor = 'teal'
        break
      case PaymentStatusCodesType.UNPAID:
        statusData.text = t('common.unpaid')
        statusData.backgroundColor = 'red'
        break
      default:
        statusData.text = t('common.unknown')
        statusData.backgroundColor = 'gray'
        break
    }

    return statusData
  }

  const status = statusMapping[statusCode]
  const { text, backgroundColor } = getStatus(status)

  return <Status text={text} backgroundColor={backgroundColor} />
}

export default PaymentStatus

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { AlertColor, Box, Link } from '@mui/material'

import { api, Transaction, Recurring, Contact, Token } from '@shared/api'
import {
  Loading,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutContainerSide,
  Button,
  ButtonBar,
  ButtonBarStart,
  ButtonBarEnd,
  Notification,
  OtherInfo,
} from '@shared/components'
import {
  useEnforceLogin,
  useFtpPortalHubCommunication,
  useAuthorization,
  useLocations,
  usePub,
  useNotification,
} from '@shared/hooks'
import { checkPermission } from '@shared/utils'

import { CUSTOMER_DETAILS_WALLET_CHANGE_EVENT } from '@/components/customer-details/CustomerDetails'
import { CustomerDetailsView } from '@/components/customer-details-view/CustomerDetailsView'
import PaymentHistory from '@/components/payment-history/PaymentHistory'

import AdvancedSettings from './components/advanced-settings/AdvancedSettings'
import MerchantAccountDetails from './components/merchant-account-details/MerchantAccountDetails'
import OptionalInformation from './components/optional-information/OptionalInformation'
import RecurrenceSettings from './components/recurrence-settings/RecurrenceSettings'
import { RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE_EVENT } from '../components/merchant-account-details/MerchantAccountDetails'
import DeferPayment from '../components/modals/defer-payment/DeferPayment'
import EndPayment from '../components/modals/end-payment/EndPayment'
import PausePayment from '../components/modals/pause-payment/PausePayment'
import ResumePayment from '../components/modals/resume-payment/ResumePayment'
import SkipPayment from '../components/modals/skip-payment/SkipPayment'
import RecurrenceSummary from '../components/recurrence-summary/RecurrenceSummary'
import RecurringPaymentDetails from '../components/recurring-payment-details/RecurringPaymentDetails'

const useStyles = tss.withName('RecurringBillingView').create(({ theme }) => ({
  link: {
    color: `${theme?.palette['network-blue']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    textDecoration: 'underline',
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default function RecurringBillingView() {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { id } = useParams()
  const { user } = useEnforceLogin()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { selectedLocation } = useLocations()
  const { userPermissionSet } = useAuthorization()
  const { setNotification } = useNotification()
  const navigate = useNavigate()
  const publish = usePub()

  const [recurringData, setRecurringData] = useState<Recurring | null>(null)
  const [customer, setCustomer] = useState<Contact | null>(null)
  const [wallet, setWallet] = useState<Token | null>(null)

  const [isLoading, setIsLoading] = useState(!recurringData)

  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showRecurringDefer, setShowRecurringDefer] = useState<boolean>(false)
  const [showRecurringEnd, setShowRecurringEnd] = useState<boolean>(false)
  const [showRecurringPause, setShowRecurringPause] = useState<boolean>(false)
  const [showRecurringResume, setShowRecurringResume] = useState<boolean>(false)
  const [showRecurringSkip, setShowRecurringSkip] = useState<boolean>(false)

  const hasProductRecurringService = !!selectedLocation.product_recurring

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.action.recurring-billing.view'),
      null,
      [t('common.gateway'), t('common.recurring-billing')],
      '/merchant/gateway/recurring-billing'
    )
  }, [])

  useEffect(() => {
    getRecurringBilling()
  }, [id])

  const getRecurringBilling = async () => {
    if (!id) {
      navigate('/merchant/gateway/recurring-billing')
      return
    }
    setIsLoading(true)

    try {
      const recurringData = await api.service('recurring-billings').get(id)

      const transaction_amount = recurringData?.transaction_amount / 100

      setRecurringData({ ...recurringData, transaction_amount })

      if (recurringData?.contact_id) {
        await api
          .service('contacts')
          .get(recurringData?.contact_id)
          .then(setCustomer)
      }

      await api.service('tokens').get(recurringData?.token_id).then(setWallet)

      setIsLoading(false)

      // Publish events for RecurrenceSummary. Order matters.
      publish(CUSTOMER_DETAILS_WALLET_CHANGE_EVENT, recurringData.account_vault)
      publish(
        RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE_EVENT,
        recurringData.product_transaction
      )
    } catch (error) {
      setIsLoading(false)

      setNotification({
        label: t('mfe-gateway.toast.recurring-billing.not-found'),
        type: 'error',
      })
    }
  }
  const displayToastAndGetRecurringBilling = (
    message: string,
    isError = false
  ) => {
    setNotification({
      label: message,
      type: isError ? 'error' : 'success',
    })

    getRecurringBilling()
  }

  const getButtonStartConfigs = () => [
    {
      label: t('common.end'),
      color: 'secondary',
      disabled: false,
      onClick: async () => {
        setShowRecurringEnd(true)
      },
      condition:
        recurringData?.active &&
        checkPermission(userPermissionSet, 'v2.recurrings.delete') &&
        recurringData?.status !== 'ended',
      guidingId: 'recurringbilling-end',
    },
    {
      label: t('common.pause'),
      color: 'secondary',
      disabled: false,
      onClick: async () => {
        setShowRecurringPause(true)
      },
      condition:
        recurringData?.active &&
        recurringData?.status === 'active' &&
        recurringData?.recurring_type_id === 'o' &&
        hasProductRecurringService,
      guidingId: 'recurringbilling-pause',
    },
    {
      label: t('common.resume'),
      color: 'secondary',
      disabled: false,
      onClick: () => {
        setShowRecurringResume(true)
      },
      condition:
        recurringData?.active &&
        recurringData?.status === 'on hold' &&
        recurringData?.recurring_type_id === 'o' &&
        hasProductRecurringService,
      guidingId: 'recurringbilling-resume',
    },
    {
      label: t('common.skip'),
      color: 'secondary',
      disabled: false,
      onClick: () => {
        setShowRecurringSkip(true)
      },
      condition:
        recurringData?.active &&
        recurringData?.status === 'active' &&
        hasProductRecurringService,
      guidingId: 'recurringbilling-skip',
    },
    {
      label: t('mfe-gateway.action.recurring-billing.defer-uppercase'),
      color: 'secondary',
      disabled: false,
      onClick: () => {
        setShowRecurringDefer(true)
      },
      condition:
        recurringData?.active &&
        recurringData?.recurring_type_id === 'i' &&
        recurringData?.status === 'active',
      guidingId: 'recurringbilling-defer',
    },
  ]

  const getButtonEndConfigs = () => [
    {
      label: t('common.edit'),
      color: 'primary',
      disabled: false,
      onClick: () => editRecurring(),
      condition:
        checkPermission(userPermissionSet, 'v2.recurrings.put') &&
        hasProductRecurringService &&
        recurringData?.status !== 'ended',
      guidingId: 'recurringbilling-edit',
    },
  ]

  const shouldRenderButtonBar = () => {
    const startConfigs = getButtonStartConfigs()
    const endConfigs = getButtonEndConfigs()

    return (
      startConfigs.some((button) => button.condition) ||
      endConfigs.some((button) => button.condition)
    )
  }

  const editRecurring = () => {
    navigate(`/merchant/gateway/recurring-billing/${id}/edit`, {
      state: {
        recurringData,
        backUrl: `/merchant/gateway/recurring-billing/${id}/view`,
      },
    })
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {shouldRenderButtonBar() && (
            <ButtonBar>
              <ButtonBarStart>
                {getButtonStartConfigs().map((button, index) =>
                  button.condition ? (
                    <Button
                      key={index}
                      label={button.label}
                      color={button.color as 'primary' | 'secondary'}
                      disabled={button.disabled}
                      onClick={button.onClick}
                      style={{ marginRight: '10px' }}
                      guidingId={button.guidingId}
                    />
                  ) : null
                )}
              </ButtonBarStart>

              <ButtonBarEnd>
                {getButtonEndConfigs().map((button, index) =>
                  button.condition ? (
                    <Button
                      key={index}
                      label={button.label}
                      color={button.color as 'primary' | 'secondary'}
                      disabled={button.disabled}
                      onClick={button.onClick}
                      style={{ marginRight: '10px' }}
                      guidingId={button.guidingId}
                    />
                  ) : null
                )}
              </ButtonBarEnd>
            </ButtonBar>
          )}
          <PageLayoutContainer>
            <PageLayoutContainerMain>
              <MerchantAccountDetails recurringData={recurringData} />

              <RecurrenceSettings recurringData={recurringData} />

              <OptionalInformation recurringData={recurringData} />

              <AdvancedSettings recurringData={recurringData} />

              <OtherInfo
                createdDate={recurringData?.created_ts}
                createdBy={recurringData?.created_user?.email}
                timezone={user?.tz}
                section="recurring-billing"
              />

              <Box>
                <PaymentHistory
                  canViewTransactions={checkPermission(
                    userPermissionSet,
                    'v2.transactions.get'
                  )}
                  paymentData={recurringData?.transactions as Transaction[]}
                  onRefresh={async () => {
                    await getRecurringBilling()
                  }}
                />
              </Box>
            </PageLayoutContainerMain>
            <PageLayoutContainerSide>
              <CustomerDetailsView customer={customer} wallet={wallet} />{' '}
              <RecurrenceSummary recurringData={recurringData} />
              <Box className={classes.link}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => setShowDetails(true)}
                  data-guiding-id="recurringbilling-authorizationagreement"
                >
                  {t('mfe-gateway.authorization-agreement')}
                </Link>
              </Box>
            </PageLayoutContainerSide>
          </PageLayoutContainer>

          {showDetails && (
            <RecurringPaymentDetails
              recurringId={recurringData?.id}
              isModalOpen={showDetails}
              tz={user?.tz}
              onClose={() => setShowDetails(false)}
            />
          )}

          <PausePayment
            recurring={recurringData}
            open={showRecurringPause}
            onClose={() => setShowRecurringPause(false)}
            onCompleted={displayToastAndGetRecurringBilling}
          />

          <ResumePayment
            recurring={recurringData}
            open={showRecurringResume}
            onClose={() => setShowRecurringResume(false)}
            onCompleted={displayToastAndGetRecurringBilling}
          />

          <SkipPayment
            recurring={recurringData}
            open={showRecurringSkip}
            onClose={() => setShowRecurringSkip(false)}
            onCompleted={displayToastAndGetRecurringBilling}
          />

          <DeferPayment
            recurring={recurringData}
            open={showRecurringDefer}
            onClose={() => setShowRecurringDefer(false)}
            onCompleted={displayToastAndGetRecurringBilling}
          />

          <EndPayment
            recurring={recurringData}
            open={showRecurringEnd}
            onClose={() => setShowRecurringEnd(false)}
            onCompleted={displayToastAndGetRecurringBilling}
          />
        </>
      )}
    </>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { Contact, Token, api } from '@shared/api'
import {
  ActionModal,
  Notification,
  NotificationProps,
} from '@shared/components'
import { useNotification } from '@shared/hooks'
import { accountTypeLabel } from '@shared/types'
import { toArrayFieldErrors } from '@shared/utils'

export interface ToggleWalletActivationModalProps {
  wallet: Token
  customer: Contact
  open: boolean
  onClose?: () => void | Promise<void>
  onSuccess?: () => void | Promise<void>
}

export const ToggleWalletActivationModal: React.FC<
  ToggleWalletActivationModalProps
> = ({ wallet, customer, open, onClose, onSuccess }) => {
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit = async () => {
    setIsLoading(true)

    try {
      !!wallet.active
        ? await api.service('tokens').remove(wallet.id)
        : await api.service('tokens').activate(wallet.id)

      setNotification({
        type: 'success',
        label: `${t('mfe-gateway.token.wallet-successfully')} ${
          !!wallet.active ? t('common.deactivated') : t('common.reactivated')
        } `,
      })

      onClose?.()
      onSuccess?.()
    } catch (error) {
      const errors = toArrayFieldErrors(error)
      const messages = errors.map((error) => error.message)

      setNotification({
        type: 'error',
        label: messages.join(' | '),
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ActionModal
      open={open}
      title={
        !!wallet.active
          ? t('mfe-gateway.token.deactivate-wallet')
          : t('mfe-gateway.token.reactivate-wallet')
      }
      buttons={[
        {
          testId: 'cancel-button',
          label: t('common.cancel'),
          color: 'secondary',
          onClick: onClose,
          guidingId: 'token-activation-cancel',
        },
        {
          testId: 'submit-button',
          type: 'submit',
          label: !!wallet.active
            ? t('common.action.yes-deactivate')
            : t('common.action.yes-reactivate'),
          isLoading,
          onClick: onSubmit,
          guidingId: 'token-activation-submit',
        },
      ]}
      onClose={onClose}
    >
      <Typography sx={{ fontSize: '14px' }} variant="body2">
        {`${t('common.modal.are-you-sure')} ${
          !!wallet.active ? t('common.deactivate') : t('common.reactivate')
        } ${t('mfe-gateway.the-wallet')} `}
        <strong>
          {accountTypeLabel[wallet.account_type]} • {wallet.last_four}
        </strong>
        {` ${t('mfe-gateway.from-customer')} `}
        <strong>
          {customer.first_name} {customer.last_name}
        </strong>
        ?
      </Typography>
    </ActionModal>
  )
}

import { useTranslation } from 'react-i18next'

import { Transaction } from '@shared/api/src'
import { Status, StatusProps } from '@shared/components'
import { codeToTransactionStatus } from '@shared/mapping/gateway-transactions'
import { TransactionStatusType } from '@shared/types'

interface TransactionStatusProps {
  statusCode: Transaction['status_code']
  testID?: string
}

const TransactionStatus = ({ statusCode }: TransactionStatusProps) => {
  const { t } = useTranslation()

  const getStatus = (status: TransactionStatusType): StatusProps => {
    let statusData: StatusProps = {
      text: undefined,
      backgroundColor: undefined,
    }

    switch (status) {
      case TransactionStatusType.PENDING_ORIGINATION:
        statusData.text = t('common.pending-origination')
        statusData.backgroundColor = 'yellow'
        break
      case TransactionStatusType.CASH_VOIDED:
        statusData.text = t('common.cash-voided')
        statusData.backgroundColor = 'yellow'
        break
      case TransactionStatusType.VOIDED:
        statusData.text = t('common.voided')
        statusData.backgroundColor = 'yellow'
        break
      case TransactionStatusType.CASH_APPROVED:
        statusData.text = t('common.cash-approved')
        statusData.backgroundColor = 'teal'
        break
      case TransactionStatusType.APPROVED:
        statusData.text = t('common.approved')
        statusData.backgroundColor = 'teal'
        break
      case TransactionStatusType.CASH_REFUNDED:
        statusData.text = t('common.cash-refunded')
        statusData.backgroundColor = 'teal'
        break
      case TransactionStatusType.REFUNDED:
        statusData.text = t('common.refunded')
        statusData.backgroundColor = 'teal'
        break
      case TransactionStatusType.AUTH_ONLY:
        statusData.text = t('common.auth-only')
        statusData.backgroundColor = 'sky'
        break
      case TransactionStatusType.DECLINED:
        statusData.text = t('common.declined')
        statusData.backgroundColor = 'red'
        break
      case TransactionStatusType.AVSONLY:
        statusData.text = t('common.avsonly')
        statusData.backgroundColor = 'sky'
        break
      default:
        statusData.text = t('common.unknown')
        statusData.backgroundColor = 'gray'
        break
    }
    return statusData
  }

  const { text, backgroundColor } = getStatus(
    codeToTransactionStatus(statusCode)
  )

  return <Status text={text} backgroundColor={backgroundColor} />
}

export default TransactionStatus

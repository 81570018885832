export const transformFiltersForStorage = (
  filter: Record<string, any>
): Record<string, any> => {
  const transformed: Record<string, any> = {}
  if (filter) {
    Object.keys(filter).forEach((key) => {
      const value = filter[key]

      if (value.filterType === 'number') {
        transformed[key] = {
          filterType: 'number',
          type: 'equals',
          filter: Math.round(parseFloat(value.filter)),
        }
      } else if (value.filterType === 'date') {
        if (value.filter) {
          transformed[key] = {
            filterType: 'date',
            type: 'equals',
            filter: value.filter,
          }
        } else {
          transformed[key] = {
            filterType: 'date',
            type: 'equals',
            dateFrom: value.dateFrom,
          }
        }
      } else if (value.filterType === 'set') {
        transformed[key] = {
          filterType: 'set',
          values: value.values.map(String),
        }
      } else {
        transformed[key] = {
          filterType: 'text',
          type: 'equal',
          filter: value.filter,
        }
      }
    })
  }
  return transformed
}

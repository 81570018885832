import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { FieldGroupRow, FieldGroupViewItem } from '@shared/components'
import { useEnforceLogin } from '@shared/hooks'
import { currency, formatDate } from '@shared/utils'

import { QuickInvoiceFormData } from '../quick-invoice-add/QuickInvoiceAdd'

const useStyles = tss.withName('QuickInvoiceSummary').create(({ theme }) => ({
  root: {
    height: 'fit-content',
    width: '100%',
    padding: '1em',
    backgroundColor: 'rgb(243 244 246 / 1)',
    border: '1px solid rgb(229 231 235 / 1)',
    borderRadius: '.5em',
    marginTop: '24px',
  },
  title: {
    color: theme.palette['neutral-900'],
    fontSize: '20px',
    lineHeight: '28px',
    fontFamily: 'Inter',
    fontWeight: 500,
    marginBottom: '24px',
  },
}))

export default function QuickInvoiceSummary() {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { user } = useEnforceLogin()

  const { watch } = useFormContext<QuickInvoiceFormData>()

  const invoiceNumber = watch('invoice_number')
  const dueDate = formatDate(watch('due_date'), 'UTC')
  const expireDate = formatDate(watch('expire_date'), 'UTC')
  const items = watch('item_list')

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        {t('mfe-gateway.quick-invoice.invoice-summary')}
      </Typography>

      <FieldGroupRow>
        <FieldGroupViewItem
          isFullWidth
          isValueAlignRight
          title={t('common.invoice-number')}
          value={invoiceNumber}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          isFullWidth
          isValueAlignRight
          title={t('common.due-date')}
          value={dueDate}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          isFullWidth
          isValueAlignRight
          title={t('common.expiration-date')}
          value={expireDate}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          isFullWidth
          isValueAlignRight
          title={t('common.total-amount-due')}
          value={currency(
            items?.reduce((acc, item) => acc + item.amount, 0),
            true
          )}
        />
      </FieldGroupRow>
    </Box>
  )
}

import { DateTime } from 'luxon'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  Box,
  TextField,
  Divider,
  List,
  Paper,
  ListItemButton,
} from '@mui/material'

import { Button } from '@shared/components'
import AppTheme from '@shared/design'
import { useEnforceLogin } from '@shared/hooks'

const useStyles = tss.withName('FutureDateRangeFilter').create(() => ({
  customListItemText: {
    color: '#495057',
    fontSize: '0.875rem',
  },
  textFieldCentered: {
    width: '92%',
    marginTop: '10px',
    marginLeft: '10px',
    '.MuiInputBase-input': {
      fontSize: '0.875rem',
      color: '#495057',
      textAlign: 'center',
    },
  },
}))

const FutureDateRangeFilter = forwardRef((props: any, ref) => {
  const {
    typeOfSearch = 'equal',
    filterType = 'text',
    onApply,
    filterPropName,
  } = props
  const { user } = useEnforceLogin()
  const { t } = useTranslation()
  const { classes } = useStyles(AppTheme)
  const [selectedRange, setSelectedRange] = useState<any>()
  const [dateRangeText, setDateRangeText] = useState('')
  const [selectedPreview, setSelectedPreview] = useState<any>()

  const paperRef = useRef(null)

  const valueMap = {
    today: 'common.filter-today',
    tomorrow: 'common.filter-tomorrow',
    'this week': 'common.filter-this-week',
    'next week': 'common.filter-next-week',
    'this month': 'common.filter-this-month',
    'next month': 'common.filter-next-month',
    'next 30 days': 'common.filter-next-30-days',
  }

  useImperativeHandle(ref, () => ({
    isFilterActive: () => {
      return selectedRange !== ''
    },
    doesFilterPass: (params) => {
      return (
        typeof selectedRange === 'string' &&
        selectedRange.includes(params.value)
      )
    },
    getModel: () => {
      if (typeof selectedRange?.filter === 'object' && selectedRange !== null) {
        if (
          selectedRange &&
          selectedRange.filter &&
          selectedRange.filter.$gte &&
          selectedRange.filter.$lte
        ) {
          const { $gte, $lte } = selectedRange.filter
          let filter = {
            $gte: $gte,
            $lte: $lte,
          }

          if (filterPropName === 'due_date') {
            setDateRangeText(
              formatDateRange(
                DateTime.fromSeconds(filter.$gte),
                DateTime.fromSeconds(filter.$lte)
              )
            )
          } else {
            filter = {
              $gte: Math.floor(
                DateTime.fromSeconds($gte).startOf('day').toMillis() / 1000
              ),
              $lte: Math.floor(
                DateTime.fromSeconds($lte).endOf('day').toMillis() / 1000
              ),
            }
            setDateRangeText(
              formatDateRange(
                DateTime.fromSeconds(filter.$gte),
                DateTime.fromSeconds(filter.$lte)
              )
            )
          }

          return {
            type: typeOfSearch,
            filterType: filterType,
            filter: filter,
          }
        }
      } else if (selectedRange?.start && selectedRange?.end) {
        let filter

        if (selectedRange?.start?.ts && selectedRange?.end?.ts) {
          const startDateTime = DateTime.fromJSDate(
            new Date(selectedRange.start.ts)
          )
          const endDateTime = DateTime.fromJSDate(
            new Date(selectedRange.end.ts)
          )
          filter = {
            $gte: Math.floor(startDateTime.startOf('day').toMillis() / 1000),
            $lte: Math.floor(endDateTime.endOf('day').toMillis() / 1000),
          }
        } else {
          filter = {
            $gte: Math.floor(
              selectedRange.start.startOf('day').toMillis() / 1000
            ),
            $lte: Math.floor(selectedRange.end.endOf('day').toMillis() / 1000),
          }
        }

        setDateRangeText(
          formatDateRange(
            DateTime.fromSeconds(filter.$gte),
            DateTime.fromSeconds(filter.$lte)
          )
        )

        return {
          type: typeOfSearch,
          filterType: filterType,
          filter: filter,
        }
      } else if (selectedRange) {
        let handleSelectedRange = ''
        if (
          typeof selectedRange === 'object' &&
          selectedRange !== null &&
          'filter' in selectedRange
        ) {
          handleSelectedRange = selectedRange.filter.toString()
        } else if (typeof selectedRange === 'string') {
          handleSelectedRange = selectedRange
        }

        if (handleSelectedRange) {
          updateDateRangeText(handleSelectedRange)
          return {
            type: typeOfSearch,
            filterType: filterType,
            filter: handleSelectedRange.toLowerCase(),
          }
        }
      }
    },

    setModel(model) {
      if (model) {
        setSelectedRange(model)
        props.filterChangedCallback()
      } else {
        handleClear()
      }
    },
    setFilter: (filter) => {
      updateDateRangeText(filter)
    },
    getModelAsString: () => {
      return dateRangeText
    },
  }))

  const handleClear = async () => {
    await setSelectedRange('')
    setSelectedPreview('')
    await setDateRangeText('')
    onApply(undefined)
    props.filterChangedCallback()
  }

  const handleApply = async () => {
    if (!selectedPreview) {
      return
    }

    if (selectedPreview?.start && selectedPreview?.end) {
      setSelectedRange('')
      await setDateRangeText(
        formatDateRange(selectedPreview.start, selectedPreview.end)
      )
      setSelectedRange({
        start: selectedPreview.start,
        end: selectedPreview.end,
      })
      setSelectedPreview('')
    } else {
      await setSelectedRange(selectedPreview)
      await setDateRangeText(selectedPreview)
      setSelectedPreview('')
    }
    props.filterChangedCallback()
  }

  const handleSelect = (range) => {
    setSelectedPreview(range)
    if (typeof range === 'string') {
      return
    } else {
      const { startDate, endDate } = calculateDateRange(range)
      setDateRangeText(formatDateRange(startDate, endDate))
    }
  }

  const calculateDateRange = (range) => {
    const today = DateTime.local().setZone(user.tz)
    let startDate, endDate

    switch (range) {
      case 'Today':
        startDate = endDate = today
        break
      case 'Tomorrow':
        startDate = endDate = today.minus({ days: 1 })
        break
      case 'This Week':
        startDate = today.startOf('week')
        endDate = today.endOf('week')
        break
      case 'Next Week':
        startDate = today.plus({ weeks: 1 }).startOf('week')
        endDate = today.plus({ weeks: 1 }).endOf('week')
        break
      case 'This Month':
        startDate = today.startOf('month')
        endDate = today.endOf('month')
        break
      case 'Next Month':
        startDate = today.plus({ months: 1 }).startOf('month')
        endDate = today.plus({ months: 1 }).endOf('month')
        break
      case 'Next 30 Days':
        startDate = today.plus({ days: 30 })
        endDate = today
        break
      default:
        startDate = endDate = null
        break
    }

    return { startDate, endDate }
  }

  const formatDateRange = (start, end) => {
    if (start && end) {
      if (start.toISODate() === end.toISODate()) {
        return start.toFormat('MM/dd/yyyy')
      }
      return `${start.toFormat('MM/dd/yyyy')} - ${end.toFormat('MM/dd/yyyy')}`
    }
    return ''
  }

  const isOptionSelected = (option) => {
    return (
      selectedPreview === option ||
      (!selectedPreview &&
        (selectedRange?.filter === option || selectedRange === option))
    )
  }

  const updateDateRangeText = (option) => {
    const translationKey = valueMap[option.toLowerCase()] || option
    setDateRangeText(t(translationKey))
  }

  const handleDateChange = (type, date) => {
    setSelectedPreview((prevRange) => {
      const updatedRange = { ...prevRange, [type]: date }
      // If changing the start date, clear end date if it is earlier than start date
      if (type === 'start' && updatedRange.end && date > updatedRange.end) {
        updatedRange.end = null
      }
      return updatedRange
    })
  }

  const updateDateRangeFromFilter = () => {
    const { $gte, $lte } = selectedRange.filter || {}
    if ($gte && $lte) {
      setSelectedRange({
        start: DateTime.fromSeconds($gte),
        end: DateTime.fromSeconds($lte),
      })
    }
  }

  useEffect(() => {
    if (selectedRange?.filter) {
      updateDateRangeFromFilter()
    }
  }, [selectedRange])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (paperRef.current && !paperRef.current.contains(event.target)) {
        setSelectedPreview('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={paperRef}>
      <Paper
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <>
          {Boolean(selectedRange?.start && !selectedPreview) ? (
            <TextField
              fullWidth
              variant="outlined"
              value={dateRangeText || ''}
              className={classes.textFieldCentered}
              InputProps={{
                readOnly: true,
              }}
              size="small"
            />
          ) : null}
          <Divider />
          <List component="nav" aria-label="date range options">
            <ListItemButton
              selected={isOptionSelected('today')}
              onClick={() => handleSelect('today')}
            >
              <span className={classes.customListItemText}>
                {t('common.filter-today')}
              </span>
            </ListItemButton>

            <ListItemButton
              selected={isOptionSelected('tomorrow')}
              onClick={() => handleSelect('tomorrow')}
            >
              <span className={classes.customListItemText}>
                {t('common.filter-tomorrow')}
              </span>
            </ListItemButton>

            <ListItemButton
              selected={isOptionSelected('this week')}
              onClick={() => handleSelect('this week')}
            >
              <span className={classes.customListItemText}>
                {t('common.filter-this-week')}
              </span>
            </ListItemButton>

            <ListItemButton
              selected={isOptionSelected('next week')}
              onClick={() => handleSelect('next week')}
            >
              <span className={classes.customListItemText}>
                {t('common.filter-next-week')}
              </span>
            </ListItemButton>

            <ListItemButton
              selected={isOptionSelected('this month')}
              onClick={() => handleSelect('this month')}
            >
              <span className={classes.customListItemText}>
                {t('common.filter-this-month')}
              </span>
            </ListItemButton>

            <ListItemButton
              selected={isOptionSelected('next month')}
              onClick={() => handleSelect('next month')}
            >
              <span className={classes.customListItemText}>
                {t('common.filter-next-month')}
              </span>
            </ListItemButton>

            <ListItemButton
              selected={isOptionSelected('next 30 days')}
              onClick={() => handleSelect('next 30 days')}
            >
              <span className={classes.customListItemText}>
                {t('common.filter-next-30-days')}
              </span>
            </ListItemButton>
          </List>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
            <Button
              label={t('common.clear')}
              onClick={handleClear}
              color={'secondary'}
              style={{
                width: '48%',
              }}
            />
            <Button
              label={t('common.apply')}
              onClick={handleApply}
              style={{
                width: '48%',
              }}
            />
          </Box>
          <Divider />
        </>
      </Paper>
    </div>
  )
})

export default FutureDateRangeFilter

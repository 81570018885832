import { MerchantAccount } from '../../../api/src/schemas/types'
import { PaymentMethodType } from '../../../types'
import { sortMerchantAccounts } from '../sort-merchant-accounts/sortMerchantAccounts'

export const filterMerchantAccount = (MerchantAccounts: MerchantAccount[]) => {
  const sortedMerchantAccounts = sortMerchantAccounts(MerchantAccounts)
  return Object.entries(sortedMerchantAccounts).map(([key, value]) => ({
    value: value.id,
    label: value.title,
  }))
}

export const filterCcMerchantAccounts = (
  MerchantAccounts: MerchantAccount[]
) => {
  const sortedMerchantAccounts = sortMerchantAccounts(MerchantAccounts)
  const ccMerchantAccounts = sortedMerchantAccounts.filter(
    (account) => account.payment_method === PaymentMethodType.CC
  )

  return Object.entries(ccMerchantAccounts).map(([key, value]) => ({
    value: value.id,
    label: value.title,
  }))
}

export const filterAchMerchantAccounts = (
  MerchantAccounts: MerchantAccount[]
) => {
  const sortedMerchantAccounts = sortMerchantAccounts(MerchantAccounts)
  const achMerchantAccounts = sortedMerchantAccounts.filter(
    (account) => account.payment_method === PaymentMethodType.ACH
  )

  return Object.entries(achMerchantAccounts).map(([key, value]) => ({
    value: value.id,
    label: value.title,
  }))
}

export const filterParams = (
  filters: Record<string, any>
): Record<string, any> => {
  return Object.entries(filters).reduce(
    (params: Record<string, any>, [key, value]) => {
      if (value !== null) {
        params[key] = value
      }
      return params
    },
    {}
  )
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Box, Grid, Typography } from '@mui/material'

import { MerchantAccount, Token, TransactionType } from '@shared/api'
import {
  SelectComponent as Select,
  Input,
  RadioButtons,
  RadioButtonsArray,
  CurrencyInput,
} from '@shared/components'
import {
  PubSubEvent,
  useAuthorization,
  useEnforceLogin,
  useLocations,
  usePub,
  useSub,
} from '@shared/hooks'
import { PaymentMethod, ProcessMethod, SelectOption } from '@shared/types'
import {
  getTransactionTypesForAccount,
  getTransactionTypesForPaymentMethod,
  getVirtualTerminalMerchantAccounts,
  getDefaultMerchantAccount,
  getDefaultTransactionType,
  checkPermission,
} from '@shared/utils'

import {
  CUSTOMER_DETAILS_WALLET_CHANGE_EVENT,
  CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT,
} from '../customer-details/CustomerDetails'
import { PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT } from '../payment-account-details/PaymentAccountDetails'

export const TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT = new Event(
  'TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE'
) as PubSubEvent<MerchantAccount | null>

export const TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT = new Event(
  'TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE'
) as PubSubEvent<TransactionType | null>

const useStyles = tss
  .withName('TransactionInformation')
  .create(({ theme }) => ({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    title: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter !important',
      fontSize: '18px !important',
      fontWeight: '500 !important',
      lineHeight: '28px !important',
    },
    multipleInputContainer: {
      display: 'grid',
      justifyItems: 'stretch',
      gridAutoFlow: 'column',
      gap: '8px',
    },
    button: {
      width: '100%',
      height: '44px',
    },
  }))

export const TransactionInformation = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { selectedLocation } = useLocations()
  const { user } = useEnforceLogin()
  const { userPermissionSet } = useAuthorization()
  const { walletId } = useParams()
  const publish = usePub()

  const {
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext()

  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null)

  const [selectedTransactionType, setSelectedTransactionType] =
    useState<TransactionType | null>(null)

  const [processMethod, setProcessMethod] = useState<ProcessMethod>()

  const hasSubtotal = useMemo(() => {
    if (!selectedMerchantAccount) return undefined

    return (
      !!selectedMerchantAccount?.surcharge ||
      !!selectedMerchantAccount?.vt_enable_sales_tax ||
      !!selectedMerchantAccount?.vt_enable_tip
    )
  }, [selectedMerchantAccount])

  useSub<typeof PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT>(
    PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT.type,
    ({ data: processMethod }) => {
      if (processMethod === 'terminal' && selectedTransactionType === 'force') {
        onChangeTransactionType('sale')
      }
      setProcessMethod(processMethod)
    },
    [selectedTransactionType]
  )

  useSub<typeof CUSTOMER_DETAILS_WALLET_CHANGE_EVENT>(
    CUSTOMER_DETAILS_WALLET_CHANGE_EVENT.type,
    ({ data: wallet }) => {
      if (!wallet) {
        return
      }
      if (
        selectedMerchantAccount &&
        merchantAccountsRecord[wallet.payment_method].length >= 1 &&
        wallet?.payment_method !== selectedMerchantAccount?.payment_method
      ) {
        onChangePaymentMethod(wallet.payment_method)
        onChangeMerchantAccount(
          getDefaultMerchantAccount(selectedLocation, wallet.payment_method)
        )
      }
    },
    [selectedMerchantAccount, walletId]
  )

  useSub<typeof CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT>(
    CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT.type,
    ({ data: customer }) => {
      // Hacky way to trigger the merchant account change event on scenarios where customer details load later than the transaction information
      // I don't like this, but it's the only way to make it work for now
      if (customer && selectedMerchantAccount) {
        publish(
          TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT,
          selectedMerchantAccount
        )
      }
    },
    [selectedMerchantAccount]
  )

  const onChangePaymentMethod = useCallback(
    (paymentMethod: PaymentMethod | null) => {
      // No need to publish the Payment Method
      // as its known through the selectedMerchantAccount.payment_method
      clearErrors()
      setSelectedPaymentMethod(paymentMethod)
    },
    []
  )

  const onChangeTransactionType = useCallback(
    (transactionType: TransactionType | null) => {
      clearErrors()
      if (transactionType !== 'force') {
        setValue('auth_code', undefined)
      }
      setSelectedTransactionType(transactionType)
    },
    []
  )

  const onChangeMerchantAccount = useCallback(
    (merchantAccount: MerchantAccount | null) => {
      if (!merchantAccount) return
      setSelectedMerchantAccount(merchantAccount)
      setValue('product_transaction_id', merchantAccount?.id ?? undefined)
      onChangeTransactionType(getDefaultTransactionType(user, merchantAccount))
      if (!selectedPaymentMethod) {
        onChangePaymentMethod(merchantAccount?.payment_method ?? null)
      }
    },
    [user, onChangeTransactionType, selectedPaymentMethod]
  )

  // Merchant Accounts Record were they're classified by payment method
  const merchantAccountsRecord: Record<PaymentMethod, MerchantAccount[]> =
    useMemo(() => {
      const record: Record<PaymentMethod, MerchantAccount[]> = {
        cc: [],
        ach: [],
        cash: [],
      }

      if (!selectedLocation?.id) {
        return record
      }

      const merchantAccounts =
        getVirtualTerminalMerchantAccounts(selectedLocation)

      const filterByPaymentMethod = (paymentMethod: PaymentMethod) =>
        merchantAccounts.filter(
          ({ payment_method }) => payment_method === paymentMethod
        )

      record.cc = filterByPaymentMethod('cc')
      record.ach = filterByPaymentMethod('ach')
      record.cash = filterByPaymentMethod('cash')

      return record
    }, [selectedLocation])

  // Merchant Accounts Options for the Select Dropdown
  const merchantAccountOptions = useMemo(() => {
    if (!merchantAccountsRecord || !selectedPaymentMethod) {
      return []
    }
    const merchantAccounts = merchantAccountsRecord[selectedPaymentMethod]

    return merchantAccounts.map((merchantAccount) => ({
      label: merchantAccount.title,
      value: merchantAccount,
    }))
  }, [merchantAccountsRecord, selectedPaymentMethod])

  // Available Payment Method Options.

  const paymentMethodOptions = useMemo(() => {
    const options: SelectOption<PaymentMethod>[] = [
      { label: 'CC', value: 'cc' },
      { label: 'ACH', value: 'ach' },
      { label: 'Cash', value: 'cash' },
    ]

    return options.filter(({ value: paymentMethod }) => {
      if (!merchantAccountsRecord) return false

      const transactionTypeOptions =
        getTransactionTypesForPaymentMethod(paymentMethod)

      const hasTransactionTypesPermissions = !!transactionTypeOptions.filter(
        ({ value: transactionType }) =>
          checkPermission(
            userPermissionSet,
            `v2.transactions.post.${transactionType}`
          )
      ).length

      if (!hasTransactionTypesPermissions) return false
      return merchantAccountsRecord[paymentMethod].length >= 1
    })
  }, [merchantAccountsRecord, userPermissionSet])

  // There the Available Payment Method Options are
  // mapped into the desired Radio Buttons Props.

  const getTranslatedMethod = (value) => {
    let label = ''
    if (value === 'cc') {
      label = 'CC'
    } else if (value === 'ach') {
      label = 'ACH'
    } else if (value === 'cash') {
      label = t('common.cash')
    }
    return label
  }

  const paymentMethodsRadioButtonsProps: RadioButtonsArray = useMemo(
    () =>
      paymentMethodOptions.map(({ value: paymentMethod }) => ({
        testId: `${paymentMethod}-payment-method-button`,
        defaultSelected: selectedPaymentMethod === paymentMethod,
        title: getTranslatedMethod(paymentMethod),
        color: 'secondary',
        onClick: () => {
          onChangePaymentMethod(paymentMethod)
          onChangeMerchantAccount(
            getDefaultMerchantAccount(selectedLocation, paymentMethod)
          )
        },
        className: classes.button,
        guidingId: `virtualterminal-transactioninformation-paymentmethod-${paymentMethod}`,
      })),
    [paymentMethodOptions, selectedPaymentMethod, onChangePaymentMethod]
  )

  // Available Transaction Type Options.
  const getTranslatedType = (value) => {
    let label = ''
    if (value === 'sale' || value === 'cash.sale') {
      label = t('common.sale')
    } else if (value === 'authonly') {
      label = t('mfe-gateway.authonly')
    } else if (value === 'avsonly') {
      label = t('common.avsonly')
    } else if (value === 'refund' || value === 'cash.refund') {
      label = t('common.refund')
    } else if (value === 'force') {
      label = t('mfe-gateway.force')
    } else if (value === 'debit') {
      label = t('common.debit')
    } else if (value === 'credit') {
      label = t('mfe-gateway.credit')
    }
    return label
  }

  const transactionTypeOptions = useMemo(() => {
    if (!selectedMerchantAccount) return []

    return getTransactionTypesForAccount(
      selectedMerchantAccount,
      user,
      processMethod
    ).map((option) => ({ ...option, label: getTranslatedType(option.value) }))
  }, [selectedMerchantAccount, user, processMethod]) // eslint-disable-line

  // There the Available Transaction Type Options are
  // mapped into the desired Radio Buttons Props.

  const transactionTypesRadioButtonsProps: RadioButtonsArray = useMemo(
    () =>
      transactionTypeOptions.map(({ value: transactionType, label }) => ({
        testId: `${transactionType}-transaction-type-button`,
        defaultSelected: selectedTransactionType === transactionType,
        title: label,
        color: 'secondary',
        onClick: () => onChangeTransactionType(transactionType),
        className: classes.button,
        guidingId: `virtualterminal-transactioninformation-transactiontype-${transactionType}`,
      })),
    [transactionTypeOptions, selectedTransactionType, onChangeTransactionType]
  )

  // On Mount set the Default Merchant Account,
  // it has to be set again if the Selected Location changes.
  useEffect(() => {
    onChangeMerchantAccount(
      getDefaultMerchantAccount(selectedLocation, selectedPaymentMethod)
    )
  }, [selectedLocation])

  // TODO: maybe a good workaround?, when setting the default merchant account in
  // the useEffect above the components/PaymentAccountDetails.tsx isn't yet mounted sometimes,
  // so it doesn't receive that default merchant account and doesn't show in the UI,
  // this fixes that, but can cause to publish more than necessary to care about?
  useEffect(() => {
    publish(
      TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT,
      selectedMerchantAccount
    )
  }, [selectedMerchantAccount?.id])

  // TODO: same as explained above, but with the pages/transaction/VirtualTerminal.tsx,
  // the transaction type sometimes wasn't defined when submitting a transaction,
  // causing an API Error.
  useEffect(() => {
    publish(
      TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT,
      selectedTransactionType
    )
  }, [selectedTransactionType])

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="h6">
        {t('common.transaction-information')}
      </Typography>

      {!!merchantAccountOptions?.length && (
        <Grid container columnGap={'8px'} flexWrap={'nowrap'}>
          {paymentMethodsRadioButtonsProps.length > 1 && !walletId && (
            <Grid item xs={6} width={'49%'}>
              <RadioButtons
                testId="payment-method-radio-buttons"
                label={t('common.payment-method')}
                buttons={paymentMethodsRadioButtonsProps}
              />
            </Grid>
          )}

          {merchantAccountOptions.length >= 2 && (
            <Grid item xs={6} width={'49%'}>
              <Select
                required
                testId="merchant-account-select"
                guidingId="virtualterminal-transactioninformation-merchantaccount"
                label={t('common.merchant-account-placeholder')}
                // TODO: use correct types there when the Select generics get fixed
                options={
                  merchantAccountOptions as unknown as SelectOption<string>[]
                }
                sort={true}
                value={selectedMerchantAccount}
                onChange={(event) =>
                  onChangeMerchantAccount(
                    event.target.value as unknown as MerchantAccount
                  )
                }
                style={{
                  width: '100%',
                  maxWidth: 'unset',
                  border: 'unset',
                  height: '44px',
                }}
              />
            </Grid>
          )}
        </Grid>
      )}

      <Box>
        <RadioButtons
          testId="transaction-type-radio-buttons"
          label={t('common.transaction-type')}
          buttons={transactionTypesRadioButtonsProps}
        />
      </Box>

      {selectedTransactionType !== 'avsonly' && hasSubtotal !== undefined && (
        <>
          <Box className={classes.multipleInputContainer}>
            <Box>
              <Controller
                control={control}
                name={'subtotal_amount'}
                render={({ field }) => (
                  <CurrencyInput
                    required
                    testId="subtotal-amount-input"
                    label={
                      hasSubtotal
                        ? t('common.amount-subtotal')
                        : t('common.amount-transaction')
                    }
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    currency={
                      selectedMerchantAccount?.vt_show_currency
                        ? 'USD'
                        : undefined
                    }
                    error={
                      !!errors.subtotal_amount || !!errors.transaction_amount
                    }
                    helperText={
                      (errors.subtotal_amount?.message ||
                        errors.transaction_amount?.message) as string
                    }
                    guidingId="virtualterminal-transactioninformation-subtotal"
                  />
                )}
              />
            </Box>

            {!!selectedMerchantAccount?.vt_enable_tip && (
              <Box>
                <Controller
                  control={control}
                  name="tip_amount"
                  render={({ field }) => (
                    <CurrencyInput
                      {...field}
                      label={t('common.tip')}
                      testId="tip-input"
                      currency={
                        selectedMerchantAccount?.vt_show_currency
                          ? 'USD'
                          : undefined
                      }
                      error={!!errors.tip}
                      helperText={errors.tip?.message as string}
                      guidingId="virtualterminal-transactioninformation-tip"
                    />
                  )}
                />
              </Box>
            )}

            {!!selectedMerchantAccount?.vt_enable_sales_tax && (
              <Box>
                <Controller
                  control={control}
                  name="tax"
                  render={({ field }) => (
                    <CurrencyInput
                      label={t('common.tax')}
                      testId="tax-input"
                      currency={
                        selectedMerchantAccount?.vt_show_currency
                          ? 'USD'
                          : undefined
                      }
                      onChange={(event) => {
                        field.onChange(event)
                        setValue('tax', event.target.value)
                      }}
                      error={!!errors.tax}
                      helperText={errors.tax?.message as string}
                      guidingId="virtualterminal-transactioninformation-tax"
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </>
      )}

      {selectedTransactionType === 'force' && (
        <Box>
          <Controller
            control={control}
            name="auth_code"
            render={({ field }) => (
              <Input
                {...field}
                testId="auth-code-input"
                label={t('common.auth-code')}
                placeholder={t('mfe-gateway.auth-code-placeholder')}
                onChange={(event) => {
                  field.onChange(event)
                  setValue('auth_code', event.target.value)
                }}
                error={!!errors.auth_code}
                helperText={errors.auth_code?.message as string}
                required
                guidingId="virtualterminal-transactioninformation-authcode"
              />
            )}
          />
        </Box>
      )}
    </Box>
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@mui/material'

import { api, MerchantAccount, QuickInvoice } from '@shared/api'
import { preprocessCurrencyAmount } from '@shared/api/src/utils/transactions/preprocessCurrencyAmount'
import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  HasPermission,
  Loading,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutContainerSide,
  PageLayoutDivider,
} from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useNotification,
  useSub,
} from '@shared/hooks'
import {
  clearBlankFields,
  formatDate,
  formatRawDate,
  processPhone,
  toFieldErrors,
} from '@shared/utils'

import { CustomerDetails } from '@/components/customer-details/CustomerDetails'
import { CustomerDetailsView } from '@/components/customer-details-view/CustomerDetailsView'

import { AdvancedSettings } from '../components/advanced-settings/AdvancedSettings'
import { InvoiceDetails } from '../components/invoice-details/InvoiceDetails'
import {
  MerchantSelector,
  QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE_EVENT,
  QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE_EVENT,
} from '../components/merchant-selector/MerchantSelector'
import { OptionalSettings } from '../components/optional-settings/OptionalSettings'
import QuickInvoiceItems from '../components/quick-invoice-items/QuickInvoiceItems'
import {
  buildQuickInvoiceSchema,
  QuickInvoiceFormData,
} from '../quick-invoice-add/QuickInvoiceAdd'
import QuickInvoiceSummary from '../quick-invoice-summary/QuickInvoiceSummary'

export default function QuickInvoiceEdit() {
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { t } = useTranslation()
  const { id } = useParams()
  const { state } = useLocation()
  const { setNotification } = useNotification()

  const schema = buildQuickInvoiceSchema(t)

  const navigate = useNavigate()

  const [quickInvoice, setQuickInvoice] = useState<QuickInvoice | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  const [ACHMerchantAccount, setACHMerchantAccount] =
    useState<MerchantAccount | null>(null)
  const [CCMerchantAccount, setCCMerchantAccount] =
    useState<MerchantAccount | null>(null)

  useSub<typeof QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE_EVENT>(
    QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE_EVENT.type,
    ({ data: merchantAccount }) => setCCMerchantAccount(merchantAccount)
  )

  useSub<typeof QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE_EVENT>(
    QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE_EVENT.type,
    ({ data: merchantAccount }) => setACHMerchantAccount(merchantAccount)
  )

  const methods = useForm<QuickInvoiceFormData>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.action.quick-invoice.edit'),
      undefined,
      [t('common.gateway'), t('common.quick-invoice')],
      state?.backUrl || '/merchant/gateway/quick-invoice'
    )
  }, [])

  useEffect(() => {
    if (!id) return
    getQuickInvoice(id)
  }, [id])

  const getQuickInvoice = async (quickInvoiceId: string) => {
    setIsInitialLoading(true)
    const quickInvoice = await api.service('quick-invoices').get(quickInvoiceId)
    setQuickInvoice(quickInvoice)
    methods.reset({
      ...clearBlankFields(quickInvoice),
      tags: quickInvoice.tags.map((tag) => tag.title),
      due_date: quickInvoice.due_date
        ? formatDate(quickInvoice.due_date, 'UTC')
        : undefined,
      expire_date: quickInvoice.expire_date
        ? formatDate(quickInvoice.expire_date, 'UTC')
        : undefined,
      single_payment_min_amount: quickInvoice.single_payment_min_amount
        ? quickInvoice.single_payment_min_amount / 100
        : undefined,
      single_payment_max_amount: quickInvoice.single_payment_max_amount
        ? quickInvoice.single_payment_max_amount / 100
        : undefined,
    })
    setIsInitialLoading(false)
  }

  const onSubmit = async (data: QuickInvoiceFormData) => {
    try {
      setIsLoading(true)

      const payload = {
        cc_product_transaction_id: data.cc_product_transaction_id || null,
        ach_product_transaction_id: data.ach_product_transaction_id || null,
        title: data.title,
        invoice_number: data.invoice_number,
        expire_date: data.expire_date
          ? formatRawDate(data.expire_date, 'yyyy-MM-dd', 'UTC')
          : undefined,
        due_date: data.due_date
          ? formatRawDate(data.due_date, 'yyyy-MM-dd', 'UTC')
          : undefined,
        send_email: data.send_email,
        send_text_to_pay: data.send_text_to_pay,
        email: data.email,
        cell_phone: data.cell_phone ? processPhone(data.cell_phone) : null,
        contact_id: data.contact_id,
        notification_on_due_date: data.notification_on_due_date,
        notification_days_before_due_date:
          data.notification_days_before_due_date,
        notification_days_after_due_date: data.notification_days_after_due_date,
        allow_overpayment: data.allow_overpayment,
        allow_partial_pay: data.allow_partial_pay,
        single_payment_min_amount: data.single_payment_min_amount
          ? preprocessCurrencyAmount(data.single_payment_min_amount)
          : undefined,
        single_payment_max_amount: data.single_payment_max_amount
          ? preprocessCurrencyAmount(data.single_payment_max_amount)
          : undefined,
        bank_funded_only_override: data.bank_funded_only_override,
        tags: data.tags,
        note: data.note,
        item_list: data.item_list,
      }

      await api
        .service('quick-invoices')
        .patch(quickInvoice.id, payload as unknown as QuickInvoice)

      setNotification({
        label: t('mfe-gateway.quick-invoice.updated-successfully'),
        type: 'success',
      })

      navigate(`/merchant/gateway/quick-invoice/${quickInvoice?.id}/view`)
    } catch (error) {
      const errorsFormatted = toFieldErrors(error)
      Object.keys(errorsFormatted).forEach(
        (key: keyof QuickInvoiceFormData) => {
          methods.setError(key, {
            type: 'manual',
            message: errorsFormatted[key],
          })
        }
      )
    } finally {
      setIsLoading(false)
    }
  }

  const isEditable = !!quickInvoice?.active && quickInvoice?.status_id === 1

  return (
    <HasPermission permission="v2.quickinvoices.put">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {isInitialLoading ? (
            <Loading />
          ) : (
            <PageLayoutContainer isButtonBarAtBottom>
              <PageLayoutContainerMain>
                <MerchantSelector
                  isEditable={
                    isEditable &&
                    quickInvoice?.payment_status_id === 1 &&
                    quickInvoice?.transactions?.length === 0
                  }
                />
                <InvoiceDetails
                  isEditable={isEditable}
                  CCMerchantAccount={CCMerchantAccount}
                  ACHMerchantAccount={ACHMerchantAccount}
                />
                <PageLayoutDivider />
                <QuickInvoiceItems
                  readOnly={
                    !(isEditable && quickInvoice?.payment_status_id === 1)
                  }
                />
                <PageLayoutDivider />
                <OptionalSettings isEditable={isEditable} />
                <PageLayoutDivider />
                <AdvancedSettings isEditable={isEditable} />
              </PageLayoutContainerMain>
              <PageLayoutContainerSide>
                {isEditable && quickInvoice?.payment_status_id === 1 ? (
                  <CustomerDetails
                    hideWallet
                    customerRequired={false}
                    guidingId="quickinvoice-add"
                  />
                ) : (
                  <CustomerDetailsView
                    customer={quickInvoice?.contact}
                    hideWalletDetails
                  />
                )}
                <QuickInvoiceSummary />
              </PageLayoutContainerSide>

              <AppBar
                sx={{
                  bottom: 0,
                  top: 'auto',
                  position: 'fixed',
                  boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
                }}
              >
                <ButtonBar style={{ marginBottom: '0 !important' }}>
                  <ButtonBarEnd>
                    <Button
                      key={0}
                      label={t('common.cancel')}
                      color={'secondary'}
                      disabled={false}
                      onClick={() => {
                        navigate(`/merchant/gateway/quick-invoice/${id}/view`)
                      }}
                      style={{ marginRight: '10px' }}
                      testId="cancel-button"
                      guidingId="quickinvoice-edit-cancel"
                    />
                    <Button
                      key={1}
                      type="submit"
                      label={t('common.save')}
                      isLoading={isLoading}
                      style={{ marginRight: '10px', width: '86px' }}
                      testId="save-button"
                      guidingId="quickinvoice-edit-save"
                    />
                  </ButtonBarEnd>
                </ButtonBar>
              </AppBar>
            </PageLayoutContainer>
          )}
        </form>
      </FormProvider>
    </HasPermission>
  )
}

import { IServerSideDatasource } from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { useMemo, useRef } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import AppTheme from '@shared/design'

const useStyles = tss.withName('DisplayTable').create(({ theme }) => ({
  agGridContainer: {
    background: '#FFF',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: '100%',
    '& .ag-root-wrapper': {
      overflow: 'hidden',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    '& .ag-header-cell-text': {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: 'normal',
      color: theme.palette['neutral-700'],
    },
    '& .ag-row': {
      backgroundColor: '#FFF',
    },
    '& .ag-row-odd': {
      backgroundColor: theme.palette['neutral-50'],
    },
    '& .ag-cell': {
      borderBottom: `1px solid ${theme.palette['neutral-200']}`,
    },
    '& .ag-cell-value': {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      color: theme.palette['neutral-700'],
    },
  },
  rowHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const DisplayTable = <T,>({
  data,
  dataSource,
  height = 'auto',
  ...AgGridReactProps
}: AgGridReactProps<T> & {
  data?: T[]
  dataSource?: IServerSideDatasource
  height?: string
}) => {
  const { t } = useTranslation()
  const gridRef = useRef<AgGridReact<T>>(null)
  const { classes } = useStyles(AppTheme)

  const localeText = useMemo(() => {
    return {
      noRowsToShow: t('common.no-data-available'),
    }
  }, [t])

  const NoRowsOverlay = () => (
    <div className="ag-overlay-loading-center">
      <p className="far fa-frown">{t('common.not-available-results')}</p>
    </div>
  )

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit()
  }

  return (
    <div
      className={`ag-theme-alpine ${classes.agGridContainer}`}
      style={{ height }}
    >
      <AgGridReact<T>
        {...AgGridReactProps}
        ref={gridRef}
        rowData={data || []}
        domLayout="autoHeight"
        localeText={localeText}
        suppressPaginationPanel
        suppressColumnVirtualisation
        suppressMovableColumns
        noRowsOverlayComponent={NoRowsOverlay}
        onGridReady={onGridReady}
        animateRows
        serverSideDatasource={dataSource}
        rowModelType={dataSource ? 'serverSide' : 'clientSide'}
        rowClassRules={{
          [classes.rowHover]: () => !!AgGridReactProps.onRowClicked,
        }}
      />
    </div>
  )
}

export default DisplayTable

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { Contact, Token } from '@shared/api/src'

import { CustomerInformationToggle } from '../customer-information-toggle/CustomerInformationToggle'

const EMPTY_FIELD_PLACEHOLDER = '-'
const BULLET_POINT_SPACING = ' • '

const useStyles = tss.withName('CustomerDetailsView').create(({ theme }) => ({
  root: {
    height: 'fit-content',
    width: '100%',
    padding: '1em',
    backgroundColor: 'rgb(243 244 246 / 1)',
    border: '1px solid rgb(229 231 235 / 1)',
    borderRadius: '.5em',
  },
  container: {
    padding: '1em 0 0',
    marginBottom: '4px',
  },
  customerInfoToggleContainer: {
    marginTop: '1.2em',
  },
  subtitle: {
    color: theme.palette['neutral-600'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  details: {
    color: theme.palette['neutral-900'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    marginTop: '5px',
  },
}))

export interface CustomerDetailsViewProps {
  customer: Contact | null
  wallet?: Token | null
  hideWalletDetails?: boolean
}

export const CustomerDetailsView: FC<CustomerDetailsViewProps> = ({
  customer,
  wallet,
  hideWalletDetails,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const getCustomerDetails = () => {
    if (!customer) return t('common.no-customer-assigned')

    return (
      (customer?.first_name ?? '') +
      ' ' +
      (customer?.last_name ?? '') +
      (!!customer?.account_number
        ? `${BULLET_POINT_SPACING}${customer?.account_number}`
        : '')
    )
  }

  const getWalletDetails = () => {
    if (!wallet) return EMPTY_FIELD_PLACEHOLDER

    return (
      (wallet?.title ?? '') +
      BULLET_POINT_SPACING +
      (wallet?.account_type ?? '') +
      BULLET_POINT_SPACING +
      (wallet?.last_four ?? '') +
      BULLET_POINT_SPACING +
      (wallet?.payment_method ?? '')
    )
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h6">{t('mfe-gateway.customer-details')}</Typography>

      <Box className={classes.container}>
        {customer && (
          <Typography className={classes.subtitle}>
            {t('common.customer')}
          </Typography>
        )}

        <Typography className={classes.details} data-testid="customer-details">
          {getCustomerDetails()}
        </Typography>

        {customer && (
          <Box className={classes.customerInfoToggleContainer}>
            <CustomerInformationToggle customer={customer} />
          </Box>
        )}
      </Box>

      {!hideWalletDetails && (
        <Box className={classes.container}>
          <Typography className={classes.subtitle}>
            {t('mfe-gateway.customers-wallet')}
          </Typography>

          <Typography className={classes.details} data-testid="wallet-details">
            {getWalletDetails()}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

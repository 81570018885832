import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { QuickInvoice, Transaction } from '@shared/api'
import { useNotification } from '@shared/hooks'

import { ConfirmAssignTransactionsModal } from '../confirm-assign-transactions-modal/ConfirmAssignTransactionsModal'
import { FilterTransactionsModal } from '../filter-transactions-modal/FilterTransactionsModal'
import {
  AssignmentResult,
  SummaryAssignTransactionsModal,
} from '../summary-assign-transactions-modal/SummaryAssignTransactionsModal'

interface AssignTransactionsModalProps {
  quickInvoice: QuickInvoice
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const AssignTransactionsModal: FC<AssignTransactionsModalProps> = ({
  quickInvoice,
  open,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation()
  const { setNotification } = useNotification()

  const [isFilterTransactionsModalOpen, setIsFilterTransactionsModalOpen] =
    useState(true)
  const [
    isConfirmAssignTransactionsModalOpen,
    setIsConfirmAssignTransactionsModalOpen,
  ] = useState(false)
  const [
    isSummaryAssignTransactionsModalOpen,
    setIsSummaryAssignTransactionsModalOpen,
  ] = useState(false)

  const [selectedTransactions, setSelectedTransactions] = useState<
    Transaction[]
  >([])

  const [allResults, setAllResults] = useState<AssignmentResult[]>([])

  const handleClose = () => {
    setIsFilterTransactionsModalOpen(true)
    setIsConfirmAssignTransactionsModalOpen(false)
    setIsSummaryAssignTransactionsModalOpen(false)
    setSelectedTransactions([])
    setAllResults([])
    onClose()
  }

  const onFindTransactions = (transactions: Transaction[]) => {
    if (transactions.length === 0) {
      setNotification({
        label: t('mfe-gateway.quick-invoice.you-need-to-select-transactions'),
        type: 'error',
      })
      return
    }
    setSelectedTransactions(transactions)
    setIsFilterTransactionsModalOpen(false)
    setIsConfirmAssignTransactionsModalOpen(true)
  }

  const onConfirmAssign = (results: AssignmentResult[]) => {
    setAllResults(results)
    setIsConfirmAssignTransactionsModalOpen(false)
    setIsSummaryAssignTransactionsModalOpen(true)
  }

  const handleCloseSuccess = () => {
    // If any of the transactions was successfully assigned, show success notification
    if (allResults.some((result) => !!result.response)) {
      setNotification({
        label: t('mfe-gateway.quick-invoice.assign-success'),
        type: 'success',
      })
      onSuccess()
    } else if (allResults.some((result) => !!result.error)) {
      setNotification({
        label: t('mfe-gateway.quick-invoice.assign-error'),
        type: 'error',
      })
    }
    handleClose()
  }

  return (
    <>
      <FilterTransactionsModal
        open={open && isFilterTransactionsModalOpen}
        quickInvoice={quickInvoice}
        onClose={handleClose}
        onAssign={onFindTransactions}
      />
      <ConfirmAssignTransactionsModal
        transactions={selectedTransactions}
        open={open && isConfirmAssignTransactionsModalOpen}
        quickInvoice={quickInvoice}
        onClose={handleCloseSuccess}
        onSuccess={onConfirmAssign}
      />
      <SummaryAssignTransactionsModal
        assignmentResults={allResults}
        open={open && isSummaryAssignTransactionsModalOpen}
        onClose={handleCloseSuccess}
      />
    </>
  )
}

import { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { MerchantAccount } from '@shared/api'
import {
  FieldGroupRow,
  PageLayoutDivider,
  SelectComponent,
  SelectOption,
} from '@shared/components'
import { PubSubEvent, useLocations, usePub } from '@shared/hooks'
import { PaymentMethodType } from '@shared/types'
import { sortMerchantAccounts } from '@shared/utils'

import { QuickInvoiceFormData } from '../../quick-invoice-add/QuickInvoiceAdd'

export const QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE_EVENT = new Event(
  'QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE'
) as PubSubEvent<MerchantAccount | null>

export const QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE_EVENT = new Event(
  'QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE'
) as PubSubEvent<MerchantAccount | null>

interface MerchantSelectorProps {
  isEditable?: boolean
  defaultMerchants?: boolean
}

export const MerchantSelector: FC<MerchantSelectorProps> = ({
  isEditable = true,
  defaultMerchants = false,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<QuickInvoiceFormData>()

  const publish = usePub()

  const { t } = useTranslation()

  const { allMerchantAccounts, selectedLocation } = useLocations()
  const [CCMerchantAccounts, setCCMerchantAccounts] = useState<
    MerchantAccount[]
  >([])
  const [ACHMerchantAccounts, setACHMerchantAccounts] = useState<
    MerchantAccount[]
  >([])

  const [isComponentHidden, setIsComponentHidden] = useState<boolean>(false)

  useEffect(() => {
    if (!allMerchantAccounts) return

    const merchantAccounts = allMerchantAccounts.filter(
      ({ payment_method, active, quick_invoice_allow }) =>
        payment_method !== PaymentMethodType.CASH &&
        active &&
        quick_invoice_allow
    )

    const ccMerchants = merchantAccounts.filter(
      ({ payment_method }) => payment_method === PaymentMethodType.CC
    )

    const achMerchants = merchantAccounts.filter(
      ({ payment_method }) => payment_method === PaymentMethodType.ACH
    )

    // Only set default merchant accounts if creating a new quick invoice
    if (defaultMerchants) {
      if (ccMerchants.length > 0) {
        const ccDefault =
          ccMerchants.find(
            (merchant) => merchant.id === selectedLocation.default_cc
          ) || ccMerchants[0]

        if (ccDefault) {
          setValue('cc_product_transaction_id', ccDefault.id)
          publish(QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE_EVENT, ccDefault)
        }
      }

      if (achMerchants.length > 0) {
        const achDefault =
          achMerchants.find(
            (merchant) => merchant.id === selectedLocation.default_ach
          ) || achMerchants[0]

        if (achDefault) {
          setValue('ach_product_transaction_id', achDefault.id)
          publish(QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE_EVENT, achDefault)
        }
      }
    } else {
      const currentCC = getValues('cc_product_transaction_id')
      const currentACH = getValues('ach_product_transaction_id')

      if (currentCC) {
        const ccMerchant = allMerchantAccounts.find(
          (merchantAccount) => merchantAccount.id === currentCC
        )
        publish(QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE_EVENT, ccMerchant)
      }

      if (currentACH) {
        const achMerchant = allMerchantAccounts.find(
          (merchantAccount) => merchantAccount.id === currentACH
        )
        publish(QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE_EVENT, achMerchant)
      }
    }

    if (
      (ccMerchants.length === 1 && achMerchants.length === 0) ||
      (ccMerchants.length === 0 && achMerchants.length === 1)
    ) {
      setIsComponentHidden(true)
    }

    setCCMerchantAccounts(ccMerchants)
    setACHMerchantAccounts(achMerchants)
  }, [allMerchantAccounts])

  const getMerchantAccountsSelectOptions = (
    merchantAccounts: MerchantAccount[]
  ): SelectOption<MerchantAccount>[] =>
    sortMerchantAccounts(merchantAccounts).map<SelectOption<MerchantAccount>>(
      (merchantAccount) => ({
        label: merchantAccount.title,
        value: merchantAccount.id,
      })
    )

  const noneOption = {
    label: t('mfe-gateway.quick-invoice.none'),
    value: '',
  }

  const ccMerchantAccountsOptions =
    getMerchantAccountsSelectOptions(CCMerchantAccounts)

  const achMerchantAccountsOptions =
    getMerchantAccountsSelectOptions(ACHMerchantAccounts)

  if (isComponentHidden) return null

  return (
    <>
      <FieldGroupRow columnSpacing="12px">
        {ccMerchantAccountsOptions.length > 0 && (
          <Grid item xs={achMerchantAccountsOptions.length > 0 ? 6 : 12}>
            <Controller
              name="cc_product_transaction_id"
              control={control}
              render={({ field }) => (
                <SelectComponent
                  {...field}
                  label={t(
                    'mfe-gateway.quick-invoice.select-cc-merchant-account'
                  )}
                  placeholder={t(
                    'mfe-gateway.quick-invoice.select-cc-merchant-account-placeholder'
                  )}
                  testId="cc-merchant-account-select"
                  options={
                    achMerchantAccountsOptions.length > 0
                      ? [noneOption, ...ccMerchantAccountsOptions]
                      : ccMerchantAccountsOptions
                  }
                  value={field.value}
                  onChange={(event) => {
                    publish(
                      QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE_EVENT,
                      event.target.value
                        ? allMerchantAccounts.find(
                            (merchantAccount) =>
                              merchantAccount.id === event.target.value
                          )
                        : null
                    )
                    field.onChange(event)
                  }}
                  style={{
                    minWidth: '100%',
                    width: '100%',
                    marginTop: '4px',
                    marginBottom: '6px',
                  }}
                  error={!!errors.cc_product_transaction_id}
                  helperText={errors.cc_product_transaction_id?.message.toString()}
                  disabled={!isEditable}
                  guidingId="quickinvoice-merchantselector-selectcc"
                />
              )}
            />
          </Grid>
        )}

        {achMerchantAccountsOptions.length > 0 && (
          <Grid item xs={ccMerchantAccountsOptions.length > 0 ? 6 : 12}>
            <Controller
              name="ach_product_transaction_id"
              control={control}
              render={({ field }) => (
                <SelectComponent
                  {...field}
                  label={t(
                    'mfe-gateway.quick-invoice.select-ach-merchant-account'
                  )}
                  placeholder={t(
                    'mfe-gateway.quick-invoice.select-ach-merchant-account-placeholder'
                  )}
                  testId="ach-merchant-account-select"
                  options={
                    ccMerchantAccountsOptions.length > 0
                      ? [noneOption, ...achMerchantAccountsOptions]
                      : achMerchantAccountsOptions
                  }
                  value={field.value}
                  onChange={(event) => {
                    publish(
                      QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE_EVENT,
                      event.target.value
                        ? allMerchantAccounts.find(
                            (merchantAccount) =>
                              merchantAccount.id === event.target.value
                          )
                        : null
                    )
                    field.onChange(event)
                  }}
                  style={{
                    minWidth: '100%',
                    width: '100%',
                    marginTop: '4px',
                    marginBottom: '6px',
                  }}
                  error={!!errors.ach_product_transaction_id}
                  helperText={errors.ach_product_transaction_id?.message.toString()}
                  disabled={!isEditable}
                  guidingId="quickinvoice-merchantselector-selectach"
                />
              )}
            />
          </Grid>
        )}
      </FieldGroupRow>
      <PageLayoutDivider />
    </>
  )
}

import { useTranslation } from 'react-i18next'

export function useFormatRecurringType(type: string) {
  const { t } = useTranslation()

  const mapping: { [key: string]: string } = {
    i: t('Installment'),
    o: t('Ongoing'),
  }

  return mapping[type]
}

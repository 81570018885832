import { useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box } from '@mui/material'

import { MerchantAccount } from '@shared/api'
import { FieldGroupContainer, SelectComponent, Input } from '@shared/components'
import { useLocations, useSub } from '@shared/hooks'

import { TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT } from '../transaction-information/TransactionInformation'

const useStyles = tss.withName('OptionalInformation').create(({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  multipleInputContainer: {
    display: 'grid',
    justifyItems: 'stretch',
    gridAutoFlow: 'column',
    gap: '8px',
  },
}))

const OptionalInformation = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { selectedLocation } = useLocations()
  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount>()
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const tagsOptions = useMemo(() => {
    if (!selectedLocation?.tags) return []
    return selectedLocation.tags.map((tag) => ({
      label: tag.title,
      value: tag.title,
    }))
  }, [selectedLocation])

  useSub<typeof TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT>(
    TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT.type,
    ({ data: merchant }) => {
      setSelectedMerchantAccount(merchant)
    }
  )

  return (
    <FieldGroupContainer
      title={t('common.optional-information')}
      showToggle
      showContent={false}
      guidingId="virtualterminal-optionalinformation"
    >
      <Box className={classes.root}>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Input
              label={t('common.description')}
              placeholder={t('common.description-placeholder')}
              {...field}
              error={!!errors.description}
              helperText={errors.description?.message as string}
              guidingId="virtualterminal-optionalinformation-description"
            />
          )}
        />

        {selectedMerchantAccount?.payment_method === 'cc' && (
          <Box className={classes.multipleInputContainer}>
            {!!selectedMerchantAccount?.vt_clerk_number && (
              <Controller
                control={control}
                name="clerk_number"
                render={({ field }) => (
                  <Input
                    {...field}
                    testId="clerk-number-input"
                    label={t('common.clerk-number')}
                    placeholder={t('common.clerk-number-placeholder')}
                    error={!!errors.clerk_number}
                    helperText={errors.clerk_number?.message as string}
                    guidingId="virtualterminal-optionalinformation-clerknumber"
                  />
                )}
              />
            )}

            {!!selectedMerchantAccount?.vt_order_num && (
              <Controller
                control={control}
                name="order_number"
                render={({ field }) => (
                  <Input
                    {...field}
                    testId="order-number-input"
                    label={t('common.order-number')}
                    placeholder={t('common.order-number')}
                    onChange={(event) => {
                      field.onChange(event)
                    }}
                    error={!!errors.order_number}
                    helperText={errors.order_number?.message as string}
                    guidingId="virtualterminal-optionalinformation-ordernumber"
                  />
                )}
              />
            )}
          </Box>
        )}

        {!!selectedMerchantAccount?.vt_show_custom_fields && (
          <Box className={classes.multipleInputContainer}>
            <Controller
              control={control}
              name="transaction_c1"
              render={({ field }) => (
                <Input
                  {...field}
                  testId="transaction-c1-input"
                  label={t('common.custom-1')}
                  placeholder={t('mfe-gateway.custom-1-placeholder')}
                  error={!!errors.transaction_c1}
                  helperText={errors.transaction_c1?.message as string}
                  guidingId="virtualterminal-optionalinformation-transactionc1"
                />
              )}
            />

            <Controller
              control={control}
              name="transaction_c2"
              render={({ field }) => (
                <Input
                  {...field}
                  testId="transaction-c2-input"
                  label={t('common.custom-2')}
                  placeholder={t('mfe-gateway.custom-2-placeholder')}
                  error={!!errors.transaction_c2}
                  helperText={errors.transaction_c2?.message as string}
                  guidingId="virtualterminal-optionalinformation-transactionc2"
                />
              )}
            />

            <Controller
              control={control}
              name="transaction_c3"
              render={({ field }) => (
                <Input
                  {...field}
                  testId="transaction-c3-input"
                  label={t('common.custom-3')}
                  placeholder={t('mfe-gateway.custom-3-placeholder')}
                  error={!!errors.transaction_c3}
                  helperText={errors.transaction_c3?.message as string}
                  guidingId="virtualterminal-optionalinformation-transactionc3"
                />
              )}
            />
          </Box>
        )}

        <Controller
          name="tags"
          control={control}
          render={({ field }) => (
            <SelectComponent
              multiple
              onChange={field.onChange}
              value={field.value}
              options={tagsOptions}
              label={t('common.tags')}
              placeholder={t('common.tags-select')}
              style={{
                width: '100%',
                maxWidth: 'unset',
                border: 'unset',
                height: '44px',
              }}
              guidingId="virtualterminal-optionalinformation-tags"
            />
          )}
        />

        <Controller
          name="notification_email_address"
          control={control}
          render={({ field }) => (
            <Input
              label={t('common.email')}
              placeholder={t('common.email-placeholder')}
              {...field}
              error={!!errors.notification_email_address}
              helperText={errors.notification_email_address?.message as string}
              guidingId="virtualterminal-optionalinformation-email"
            />
          )}
        />
      </Box>
    </FieldGroupContainer>
  )
}

export default OptionalInformation

import { useTranslation } from 'react-i18next'

import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'

const AdvancedSettings = ({ apiId }) => {
  const { t } = useTranslation()

  return (
    <FieldGroupContainer title={t('common.advanced-settings')}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.quick-invoice-api-id')}
          value={apiId}
          valueTestID="quick-invoice-api-id-value"
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}

export default AdvancedSettings

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Contact, api } from '@shared/api/src'
import {
  HasPermission,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutContainerSide,
  UserNotAllowed,
  ButtonBarEnd,
  ButtonBarStart,
  Button,
  ButtonBar,
  OtherInfo,
} from '@shared/components'
import {
  useAuthorization,
  useFtpPortalHubCommunication,
  useLocations,
} from '@shared/hooks'
import { checkAllPermissions, checkPermission } from '@shared/utils'

import { ToggleCustomerActivationModal } from '@/components/toggle-customer-activation-modal/ToggleCustomerActivationModal'

import {
  Address,
  AdvancedSettings,
  CustomerDetails,
  ContactDetails,
} from './components'
import CustomerWallet from '../customer-wallet/CustomerWallet'

export default function CustomerView() {
  const navigate = useNavigate()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { userPermissionSet } = useAuthorization()
  const { selectedLocation } = useLocations()
  const { state } = useLocation()
  const { id } = useParams()
  const { t } = useTranslation()

  const [customer, setCustomer] = useState<Contact | null>(null)

  const [openChangeStatusModal, setOpenChangeStatusModal] =
    useState<boolean>(false)

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.customer.view-customer'),
      null,
      [
        t('common.customers'),
        `${customer?.first_name || ''} ${customer?.last_name || ''}`,
      ],
      state?.backUrl || '/merchant/gateway/customer'
    )
  }, [customer?.first_name, customer?.last_name])

  useEffect(() => {
    getCustomer()
  }, [])

  const getCustomer = async () => {
    try {
      await api.service('contacts').get(id).then(setCustomer)
    } catch (error) {
      console.error(error)
    }
  }

  const customerViewBackUrl = customer
    ? `/merchant/gateway/customer/${customer.id}/view`
    : ''

  return (
    <HasPermission
      permission="v2.contacts.get"
      unauthorizedComponent={<UserNotAllowed />}
    >
      <>
        {customer && (
          <>
            <ButtonBar>
              <ButtonBarStart>
                {customer.active ? (
                  <>
                    <Button
                      label={t('common.deactivate')}
                      color="secondary"
                      style={{ width: '131px' }}
                      onClick={() => setOpenChangeStatusModal(true)}
                      guidingId="customers-deactivate"
                    />

                    {checkPermission(
                      userPermissionSet,
                      'v2.transactions.post'
                    ) &&
                      !!selectedLocation?.product_transactions?.length && (
                        <Button
                          label={t('common.charge')}
                          color="secondary"
                          style={{ marginLeft: '8px', width: '104px' }}
                          onClick={() =>
                            navigate(
                              `/merchant/gateway/customer/${customer.id}/charge`,
                              {
                                state: {
                                  backUrl: customerViewBackUrl,
                                },
                              }
                            )
                          }
                          guidingId="customers-charge"
                        />
                      )}
                  </>
                ) : (
                  <Button
                    label={t('common.reactivate')}
                    color="secondary"
                    style={{ width: '131px' }}
                    onClick={() => setOpenChangeStatusModal(true)}
                    guidingId="customers-reactivate"
                  />
                )}
              </ButtonBarStart>
              <ButtonBarEnd>
                {customer.active &&
                checkPermission(userPermissionSet, 'v2.contacts.put') ? (
                  <Button
                    label={t('common.edit')}
                    onClick={() =>
                      navigate(
                        `/merchant/gateway/customer/${customer.id}/edit`,
                        {
                          state: {
                            backUrl: customerViewBackUrl,
                          },
                        }
                      )
                    }
                    guidingId="customers-edit"
                  />
                ) : null}
              </ButtonBarEnd>
            </ButtonBar>

            <PageLayoutContainer>
              <PageLayoutContainerMain>
                <CustomerDetails customer={customer} />

                <ContactDetails customer={customer} />

                <Address customer={customer} />

                <AdvancedSettings customer={customer} />

                <OtherInfo
                  createdBy={customer?.created_user?.email}
                  createdDate={customer?.created_ts}
                  section="customer"
                />
              </PageLayoutContainerMain>
              {/* This needs to be updated after oas specs for this endpoint are updated */}
              {customer.active &&
                Boolean(selectedLocation.product_token?.active) &&
                checkAllPermissions(
                  userPermissionSet,
                  'v2.accountvaults.get'
                ) && (
                  <PageLayoutContainerSide>
                    <CustomerWallet customer={customer} />
                  </PageLayoutContainerSide>
                )}
            </PageLayoutContainer>

            {customer && (
              <ToggleCustomerActivationModal
                customer={customer}
                open={openChangeStatusModal}
                onClose={() => setOpenChangeStatusModal(false)}
                onSuccess={getCustomer}
              />
            )}
          </>
        )}
      </>
    </HasPermission>
  )
}

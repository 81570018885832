import { useCallback, useEffect } from 'react'

import { PubSubEvent } from './usePub'

/**
 * Custom Hook to Subscribe to an Event. It already takes care of Unsubscribing on Component Unmount.
 * @param event Name of the Event.
 * @param callback The Event Listener.
 * @param dependencies The Event Listener Dependencies for it to be recalculated.
 * @returns A Function to Unsubscribe.
 */
export const useSub = <T extends Event | PubSubEvent<unknown>>(
  event: string,
  callback: (event: T) => void,
  dependencies?: unknown[]
) => {
  const deps = dependencies ?? []

  const unsubscribe = useCallback(() => {
    window.removeEventListener(event, callback as EventListener)
  }, deps)

  useEffect(() => {
    window.addEventListener(event, callback as EventListener)
    return unsubscribe
  }, deps)

  return unsubscribe
}

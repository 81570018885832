import { Controller, FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Grid, Typography } from '@mui/material'

import { Token } from '@shared/api'
import { Input, AccountType, MaskedInput } from '@shared/components'
import { PaymentMethodType } from '@shared/types'
import { expDateMask } from '@shared/utils'

import ACHDetailsView from '../ach-details-view/ACHDetailsView'

const useStyles = tss.withName('WalletDetailsEdit').create(({ theme }) => ({
  title: {
    color: theme.palette['neutral-700'],
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 500,
    marginBottom: '20px',
  },
  item: {
    color: theme.palette['neutral-600'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  value: {
    color: theme.palette['neutral-900'],
    fontWeight: '500',
    lineHeight: '20px',
    fontSize: '14px',
  },
  accountTypeContainer: {
    display: 'flex',
    gap: '8px',
    paddingTop: '20px',
  },
}))

interface WalletDetailsProps {
  wallet: Token
}

const WalletDetailsEdit = ({ wallet }: WalletDetailsProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const methods = useFormContext()
  return (
    <FormProvider {...methods}>
      <Typography className={classes.title}>
        {wallet.payment_method === PaymentMethodType.CC
          ? t('mfe-gateway.token-cc.card-details')
          : t('mfe-gateway.token-ach.account-details')}
      </Typography>
      <Controller
        name="title"
        control={methods.control}
        render={({ field }) => (
          <Input
            {...field}
            onChange={(event) => {
              field.onChange(event)
            }}
            label={t('common.title')}
            placeholder={t('common.account-title-placeholder')}
            error={!!methods.formState.errors.title}
            helperText={methods.formState.errors.title?.message.toString()}
            style={{
              marginBottom: '24px',
            }}
            guidingId="token-title"
          />
        )}
      />

      <Controller
        name="account_holder_name"
        control={methods.control}
        render={({ field }) => (
          <Input
            {...field}
            onChange={(event) => {
              field.onChange(event)
            }}
            label={
              wallet.payment_method === PaymentMethodType.CC
                ? t('common.card-holder-name')
                : t('common.account-holder-name')
            }
            placeholder={
              wallet.payment_method === PaymentMethodType.CC
                ? t('common.card-holder-name-placeholder')
                : t('common.account-holder-name-placeholder')
            }
            testId="account-holder-name-field"
            required={wallet.payment_method === PaymentMethodType.ACH}
            error={!!methods.formState.errors.account_holder_name}
            helperText={methods.formState.errors.account_holder_name?.message.toString()}
            guidingId={
              wallet.payment_method === PaymentMethodType.CC
                ? 'token-cc-accountholdername'
                : 'token-ach-accountholdername'
            }
          />
        )}
      />

      <Box marginTop="20px">
        {wallet.payment_method === PaymentMethodType.CC && (
          <Grid container alignItems="center">
            <Grid item xs={6} className={classes.accountTypeContainer}>
              <Typography className={classes.item}>
                {t('mfe-gateway.token-cc.card-details')}
              </Typography>
              <AccountType showLabel={false} type={wallet.account_type} />
              <Typography className={classes.value}>
                • {wallet.last_four}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="exp_date"
                control={methods.control}
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    testId="exp-date-input"
                    label={t('common.expire-date')}
                    placeholder="MM/YY"
                    mask={expDateMask}
                    onChange={(event) => {
                      field.onChange(event.target.value.replace(/\//g, ''))
                    }}
                    error={!!methods.formState.errors.exp_date}
                    helperText={
                      methods.formState.errors.exp_date?.message as string
                    }
                    guidingId="token-cc-expdate"
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        {wallet.payment_method === PaymentMethodType.ACH && (
          <ACHDetailsView wallet={wallet} hideEditableFields />
        )}
      </Box>
    </FormProvider>
  )
}

export default WalletDetailsEdit

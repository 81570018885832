import { api } from '../../../api/src'

export const getSalesTaxByZipCode = async (
  postalCode: string,
  locationId: string,
  country: string = 'US'
) => {
  // Temporary solution, billing address accepts all countries
  // but we only have sales tax data for US and Canada
  let mappedCountry: string
  switch (country) {
    case 'USA':
      mappedCountry = 'US'
      break
    case 'CAN':
      mappedCountry = 'CA'
      break
    default:
      mappedCountry = country
      break
  }

  const results = await api.service('sales-taxes').find({
    query: {
      'filter[country]': `"${mappedCountry}"`,
      'filter[zip_code]': `"${postalCode}"`,
      'filter[location_id]': `"${locationId}"`,
    },
  })

  if (results.length === 0) {
    return undefined
  }

  return results[0].rate
}

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import MultiSelectSearchFilter from '@shared/ag-grid/pagination-table/filters/MultiSelectSearchFilter'
import RadioFilter from '@shared/ag-grid/pagination-table/filters/radio-filter/RadioFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { Contact, User } from '@shared/api/src/schemas/types'
import {
  ActiveStatus,
  HasPermission,
  UserNotAllowed,
  ThreeDotMenu,
} from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useEnforceLogin,
  useLocations,
  useAuthorization,
  useFilterModel,
} from '@shared/hooks'
import { CountryType, EnumServiceName } from '@shared/types'
import { formatDatetime, checkPermission, DataSource } from '@shared/utils'

import { ToggleCustomerActivationModal } from '@/components/toggle-customer-activation-modal/ToggleCustomerActivationModal'

export default function CustomerGrid() {
  //TODO: add translation when email receipt or other functionality is added
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { selectedLocation } = useLocations()
  const { user } = useEnforceLogin()
  const { userPermissionSet } = useAuthorization()

  const gridRef = useRef<AgGridReact>(null)

  const [customerSelected, setCustomerSelected] = useState<Contact | null>(null)

  const [openChangeStatusModal, setOpenChangeStatusModal] =
    useState<boolean>(false)

  const CustomerGridPrivs = ['v2.contacts.get']

  const [selectedUsers, setSelectedUsers] = useState<User[]>([])

  const filterCountries = Object.entries(CountryType).map(([key, value]) => ({
    value: key,
    label: value,
  }))

  useEffect(() => {
    setAppBarTitle(t('common.customers'), null, t('common.gateway'))
  }, [])

  const datasource = useMemo(() => {
    return new DataSource('contacts', {
      filterVariant: 'filter',
      fixedFilters: {
        location_id: selectedLocation?.id,
      },
    })
  }, [selectedLocation.id])

  const customerViewBackUrl = '/merchant/gateway/customer'

  const columnDefs: ColDef<Contact>[] = useMemo(
    () => [
      {
        headerName: t('common.name-last'),
        field: 'last_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        sort: 'asc',
        sortIndex: 0,
      },
      {
        headerName: t('common.name-first'),
        field: 'first_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.email'),
        field: 'email',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('mfe-gateway.customer-number'),
        field: 'account_number',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.street'),
        field: 'address.street',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.city'),
        field: 'address.city',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.state'),
        field: 'address.state',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.country'),
        field: 'address.country',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterCountries.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterCountries.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        cellRenderer: (data) => {
          return data.value
        },
        sortable: true,
      },
      {
        headerName: t('common.zip-code'),
        field: 'address.postal_code',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.status'),
        field: 'active',
        floatingFilter: true,
        filter: RadioFilter,
        filterParams: {
          showSelectAll: true,
          defaultTo: 'true',
          trueLabel: t('common.active'),
          falseLabel: t('common.inactive'),
        },
        cellRenderer: (data) => {
          return <ActiveStatus active={!!data.value} />
        },
        sortable: true,
      },
      {
        headerName: t('common.date-created'),
        field: 'created_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.created_ts
          return formatDatetime(timestamp, user?.tz) || '-'
        },
        filterParams: {
          type: 'past',
          showTimePicker: true,
        },
        sortable: true,
      },
      {
        headerName: t('common.created-by'),
        field: 'created_user_id',
        floatingFilter: true,
        filter: MultiSelectSearchFilter,
        filterParams: {
          typeOfSearch: 'contains',
          filterType: 'text',
          placeholder: t('common.email-search'),
          service: 'users',
          filterPropName: 'email',
          primaryDisplayField: 'email',
          value: selectedUsers,
          multiple: true,
          onChange: (event) => {
            setSelectedUsers(event)
          },
        },
        valueGetter: (params) => {
          return params.data?.created_user?.email
        },
      },
    ],
    [selectedLocation, userPermissionSet]
  )

  const threeDotsOptions = useCallback(
    (data: Contact) => [
      {
        label: t('common.view'),
        action: () => navigate(`/merchant/gateway/customer/${data.id}/view`),
        enabled: true,
      },
      {
        label: t('common.edit'),
        action: () =>
          navigate(`/merchant/gateway/customer/${data.id}/edit`, {
            state: {
              backUrl: customerViewBackUrl,
            },
          }),
        enabled:
          data.active && checkPermission(userPermissionSet, 'v2.contacts.put'),
      },
      {
        label: t('common.charge'),
        action: () =>
          navigate(`/merchant/gateway/customer/${data.id}/charge`, {
            state: {
              backUrl: customerViewBackUrl,
            },
          }),
        enabled:
          data.active &&
          checkPermission(userPermissionSet, 'v2.transactions.post') &&
          !!selectedLocation?.product_transactions?.length,
      },
      {
        label: data.active ? t('common.deactivate') : t('common.reactivate'),
        action: () => {
          setCustomerSelected(data), setOpenChangeStatusModal(true)
        },
        enabled: data.active
          ? checkPermission(userPermissionSet, 'v2.contacts.delete')
          : checkPermission(userPermissionSet, 'v2.contacts.post.activate'),
      },
    ],
    [userPermissionSet, selectedLocation, customerViewBackUrl]
  )

  const refreshGrid = () => {
    gridRef.current!.api.refreshServerSide({ purge: true })
  }

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  const defaultFilters = {
    active: {
      filterType: 'set',
      values: ['true'],
    },
  }

  const initialFilterModel = useFilterModel(defaultFilters)

  return (
    <section>
      <HasPermission
        allPermissions={CustomerGridPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <>
          <PaginationTable<Contact>
            getRowId={(data) => data.data.id}
            columnDefs={columnDefs}
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            defaultColDef={defaultColDef}
            serviceName={EnumServiceName.Contacts}
            onRowClicked={({ data }) =>
              navigate(`/merchant/gateway/customer/${data.id}/view`)
            }
            getGridRef={getGridRef}
            showExportButton
            showClearFiltersButton
            primaryButtonData={
              checkPermission(userPermissionSet, 'v2.contacts.post')
                ? {
                    text: t('mfe-gateway.customer.add-customer'),
                    action: () => navigate('/merchant/gateway/customer/add'),
                  }
                : undefined
            }
            initialFilterModel={initialFilterModel}
            guidingId="customers"
            threeDotsOptions={threeDotsOptions}
          />

          {!!customerSelected && (
            <ToggleCustomerActivationModal
              customer={customerSelected}
              open={openChangeStatusModal}
              onClose={() => setOpenChangeStatusModal(false)}
              onSuccess={refreshGrid}
            />
          )}
        </>
      </HasPermission>
    </section>
  )
}

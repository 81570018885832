import { omit } from 'lodash'
import { FC, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
} from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import {
  CreditCardType,
  getCreditCardType,
} from '@shared/api/src/utils/transactions/creditCardDetector'
import {
  Input,
  useInputStyles,
  MaskedInput,
  AccountType,
} from '@shared/components'
import {
  createMask,
  defaultMask,
  is_valid_luhn,
  expDateMask,
} from '@shared/utils'

type StylesParams = {
  showExpDate: boolean
  showCvv: boolean
}

export interface CreditCardInformationProps {
  /**
   * If `true`, displays the expiration date field.
   * Defaults to `true`.
   */
  showExpDate?: boolean

  /**
   * If `true`, displays the cvv field.
   * Defaults to `true`.
   */
  showCvv?: boolean
  guidingId?: string
}

const useStyles = tss
  .withName('CreditCardInformation')
  .withParams<StylesParams>()
  .create(({ showExpDate, showCvv }) => ({
    inputContainer: {
      marginBottom: '1.5em',
    },
    cardDetailsBottomContainer: {
      display: 'grid',
      gridTemplateColumns: showExpDate && showCvv ? '1fr 1fr' : '1fr',
    },
    ccValidIcon: { cursor: 'default', color: 'green' },
    ccInvalidIcon: { cursor: 'default', color: 'red' },
  }))

export const CreditCardInformation: FC<CreditCardInformationProps> = ({
  showExpDate = true,
  showCvv = true,
  guidingId,
}) => {
  const { classes } = useStyles({ showExpDate, showCvv })
  const { classes: inputClasses } = useInputStyles()
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
    trigger,
    watch,
  } = useFormContext()

  const [ccType, setCcType] = useState<CreditCardType>()
  const [ccMask, setCcMask] = useState<(string | RegExp)[]>(defaultMask)
  const [valid, setValid] = useState<boolean>()

  const cvv = watch('cvv')

  const determineCCType = (accountNumber: string) => {
    // Remove mask
    const raw_number = accountNumber.replace(/ /g, '')

    const ccOptions = getCreditCardType(raw_number)

    if (!accountNumber || ccOptions.length !== 1) {
      updateCcType(undefined)
      return
    }

    if (ccOptions[0]?.type !== ccType?.type) updateCcType(ccOptions[0])

    if (raw_number.length >= ccOptions[0].lengths[0]) {
      const isValid = is_valid_luhn(raw_number)

      // Need to manually trigger CVV validation after CC number is validated
      if (isValid && cvv?.length) {
        trigger('cvv')
      }
      setValid(isValid)
      return
    }

    if (valid) setValid(false)
  }

  const updateCcType = (ccType: CreditCardType | undefined) => {
    setCcType(ccType)

    if (ccType) {
      setCcMask(
        createMask(ccType.lengths[ccType.lengths.length - 1], ccType.gaps)
      )
    }
  }

  return (
    <Box>
      <Box className={classes.inputContainer}>
        <Controller
          name="account_holder_name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              testId="card-holder-name-input"
              label={t('common.card-holder-name')}
              placeholder={t('common.card-holder-name-placeholder')}
              error={!!errors.account_holder_name}
              helperText={errors.account_holder_name?.message as string}
              guidingId={`${guidingId}-cc-accountholdername`}
            />
          )}
        />
      </Box>

      <Box>
        {(showExpDate || showCvv) && (
          <Box className={inputClasses.labelContainer}>
            <Typography className={inputClasses.label}>
              {t('mfe-gateway.token-cc.card-details')}{' '}
              <strong className={inputClasses.required}>*</strong>
            </Typography>
          </Box>
        )}

        <Box>
          <Box>
            <Controller
              name="account_number"
              control={control}
              render={({ field }) => (
                <>
                  <MaskedInput
                    {...omit(field, 'ref')}
                    testId="account-number-input"
                    data-guiding-id={`${guidingId}-cc-accountnumber`}
                    noHoverBorder={showCvv && showExpDate}
                    label=""
                    placeholder={t('common.card-number')}
                    mask={ccMask}
                    icon={[
                      !!ccType
                        ? {
                            component: valid ? (
                              <CheckIcon
                                className={classes.ccValidIcon}
                                height={10}
                                width={10}
                              />
                            ) : (
                              <ErrorIcon
                                className={classes.ccInvalidIcon}
                                height={10}
                                width={10}
                              />
                            ),
                          }
                        : null,
                      {
                        component: (
                          <AccountType showLabel={false} type={ccType?.type} />
                        ),
                      },
                    ]}
                    onChange={(event) => {
                      field.onChange(event)
                      determineCCType(event.target.value)
                    }}
                    style={{
                      height: '44px',
                      borderRadius:
                        showExpDate || showCvv ? '4px 4px 0 0' : '4px',
                    }}
                    error={!!errors.account_number}
                    helperText={errors.account_number?.message as string}
                  />
                </>
              )}
            />
          </Box>

          <Box className={classes.cardDetailsBottomContainer}>
            {!!showExpDate && (
              <Box>
                <Controller
                  name="exp_date"
                  control={control}
                  render={({ field }) => (
                    <MaskedInput
                      {...omit(field, 'ref')}
                      testId="exp-date-input"
                      guidingId={`${guidingId}-cc-expdate`}
                      noHoverBorder={!!showCvv}
                      label=""
                      placeholder="MM/YY"
                      mask={expDateMask}
                      onChange={(event) => {
                        field.onChange(event.target.value.replace(/\//g, ''))
                      }}
                      style={{
                        height: '44px',
                        borderRadius: showCvv ? '0 0 0 4px' : '0 0 4px 4px',
                      }}
                      error={!!errors.exp_date}
                      helperText={errors.exp_date?.message as string}
                    />
                  )}
                />
              </Box>
            )}

            {!!showCvv && (
              <Box>
                <Controller
                  name="cvv"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      testId="cvv-input"
                      noHoverBorder={!!showExpDate}
                      onChange={(event) => {
                        field.onChange(event)
                      }}
                      inputProps={{
                        style: {
                          borderRadius: showExpDate
                            ? '0 0 4px 0'
                            : '0 0 4px 4px',
                          height: '44px',
                          padding: '12px',
                        },
                      }}
                      label=""
                      placeholder="CVV"
                      error={!!errors.cvv}
                      helperText={errors.cvv?.message as string}
                      guidingId={`${guidingId}-cc-cvv`}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

import { debounce, isNil } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, CircularProgress, Typography } from '@mui/material'

import {
  MerchantAccount,
  ProductTransactionDetail,
  Recurring,
  Token,
} from '@shared/api'
import { valueToNumber } from '@shared/api/src/utils/valueToNumber'
import { RecurringStatus, SurchargeDisclosure } from '@shared/components'
import { useEnforceLogin, useSub } from '@shared/hooks'
import { formatDate, currency, calculateSurcharge } from '@shared/utils'

import { CUSTOMER_DETAILS_WALLET_CHANGE_EVENT } from '@/components/customer-details/CustomerDetails'

import { mapRecurringTypeId } from '../../recurring-billing-grid/utils/RecurringMapping'
import { RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE_EVENT } from '../merchant-account-details/MerchantAccountDetails'

const useStyles = tss.withName('RecurrenceSummary').create(({ theme }) => ({
  main: {
    marginTop: '16px',
    display: 'flex',
    width: '100%',
    padding: '20px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    borderRadius: '6px',
    border: '1px solid #E5E7EB',
    backgroundColor: theme.palette['gray-tones-100'] || '#F3F4F6',
  },
  labelTitle: {
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette['neutral-700'],
  },
  label: {
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette['gray-tones-700'],
    paddingRight: '4px',
  },
  content: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: '500',
    color: theme.palette['neutral-700'],
  },
  line: {
    borderBottom: '1px solid #E5E7EB',
    width: '100%',
  },
  totalToCollect: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '28px',
  },
}))

interface AddRecurringForm {
  location_id?: string
  notification_days?: number
  installment_total_count?: number
  interval?: number | string
  interval_type?: string
  start_date?: string
  end_date?: string
  transaction_amount?: string | number
  product_transaction_id?: string
  product_transaction?: ProductTransactionDetail
  description?: string
  api_id?: string
  token_id?: string
  recurring_type_id?: string
  status?: string
  next_run_date?: string
  installment_amount_total?: number
}

export type RecurrenceSummaryProps = {
  isAddRecurring?: boolean
  isEditRecurring?: boolean
  recurringData: Recurring | AddRecurringForm
}
function RecurrenceSummary({
  isAddRecurring = false,
  isEditRecurring = false,
  recurringData,
}: RecurrenceSummaryProps) {
  const { classes } = useStyles()
  const { user } = useEnforceLogin()

  const [subtotal, setSubtotal] = useState<number>(0)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [surcharge, setSurcharge] = useState<number>(0)
  const [isCompliantSurchargeApplied, setIsCompliantSurchargeApplied] =
    useState(false)

  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const [selectedWallet, setSelectedWallet] = useState<Token | null>(null)

  const [loadingSurcharge, setLoadingSurcharge] = useState(false)

  const { t } = useTranslation()

  const interval =
    recurringData?.interval_type === 'm'
      ? t('common.month-s')
      : recurringData?.interval_type === 'w'
      ? t('common.week-s')
      : t('common.day-s')

  useSub<
    typeof RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE_EVENT
  >(
    RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE_EVENT.type,
    ({ data: selectedMerchantAccount }) => {
      setSelectedMerchantAccount(selectedMerchantAccount)
    }
  )

  useSub<typeof CUSTOMER_DETAILS_WALLET_CHANGE_EVENT>(
    CUSTOMER_DETAILS_WALLET_CHANGE_EVENT.type,
    ({ data: selectedWallet }) => {
      setSelectedWallet(selectedWallet)
    }
  )

  const fetchSurcharge = async () => {
    if (
      !selectedWallet ||
      (selectedMerchantAccount?.surcharge?.compliant &&
        selectedMerchantAccount?.surcharge?.state_exception_check &&
        !selectedWallet.billing_address.postal_code)
    ) {
      setSurcharge(0)
      return
    }

    setLoadingSurcharge(true)

    try {
      const amounts = await calculateSurcharge(
        selectedMerchantAccount,
        valueToNumber(recurringData.transaction_amount),
        0, // tax - will not apply with recurring
        0, // tip - will not apply with recurring
        selectedWallet?.id
      )

      setIsCompliantSurchargeApplied(
        amounts?.surcharge_applied &&
          !!selectedMerchantAccount?.surcharge?.compliant
      )
      setSurcharge(amounts?.surcharge_amount / 100)
    } catch (error) {
      console.error('Error calculating surcharge:', error)
      setIsCompliantSurchargeApplied(false)
    } finally {
      setLoadingSurcharge(false)
    }
  }

  const debounceCalculation = useCallback(debounce(fetchSurcharge, 500), [
    fetchSurcharge,
  ])

  useEffect(() => {
    debounceCalculation()
    return () => {
      debounceCalculation.cancel()
    }
  }, [
    recurringData.transaction_amount,
    selectedMerchantAccount,
    selectedWallet,
  ])

  const showSurcharge = useMemo(() => {
    return (
      !isNil(selectedMerchantAccount?.surcharge) &&
      !isNil(selectedMerchantAccount?.surcharge?.surcharge_on_recurring) &&
      (isNil(selectedMerchantAccount?.surcharge?.apply_to_user_type_id) ||
        selectedMerchantAccount?.surcharge.apply_to_user_type_id ===
          String(user.user_type_code))
    )
  }, [user, selectedMerchantAccount])

  useEffect(() => {
    if (
      recurringData?.transaction_amount === null ||
      recurringData?.transaction_amount === undefined
    )
      return

    let subtotal = valueToNumber(recurringData?.transaction_amount)
    subtotal = valueToNumber(subtotal)
    setSubtotal(subtotal)

    let totalAmount = subtotal + valueToNumber(surcharge)
    setTotalAmount(valueToNumber(totalAmount))
  }, [showSurcharge, recurringData?.transaction_amount, surcharge])

  return (
    <Box className={classes.main}>
      <Typography className={classes.title}>
        {t('mfe-gateway.recurring-billing.recurrence-summary')}
      </Typography>
      {!isAddRecurring && (
        <div className={classes.content}>
          <div>
            <Typography className={classes.labelTitle}>
              {t('common.status')}
            </Typography>
          </div>
          <div>
            {recurringData?.status ? (
              <RecurringStatus
                statusCode={recurringData.status as Recurring['status']}
              />
            ) : (
              '-'
            )}
          </div>
        </div>
      )}
      <div className={classes.content}>
        <div>
          <Typography className={classes.labelTitle}>
            {t('common.type')}
          </Typography>
        </div>
        <div>
          <Typography className={classes.label}>
            {mapRecurringTypeId(recurringData?.recurring_type_id) || '-'}
          </Typography>
        </div>
      </div>

      {!!showSurcharge && (
        <>
          <div className={classes.content}>
            <div>
              <Typography className={classes.labelTitle}>
                {t('common.amount-subtotal')}
              </Typography>
            </div>
            <div>
              <Typography variant="body2" data-testid="surcharge-amount-value">
                {currency(valueToNumber(subtotal))}
              </Typography>
            </div>
          </div>
          <div className={classes.content}>
            <div>
              <Typography className={classes.labelTitle}>
                {selectedMerchantAccount?.surcharge?.surcharge_label ??
                  t('common.amount-surcharge')}
              </Typography>
            </div>
            <div>
              <Typography variant="body2" data-testid="surcharge-amount-value">
                {loadingSurcharge ? (
                  <CircularProgress
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                ) : (
                  currency(valueToNumber(surcharge))
                )}
              </Typography>
            </div>
          </div>
        </>
      )}

      <div className={classes.content}>
        <div>
          <Typography className={classes.labelTitle}>
            {t('common.recurring-billing.amount-per-payment')}
          </Typography>
        </div>
        <div>
          <Typography className={classes.label}>
            {!isAddRecurring && !isEditRecurring
              ? currency(
                  valueToNumber(recurringData.transaction_amount) +
                    valueToNumber(surcharge)
                ) + ' USD' || '-'
              : currency(totalAmount) + ' USD' || '-'}
          </Typography>
        </div>
      </div>
      <div className={classes.content}>
        <div>
          <Typography className={classes.labelTitle}>
            {t('common.every')}
          </Typography>
        </div>
        <div>
          <Typography className={classes.label}>
            {recurringData?.interval
              ? recurringData?.interval + ' ' + interval
              : '-'}
          </Typography>
        </div>
      </div>
      {(recurringData?.next_run_date !== '0000-00-00' && !isAddRecurring) ||
      (isEditRecurring && recurringData?.recurring_type_id === 'o') ? (
        <div className={classes.content}>
          <div>
            <Typography className={classes.labelTitle}>
              {t('common.date-next-run')}
            </Typography>
          </div>
          <div>
            <Typography className={classes.label}>
              {formatDate(recurringData?.next_run_date, 'UTC') || '-'}
            </Typography>
          </div>
        </div>
      ) : null}
      <div className={classes.content}>
        <div>
          <Typography className={classes.labelTitle}>
            {t('common.date-start')}
          </Typography>
        </div>
        <div>
          <Typography className={classes.label}>
            {formatDate(recurringData?.start_date, 'UTC') || '-'}
          </Typography>
        </div>
      </div>
      {(recurringData?.end_date !== '0000-00-00' && !isAddRecurring) ||
      (isEditRecurring && recurringData?.recurring_type_id === 'o') ? (
        <div className={classes.content}>
          <div>
            <Typography className={classes.labelTitle}>
              {t('common.date-end')}
            </Typography>
          </div>
          <div>
            <Typography className={classes.label}>
              {formatDate(recurringData?.end_date, 'UTC') || '-'}
            </Typography>
          </div>
        </div>
      ) : null}
      {recurringData?.recurring_type_id === 'i' && (
        <>
          <div className={classes.content}>
            <div>
              <Typography className={classes.labelTitle}>
                {t('common.recurring-billing.number-of-payments')}
              </Typography>
            </div>
            <div>
              <Typography className={classes.label}>
                {recurringData?.installment_total_count || '-'}
              </Typography>
            </div>
          </div>
          <div className={classes.line} />
          <div className={classes.content}>
            <div>
              <Typography className={classes.labelTitle}>
                {t('common.recurring-billing.total-to-collect')}
              </Typography>
            </div>
            <div>
              <Typography className={classes.totalToCollect}>
                {!isAddRecurring && !isEditRecurring
                  ? currency(
                      recurringData?.installment_amount_total / 100 +
                        valueToNumber(surcharge) *
                          recurringData?.installment_total_count
                    ) + ' USD'
                  : recurringData?.transaction_amount &&
                    recurringData?.installment_total_count
                  ? currency(
                      (parseFloat(
                        (recurringData?.transaction_amount as string).replace(
                          /,/g,
                          ''
                        )
                      ) +
                        valueToNumber(surcharge)) *
                        recurringData?.installment_total_count
                    ) + ' USD'
                  : '$0.00'}
              </Typography>
            </div>
          </div>
        </>
      )}
      {isCompliantSurchargeApplied && (
        <SurchargeDisclosure merchantAccount={selectedMerchantAccount} />
      )}
    </Box>
  )
}
export default RecurrenceSummary

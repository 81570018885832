import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { Contact, Token } from '@shared/api/src'
import { useFtpPortalHubCommunication, useSub } from '@shared/hooks'
import { accountTypeLabel } from '@shared/types'

import {
  CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT,
  CUSTOMER_DETAILS_WALLET_CHANGE_EVENT,
} from '@/components/customer-details/CustomerDetails'
import VirtualTerminal from '@/components/virtual-terminal/VirtualTerminal'

export default function CustomerCharge() {
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { state } = useLocation()

  const { walletId } = useParams<{ walletId: string }>()

  const [selectedCustomer, setSelectedCustomer] = useState<Contact | null>(null)
  const [selectedWallet, setSelectedWallet] = useState<Token | null>(null)

  // Subscribe to Selected Customer
  useSub<typeof CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT>(
    CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT.type,
    ({ data: customer }) => {
      setSelectedCustomer(customer)
    }
  )

  useSub<typeof CUSTOMER_DETAILS_WALLET_CHANGE_EVENT>(
    CUSTOMER_DETAILS_WALLET_CHANGE_EVENT.type,
    ({ data: wallet }) => {
      setSelectedWallet(wallet)
    }
  )

  useEffect(() => {
    const breadcrumb = [
      t('common.customers'),
      `${selectedCustomer?.first_name || ''} ${
        selectedCustomer?.last_name || ''
      }`,
      t('common.charge'),
    ]

    if (walletId && selectedWallet) {
      breadcrumb.push(
        `${accountTypeLabel[selectedWallet?.account_type]} ${
          selectedWallet?.last_four
        }`
      )
    }

    setAppBarTitle(
      t('mfe-gateway.transaction.run-transaction'),
      null,
      breadcrumb,
      state?.backUrl || '/merchant/gateway/customer'
    )
  }, [selectedCustomer, selectedWallet, walletId])

  return <VirtualTerminal />
}

import { useTranslation } from 'react-i18next'

import { Token } from '@shared/api'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { formatPhoneNumber, getCountryById } from '@shared/utils'

interface BillingInformationViewProps {
  billing_information: Token['billing_address']
}

const BillingInformationView = ({
  billing_information,
}: BillingInformationViewProps) => {
  const { t } = useTranslation()

  const valueStreet = billing_information?.street
  const valueCity = billing_information?.city
  const valueState = billing_information?.state
  const valueCountry = getCountryById(billing_information?.country)
  const valueZip = billing_information?.postal_code
  const valuePhone = formatPhoneNumber(billing_information?.phone)

  return (
    <>
      <FieldGroupContainer title={t('common.billing-information')}>
        <FieldGroupRow>
          <FieldGroupViewItem title={t('common.street')} value={valueStreet} />
          <FieldGroupViewItem title={t('common.city')} value={valueCity} />
        </FieldGroupRow>
        <FieldGroupRow>
          <FieldGroupViewItem title={t('common.state')} value={valueState} />
          <FieldGroupViewItem
            title={t('common.country')}
            value={valueCountry}
          />
        </FieldGroupRow>
        <FieldGroupRow>
          <FieldGroupViewItem title={t('common.zip-code')} value={valueZip} />
          <FieldGroupViewItem title={t('common.phone')} value={valuePhone} />
        </FieldGroupRow>
      </FieldGroupContainer>
    </>
  )
}

export default BillingInformationView

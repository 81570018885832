import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import MultiSelectSearchFilter from '@shared/ag-grid/pagination-table/filters/MultiSelectSearchFilter'
import RadioFilter from '@shared/ag-grid/pagination-table/filters/radio-filter/RadioFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { Paylinks, User } from '@shared/api/src'
import {
  ActiveStatus,
  PaymentStatus,
  HasPermission,
  UserNotAllowed,
} from '@shared/components'
import {
  useLocations,
  useFtpPortalHubCommunication,
  useAuthorization,
} from '@shared/hooks'
import { EnumServiceName, paymentStatusPaylinksCodes } from '@shared/types'
import {
  currency,
  DataSource,
  formatPhoneNumber,
  checkPermission,
  formatDate,
} from '@shared/utils'

import { DeletePaylink } from '../components/modals/delete-paylink/DeletePaylink'

export default function PaylinkGrid() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const gridRef = useRef<AgGridReact>(null)

  const { selectedLocation } = useLocations()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { userPermissionSet } = useAuthorization()
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const [selectedDate, setSelectedDate] = useState<string>(null)
  const [selectedPaylinkId, setSelectedPaylinkId] = useState<string>(null)
  const [isPaylinkModalOpen, setIsPaylinkModalOpen] = useState(false)

  useEffect(() => {
    setAppBarTitle(t('common.paylinks'), null, t('common.gateway'))
  }, [])

  const PaylinksPrivs = ['v2.paylinks.get']
  const paylinkViewBackUrl = '/merchant/gateway/paylinks'

  const datasource = useMemo(() => {
    return new DataSource('paylinks', {
      filterVariant: 'filter',
      fixedFilters: {
        location_id: selectedLocation?.id,
      },
    })
  }, [selectedLocation?.id])

  const columnDefs: ColDef<any>[] = useMemo(() => {
    return [
      {
        headerName: t('common.amount'),
        field: 'amount_due',
        floatingFilter: true,
        type: 'rightAligned',
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) => currency(params.data?.amount_due / 100),
      },
      {
        headerName: t('common.expire-date'),
        field: 'expire_date',
        floatingFilter: true,
        filter: DateRangeFilter,
        sortable: true,
        sort: 'desc',
        sortIndex: 0,
        valueGetter: (params) => {
          return formatDate(params.data?.expire_date, 'UTC') || '-'
        },
        filterParams: {
          type: 'all',
          customFormat: 'yyyy-MM-dd',
        },
      },
      {
        headerName: t('common.status'),
        field: 'active',
        floatingFilter: true,
        cellRenderer: (data) => {
          return <ActiveStatus active={data.value} />
        },
        filter: RadioFilter,
        filterParams: {
          defaultTo: 'true',
          trueLabel: t('common.active'),
          falseLabel: t('common.inactive'),
        },
        sortable: true,
      },
      {
        headerName: t('common.payment-status'),
        field: 'status_code',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.keys(paymentStatusPaylinksCodes),
          valueFormatter: (params) => {
            const option = paymentStatusPaylinksCodes[params.value]
            return option ? option : params.value
          },
        },
        cellRenderer: (data) => (
          <PaymentStatus
            statusCode={data.value}
            statusMapping={paymentStatusPaylinksCodes}
          />
        ),
      },
      {
        headerName: t('common.customer'),
        field: 'contact_id',
        floatingFilter: true,
        sortable: false,
        filter: MultiSelectSearchFilter,
        filterParams: {
          typeOfSearch: 'contains',
          filterType: 'text',
          filterPropName: 'keyword',
          primaryDisplayField: 'first_name',
          secondaryDisplayField: 'last_name',
          placeholder: t('common.search-by-customer-name'),
          service: 'contact-searches',
          value: selectedUsers,
          multiple: true,
          isSearchCustomers: true,
          onChange: (event) => setSelectedUsers(event),
        },
        valueGetter: (params) => {
          const firstName = params.data.contact?.first_name || ''
          const lastName = params.data.contact?.last_name || ''
          return firstName && lastName ? `${firstName} ${lastName}` : ''
        },
      },
      {
        headerName: t('common.email'),
        field: 'email',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.phone-cell'),
        field: 'cell_phone',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => formatPhoneNumber(params.data?.cell_phone),
      },
    ]
  }, [t, selectedDate, selectedUsers, userPermissionSet, navigate])

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const refreshGrid = () => {
    gridRef.current!.api.refreshServerSide({ purge: true })
  }

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  const threeDotsOptions = useCallback(
    (data: Paylinks) => [
      {
        label: t('common.view'),
        action: () =>
          navigate(`/merchant/gateway/paylinks/${data.id}/view`, {
            state: { backUrl: paylinkViewBackUrl },
          }),
        enabled: checkPermission(userPermissionSet, 'v2.paylinks.get'),
      },
      {
        label: t('common.edit'),
        action: () =>
          navigate(`/merchant/gateway/paylinks/${data.id}/edit`, {
            state: { backUrl: paylinkViewBackUrl },
          }),
        enabled:
          checkPermission(userPermissionSet, 'v2.paylinks.put') &&
          data.active &&
          data.status_code === 0 &&
          data.expire_date >= new Date().toISOString().split('T')[0],
      },
      {
        label: t('common.delete'),
        action: () => {
          setSelectedPaylinkId(data.id)
          setIsPaylinkModalOpen(true)
        },
        enabled:
          data.active &&
          checkPermission(userPermissionSet, 'v2.paylinks.delete'),
      },
    ],
    [userPermissionSet, t, paylinkViewBackUrl]
  )

  return (
    <section>
      <HasPermission
        allPermissions={PaylinksPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <>
          <DeletePaylink
            open={isPaylinkModalOpen}
            id={selectedPaylinkId}
            onSuccess={refreshGrid}
            onClose={() => {
              setIsPaylinkModalOpen(false)
              setSelectedPaylinkId(null)
            }}
          />
          <PaginationTable<Paylinks>
            getRowId={(data) => data.data.id}
            columnDefs={columnDefs}
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            defaultColDef={defaultColDef}
            showExportButton={true}
            showClearFiltersButton={true}
            serviceName={EnumServiceName.Paylinks}
            onRowClicked={({ data }) =>
              navigate(`/merchant/gateway/paylinks/${data.id}/view`)
            }
            getGridRef={getGridRef}
            guidingId="paylinks"
            primaryButtonData={
              checkPermission(userPermissionSet, 'v2.paylinks.post')
                ? {
                    text: t('mfe-gateway.paylink.action.add'),
                    action: () => navigate('/merchant/gateway/paylinks/add'),
                  }
                : null
            }
            threeDotsOptions={threeDotsOptions}
          />
        </>
      </HasPermission>
    </section>
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import React, { SyntheticEvent, useState } from 'react'
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Grid, Typography } from '@mui/material'

import { Recurring, api } from '@shared/api'
import { ActionModal, InputIntegerOnly } from '@shared/components'
import { toArrayFieldErrors } from '@shared/utils'

const useStyles = tss.withName('DeferPayment').create(({ theme }) => ({
  modal: {
    width: '600px',
    borderRadius: '6px',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.palette['neutral-700'],
    marginBottom: '20px',
  },
}))

interface FormData {
  payments_number: string
}

export interface DeferPaymentProps {
  recurring: Recurring
  open: boolean
  onClose: () => void | SyntheticEvent<any, Event>
  onCompleted: (message: string, isError?: boolean) => void
}

const DeferPayment: React.FC<DeferPaymentProps> = ({
  recurring,
  open,
  onClose,
  onCompleted,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [showDeferPaymentAction, setShowDeferPaymentAction] =
    useState<boolean>(false)

  const [recurringActionLabel, setRecurringActionLabel] =
    useState<React.ReactNode>()

  const schema = yup.object().shape({
    payments_number: yup
      .string()
      .required(
        t(
          'mfe-gateway.validations.recurring-billing.installment-total-count-required'
        )
      )
      .test(
        'is-valid-number',
        t(
          'mfe-gateway.validations.recurring-billing.number-of-payments-invalid'
        ),
        (value) => {
          if (!value) return false
          const onlyDigits = value.replace(/\D/g, '')
          if (!onlyDigits) return false
          const num = parseFloat(onlyDigits)
          return num >= 1 && num <= 99
        }
      ),
  })

  const methods = useForm({
    defaultValues: {
      payments_number: '',
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const onCloseDeferPayment = () => {
    methods.reset()
    setShowDeferPaymentAction(false)
    onClose()
  }

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (!recurring?.id) return
    setIsLoading(true)

    try {
      await api
        .service('recurring-billings')
        .defer(
          recurring.id,
          parseFloat(data.payments_number.replace(/\D/g, ''))
        )
      onCompleted(t('mfe-gateway.toast.recurring-billing.defer-success'), false)
      onCloseDeferPayment()
    } catch (error) {
      const errors = toArrayFieldErrors(error)
      const messages = errors.map((err) => err.message)
      const displayMessages = messages.join(' | ')
      onCompleted(
        t('mfe-gateway.validations.recurring-billing.error-deferring-payment'),
        true
      )
      methods.setError('payments_number', {
        type: 'error',
        message: displayMessages,
      })
      setShowDeferPaymentAction(false)
    } finally {
      setIsLoading(false)
    }
  }

  const openDeferPaymentAction: SubmitHandler<FormData> = (data) => {
    setRecurringActionLabel(
      <>
        {t('common.modal.are-you-sure')}{' '}
        <strong>
          {`${t('mfe-gateway.action.recurring-billing.defer-lowercase')} ${
            data.payments_number
          } ${t('common.payment-s')}`}
        </strong>
        ? {t('common.modal.this-cannot-be-undone')}
      </>
    )
    setShowDeferPaymentAction(true)
  }

  return (
    <>
      <ActionModal
        open={open}
        title={t('mfe-gateway.action.recurring-billing.defer')}
        className={classes.modal}
        buttons={[
          {
            testId: 'cancel-button',
            label: t('common.cancel'),
            color: 'secondary',
            onClick: onCloseDeferPayment,
            guidingId: 'recurringbilling-defer-cancel',
          },
          {
            testId: 'submit-button',
            label: t('mfe-gateway.action.recurring-billing.defer'),
            onClick: methods.handleSubmit(openDeferPaymentAction),
            isLoading,
            guidingId: 'recurringbilling-defer-confirm',
          },
        ]}
        onClose={onCloseDeferPayment}
      >
        <FormProvider {...methods}>
          <form>
            <Typography
              className={classes.text}
              data-testid="modal-text"
              sx={{ marginBottom: '20px' }}
            >
              {t('mfe-gateway.action.recurring-billing.defer-explanation')}
            </Typography>

            <Typography className={classes.text} data-testid="modal-sub-text">
              {t('mfe-gateway.action.recurring-billing.defer-explanation-more')}
            </Typography>

            <Grid item sm={12} md={12}>
              <Controller
                name="payments_number"
                control={methods.control}
                render={({ field }) => (
                  <InputIntegerOnly
                    {...field}
                    value={String(field.value)}
                    onChange={(evt) => field.onChange(evt.target.value)}
                    testId="payments-number-input"
                    required
                    label={t(
                      'mfe-gateway.action.recurring-billing.defer-input-label'
                    )}
                    placeholder={t(
                      'mfe-gateway.action.recurring-billing.defer-input-placeholder'
                    )}
                    error={!!methods.formState.errors.payments_number}
                    helperText={
                      methods.formState.errors.payments_number?.message
                    }
                    autoFocus
                    guidingId="recurringbilling-defer-paymentsnumber"
                  />
                )}
              />
            </Grid>
          </form>
        </FormProvider>
      </ActionModal>

      <ActionModal
        className={classes.modal}
        open={showDeferPaymentAction}
        title={t('mfe-gateway.action.recurring-billing.defer')}
        buttons={[
          {
            testId: 'recurring-action-cancel-button',
            label: t('common.cancel'),
            color: 'secondary',
            onClick: onCloseDeferPayment,
          },
          {
            testId: 'recurring-action-submit-button',
            label: t('mfe-gateway.action.recurring-billing.defer-confirm'),
            isLoading,
            onClick: methods.handleSubmit(onSubmit),
          },
        ]}
        onClose={onCloseDeferPayment}
      >
        {recurringActionLabel}
      </ActionModal>
    </>
  )
}

export default DeferPayment

import { FC } from 'react'
import { Controller, FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { FieldGroupContainer, FieldGroupRow, Input } from '@shared/components'

interface AdvancedSettingsProps {
  isEditable?: boolean
}

export const AdvancedSettings: FC<AdvancedSettingsProps> = ({
  isEditable = true,
}) => {
  const { t } = useTranslation()
  const methods = useFormContext()
  return (
    <FormProvider {...methods}>
      <FieldGroupContainer
        title={t('common.advanced-settings')}
        showContent={false}
        showToggle
        titleTestID="quick-invoice-advanced-settings"
        toggleTestID="quick-invoice-advanced-settings-toggle"
        guidingId="quickinvoice-advancedsettings"
      >
        <FieldGroupRow>
          <Grid item xs={12}>
            <Controller
              name="quick_invoice_api_id"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(event) => {
                    field.onChange(event)
                  }}
                  label={t('mfe-gateway.quick-invoice.invoice-api-id')}
                  placeholder={t('common.api-id-placeholder')}
                  error={!!methods.formState.errors.quick_invoice_api_id}
                  helperText={methods.formState.errors.quick_invoice_api_id?.message.toString()}
                  disabled={!isEditable}
                  guidingId="quickinvoice-advancedsettings-apiid"
                />
              )}
            />
          </Grid>
        </FieldGroupRow>
      </FieldGroupContainer>
    </FormProvider>
  )
}

const errorMapping = [
  { oneco: 'billing_address.country', legacy: 'billing_country' },
  { oneco: 'billing_address.state', legacy: 'billing_state' },
  { oneco: 'billing_address.city', legacy: 'billing_city' },
  { oneco: 'billing_address.postal_code', legacy: 'billing_zip' },
  { oneco: 'billing_address.phone', legacy: 'billing_phone' },
  { oneco: 'billing_address.street', legacy: 'billing_street' },
  { oneco: 'transactionType', legacy: 'action' },
  { oneco: 'account_number', legacy: 'account_vault_id' },
]

export default errorMapping

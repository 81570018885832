import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { FieldGroupContainer, Input } from '@shared/components'

interface FormData {
  contact_api_id: string
}

const AdvancedSettings = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>()

  return (
    <FieldGroupContainer
      title={t('common.advanced-settings')}
      showToggle={true}
      showContent={false}
    >
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item sm={12}>
          <Controller
            name="contact_api_id"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="contact-api-id-input"
                label={t('mfe-gateway.customer.customer-api-id')}
                placeholder={t('common.api-id-placeholder')}
                error={!!errors.contact_api_id}
                helperText={
                  !!errors.contact_api_id
                    ? errors.contact_api_id?.message
                    : t('common.leave-this-field-blank')
                }
                guidingId={'customers-apiid'}
              />
            )}
          />
        </Grid>
      </Grid>
    </FieldGroupContainer>
  )
}

export default AdvancedSettings

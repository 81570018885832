import React, { SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box } from '@mui/material'

import { Recurring, api } from '@shared/api'
import { ActionModal } from '@shared/components'
import { toArrayFieldErrors } from '@shared/utils'

const useStyles = tss.withName('PausePayment').create(({ theme }) => ({
  modal: {
    width: '600px',
    borderRadius: '6px',
  },
  textContainer: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.palette['neutral-700'],
  },
}))

export interface PausePaymentProps {
  recurring: Recurring
  open: boolean
  onClose: () => void | SyntheticEvent<any, Event>
  onCompleted: (message: string, isError?: boolean) => void
}

const PausePayment: React.FC<PausePaymentProps> = ({
  recurring,
  open,
  onClose,
  onCompleted,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit = async () => {
    if (!recurring?.id) return
    setIsLoading(true)

    try {
      await api.service('recurring-billings').pause(recurring.id)

      onCompleted(t('mfe-gateway.toast.recurring-billing.pause-success'), false)
      onClose()
    } catch (error) {
      const errors = toArrayFieldErrors(error)

      const messages = errors.map((error) => error.message)
      const displayMessages = messages.join(' | ')

      onCompleted(displayMessages, true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ActionModal
      open={open}
      title={t('mfe-gateway.action.recurring-billing.pause')}
      buttons={[
        {
          testId: 'cancel-button',
          label: t('common.cancel'),
          color: 'secondary',
          onClick: onClose,
          guidingId: 'recurringbilling-pause-cancel',
        },
        {
          testId: 'submit-button',
          label: t('mfe-gateway.action.recurring-billing.pause-confirm'),
          isLoading,
          onClick: onSubmit,
          guidingId: 'recurringbilling-pause-confirm',
        },
      ]}
      onClose={onClose}
      className={classes.modal}
    >
      <Box className={classes.textContainer} data-testid="modal-text">
        {t('common.modal.are-you-sure')}{' '}
        <strong>
          {t('mfe-gateway.action.recurring-billing.pause-explanation')}
        </strong>
        ?
      </Box>
    </ActionModal>
  )
}

export default PausePayment

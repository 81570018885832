import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { AppBar } from '@mui/material'

import { api } from '@shared/api/src'
import {
  ButtonBar,
  ButtonBarEnd,
  Button,
  Notification,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutDivider,
} from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useLocations,
  useNotification,
} from '@shared/hooks'
import { formatRawDate, processPhone, toArrayFieldErrors } from '@shared/utils'

import { BillingInformation } from '@/components/billing-information/BillingInformation'

import {
  AdvancedSettings,
  CustomerDetails,
  ContactInformation,
} from '../components'

interface FormData {
  location_id: string
  address: {
    country: string
    street: string
    city: string
    state: string
    postal_code: string
  }
  first_name: string
  last_name: string
  email: string
  home_phone: string
  cell_phone: string
  account_number: string
  date_of_birth: string
  contact_api_id: string
}

const buildSchema = (t: (key: string) => string) =>
  yup.object().shape({
    location_id: yup.string(),
    first_name: yup.string(),
    last_name: yup
      .string()
      .required(t('mfe-gateway.validations.customer.last-name-required')),
    email: yup
      .string()
      .email(t('common.validations.invalid-email'))
      .test('valid-email', t('common.validations.invalid-email'), (value) => {
        if (!value) return true
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
      }),
    address: yup.object().shape({
      state: yup.string(),
      postal_code: yup.string(),
      country: yup.string(),
      street: yup.string(),
      city: yup.string(),
    }),
    home_phone: yup.string(),
    cell_phone: yup.string(),
    account_number: yup.string(),
    date_of_birth: yup
      .string()
      .test(
        'valid-date',
        t('mfe-gateway.validations.customer.invalid-date-format'),
        (value) => {
          if (!value) return true
          return /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(
            value
          )
        }
      ),
    contact_api_id: yup.string(),
  })

export default function CustomerAdd() {
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { selectedLocation } = useLocations()
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const schema = buildSchema(t)

  const methods = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      home_phone: '',
      cell_phone: '',
      account_number: '',
      location_id: selectedLocation?.id,
      address: { country: selectedLocation?.address?.country },
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.customer.add-customer'),
      null,
      t('common.customers'),
      '/merchant/gateway/customer'
    )
  }, [])

  const onSubmit: SubmitHandler<FormData> = async ({
    home_phone,
    cell_phone,
    date_of_birth,
    account_number,
    contact_api_id,
    email,
    first_name,
    last_name,
    location_id,
    address,
  }) => {
    const payload = {
      location_id,
      address: {
        city: !!address.city ? address.city : undefined,
        country: !!address.country ? address.country : undefined,
        postal_code: !!address.postal_code ? address.postal_code : undefined,
        state: !!address.state ? address.state : undefined,
        street: !!address.street ? address.street : undefined,
      },
      contact_api_id: !!contact_api_id ? contact_api_id : undefined,
      account_number: !!account_number ? account_number : undefined,
      email: !!email ? email : undefined,
      first_name: !!first_name ? first_name : undefined,
      last_name: !!last_name ? last_name : undefined,
      home_phone: home_phone ? processPhone(home_phone) : undefined,
      date_of_birth: date_of_birth
        ? formatRawDate(date_of_birth, 'yyyy-MM-dd', 'UTC')
        : undefined,
      cell_phone: cell_phone ? processPhone(cell_phone) : undefined,
    }

    setIsLoading(true)
    try {
      const result = await api.service('contacts').create(payload)

      navigate(`/merchant/gateway/customer/${result.id}/view`)
    } catch (error) {
      setNotification({
        label: t('common.please-review-information'),
        type: 'error',
      })

      const errorsFormatted = toArrayFieldErrors(error)

      errorsFormatted.forEach((e) => {
        methods.setError(e.field, {
          type: 'manual',
          message: e.message,
        })
      })

      methods.setFocus(errorsFormatted[0]?.field)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PageLayoutContainer isButtonBarAtBottom>
          <PageLayoutContainerMain>
            <CustomerDetails />

            <PageLayoutDivider />

            <ContactInformation />

            <PageLayoutDivider />

            <BillingInformation
              basePath="address"
              type="address"
              showPhone={false}
              showCountryAllOptions={false}
            />

            <PageLayoutDivider />

            <AdvancedSettings />
          </PageLayoutContainerMain>

          <AppBar
            sx={{
              bottom: 0,
              top: 'auto',
              position: 'fixed',
              boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
            }}
          >
            <ButtonBar style={{ marginBottom: '0 !important' }}>
              <ButtonBarEnd>
                <Button
                  label={t('common.cancel')}
                  color="secondary"
                  style={{ width: '101px' }}
                  testId="cancel-button"
                  onClick={() => {
                    navigate('/merchant/gateway/customer')
                  }}
                  guidingId="add-customers-cancel"
                />
                <Button
                  type="submit"
                  label={t('common.save')}
                  isLoading={isLoading}
                  style={{ marginLeft: '8px', width: '86px' }}
                  testId="save-button"
                  guidingId="add-customers-save"
                />
              </ButtonBarEnd>
            </ButtonBar>
          </AppBar>
        </PageLayoutContainer>
      </form>
    </FormProvider>
  )
}

import { FC, useCallback, useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { Contact, MerchantAccount, Token, api } from '@shared/api'
import { HasPermission, GetItemsOptions, Input } from '@shared/components'
import { useLocations } from '@shared/hooks/useLocations'
import { usePub, PubSubEvent } from '@shared/hooks/usePub'
import { useSub } from '@shared/hooks/useSub'
import { searchCustomers } from '@shared/services/customers'
import { getWalletByCustomerId } from '@shared/services/wallet'
import { ProcessMethod } from '@shared/types'

import { RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE_EVENT } from '@/pages/recurring-billing/components/merchant-account-details/MerchantAccountDetails'

import { CustomerInformationToggle } from '../customer-information-toggle/CustomerInformationToggle'
import { CustomerSelector } from '../customer-selector/CustomerSelector'
import { PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT } from '../payment-account-details/PaymentAccountDetails'
import { TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT } from '../transaction-information/TransactionInformation'
import { WalletSelector } from '../wallet-selector/WalletSelector'

export const CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT = new Event(
  'CUSTOMER_DETAILS_CUSTOMER_CHANGE'
) as PubSubEvent<Contact | null>

export const CUSTOMER_DETAILS_WALLET_CHANGE_EVENT = new Event(
  'CUSTOMER_DETAILS_WALLET_CHANGE'
) as PubSubEvent<Token | null>

type StylesParams = {
  hideWallet: boolean
}

const useStyles = tss
  .withName('CustomerDetails')
  .withParams<StylesParams>()
  .create(({ hideWallet }) => ({
    root: {
      height: 'fit-content',
      width: '100%',
      padding: '1em',
      backgroundColor: 'rgb(243 244 246 / 1)',
      border: '1px solid rgb(229 231 235 / 1)',
      borderRadius: '.5em',
    },
    title: {
      marginBottom: '.3em',
    },
    customerSelectorContainer: { margin: '1em 0' },
    customerInfoToggleContainer: {
      marginBottom: !hideWallet ? '1.2em' : undefined,
    },
    cvvInput: {
      marginTop: '16px',
    },
  }))

export interface CustomerDetailsProps {
  /**
   * If `true`, the Customer Selector will be disabled.
   */
  disableCustomer?: boolean

  /**
   * If `true`, the Wallet Selector will be disabled.
   */
  disableWallet?: boolean

  /**
   * If `true`, the Wallet Selector will be hidden.
   */
  hideWallet?: boolean

  /**
   * If `true`, the Customer Selector will be required.
   */
  customerRequired?: boolean

  setCustomerCountry?: (country: string) => void

  guidingId?: string
}

export const CustomerDetails: FC<CustomerDetailsProps> = ({
  disableCustomer,
  hideWallet,
  disableWallet,
  setCustomerCountry,
  customerRequired = true,
  guidingId,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles({ hideWallet: !!hideWallet })
  const { selectedLocation } = useLocations()
  const { setValue, getValues, formState, control } = useFormContext()
  const publish = usePub()

  const [customers, setCustomers] = useState<Contact[] | null>(null)
  const [selectedCustomer, setSelectedCustomer] = useState<Contact | null>(null)

  const [wallets, setWallets] = useState<Token[] | null>(null)
  const [selectedWallet, setSelectedWallet] = useState<Token | null>(null)
  const [loadingWallets, setLoadingWallets] = useState(false)

  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const [selectedProcessMethod, setSelectedProcessMethod] =
    useState<ProcessMethod | null>(null)

  const getCustomers = useCallback(
    async (options?: GetItemsOptions) => {
      if (!selectedLocation?.id) return []
      return searchCustomers(selectedLocation.id, options?.search ?? '')
    },
    [selectedLocation]
  )

  const getWallet = (walletId: string) => {
    api
      .service('tokens')
      .get(walletId)
      .then((wallet) => {
        onChangeWallet(wallet)
        setSelectedWallet(wallet)
      })
  }

  const getWallets = useCallback(
    async (
      options: GetItemsOptions & {
        customer: Contact | null
        merchantAccount?: MerchantAccount | null
      }
    ) => {
      const { customer, merchantAccount } = options

      if (!customer) return null

      setLoadingWallets(true)
      return getWalletByCustomerId(
        customer.id,
        true,
        merchantAccount?.payment_method
      ).finally(() => setLoadingWallets(false))
    },
    [selectedWallet]
  )

  const onChangeCustomer = useCallback(
    (customer: Contact | null) => {
      setSelectedCustomer(customer)
      setValue('contact_id', customer?.id ?? undefined)
      publish(CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT, customer)

      if (!!customer && !customers?.length) {
        setCustomers([customer])
      }
      if (setCustomerCountry) {
        setCustomerCountry(customer?.address?.country)
      }

      if (!customer && !!selectedWallet) {
        setSelectedWallet(null)
        setValue('token_id', undefined)
        setValue('account_number', undefined)
        publish(CUSTOMER_DETAILS_WALLET_CHANGE_EVENT, null)
      }

      if (!hideWallet) {
        getWallets({
          customer,
          merchantAccount: selectedMerchantAccount,
        }).then((response) => {
          setWallets(response)
          if (
            selectedWallet &&
            response?.findIndex((w) => w.id === selectedWallet?.id) === -1
          ) {
            onChangeWallet(null)
          }
        })
      }
    },
    [customers, selectedWallet, selectedMerchantAccount, hideWallet, getWallets]
  )

  const onChangeWallet = useCallback(
    (wallet: Token | null) => {
      setSelectedWallet(wallet)
      setValue('token_id', wallet?.id ?? undefined)
      setValue('account_number', wallet?.account_number ?? undefined)
      publish(CUSTOMER_DETAILS_WALLET_CHANGE_EVENT, wallet)

      if (!!wallet && !wallets?.length) {
        setWallets([wallet])
      }
    },
    [wallets]
  )

  const onChangeMerchantAccount = useCallback(
    (merchantAccount: MerchantAccount | null) => {
      setSelectedMerchantAccount(merchantAccount)

      if (!hideWallet) {
        getWallets({
          customer: selectedCustomer,
          merchantAccount: merchantAccount,
        }).then((response) => {
          setWallets(response)
          if (response?.findIndex((w) => w.id === selectedWallet?.id) === -1) {
            onChangeWallet(null)
          }
        })
      }
    },
    [selectedCustomer, selectedWallet, hideWallet, getWallets]
  )

  // Subscribe to Selected Merchant Account
  useSub<typeof TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT>(
    TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT.type,
    ({ data: merchantAccount }) => onChangeMerchantAccount(merchantAccount),
    [onChangeMerchantAccount]
  )

  useSub<
    typeof RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE_EVENT
  >(
    RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE_EVENT.type,
    ({ data: merchantAccount }) => onChangeMerchantAccount(merchantAccount),
    [onChangeMerchantAccount]
  )

  // Subscribe to Selected Process Method
  useSub<typeof PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT>(
    PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT.type,
    ({ data: processMethod }) => {
      if (processMethod === selectedProcessMethod) return
      setSelectedProcessMethod(processMethod)

      if (processMethod === 'wallet') {
        getWallets({
          customer: selectedCustomer,
          merchantAccount: selectedMerchantAccount,
        }).then((response) => {
          setWallets(response)
          if (wallets?.findIndex((w) => w.id === selectedWallet?.id) === -1) {
            onChangeWallet(null)
          }
        })
      } else if (wallets?.length || !!selectedWallet) {
        setWallets([])
        setSelectedWallet(null)
      }
    },
    [
      getWallets,
      wallets,
      selectedWallet,
      selectedCustomer,
      selectedMerchantAccount,
    ]
  )

  useEffect(() => {
    const customerId = getValues('contact_id')
    const tokenId = getValues('token_id')

    if (customerId) {
      api
        .service('contacts')
        .get(customerId)
        .then((customer) => {
          onChangeCustomer(customer)
        })
    }
    if (tokenId) {
      getWallet(tokenId)
    }
  }, [])

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="h6">
        {t('mfe-gateway.customer-details')}
      </Typography>

      <HasPermission permission="v2.contacts.get">
        <Box>
          <Box className={classes.customerSelectorContainer}>
            <CustomerSelector
              customers={customers ?? []}
              selectedCustomer={selectedCustomer}
              getCustomers={getCustomers}
              onChangeCustomers={setCustomers}
              onChangeSelectedCustomer={onChangeCustomer}
              disabled={disableCustomer}
              required={customerRequired}
              guidingId={`${guidingId}-customerdetails`}
            />
          </Box>

          <Box className={classes.customerInfoToggleContainer}>
            <CustomerInformationToggle
              customer={selectedCustomer}
              guidingId={`${guidingId}-customerdetails`}
            />
          </Box>
        </Box>
      </HasPermission>

      {!hideWallet && (
        <>
          <Box>
            <WalletSelector
              wallets={wallets ?? []}
              selectedWallet={selectedWallet}
              onChangeWallets={setWallets}
              onChangeSelectedWallet={onChangeWallet}
              loading={loadingWallets}
              disabled={disableWallet}
              showHint={
                !!selectedCustomer || selectedProcessMethod === 'wallet'
              }
              required
              guidingId={`${guidingId}-customerdetails`}
            />
          </Box>

          {!!selectedWallet &&
            selectedProcessMethod === 'wallet' &&
            selectedMerchantAccount?.payment_method === 'cc' &&
            selectedMerchantAccount?.vt_cvv && (
              <Box className={classes.cvvInput}>
                <Controller
                  name="cvv"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      testId="cvv-input"
                      onChange={(event) => {
                        field.onChange(event)
                      }}
                      noHoverBorder
                      label="CVV"
                      placeholder="CVV"
                      error={!!formState.errors.cvv}
                      helperText={formState.errors.cvv?.message as string}
                      inputProps={{
                        style: { backgroundColor: 'white' },
                      }}
                      required={
                        !!selectedMerchantAccount.require_cvv_on_tokenized_cnp
                      }
                      guidingId={`${guidingId}-customerdetails-cvv`}
                    />
                  )}
                />
              </Box>
            )}
        </>
      )}
    </Box>
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'
import * as yup from 'yup'

import { AppBar } from '@mui/material'

import { Recurring, Tags, api } from '@shared/api'
import {
  HasPermission,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutContainerSide,
  PageLayoutDivider,
  UserNotAllowed,
  Button,
  ButtonBar,
  ButtonBarEnd,
} from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useLocations,
  useNotification,
} from '@shared/hooks'
import { usePub } from '@shared/hooks/usePub'
import { formatRawDate, formatDate, toFieldErrors } from '@shared/utils'

import { CustomerDetails } from '@/components/customer-details/CustomerDetails'

import AdvancedSettings from '../components/advanced-settings/AdvancedSettings'
import MerchantAccountDetails from '../components/merchant-account-details/MerchantAccountDetails'
import OptionalInformation from '../components/optional-information/OptionalInformation'
import RecurrenceSettings from '../components/recurrence-settings/RecurrenceSetting'
import RecurrenceSummary from '../components/recurrence-summary/RecurrenceSummary'
interface FormData {
  location_id: string
  notification_days: number
  installment_total_count: number
  interval: string
  interval_type: 'd' | 'w' | 'm'
  next_run_date?: string | null
  start_date: string
  end_date: string
  transaction_amount: string
  product_transaction_id: string
  description: string
  recurring_api_id: string
  contact_id: string
  token_id: string
  recurring_type_id: string
  tags: Tags[]
  status: 'active' | 'on hold' | 'ended'
  active: boolean
  account_vault_id: string
  payment_method: 'cc' | 'ach'
}

export default function RecurringBillingEdit() {
  const { t } = useTranslation()
  const { state } = useLocation()
  const publish = usePub()
  const { setAppBarTitle } = useFtpPortalHubCommunication()

  const { selectedLocation } = useLocations()
  const { setNotification } = useNotification()
  const navigate = useNavigate()
  const recurringBillingPrivs = ['v2.recurrings.put']

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [recurringData] = useState<Recurring | null>(state.recurringData)

  const returnToUrl = state?.backUrl || '/merchant/gateway/recurring-billing'

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.action.recurring-billing.edit'),
      null,
      [t('common.gateway'), t('common.recurring-billing')],
      returnToUrl
    )
  }, [])

  const schema = yup.object().shape({
    location_id: yup.string(),
    notification_days: yup
      .number()
      .nullable()
      .min(
        1,
        t('mfe-gateway.validations.recurring-billing.payment-reminder-days-min')
      )
      .max(
        365,
        t('mfe-gateway.validations.recurring-billing.payment-reminder-days-max')
      )
      .transform((value, original) => (original === '' ? null : value)),
    installment_total_count: yup
      .number()
      .nullable()
      .typeError(
        t(
          'mfe-gateway.validations.recurring-billing.installment-total-count-required'
        )
      )
      .test(
        'conditionalValidation',
        t('mfe-gateway.validations.recurring-billing.installment-total-count'),
        function (value) {
          const recurringType = this.parent.recurring_type_id
          // Validate only if recurring_type_id is 'i'
          if (recurringType === 'i') {
            return yup.number().min(1).isValidSync(value)
          }
          // Return true if recurringType is not 'i' (skipping validation)
          return true
        }
      ),
    interval: yup
      .string()
      .required(
        t('mfe-gateway.validations.recurring-billing.interval-required')
      )
      .test(
        'is-valid-number',
        t('mfe-gateway.validations.recurring-billing.interval-invalid'),
        (value) => {
          if (!value) return false
          const parsedValue = parseFloat(value)
          return !isNaN(parsedValue) && parsedValue >= 1
        }
      ),
    interval_type: yup.string().nullable(),
    start_date: yup
      .string()
      .required(
        t('mfe-gateway.validations.recurring-billing.starting-on-required')
      )
      .test(
        'valid-date',
        t('common.validations.invalid-date-format'),
        (value) => {
          if (!value) return true
          return /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(
            value
          )
        }
      ),
    end_date: yup
      .string()
      .test(
        'valid-date',
        t('common.validations.invalid-date-format'),
        (value) => {
          if (!value) return true
          return /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(
            value
          )
        }
      ),
    next_run_date: yup
      .string()
      .nullable()
      .test(
        'valid-date',
        t('common.validations.invalid-date-format'),
        (value) => {
          if (!value) return true
          return /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(
            value
          )
        }
      )
      .transform((value, original) =>
        original === '' || original === '-' ? null : value
      ),
    transaction_amount: yup
      .string()
      .required(
        t(
          'mfe-gateway.validations.recurring-billing.transaction-amount-is-required'
        )
      ),
    product_transaction_id: yup.string(),
    description: yup.string().nullable(),
    recurring_api_id: yup.string().nullable(),
    contact_id: yup
      .string()
      .required(
        t('mfe-gateway.validations.recurring-billing.customer-required')
      ),
    token_id: yup
      .string()
      .required(t('mfe-gateway.validations.recurring-billing.wallet-required')),
    recurring_type_id: yup.string().required(),
    tags: yup.array(),
    status: yup.string(),
    active: yup.boolean(),
    account_vault_id: yup.string(),
    payment_method: yup.string(),
  })

  const methods = useForm({
    defaultValues: {
      location_id: selectedLocation?.id,
      notification_days:
        (recurringData?.notification_days as number) || undefined,
      installment_total_count: recurringData?.installment_total_count,
      interval: recurringData?.interval.toString(),
      interval_type: recurringData?.interval_type,
      next_run_date: formatDate(recurringData?.next_run_date, 'UTC'),
      start_date: formatDate(recurringData?.start_date, 'UTC'),
      end_date:
        recurringData?.end_date !== '0000-00-00'
          ? formatDate(recurringData?.end_date, 'UTC')
          : undefined,
      transaction_amount: (recurringData?.transaction_amount).toString(),
      product_transaction_id: recurringData?.product_transaction_id,
      description: recurringData?.description,
      recurring_api_id: recurringData?.recurring_api_id,
      contact_id: recurringData?.contact_id,
      token_id: recurringData?.token_id,
      recurring_type_id: recurringData?.recurring_type_id,
      tags: recurringData?.tags?.map((tag) => tag.title),
      status: recurringData?.status,
      active: recurringData?.active,
      account_vault_id: recurringData?.account_vault_id,
      payment_method: recurringData?.payment_method,
    },
    resolver: yupResolver(schema),
    mode: 'all',
  })

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const payload = {
      location_id: selectedLocation?.id,
      notification_days: data.notification_days,
      installment_total_count: data.installment_total_count,
      interval: parseFloat(data.interval),
      interval_type: data.interval_type,
      next_run_date: data.next_run_date
        ? formatRawDate(data.next_run_date, 'yyyy-MM-dd', 'UTC')
        : data.status === 'on hold'
        ? '0000-00-00'
        : null,
      start_date: formatRawDate(data.start_date, 'yyyy-MM-dd', 'UTC'),
      end_date: data.end_date
        ? formatRawDate(data.end_date, 'yyyy-MM-dd', 'UTC')
        : '0000-00-00', // if null, it would end the recurring
      transaction_amount: Math.round(
        parseFloat(data.transaction_amount.replace(/,/g, '')) * 100
      ),
      product_transaction_id: data.product_transaction_id,
      description: data.description || null,
      recurring_api_id: data.recurring_api_id,
      contact_id: data.contact_id,
      token_id: data.token_id,
      tags: data.tags,
      status: data.status,
      active: data.active,
      account_vault_id: data.account_vault_id,
      payment_method: data.payment_method,
    }

    setIsLoading(true)

    try {
      const result = await api
        .service('recurring-billings')
        .patch(recurringData?.id, payload)

      navigate(`/merchant/gateway/recurring-billing/${result.id}/view`)
    } catch (error) {
      setNotification({
        label: t('common.please-review-information'),
        type: 'error',
      })

      if (error.code) {
        const errorsFormatted = toFieldErrors(error)

        Object.keys(errorsFormatted).forEach((key: any) => {
          methods.setError(key, {
            type: 'manual',
            message: errorsFormatted[key],
          })
        })

        methods.setFocus(errorsFormatted[0]?.field)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <HasPermission
      allPermissions={recurringBillingPrivs}
      unauthorizedComponent={<UserNotAllowed />}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PageLayoutContainer isButtonBarAtBottom>
            <PageLayoutContainerMain>
              <MerchantAccountDetails />
              <PageLayoutDivider />

              <RecurrenceSettings isEditRecurring={true} />
              <PageLayoutDivider />

              <OptionalInformation />
              <PageLayoutDivider />

              <AdvancedSettings />
            </PageLayoutContainerMain>
            <PageLayoutContainerSide>
              <CustomerDetails
                disableWallet={!methods.getValues('contact_id')}
                guidingId="recurringbilling"
              />
              <RecurrenceSummary
                isEditRecurring={true}
                recurringData={{
                  ...methods.watch(),
                  status: recurringData?.status,
                }}
              />
            </PageLayoutContainerSide>

            <AppBar
              sx={{
                bottom: 0,
                top: 'auto',
                position: 'fixed',
                boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
              }}
            >
              <ButtonBar style={{ marginBottom: '0 !important' }}>
                <ButtonBarEnd>
                  <Button
                    key={0}
                    label={t('common.cancel')}
                    color={'secondary'}
                    disabled={false}
                    onClick={() => {
                      navigateToUrl(returnToUrl)
                    }}
                    style={{ marginRight: '10px' }}
                    testId="cancel-button"
                    guidingId="recurringbilling-cancel"
                  />
                  <Button
                    key={1}
                    type="submit"
                    label={t('common.save-changes')}
                    isLoading={isLoading}
                    style={{ marginRight: '10px' }}
                    testId="save-button"
                    guidingId="recurringbilling-save"
                  />
                </ButtonBarEnd>
              </ButtonBar>
            </AppBar>
          </PageLayoutContainer>
        </form>
      </FormProvider>
    </HasPermission>
  )
}

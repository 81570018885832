import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Grid, InputLabel } from '@mui/material'

import {
  FieldGroupContainer,
  Input,
  InputAmount,
  InputWithSelect,
  DatePicker,
  Checkbox,
  RadioButtons,
  RadioButtonsArray,
} from '@shared/components'

const useStyles = tss.withName('RecurrenceSettings').create({
  inputLabel: {
    color: '#374151',
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: '4px',
  },
})

interface FormData {
  transaction_amount: number
  installment_total_count: number
  interval: number
  interval_type: string
  start_date: string
  end_date: string
  next_run_date?: string
  notification_days: number
  recurring_type_id: string
}

type RecurrenceSettingsProps = {
  isEditRecurring?: boolean
  disabled?: boolean
}

const RecurrenceSettings = ({
  isEditRecurring = false,
}: RecurrenceSettingsProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<FormData>()
  const { classes } = useStyles()
  const { t } = useTranslation()

  const [typeSelected, setTypeSelected] = useState<string>(
    getValues('recurring_type_id') === 'i' ? 'installment' : 'ongoing'
  )

  const radioButtonsArray: RadioButtonsArray = [
    {
      title: t('common.ongoing'),
      description: t('mfe-gateway.recurring-billing.ongoing-description'),
      color: 'secondary',
      defaultSelected: typeSelected === 'ongoing' ? true : false,
      onClick: () => {
        setTypeSelected('ongoing')
        setValue('recurring_type_id', 'o')
      },
      guidingId: 'recurringbilling-type-ongoing',
    },
    {
      title: t('common.installment'),
      description: t('mfe-gateway.recurring-billing.installment-description'),
      color: 'secondary',
      defaultSelected: typeSelected === 'installment' ? true : false,
      onClick: () => {
        setTypeSelected('installment')
        setValue('recurring_type_id', 'i')
      },
      guidingId: 'recurringbilling-type-installment',
    },
  ]

  return (
    <FieldGroupContainer
      title={t('mfe-gateway.recurring-billing.recurrence-settings')}
    >
      <Grid container spacing={1} rowSpacing={2}>
        {!isEditRecurring && (
          <Grid item sm={12} md={12}>
            <div style={{ width: '375px' }}>
              <InputLabel
                variant="standard"
                htmlFor="uncontrolled-native"
                className={classes.inputLabel}
                sx={{
                  '.MuiInputLabel-asterisk': {
                    color: '#ff0000',
                  },
                }}
              >
                {t('common.recurring-billing.type')}
              </InputLabel>
              <RadioButtons buttons={radioButtonsArray} />
            </div>
          </Grid>
        )}

        <Grid item sm={12} md={6}>
          <ControllerTransactionAmount
            disabled={isEditRecurring && typeSelected !== 'ongoing'}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <ControllerInterval disabled={isEditRecurring} />
        </Grid>

        {!isEditRecurring && (
          <Grid item sm={12} md={6}>
            <ControllerDateStart />
          </Grid>
        )}

        {typeSelected === 'ongoing' && (
          <Grid item sm={12} md={6}>
            <ControllerDateEnd />
          </Grid>
        )}
        {isEditRecurring && (
          <Grid item sm={12} md={6}>
            <ControllerDateNextRun />
          </Grid>
        )}

        {!isEditRecurring && typeSelected === 'installment' && (
          <Grid item sm={12} md={6}>
            <ControllerInstallmentTotalCount />
          </Grid>
        )}
        <ControllerPaymentReminders />
      </Grid>
    </FieldGroupContainer>
  )
}
export default RecurrenceSettings

const ControllerTransactionAmount = ({
  disabled = false,
}: RecurrenceSettingsProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>()
  const { t } = useTranslation()

  return (
    <Controller
      name="transaction_amount"
      control={control}
      render={({ field }) => (
        <InputAmount
          {...field}
          required={true}
          testId="transaction-amount-input"
          label={t('mfe-gateway.recurring-billing.charge-the-amount-of')}
          placeholder="0.00"
          error={!!errors.transaction_amount}
          helperText={errors.transaction_amount?.message}
          disabled={disabled}
          guidingId="recurringbilling-transactionamount"
        />
      )}
    />
  )
}
const ControllerInterval = ({ disabled = false }: RecurrenceSettingsProps) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<FormData>()
  const { t } = useTranslation()
  const intervalTypes = [
    {
      label: t('common.days'),
      value: 'd',
    },
    {
      label: t('common.weeks'),
      value: 'w',
    },
    {
      label: t('common.months'),
      value: 'm',
    },
  ]

  return (
    <Controller
      name="interval"
      control={control}
      render={({ field }) => (
        <InputWithSelect
          {...field}
          type="tel"
          selectContent={intervalTypes}
          selectContentValue={
            getValues('interval_type') ? getValues('interval_type') : 'm'
          }
          setContentValue={(value) => {
            setValue('interval_type', value)
          }}
          required={true}
          testId="interval-input"
          label={t('common.every')}
          placeholder="0"
          error={!!errors.interval}
          helperText={errors.interval?.message}
          disabled={disabled}
          guidingId="recurringbilling-interval"
        />
      )}
    />
  )
}
const ControllerDateStart = ({ disabled = false }: RecurrenceSettingsProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>()
  const { t } = useTranslation()

  return (
    <Controller
      name="start_date"
      control={control}
      render={({ field }) => (
        <DatePicker
          {...field}
          required={true}
          testId="start-date-input"
          label={t('mfe-gateway.recurring-billing.starting-on')}
          style={{ width: '100%', minWidth: '100%' }}
          error={!!errors.start_date}
          helperText={errors.start_date?.message}
          disabled={disabled}
          guidingId="recurringbilling-startdate"
        />
      )}
    />
  )
}
const ControllerDateEnd = ({ disabled = false }: RecurrenceSettingsProps) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<FormData>()
  const { t } = useTranslation()

  const [typeSelected, setTypeSelected] = useState<string>(
    getValues('recurring_type_id') === 'i' ? 'installment' : 'ongoing'
  )

  return (
    <Controller
      name="end_date"
      control={control}
      render={({ field }) => (
        <DatePicker
          {...field}
          testId="end-date-input"
          label={t('mfe-gateway.recurring-billing.ends-on')}
          error={!!errors.end_date}
          disabled={disabled}
          helperText={
            errors.end_date?.message
              ? errors.end_date?.message
              : t('mfe-gateway.recurring-billing.end-date-description')
          }
          guidingId="recurringbilling-enddate"
        />
      )}
    />
  )
}
const ControllerDateNextRun = ({
  disabled = false,
}: RecurrenceSettingsProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>()
  const { t } = useTranslation()

  return (
    <Controller
      name="next_run_date"
      control={control}
      render={({ field }) => (
        <DatePicker
          {...field}
          testId="next-run-date-input"
          label={t('common.date-next-run')}
          style={{ width: '100%', minWidth: '100%' }}
          error={!!errors.next_run_date}
          helperText={
            errors.next_run_date?.message
              ? errors.next_run_date?.message
              : t('mfe-gateway.recurring-billing.next-run-date-description')
          }
          guidingId="recurringbilling-nextrundate"
        />
      )}
    />
  )
}
const ControllerInstallmentTotalCount = ({
  disabled = false,
}: RecurrenceSettingsProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>()
  const { t } = useTranslation()

  return (
    <Controller
      name="installment_total_count"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          testId="installment-total-count-input"
          label={t('common.recurring-billing.number-of-payments')}
          inputProps={{ min: 1 }}
          required={true}
          type="number"
          placeholder={t(
            'mfe-gateway.recurring-billing.number-of-payments-placeholder'
          )}
          error={!!errors.installment_total_count}
          helperText={errors.installment_total_count?.message}
          disabled={disabled}
          guidingId="recurringbilling-installmenttotalcount"
        />
      )}
    />
  )
}

const ControllerPaymentReminders = ({
  disabled = false,
}: RecurrenceSettingsProps) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<FormData>()
  const { t } = useTranslation()
  const [paymentReminders, setPaymentReminders] = useState<boolean>(
    getValues('notification_days') ? true : false
  )
  return (
    <>
      <Grid item xs={12} sm={6}>
        <div style={{ marginLeft: '9px' }}>
          <Checkbox
            label={t('mfe-gateway.recurring-billing.send-payment-reminder')}
            description={t(
              'mfe-gateway.recurring-billing.notification-days-description'
            )}
            checked={paymentReminders}
            onChange={() => {
              setPaymentReminders(!paymentReminders)
            }}
            guidingId="recurringbilling-sendpaymentreminder"
          />
        </div>
      </Grid>
      {paymentReminders && (
        <Grid item xs={12} sm={6}>
          <Controller
            name="notification_days"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="notification_days-input"
                label={t(
                  'mfe-gateway.recurring-billing.send-reminder-before-due-date'
                )}
                type="number"
                inputProps={{ min: 1, max: 365 }}
                endAdornmentText={t('common.days')}
                placeholder="0"
                error={!!errors.notification_days}
                helperText={
                  errors.notification_days?.message
                    ? errors.notification_days?.message
                    : ''
                }
                guidingId="recurringbilling-notificationdays"
              />
            )}
          />
        </Grid>
      )}
    </>
  )
}

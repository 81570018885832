import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Grid } from '@mui/material'

import { Contact, QuickInvoice, Transaction, api } from '@shared/api'
import {
  HasPermission,
  Loading,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutContainerSide,
  UserNotAllowed,
  ButtonBarEnd,
  ButtonBarStart,
  ButtonBar,
  Button,
  QuickInvoiceInfoCard,
  OtherInfo,
} from '@shared/components'
import { useEnforceLogin, useFtpPortalHubCommunication } from '@shared/hooks'
import {
  checkAllPermissions,
  checkPermission,
  createUserPermissionSet,
  isInPast,
} from '@shared/utils'

import { CustomerDetailsView } from '@/components/customer-details-view/CustomerDetailsView'
import PaymentHistory from '@/components/payment-history/PaymentHistory'

import AdvancedSettings from './components/AdvancedSettings'
import { QuickInvoiceDetails } from './components/InvoiceDetails'
import { MerchantAccounts } from './components/MerchantAccount'
import OptionalSettings from './components/OptionalSettings'
import { AssignTransactionsModal } from '../components/assign-transactions-modal/AssignTransactionsModal'
import { DeleteQuickInvoice } from '../components/delete-quick-invoice/DeleteQuickInvoice'
import QuickInvoiceItemsList from '../components/quick-invoice-items/quick-invoice-items-list/QuickInvoiceItemsList'
import { ReopenQuickInvoice } from '../components/reopen-quick-invoice/ReopenQuickInvoice'
import { ResendQuickInvoice } from '../components/resend-quick-invoice/ResendQuickInvoice'

export default function QuickInvoiceView() {
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()
  const { id } = useParams()
  const userPermissions = createUserPermissionSet(user)
  const navigate = useNavigate()

  const [quickInvoice, setQuickInvoice] = useState<QuickInvoice | null>(null)
  const [customer, setCustomer] = useState<Contact | null>(null)

  const [isLoading, setIsLoading] = useState(!quickInvoice)
  const [isAssignTransactionModalOpen, setIsAssignTransactionModalOpen] =
    useState(false)

  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false)
  const [isReopenModalOpen, setIsReopenModalOpen] = useState(false)
  const [isResendModalOpen, setIsResendModalOpen] = useState(false)
  const [resendType, setResendType] = useState<'email' | 'sms'>('email')

  const getQuickInvoice = async () => {
    if (!id) return
    setIsLoading(true)

    try {
      const quickInvoice = await api.service('quick-invoices').get(id)
      setQuickInvoice(quickInvoice)
      if (quickInvoice?.contact_id) {
        await api
          .service('contacts')
          .get(quickInvoice?.contact_id)
          .then(setCustomer)
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Error getting quick invoice view: ', error)
    }
  }

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.action.quick-invoice.view'),
      null,
      [t('common.gateway'), t('common.quick-invoice')],
      '/merchant/gateway/quick-invoice'
    )
  }, [])

  useEffect(() => {
    getQuickInvoice()
  }, [])

  const isAssignTransactionButtonVisible =
    checkAllPermissions(
      userPermissions,
      'v2.transactions.get',
      'v2.quickinvoices.put.addtransaction'
    ) &&
    quickInvoice?.active &&
    quickInvoice.status_id === 1 &&
    (quickInvoice?.allow_overpayment ||
      (!quickInvoice?.allow_overpayment &&
        [1, 3].includes(quickInvoice?.payment_status_id)))

  const isDeleteButtonVisible =
    quickInvoice?.active &&
    checkPermission(userPermissions, 'v2.quickinvoices.delete')

  const isReopenButtonVisible =
    quickInvoice?.active &&
    !isInPast(quickInvoice?.expire_date) &&
    checkPermission(userPermissions, 'v2.quickinvoices.post.reopen') &&
    quickInvoice?.status_code === 0 &&
    !!(
      (quickInvoice?.cc_product_transaction?.active &&
        quickInvoice?.cc_product_transaction?.quick_invoice_allow) ||
      (quickInvoice?.ach_product_transaction?.active &&
        quickInvoice?.ach_product_transaction?.quick_invoice_allow)
    ) &&
    (!quickInvoice?.contact || quickInvoice?.contact?.active) &&
    ((quickInvoice?.allow_overpayment &&
      [1, 2, 3].includes(quickInvoice?.payment_status_id || 0)) ||
      (!quickInvoice?.allow_overpayment &&
        [1, 3].includes(quickInvoice?.payment_status_id || 0)))

  const isResendEmailButtonVisible =
    quickInvoice?.active &&
    checkAllPermissions(
      userPermissions,
      'v2.quickinvoices.post',
      'v2.quickinvoices.post.resend'
    ) &&
    !!quickInvoice?.email

  const isResendSmsButtonVisible =
    quickInvoice?.active &&
    checkAllPermissions(
      userPermissions,
      'v2.quickinvoices.post',
      'v2.quickinvoices.post.resend'
    ) &&
    !!quickInvoice?.cell_phone &&
    !!(
      quickInvoice?.ach_product_transaction?.sms_enable ||
      quickInvoice?.cc_product_transaction?.sms_enable
    )

  const shouldRenderButtonBar = () => {
    return (
      isDeleteButtonVisible ||
      isReopenButtonVisible ||
      isAssignTransactionButtonVisible ||
      isResendEmailButtonVisible ||
      isResendSmsButtonVisible ||
      (quickInvoice.active && quickInvoice.status_id === 1)
    )
  }

  return (
    <HasPermission
      permission="v2.quickinvoices.get"
      unauthorizedComponent={<UserNotAllowed />}
    >
      {isLoading || !quickInvoice ? (
        <Loading />
      ) : (
        <>
          {!isLoading && quickInvoice && (
            <>
              <AssignTransactionsModal
                quickInvoice={quickInvoice}
                open={isAssignTransactionModalOpen}
                onClose={() => setIsAssignTransactionModalOpen(false)}
                onSuccess={getQuickInvoice}
              />

              <DeleteQuickInvoice
                open={isDeactivateModalOpen}
                quickInvoice={quickInvoice}
                onSuccess={() => {
                  getQuickInvoice()
                }}
                onClose={() => {
                  setIsDeactivateModalOpen(false)
                }}
              />

              <ReopenQuickInvoice
                open={isReopenModalOpen}
                quickInvoice={quickInvoice}
                onSuccess={() => {
                  getQuickInvoice()
                }}
                onClose={() => {
                  setIsReopenModalOpen(false)
                }}
              />

              <ResendQuickInvoice
                open={isResendModalOpen}
                type={resendType}
                quickInvoice={quickInvoice}
                onSuccess={() => {
                  getQuickInvoice()
                }}
                onClose={() => {
                  setIsResendModalOpen(false)
                }}
              />

              {shouldRenderButtonBar() && (
                <ButtonBar>
                  <ButtonBarStart>
                    <Grid container spacing={1}>
                      {isDeleteButtonVisible && (
                        <Grid item>
                          <Button
                            label={t('common.delete')}
                            color="secondary"
                            onClick={() => setIsDeactivateModalOpen(true)}
                            guidingId="quickinvoice-view-delete"
                          />
                        </Grid>
                      )}

                      {isReopenButtonVisible && (
                        <Grid item>
                          <Button
                            label={t('common.reopen')}
                            color="secondary"
                            onClick={() => setIsReopenModalOpen(true)}
                            guidingId="quickinvoice-view-reopen"
                          />
                        </Grid>
                      )}

                      {isAssignTransactionButtonVisible && (
                        <Grid item>
                          <Button
                            label={t(
                              'mfe-gateway.quick-invoice.assign-transaction'
                            )}
                            color="secondary"
                            onClick={() =>
                              setIsAssignTransactionModalOpen(true)
                            }
                            guidingId="quickinvoice-view-assign-transaction"
                          />
                        </Grid>
                      )}

                      {isResendEmailButtonVisible && (
                        <Grid item>
                          <Button
                            label={t('common.resend-email')}
                            color="secondary"
                            onClick={() => {
                              setResendType('email')
                              setIsResendModalOpen(true)
                            }}
                            guidingId="quickinvoice-view-resendemail"
                          />
                        </Grid>
                      )}

                      {isResendSmsButtonVisible && (
                        <Grid item>
                          <Button
                            label={t('common.resend-sms')}
                            color="secondary"
                            onClick={() => {
                              setResendType('sms')
                              setIsResendModalOpen(true)
                            }}
                            guidingId="quickinvoice-view-resendsms"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </ButtonBarStart>

                  {quickInvoice.active && quickInvoice.status_id === 1 && (
                    <ButtonBarEnd>
                      <Button
                        label={t('common.edit')}
                        onClick={() =>
                          navigate(
                            `/merchant/gateway/quick-invoice/${quickInvoice.id}/edit`,
                            {
                              state: {
                                backUrl: `/merchant/gateway/quick-invoice/${quickInvoice.id}/view`,
                              },
                            }
                          )
                        }
                        guidingId="quickinvoice-view-edit"
                      />
                    </ButtonBarEnd>
                  )}
                </ButtonBar>
              )}

              <PageLayoutContainer>
                <PageLayoutContainerMain>
                  <MerchantAccounts
                    accountNameCC={quickInvoice?.cc_product_transaction?.title}
                    accountNameACH={
                      quickInvoice?.ach_product_transaction?.title
                    }
                  />

                  <QuickInvoiceDetails invoice={quickInvoice} />

                  <QuickInvoiceItemsList list={quickInvoice?.item_list} />

                  <OptionalSettings invoice={quickInvoice} />

                  <AdvancedSettings
                    apiId={quickInvoice?.contact?.contact_api_id}
                  />

                  <OtherInfo
                    createdDate={quickInvoice.created_ts}
                    createdBy={quickInvoice?.created_user?.email}
                    timezone={user?.tz}
                    section="quick-invoice"
                  />

                  <PaymentHistory
                    canViewTransactions={true}
                    paymentData={
                      quickInvoice.transactions as unknown as Transaction[]
                    }
                    onRefresh={getQuickInvoice}
                    guidingId="quickinvoice"
                  />
                </PageLayoutContainerMain>

                <PageLayoutContainerSide>
                  <CustomerDetailsView customer={customer} hideWalletDetails />

                  <QuickInvoiceInfoCard
                    quickInvoiceInfo={quickInvoice}
                    tz={user?.tz}
                    title={t('mfe-gateway.quick-invoice.invoice-summary')}
                    labelStyle={{
                      color: '#374151',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                    }}
                    showButtonUnassignTransaction={false}
                    showButtonViewQuickInvoice={false}
                    showTotalAmountDue={true}
                  />
                </PageLayoutContainerSide>
              </PageLayoutContainer>
            </>
          )}
        </>
      )}
    </HasPermission>
  )
}

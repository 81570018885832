import { IAfterGuiAttachedParams } from 'ag-grid-community'
import { CustomFilterProps, useGridFilter } from 'ag-grid-react'
import { debounce } from 'lodash'
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

import { Checkbox, CircularProgress } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'

import { ServiceTypes, api } from '@shared/api'
import { Button, Input } from '@shared/components'
import { useLocations } from '@shared/hooks'

const useStyles = tss.withName('CustomFilter').create(() => ({
  card: {
    padding: '8px 8px 8px 8px',
    width: '300px',
    overflowY: 'auto',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    width: 250,
    justifyContent: 'flex-start',
    alignItems: 'center',
    whiteSpace: 'normal',
    '& .MuiCheckbox-root': {
      padding: 0,
      marginRight: '8px',
      background: '#FFF',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
    },
    '&.Mui-checked': {
      color: '#0369A1',
    },
    '& .MuiIconButton-label': {
      background: 'var(--white, #FFF)',
    },
  },
  menuItemText: {
    flexGrow: 1,
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
  submitButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  middleSection: {
    zIndex: '-2 !important',
    marginBottom: '12px',
  },
  bottomSection: {
    marginTop: '16px',
  },
}))

export interface MultiSelectSearchFilterProps extends CustomFilterProps {
  typeOfSearch: string
  filterType: string
  placeholder?: string
  service: keyof Omit<ServiceTypes, 'authentication' | 'users/login'>
  filterPropName: string
  onChange: (values: any) => void
  multiple?: boolean
  sort?: boolean
  isSearchCustomers?: boolean
  primaryDisplayField: string
  secondaryDisplayField?: string
}

const CustomFilter = ({
  model,
  onModelChange,
  typeOfSearch,
  filterType,
  placeholder,
  service,
  filterPropName,
  onChange,
  multiple,
  sort,
  isSearchCustomers,
  primaryDisplayField,
  secondaryDisplayField,
}: MultiSelectSearchFilterProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { selectedLocation } = useLocations()
  const inputRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [filterString, setFilterString] = useState('')
  const [results, setResults] = useState([])
  const [selectedValues, setSelectedValues] = useState([])

  const [closeFilter, setCloseFilter] = useState<() => void>()

  const clearInputFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const doesFilterPass = useCallback(() => {
    return true
  }, [])

  const handleApply = () => {
    if (selectedValues.length === 0) {
      onModelChange(null)
      return
    }
    onModelChange({
      type: typeOfSearch,
      filterType: filterType,
      filter: selectedValues.map((val) => val.value).join(','),
    })
  }

  const setModel = () => {
    if (model && model.filter) {
      const selectedArray = model.filter.split(',').map((id) => ({
        value: id,
        label: results.find((res) => res.value === id)?.label || id,
      }))
      setSelectedValues(selectedArray)
    } else {
      setSelectedValues([])
    }
  }

  const getModelAsString = useCallback(
    (model) => {
      const filterValues = model.filter.split(',').map((val) => val.trim())
      const string = results
        .filter((option) => filterValues.includes(option.value))
        .map((option) => option.label)
        .join(', ')

      return string
    },
    [results]
  )

  const afterGuiAttached = useCallback(
    (params: IAfterGuiAttachedParams) => {
      setCloseFilter(() => params.hidePopup)
      setFilterString('')
      setResults([...selectedValues])
    },
    [selectedValues]
  )

  const getData = useCallback(
    async (filterRecord: Record<string, string>) => {
      if (!selectedLocation.id) return

      setIsLoading(true)

      try {
        const query = isSearchCustomers
          ? {
              ...filterRecord,
              location_id: selectedLocation.id,
            }
          : {
              filter: {
                ...filterRecord,
                location_id: selectedLocation.id,
              },
            }

        const data = await api.service(service).find({
          query: {
            page: {
              size: 10,
            },
            ...query,
          },
        })

        const sortedOptions = sort
          ? data.sort((a, b) =>
              a[primaryDisplayField].localeCompare(b[primaryDisplayField])
            )
          : data

        const resultsMap = sortedOptions.map((option) => ({
          value: option.id,
          label: secondaryDisplayField
            ? `${option[primaryDisplayField]} ${option[secondaryDisplayField]}`
            : option[primaryDisplayField],
        }))

        const filteredResults = resultsMap.filter(
          (result) => !selectedValues.some((val) => val.value === result.value)
        )

        setResults([...selectedValues, ...filteredResults])
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setIsLoading(false)
      }
    },
    [
      selectedLocation.id,
      isSearchCustomers,
      service,
      sort,
      selectedValues,
      primaryDisplayField,
    ]
  )

  const debouncedGetUsers = useCallback(debounce(getData, 200), [
    selectedLocation.id,
    service,
    sort,
    filterPropName,
    selectedValues,
  ])

  useEffect(() => {
    ;(async () => {
      if (filterString) {
        await debouncedGetUsers({ [filterPropName]: filterString })
      }
    })()
  }, [filterString])

  const clearValues = () => {
    setSelectedValues([])
    setResults([])
    setFilterString('')
    clearInputFocus()
    onModelChange(null)
    closeFilter?.()
  }

  const applySelectedValues = () => {
    closeFilter?.()
    if (selectedValues.length === 0) return
    onChange(selectedValues)
    handleApply()
  }

  useEffect(() => {
    setModel()
  }, [model])

  useGridFilter({
    getModelAsString,
    afterGuiAttached,
    doesFilterPass,
  })

  return (
    <div className={classes.card}>
      <Input
        ref={inputRef}
        type="text"
        label=""
        placeholder={placeholder}
        value={filterString}
        onChange={(e) => setFilterString(e.target.value)}
      />
      {isLoading ? (
        <CircularProgress style={{ marginTop: '8px', paddingBottom: '8px' }} />
      ) : (
        <>
          <div className={classes.middleSection}>
            {results.length
              ? results.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.value}
                    className={classes.menuItem}
                    onClick={() => {
                      if (!multiple) {
                        setSelectedValues([option])
                        return
                      }
                      setSelectedValues((prevValues) =>
                        prevValues.some((val) => val.value === option.value)
                          ? prevValues.filter(
                              (value) => value.value !== option.value
                            )
                          : [...prevValues, option]
                      )
                    }}
                  >
                    <Checkbox
                      checked={selectedValues.some(
                        (val) => val.value === option.value
                      )}
                      className={classes.checkbox}
                    />
                    <span className={classes.menuItemText}>{option.label}</span>
                  </MenuItem>
                ))
              : null}
            <div className={classes.bottomSection}>
              <div
                style={{ borderTop: '1px solid #ccc', margin: '8px 0' }}
              ></div>
              <div className={classes.submitButtons}>
                <Button
                  label={t('common.clear')}
                  color={'secondary'}
                  style={{
                    width: '48%',
                  }}
                  onClick={clearValues}
                />

                <Button
                  label={t('common.apply')}
                  style={{
                    width: '48%',
                  }}
                  onClick={applySelectedValues}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default CustomFilter

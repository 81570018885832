import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { Contact, api } from '@shared/api'
import {
  ActionModal,
  Notification,
  NotificationProps,
} from '@shared/components'
import { useNotification } from '@shared/hooks'
import { toArrayFieldErrors } from '@shared/utils'

export interface ToggleCustomerActivationModalProps {
  customer: Contact
  open: boolean
  onClose?: () => void | Promise<void>
  onSuccess?: () => void | Promise<void>
}

export const ToggleCustomerActivationModal: React.FC<
  ToggleCustomerActivationModalProps
> = ({ customer, open, onClose, onSuccess }) => {
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit = async () => {
    setIsLoading(true)

    try {
      !!customer.active
        ? await api.service('contacts').remove(customer.id)
        : await api.service('contacts').activate(customer.id)

      setNotification({
        type: 'success',
        label: `${t('mfe-gateway.customer.customer-successfully')} ${
          !!customer.active ? t('common.deactivated') : t('common.reactivated')
        } `,
      })

      onClose?.()
      onSuccess?.()
    } catch (error) {
      const errors = toArrayFieldErrors(error)
      const messages = errors.map((error) => error.message)

      setNotification({
        type: 'error',
        label: messages.join(' | '),
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ActionModal
        open={open}
        title={
          !!customer.active
            ? t('mfe-gateway.customer.deactivate-customer')
            : t('mfe-gateway.customer.reactivate-customer')
        }
        buttons={[
          {
            testId: 'cancel-button',
            label: t('common.cancel'),
            style: { width: '40%' },
            color: 'secondary',
            onClick: onClose,
            guidingId: 'customers-activation-modal-cancel',
          },
          {
            testId: 'submit-button',
            type: 'submit',
            label: !!customer.active
              ? t('common.action.yes-deactivate')
              : t('common.action.yes-reactivate'),
            style: { width: '40%' },
            isLoading,
            onClick: onSubmit,
            guidingId: 'customers-activation-modal-submit',
          },
        ]}
        onClose={onClose}
      >
        <Typography sx={{ fontSize: '14px' }} variant="body2">
          {`${t('common.modal.are-you-sure')} ${(!!customer.active
            ? t('common.deactivate')
            : t('common.reactivate')
          ).toLocaleLowerCase()} ${t('common.customer').toLocaleLowerCase()} `}
          <strong>
            {customer.first_name} {customer.last_name}
          </strong>
          ?
        </Typography>
      </ActionModal>
    </>
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import { capitalize, omit } from 'lodash'
import { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'

import { api } from '@shared/api'
import {
  ActionModal,
  Button,
  RadioButtons,
  RadioButtonsArray,
} from '@shared/components'
import { useLocations } from '@shared/hooks'
import { PaymentMethodType } from '@shared/types'
import { billingAddressErrorConverter, toFieldErrors } from '@shared/utils'

import { BillingInformation } from '@/components/billing-information/BillingInformation'

import AdvancedSettingsAdd from '../advanced-settings-add/AdvancedSettingsAdd'
import DetailsAdd from '../details-add/DetailsAdd'

const useStyles = tss.withName('WalletAdd').create(({ theme }) => ({
  modal: {
    maxWidth: '640px',
    minWidth: '400px',
    width: '100%',
    overflowY: 'hidden',
  },
  selector: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: theme.palette['neutral-700'],
    marginBottom: '4px',
  },
}))

interface WalletAddProps {
  customer_id: string
  open: boolean
  onClose: () => void
  onCompleted: () => void
  onError: () => void
}

const buildSchema = (
  t: (text: string) => string,
  paymentMethod: PaymentMethodType
) => {
  return yup.object({
    title: yup.string(),
    account_holder_name: yup.string().when([], {
      is: () => paymentMethod === PaymentMethodType.ACH,
      then: (schema) =>
        schema.required(
          t('mfe-gateway.validations.token.account-holder-name-required')
        ),
    }),
    account_number: yup
      .string()
      .required(
        t('mfe-gateway.validations.transaction.account-number-is-required')
      ),
    billing_address: yup.object().shape({
      street: yup.string(),
      city: yup.string(),
      country: yup.string(),
      state: yup.string(),
      postal_code: yup.string(),
      phone: yup.string(),
    }),
    run_avs: yup.boolean(),
    token_api_id: yup.string(),
    exp_date: yup.string().when([], {
      is: () => paymentMethod === PaymentMethodType.CC,
      then: (schema) =>
        schema
          .required(
            t('mfe-gateway.validations.transaction.expiration-date-is-required')
          )
          .matches(
            /^(0[1-9]|1[0-2])\d{2}$/,
            t('mfe-gateway.validations.transaction.invalid-expiration-date')
          ),
    }),
    routing_number: yup.string().when([], {
      is: () => paymentMethod === PaymentMethodType.ACH,
      then: (schema) =>
        schema
          .required(t('mfe-gateway.validations.token.routing-number-required'))
          .matches(
            /^\d{9}$/,
            t('mfe-gateway.validations.token.invalid-routing-number')
          ),
    }),
    account_type: yup.string().when([], {
      is: () => paymentMethod === PaymentMethodType.ACH,
      then: (schema) =>
        schema.required(
          t('mfe-gateway.validations.token.account-type-required')
        ),
    }),
    ach_sec_code: yup.string().when([], {
      is: () => paymentMethod === PaymentMethodType.ACH,
      then: (schema) =>
        schema.required(
          t('mfe-gateway.validations.token.ach-sec-code-required')
        ),
    }),
  })
}

const WalletAdd = ({
  customer_id,
  open,
  onClose,
  onCompleted,
  onError,
}: WalletAddProps) => {
  const { classes } = useStyles()
  const {
    selectedLocation,
    hasMerchantAccountACHActive,
    hasMerchantAccountCCActive,
  } = useLocations()
  const { t } = useTranslation()

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodType>(
      hasMerchantAccountCCActive ? PaymentMethodType.CC : PaymentMethodType.ACH
    )
  const [loading, setLoading] = useState(false)

  const schema = buildSchema(t, selectedPaymentMethod)

  const methods = useForm<yup.InferType<typeof schema>>({
    defaultValues: {
      title: '',
      account_holder_name: '',
      account_number: '',
      routing_number: '',
      run_avs: false,
      account_type: 'checking',
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const buttons: RadioButtonsArray = useMemo(
    () => [
      {
        title: t('common.credit-card'),
        color: 'secondary',
        onClick: () => {
          setSelectedPaymentMethod(PaymentMethodType.CC)
          methods.clearErrors()
        },
        style: {
          width: 'fit-content',
        },
        defaultSelected: selectedPaymentMethod === PaymentMethodType.CC,
        guidingId: 'token-type-cc',
      },
      {
        title: t('common.bank-account'),
        color: 'secondary',
        onClick: () => {
          setSelectedPaymentMethod(PaymentMethodType.ACH)
          methods.clearErrors()
        },
        defaultSelected: selectedPaymentMethod === PaymentMethodType.ACH,
        style: {
          width: 'fit-content',
        },
        guidingId: 'token-type-ach',
      },
    ],
    []
  )

  const resetForm = () => {
    setSelectedPaymentMethod(PaymentMethodType.CC)
    methods.reset()
  }

  const onSubmit = async ({
    title,
    account_holder_name,
    account_number,
    billing_address,
    run_avs,
    token_api_id,
    exp_date,
    routing_number,
    account_type,
    ach_sec_code,
  }) => {
    try {
      setLoading(true)

      const payload = {
        title: !!title ? title : undefined,
        account_holder_name: !!account_holder_name
          ? account_holder_name
          : undefined,
        account_number: !!account_number
          ? account_number.replace(/\s/g, '')
          : undefined,
        billing_address: {
          street: !!billing_address.street ? billing_address.street : undefined,
          city: !!billing_address.city ? billing_address.city : undefined,
          country: !!billing_address.country
            ? billing_address.country
            : undefined,
          state: !!billing_address.state ? billing_address.state : undefined,
          postal_code: !!billing_address.postal_code
            ? billing_address.postal_code
            : undefined,
          phone: !!billing_address.phone ? billing_address.phone : undefined,
        },
        run_avs: !!run_avs ? run_avs : undefined,
        token_api_id: !!token_api_id ? token_api_id : undefined,
        exp_date: !!exp_date ? exp_date : undefined,
        routing_number: !!routing_number ? routing_number : undefined,
        account_type: !!account_type ? account_type : undefined,
        ach_sec_code: !!ach_sec_code ? ach_sec_code : undefined,
        contact_id: customer_id,
        location_id: selectedLocation.id,
      }

      if (selectedPaymentMethod === PaymentMethodType.ACH) {
        await api
          .service('tokens')
          .create_ach(omit(payload, 'exp_date', 'run_avs'))
      } else {
        await api
          .service('tokens')
          .create_cc(
            omit(payload, 'routing_number', 'account_type', 'ach_sec_code')
          )
      }

      resetForm()

      onCompleted()
    } catch (error) {
      let fieldErrors = toFieldErrors(error)

      if (fieldErrors['billing_address']) {
        fieldErrors = {
          ...omit(fieldErrors, 'billing_address'),
          ...billingAddressErrorConverter({
            errors: fieldErrors['billing_address'],
          }),
        }
      }

      Object.keys(fieldErrors).forEach(
        (key: keyof yup.InferType<typeof schema>) => {
          methods.setError(key, {
            type: 'manual',
            message: capitalize(fieldErrors[key]),
          })
        }
      )

      onError()
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    resetForm()
    onClose()
  }
  return (
    <ActionModal
      open={open}
      onClose={handleClose}
      title={t('mfe-gateway.token.add-wallet')}
      isScrollableContent
      buttons={[
        {
          label: t('common.cancel'),
          color: 'secondary',
          onClick: handleClose,
          guidingId: 'token-cancel',
        },
        {
          label: t('mfe-gateway.token.save-wallet'),
          color: 'primary',
          onClick: methods.handleSubmit(onSubmit),
          isLoading: loading,
          guidingId: 'token-save',
        },
      ]}
      className={classes.modal}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container rowSpacing={'24px'}>
            {hasMerchantAccountCCActive && hasMerchantAccountACHActive && (
              <Grid item>
                <Typography className={classes.selector}>
                  {t('common.type')}
                </Typography>
                <RadioButtons buttons={buttons} />
              </Grid>
            )}

            <Grid item xs={12}>
              <DetailsAdd paymentMethod={selectedPaymentMethod} />

              <Divider
                style={{
                  marginTop: '24px',
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <BillingInformation guidingId="token" />
              <Divider
                style={{
                  marginTop: '24px',
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AdvancedSettingsAdd
                showRunAvs={selectedPaymentMethod === PaymentMethodType.CC}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </ActionModal>
  )
}

export default WalletAdd

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Link } from '@mui/material'

import { QuickInvoice } from '@shared/api/src'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
  FieldGroupViewItemWithChildren,
  TagList,
} from '@shared/components'
import { currency, useFormatBooleanAsYesNo } from '@shared/utils'

const useStyles = tss.withName('OptionalSettings').create(({ theme }) => ({
  linkValue: {
    color: theme.palette['neutral-900'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    marginLeft: '10px',
  },
}))

interface QuickInvoiceDetailsProps {
  invoice: QuickInvoice
}

const OptionalSettings: React.FC<QuickInvoiceDetailsProps> = ({ invoice }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const formatPluralDays = useCallback((days: number | null | undefined) => {
    if (!days) return `0 ${t('common.days')}`
    return days === 1
      ? `${days} ${t('common.day')}`
      : `${days} ${t('common.days')}`
  }, [])

  const valueQuickInvoiceSinglePaymentMinAmount = currency(
    parseFloat(String(invoice.single_payment_min_amount)),
    true
  )

  const valueQuickInvoiceSinglePaymentMaxAmount = currency(
    parseFloat(String(invoice.single_payment_max_amount)),
    true
  )

  const valueQuickInvoiceAllowPartialPay = useFormatBooleanAsYesNo(
    invoice.allow_partial_pay
  )

  const valueQuickInvoiceAllowOverpayment = useFormatBooleanAsYesNo(
    invoice.allow_overpayment
  )

  const valueQuickInvoiceNotificationDaysBeforeDueDate = formatPluralDays(
    invoice.notification_days_before_due_date
  )

  const valueQuickInvoiceNotificationDaysAfterDueDate = formatPluralDays(
    invoice.notification_days_after_due_date
  )

  const valueQuickInvoiceNotificationOnDueDate = useFormatBooleanAsYesNo(
    invoice.notification_on_due_date
  )

  const valueQuickInvoiceBankFundedOnlyOverride =
    invoice.bank_funded_only_override === undefined
      ? t('mfe-gateway.quick-invoice.follow-merchant-account-setting')
      : // eslint-disable-next-line
        useFormatBooleanAsYesNo(invoice.bank_funded_only_override)

  const valueQuickInvoiceNote = invoice.note

  return (
    <FieldGroupContainer
      title={t('mfe-gateway.quick-invoice.optional-settings')}
    >
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('mfe-gateway.quick-invoice.allow-partial-payment')}
          value={valueQuickInvoiceAllowPartialPay}
          valueTestID="quick-invoice-allow-partial-payment-value"
        />

        <FieldGroupViewItem
          title={t('mfe-gateway.quick-invoice.allow-overpayment')}
          value={valueQuickInvoiceAllowOverpayment}
          valueTestID="quick-invoice-allow-overpayment-value"
        />
      </FieldGroupRow>

      {(invoice.allow_partial_pay || invoice.allow_overpayment) && (
        <FieldGroupRow>
          {invoice.allow_partial_pay && (
            <FieldGroupViewItem
              title={t('mfe-gateway.quick-invoice.partial-payment-(min)')}
              value={valueQuickInvoiceSinglePaymentMinAmount}
              valueTestID="quick-invoice-single-payment-min-amount-value"
            />
          )}

          {invoice.allow_overpayment && (
            <FieldGroupViewItem
              title={t('mfe-gateway.quick-invoice.over-payment-(max)')}
              value={valueQuickInvoiceSinglePaymentMaxAmount}
              valueTestID="quick-invoice-single-payment-max-amount-value"
            />
          )}
        </FieldGroupRow>
      )}

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('mfe-gateway.quick-invoice.reminder-before-due-date')}
          value={valueQuickInvoiceNotificationDaysBeforeDueDate}
          valueTestID="quick-invoice-notification-days-before-due-date-value"
        />

        <FieldGroupViewItem
          title={t('mfe-gateway.quick-invoice.reminder-after-due-date')}
          value={valueQuickInvoiceNotificationDaysAfterDueDate}
          valueTestID="quick-invoice-notification-days-after-due-date-value"
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('mfe-gateway.quick-invoice.reminder-on-due-date')}
          value={valueQuickInvoiceNotificationOnDueDate}
          valueTestID="quick-invoice-notification-on-due-date-value"
        />
      </FieldGroupRow>

      <FieldGroupRow>
        {invoice.cc_product_transaction_id && (
          <FieldGroupViewItem
            title={t('mfe-gateway.quick-invoice.bank-funded')}
            value={valueQuickInvoiceBankFundedOnlyOverride}
            valueTestID="quick-invoice-bank-funded-only-override-value"
          />
        )}

        <FieldGroupViewItem
          title={t('mfe-gateway.quick-invoice.notes')}
          value={valueQuickInvoiceNote}
          valueTestID="quick-invoice-note-value"
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItemWithChildren
          title={t('common.tags')}
          titleTestID="tags-title"
        >
          {invoice.tags && invoice.tags.length > 0 && (
            <TagList
              items={invoice.tags}
              nameSelector={(tag) => tag.title}
              wrapWords={false}
            />
          )}
        </FieldGroupViewItemWithChildren>

        <FieldGroupViewItemWithChildren
          title={t('mfe-gateway.quick-invoice.files')}
          titleTestID="files-title"
        >
          {invoice.files?.map((file, index) => (
            <Link
              key={index}
              href="#"
              underline="hover"
              sx={{ display: 'block' }}
              className={classes.linkValue}
            >
              {file ?? '-'}
            </Link>
          ))}
        </FieldGroupViewItemWithChildren>
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}

export default OptionalSettings

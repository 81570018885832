import {
  api,
  ApiService,
  TransactionsDataTypes,
  ServiceTypes,
  TerminalTransactionResponse,
  TerminalTransactionType,
  TokenTransactionType,
  Transaction,
  TransactionType,
  ACHTransactionData,
  CreditCardTransactionData,
  VoidTransactionData,
  AsyncStatus,
} from '../api'

type TransactionApiService<
  T extends TerminalTransactionResponse | Transaction
> = ApiService<
  T,
  | CreditCardTransactionData
  | ACHTransactionData
  | TransactionsDataTypes['transactions/cash/sale']
  | TransactionsDataTypes['transactions/cash/refund']
>

export const keyedTransactionServices: Record<
  TransactionType,
  keyof ServiceTypes
> = {
  authonly: 'transaction-cc-authonly',
  avsonly: 'transaction-cc-avsonly',
  force: 'transaction-cc-force',
  refund: 'transaction-cc-refund',
  sale: 'transaction-cc-sale',
  credit: 'transaction-ach-credit',
  debit: 'transaction-ach-debit',
  'cash.sale': 'transaction-cash-sale',
  'cash.refund': 'transaction-cash-refund',
}

export const terminalTransactionServices: Record<
  TerminalTransactionType,
  keyof ServiceTypes
> = {
  authonly: 'transaction-cc-authonly-terminal',
  avsonly: 'transaction-cc-avsonly-terminal',
  sale: 'transaction-cc-sale-terminal',
  refund: 'transaction-cc-refund-terminal',
}

export const tokenTransactionServices: Record<
  TokenTransactionType,
  keyof ServiceTypes
> = {
  authonly: 'transaction-cc-authonly-token',
  avsonly: 'transaction-cc-avsonly-token',
  force: 'transaction-cc-force-token',
  refund: 'transaction-cc-refund-token',
  sale: 'transaction-cc-sale-token',
  debit: 'transaction-ach-debit-token',
  credit: 'transaction-ach-credit-token',
}

/**
 * Make a Keyed Transaction request.
 * @param {TransactionType} transactionType The type of Transaction.
 * @param {CreditCardTransactionData} transaction Transaction data.
 * @returns {Promise<string>} A promise that resolves to the Transaction ID.
 */
export const submitKeyedTransaction = async (
  transactionType: TransactionType,
  transaction: CreditCardTransactionData | ACHTransactionData
): Promise<string> => {
  const service = api.service(
    keyedTransactionServices[transactionType]
  ) as TransactionApiService<Transaction>

  return service.create(transaction).then(({ id }) => id) as Promise<string>
}

/**
 * Make a Terminal Transaction request.
 * @param {TerminalTransactionType} transactionType The type of Transaction.
 * @param {CreditCardTransactionData} transaction Transaction data.
 * @returns {Promise<string>} A promise that resolves to the Transaction status ID.
 */
export const submitTerminalTransaction = async (
  transactionType: TerminalTransactionType,
  transaction: CreditCardTransactionData
): Promise<string> => {
  const service = api.service(
    terminalTransactionServices[transactionType]
  ) as TransactionApiService<TerminalTransactionResponse>

  const statusDetails = await service.create(transaction)

  let status: AsyncStatus | undefined

  do {
    await new Promise((resolve) => setTimeout(resolve, 5000))

    status = await api.service('async-status').get(statusDetails.async.code)

    if (status.error) {
      throw new Error(status.error)
    }
  } while ((status?.progress ?? 0) < 100)

  return status.id as string
}

/**
 * Make a Token Transaction request.
 * @param {TokenTransactionType} transactionType The type of Transaction.
 * @param {CreditCardTransactionData} transaction Transaction data.
 * @returns {Promise<string>} A promise that resolves to the Transaction ID.
 */
export const submitTokenTransaction = async (
  transactionType: TokenTransactionType,
  transaction: CreditCardTransactionData
): Promise<string> => {
  const service = api.service(
    tokenTransactionServices[transactionType]
  ) as TransactionApiService<Transaction>

  return service.create(transaction).then(({ id }) => id) as Promise<string>
}

/**
 *
 * Make a request to Void a transaction.
 * @param {TransactionType} transactionId The transaction id.
 * @param {VoidTransactionData} voidTransactionData Void transaction data.
 * @returns {Promise<void>} A promise that resolves to void.
 */
export const voidTransaction = async (
  transactionId: string,
  voidTransactionData: VoidTransactionData
): Promise<void> => {
  await api.service('transactions').void(transactionId, voidTransactionData)
}

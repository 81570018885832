import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { AppBar } from '@mui/material'

import { api, ProductRecurring, Tags } from '@shared/api'
import {
  HasPermission,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutContainerSide,
  PageLayoutDivider,
  UserNotAllowed,
  Button,
  ButtonBar,
  ButtonBarEnd,
  ErrorModal,
} from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useLocations,
  useEnforceLogin,
  useNotification,
} from '@shared/hooks'
import { toFieldErrors, formatRawDate } from '@shared/utils'

import { CustomerDetails } from '@/components/customer-details/CustomerDetails'

import AdvancedSettings from '../components/advanced-settings/AdvancedSettings'
import MerchantAccountDetails from '../components/merchant-account-details/MerchantAccountDetails'
import OptionalInformation from '../components/optional-information/OptionalInformation'
import RecurrenceSettings from '../components/recurrence-settings/RecurrenceSetting'
import RecurrenceSummary from '../components/recurrence-summary/RecurrenceSummary'

const useStyles = tss.withName('RecurringBillingAdd').create({
  cancelButtonContainer: { marginRight: '10px' },
  saveButtonContainer: { marginRight: '10px', width: '86px' },
  closeModalButtonContainer: { marginTop: '12px' },
})

interface FormData {
  location_id: string
  notification_days: number
  installment_total_count: number
  interval: string
  interval_type: 'd' | 'w' | 'm'
  start_date: string
  end_date: string
  transaction_amount: string
  product_transaction_id: string
  description: string
  recurring_api_id: string
  contact_id: string
  token_id: string
  recurring_type_id: string
  tags: Tags[]
}

export default function RecurringBillingAdd() {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { setNotification } = useNotification()
  const { selectedLocation } = useLocations()
  const navigate = useNavigate()

  const recurringBillingPrivs = ['v2.recurrings.post']
  const guidingId = 'recurringbilling'

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [product_recurring] = useState<ProductRecurring>(
    selectedLocation?.product_recurring as ProductRecurring
  )

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.action.recurring-billing.add'),
      null,
      [t('common.gateway'), t('common.recurring-billing')],
      `/merchant/gateway/recurring-billing`
    )
  }, [])

  const schema = yup.object().shape({
    location_id: yup.string(),
    notification_days: yup
      .number()
      .nullable()
      .min(
        1,
        t('mfe-gateway.validations.recurring-billing.payment-reminder-days-min')
      )
      .max(
        365,
        t('mfe-gateway.validations.recurring-billing.payment-reminder-days-max')
      )
      .transform((value, original) => (original === '' ? null : value)),
    installment_total_count: yup
      .number()
      .nullable()
      .typeError(
        t(
          'mfe-gateway.validations.recurring-billing.installment-total-count-required'
        )
      )
      .test(
        'conditionalValidation',
        t('mfe-gateway.validations.recurring-billing.installment-total-count'),
        function (value) {
          const recurringType = this.parent.recurring_type_id
          // Validate only if recurring_type_id is 'i'
          if (recurringType === 'i') {
            return yup.number().min(1).isValidSync(value)
          }
          // Return true if recurringType is not 'i' (skipping validation)
          return true
        }
      ),
    interval: yup
      .string()
      .required(
        t('mfe-gateway.validations.recurring-billing.interval-required')
      )
      .test(
        'is-valid-number',
        t('mfe-gateway.validations.recurring-billing.interval-invalid'),
        (value) => {
          if (!value) return false
          const parsedValue = parseFloat(value)
          return !isNaN(parsedValue) && parsedValue >= 1
        }
      ),
    interval_type: yup.string().nullable(),
    start_date: yup
      .string()
      .required(
        t('mfe-gateway.validations.recurring-billing.starting-on-required')
      )
      .test(
        'valid-date',
        t('common.validations.invalid-date-format'),
        (value) => {
          if (!value) return true
          return /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(
            value
          )
        }
      ),
    end_date: yup
      .string()
      .test(
        'valid-date',
        t('common.validations.invalid-date-format'),
        (value) => {
          if (!value) return true
          return /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(
            value
          )
        }
      ),
    transaction_amount: yup
      .string()
      .required(
        t(
          'mfe-gateway.validations.recurring-billing.transaction-amount-is-required'
        )
      ),
    product_transaction_id: yup.string(),
    description: yup.string(),
    recurring_api_id: yup.string().nullable(),
    contact_id: yup
      .string()
      .required(
        t('mfe-gateway.validations.recurring-billing.customer-required')
      ),
    token_id: yup
      .string()
      .required(t('mfe-gateway.validations.recurring-billing.wallet-required')),
    recurring_type_id: yup.string().required(),
    tags: yup.array(),
  })

  const methods = useForm({
    defaultValues: {
      location_id: selectedLocation?.id || '',
      notification_days: product_recurring?.notification_days_default || null,
      interval_type: 'm',
      installment_total_count: 0,
      interval: '',
      start_date: '',
      end_date: '',
      transaction_amount: '',
      product_transaction_id:
        selectedLocation?.default_cc || selectedLocation?.default_ach || '',
      description: '',
      recurring_api_id: '',
      contact_id: '',
      token_id: '',
      recurring_type_id: 'o',
    },
    resolver: yupResolver(schema),
    mode: 'all',
  })

  const { formState } = methods

  useEffect(() => {
    if (Object.keys(formState?.errors).length > 0) {
      setNotification({
        label: t('common.please-review-information'),
        type: 'error',
      })
    }
  }, [formState?.errors])

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const payload = {
      location_id: selectedLocation?.id,
      notification_days: data.notification_days,
      installment_total_count:
        data.recurring_type_id === 'i' ? data.installment_total_count : null,
      interval: parseFloat(data.interval),
      interval_type: data.interval_type,
      start_date: formatRawDate(data.start_date, 'yyyy-MM-dd', 'UTC'),
      end_date:
        data?.end_date && data?.recurring_type_id !== 'i'
          ? formatRawDate(data.end_date, 'yyyy-MM-dd', 'UTC')
          : null,
      transaction_amount: Math.round(
        parseFloat(data.transaction_amount.replace(/,/g, '')) * 100
      ),
      product_transaction_id: data.product_transaction_id,
      description: data.description || null,
      recurring_api_id: data.recurring_api_id || null,
      contact_id: data.contact_id,
      token_id: data.token_id,
      tags: data.tags,
    }
    setIsLoading(true)
    try {
      const result = await api.service('recurring-billings').create(payload)
      navigate(`/merchant/gateway/recurring-billing/${result.id}/view`)
    } catch (error) {
      setNotification({
        label: t('common.please-review-information'),
        type: 'error',
      })
      if (error.code === 409) {
        setShowErrorModal(true)
      } else {
        const errorsFormatted = toFieldErrors(error)
        Object.keys(errorsFormatted).forEach((key: any) => {
          methods.setError(key, {
            type: 'manual',
            message: errorsFormatted[key],
          })
        })
        methods.setFocus(errorsFormatted[0]?.field)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <HasPermission
      allPermissions={recurringBillingPrivs}
      unauthorizedComponent={<UserNotAllowed />}
    >
      <>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <PageLayoutContainer isButtonBarAtBottom>
              <PageLayoutContainerMain>
                <MerchantAccountDetails />
                <PageLayoutDivider />

                <RecurrenceSettings />
                <PageLayoutDivider />

                <OptionalInformation />
                <PageLayoutDivider />

                <AdvancedSettings />
              </PageLayoutContainerMain>
              <PageLayoutContainerSide>
                <CustomerDetails
                  disableWallet={!methods.watch().contact_id}
                  hideWallet={false}
                  guidingId={guidingId}
                />
                <RecurrenceSummary
                  isAddRecurring={true}
                  recurringData={methods.watch()}
                />
              </PageLayoutContainerSide>

              <AppBar
                sx={{
                  bottom: 0,
                  top: 'auto',
                  position: 'fixed',
                  boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
                }}
              >
                <ButtonBar style={{ marginBottom: '0 !important' }}>
                  <ButtonBarEnd>
                    <Button
                      key={0}
                      label={t('common.cancel')}
                      color={'secondary'}
                      disabled={false}
                      onClick={() => {
                        navigate('/merchant/gateway/recurring-billing')
                      }}
                      containerClassName={classes.cancelButtonContainer}
                      testId="cancel-button"
                      guidingId={`${guidingId}-cancel`}
                    />
                    <Button
                      key={1}
                      type="submit"
                      label={t('common.save')}
                      isLoading={isLoading}
                      containerClassName={classes.saveButtonContainer}
                      testId="save-button"
                      guidingId={`${guidingId}-save`}
                    />
                  </ButtonBarEnd>
                </ButtonBar>
              </AppBar>
            </PageLayoutContainer>
          </form>
        </FormProvider>
        {showErrorModal && (
          <ErrorModal
            title={t('common.something-went-wrong')}
            open={showErrorModal}
            handleClose={() => setShowErrorModal(false)}
            buttons={[
              {
                label: t('common.try-again'),
                onClick: () => {
                  navigate('/merchant/gateway/recurring-billing/add')
                  setShowErrorModal(false)
                },
                show: true,
                color: 'primary',
                guidingId: `${guidingId}-error-tryagain`,
              },
              {
                label: t('common.close'),
                onClick: () => setShowErrorModal(false),
                show: true,
                color: 'secondary',
                style: classes.closeModalButtonContainer,
                guidingId: `${guidingId}-error-close`,
              },
            ]}
          />
        )}
      </>
    </HasPermission>
  )
}

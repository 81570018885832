import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function useFilterModel(defaultFilters = {}, paramKeys = null) {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const passedDefaultFilters = JSON.stringify(defaultFilters)
  const passedParamKeys = JSON.stringify(paramKeys)

  return useMemo(() => {
    let filters = { ...defaultFilters }

    if (paramKeys) {
      paramKeys.forEach((key) => {
        const value = query.get(key.queryKey)
        if (value !== null && value !== undefined) {
          switch (key.filterType) {
            case 'agSetColumnFilter':
              filters[key.filterKey] = {
                filterType: 'set',
                values: value.split(','),
              }
              break
            case 'greaterThanOrEqual':
              const endKey = key.queryKey.replace('[$gte]', '[$lte]')
              const endValue = query.get(endKey)

              filters[key.filterKey] = {
                type: 'range',
                filterType: 'text',
                filter: {
                  $gte: parseInt(value),
                  ...(endValue ? { $lte: parseInt(endValue) } : {}),
                },
              }
              break
            default:
              filters[key.filterKey] = {
                type: 'text',
                filter: value,
              }
              break
          }
        }
      })
    }

    return filters
  }, [location.search, passedDefaultFilters, passedParamKeys, query])
}

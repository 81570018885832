import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Grid, Typography } from '@mui/material'

import { api, FeathersError, QuickInvoice, Transaction } from '@shared/api'
import { ActionModal } from '@shared/components'
import { currency, formatDate } from '@shared/utils'

import { AssignmentResult } from '../summary-assign-transactions-modal/SummaryAssignTransactionsModal'

const useStyles = tss
  .withName('ConfirmAssignTransactionsModal')
  .create(({ theme }) => ({
    modal: {
      width: '706px',
      maxWidth: '706px',
      maxHeight: '500px',
    },
    text: {
      color: theme.palette['neutral-700'],
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Inter',
    },
  }))

interface ConfirmAssignTransactionsModalProps {
  open: boolean
  quickInvoice: QuickInvoice
  onClose: () => void
  onSuccess: (allResults: AssignmentResult[]) => void
  transactions: Transaction[]
}

export const ConfirmAssignTransactionsModal: FC<
  ConfirmAssignTransactionsModalProps
> = ({ open, quickInvoice, onClose, onSuccess, transactions }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const onAssign = async () => {
    setIsLoading(true)
    const updateRequests: Promise<unknown>[] = []

    transactions.forEach((transaction) => {
      if (transaction.contact_id === quickInvoice.contact_id) return
      updateRequests.push(
        api.service('transactions').patch(transaction.id, {
          contact_id: quickInvoice.contact_id,
        })
      )
    })

    // try-catch, if any of the update requests fails, we want to continue with the rest
    await Promise.all(updateRequests).catch(() => {
      return
    })

    const promiseResults: {
      status: 'fulfilled' | 'rejected'
      value?: {
        id: string
        response?: QuickInvoice
        error?: FeathersError
      }
    }[] = await Promise.allSettled(
      transactions.map(async (t) => {
        const result: {
          id: string
          response?: QuickInvoice
          error?: FeathersError
        } = {
          id: t.id,
        }
        try {
          const res = await api
            .service('quick-invoices')
            .assignTransaction(quickInvoice.id, t.id)
          result.response = res
        } catch (error) {
          result.error = error as FeathersError
        }

        return result
      })
    )

    const allResults = transactions.map((t) => {
      const result = promiseResults.find((p) => p.value?.id === t.id)
      return {
        transaction: t,
        response: result?.value?.response,
        error: result?.value?.error,
      }
    })

    setIsLoading(false)
    onSuccess(allResults)
  }

  return (
    <ActionModal
      open={open}
      title={t('mfe-gateway.quick-invoice.assign-transaction')}
      subtitle={t('mfe-gateway.quick-invoice.are-you-sure-you-want-to-assign')}
      onClose={onClose}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: onClose,
          disabled: isLoading,
          guidingId: 'quickinvoice-confirmassigntransactions-cancel',
        },
        {
          label: t('common.assign'),
          onClick: onAssign,
          isLoading: isLoading,
          guidingId: 'quickinvoice-confirmassigntransactions-assign',
        },
      ]}
      isCloseDisabled={isLoading}
      className={classes.modal}
      guidingId="quickinvoice-confirmassigntransactions"
    >
      {transactions.map((transaction) => (
        <Grid container columns={15} key={transaction.id} marginBottom="24px">
          <Grid item xs={3}>
            <Typography className={classes.text}>
              {formatDate(transaction.created_ts)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.text}>
              {currency(transaction.transaction_amount, true)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.text}>
              {transaction.last_four}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.text}>
              {transaction.account_holder_name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.text}>
              {`${transaction.contact?.first_name || ''} ${
                transaction.contact?.last_name || ''
              }`}
            </Typography>
          </Grid>
        </Grid>
      ))}

      <Typography className={classes.text}>
        {`${t(
          'mfe-gateway.quick-invoice.all-transactions-selected-without-customer'
        )}
        ${quickInvoice.contact?.first_name} ${quickInvoice.contact?.last_name}`}
      </Typography>
    </ActionModal>
  )
}

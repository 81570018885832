import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { Contact } from '@shared/api'
import { ContactInfoCard } from '@shared/components'

const useStyles = tss.withName('CustomerInformationToggle').create(() => ({
  informationToggleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':hover': {
      cursor: 'pointer',
    },
  },
}))

export interface CustomerInformationToggleProps {
  customer: Contact | null
  guidingId?: string
}

export const CustomerInformationToggle: FC<CustomerInformationToggleProps> = ({
  customer,
  guidingId,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [showCustomerInfo, setShowCustomerInfo] = useState(false)

  const ShowCustomerInfoArrowIcon = useMemo(
    () => (showCustomerInfo ? KeyboardArrowUp : KeyboardArrowDown),
    [showCustomerInfo]
  )

  const onClickShowCustomerInfo = useCallback(
    () => setShowCustomerInfo((v) => !v),
    []
  )

  return (
    <Box>
      <Box
        onClick={onClickShowCustomerInfo}
        className={classes.informationToggleContainer}
        data-guiding-id={`${guidingId}-toggle`}
      >
        <Typography variant="body2">{t('common.information')}</Typography>

        <ShowCustomerInfoArrowIcon
          data-testid={'arrow-icon'}
          height={5}
          width={5}
        />
      </Box>

      {showCustomerInfo && <ContactInfoCard contact={customer} />}
    </Box>
  )
}

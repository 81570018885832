import { yupResolver } from '@hookform/resolvers/yup'
import { capitalize, omit } from 'lodash'
import React, { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'

import { Token, api } from '@shared/api'
import { ActionModal, Button } from '@shared/components'
import { PaymentMethodType } from '@shared/types'
import {
  billingAddressErrorConverter,
  toFieldErrors,
  processPhone,
} from '@shared/utils'

import { BillingInformation } from '@/components/billing-information/BillingInformation'

import AdvancedSettingsAdd from '../advanced-settings-add/AdvancedSettingsAdd'
import WalletDetailsEdit from '../details-edit/DetailsEdit'

const useStyles = tss.withName('WalletEdit').create(({ theme }) => ({
  modal: {
    maxWidth: '640px',
    minWidth: '400px',
    width: '100%',
    overflowY: 'hidden',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: theme.palette['neutral-900'],
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 600,
  },
}))

interface WalletEditProps {
  wallet: Token
  open: boolean
  onClose: () => void
  onCompleted: () => void
  onError: () => void
}

const buildSchema = (
  t: (text: string) => string,
  paymentMethod: Token['payment_methood']
) => {
  return yup.object({
    title: yup.string(),
    account_holder_name: yup.string().when([], {
      is: () => paymentMethod === PaymentMethodType.ACH,
      then: (schema) =>
        schema.required(
          t('mfe-gateway.validations.token.account-holder-name-required')
        ),
    }),
    billing_address: yup.object({
      street: yup.string(),
      city: yup.string(),
      country: yup.string(),
      state: yup.string(),
      postal_code: yup.string(),
      phone: yup.string(),
    }),
    token_api_id: yup.string(),
    exp_date: yup.string().when([], {
      is: () => paymentMethod === PaymentMethodType.CC,
      then: (schema) =>
        schema
          .required(
            t('mfe-gateway.validations.transaction.expiration-date-is-required')
          )
          .matches(
            /^(0[1-9]|1[0-2])\d{2}$/,
            t('mfe-gateway.validations.transaction.invalid-expiration-date')
          ),
    }),
  })
}

const WalletEdit = ({
  wallet,
  open,
  onClose,
  onCompleted,
  onError,
}: WalletEditProps) => {
  const { classes } = useStyles()

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const walletEditSchema = buildSchema(t, wallet.payment_method)

  const methods = useForm<yup.InferType<typeof walletEditSchema>>({
    defaultValues: {
      title: wallet.title || undefined,
      account_holder_name: wallet.account_holder_name || undefined,
      billing_address: {
        street: wallet.billing_address.street || undefined,
        city: wallet.billing_address.city || undefined,
        state: wallet.billing_address.state || undefined,
        postal_code: wallet.billing_address.postal_code || undefined,
        country: wallet.billing_address.country || undefined,
        phone: wallet.billing_address.phone || undefined,
      },
      token_api_id: wallet.token_api_id || undefined,
      exp_date: wallet.exp_date || undefined,
    },
    resolver: yupResolver(walletEditSchema),
    mode: 'onBlur',
  })

  const onSubmit = async ({
    title,
    account_holder_name,
    billing_address,
    token_api_id,
    exp_date,
  }) => {
    try {
      const payload = {
        title: !!title ? title : null,
        account_holder_name: !!account_holder_name ? account_holder_name : null,
        billing_address: {
          street: !!billing_address.street ? billing_address.street : null,
          city: !!billing_address.city ? billing_address.city : null,
          country: !!billing_address.country ? billing_address.country : null,
          state: !!billing_address.state ? billing_address.state : null,
          postal_code: !!billing_address.postal_code
            ? billing_address.postal_code
            : null,
          phone: !!billing_address.phone ? billing_address.phone : null,
        },
        token_api_id: !!token_api_id ? token_api_id : null,
        exp_date: !!exp_date ? exp_date : null,
      }

      setLoading(true)
      if (wallet.payment_method === PaymentMethodType.CC) {
        await api.service('tokens').update_cc(wallet.id, payload)
      }
      if (wallet.payment_method === PaymentMethodType.ACH) {
        await api
          .service('tokens')
          .update_ach(wallet.id, omit(payload, 'exp_date'))
      }

      methods.reset()

      onCompleted()
    } catch (error) {
      let fieldErrors = toFieldErrors(error)

      if (fieldErrors['billing_address']) {
        fieldErrors = {
          ...omit(fieldErrors, 'billing_address'),
          ...billingAddressErrorConverter({
            errors: fieldErrors['billing_address'],
          }),
        }
      }

      Object.keys(fieldErrors).forEach(
        (key: keyof yup.InferType<typeof walletEditSchema>) => {
          methods.setError(key, {
            type: 'manual',
            message: capitalize(fieldErrors[key]),
          })
        }
      )

      onError()
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    methods.reset()
    onClose()
  }

  const disableSubmit = useMemo(
    () => !methods.formState.isValid || !methods.formState.isDirty,
    [methods.formState.isValid, methods.formState.isDirty]
  )

  return (
    <ActionModal
      open={open}
      onClose={handleClose}
      isScrollableContent
      title={t('mfe-gateway.token.edit-wallet')}
      buttons={[
        {
          label: t('common.cancel'),
          color: 'secondary',
          onClick: handleClose,
          guidingId: 'token-cancel',
        },
        {
          label: t('common.save-changes'),
          color: 'primary',
          onClick: methods.handleSubmit(onSubmit),
          isLoading: loading,
          disabled: disableSubmit,
          guidingId: 'token-save',
        },
      ]}
      className={classes.modal}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container rowSpacing={'24px'}>
            <Grid item xs={12}>
              <WalletDetailsEdit wallet={wallet} />
              <Divider
                style={{
                  marginTop: '24px',
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <BillingInformation showToggle guidingId="token" />
              <Divider
                style={{
                  marginTop: '24px',
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AdvancedSettingsAdd showRunAvs={false} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </ActionModal>
  )
}

export default WalletEdit

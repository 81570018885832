import { Location, MerchantAccount } from '../../../api'

export const getVirtualTerminalMerchantAccounts = (
  location: Location
): MerchantAccount[] => {
  return (
    location?.product_transactions?.filter((merchantAccount) => {
      if (!merchantAccount.active) return false
      // We should only validate vt_enable on cc accounts
      if (merchantAccount.payment_method === 'cc') {
        return merchantAccount.vt_enable
      }

      if (merchantAccount.payment_method === 'ach') {
        return (
          merchantAccount.ach_allow_debit || merchantAccount.ach_allow_credit
        )
      }

      return true
    }) ?? []
  )
}

import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
export const FolderIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="28"
        height="24"
        viewBox="0 0 28 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.666504 8H25.9998C26.3535 8 26.6926 8.14048 26.9426 8.39052C27.1927 8.64057 27.3332 8.97971 27.3332 9.33333V22.6667C27.3332 23.0203 27.1927 23.3594 26.9426 23.6095C26.6926 23.8595 26.3535 24 25.9998 24H1.99984C1.64622 24 1.30708 23.8595 1.05703 23.6095C0.80698 23.3594 0.666504 23.0203 0.666504 22.6667V8ZM1.99984 0H21.9998V5.33333H0.666504V1.33333C0.666504 0.979711 0.80698 0.640573 1.05703 0.390524C1.30708 0.140476 1.64622 0 1.99984 0ZM17.9998 14.6667V17.3333H21.9998V14.6667H17.9998Z"
          fill="#0EA5E9"
        />
      </svg>
    </SvgIcon>
  )
}

import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { Tags } from '@shared/api/src'
import { FieldGroupContainer, Input, SelectComponent } from '@shared/components'
import { useLocations } from '@shared/hooks'

interface FormData {
  description: string
  tags: Tags[]
}

const OptionalInformation = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>()
  const { t } = useTranslation()

  const { selectedLocation } = useLocations()

  const tagsOptions = useMemo(() => {
    if (!selectedLocation?.tags) return []
    return selectedLocation.tags.map((tag) => ({
      label: tag.title,
      value: tag.title,
    }))
  }, [selectedLocation])

  return (
    <FieldGroupContainer title={t('common.optional-information')}>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item sm={12} md={6}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="description-input"
                label={t('common.description')}
                placeholder={t('common.description-placeholder')}
                error={!!errors.description}
                helperText={errors.description?.message}
                guidingId="recurringbilling-description"
              />
            )}
          />
        </Grid>
        {tagsOptions.length > 0 && (
          <Grid item sm={12} md={6}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <SelectComponent
                  multiple
                  onChange={field.onChange}
                  value={field.value}
                  options={tagsOptions}
                  label={t('common.tags')}
                  placeholder={t('common.tags-select')}
                  style={{
                    width: '100%',
                    maxWidth: 'unset',
                    border: 'unset',
                    height: '44px',
                  }}
                  error={!!errors.tags}
                  helperText={errors.tags?.message}
                  guidingId="recurringbilling-tags"
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </FieldGroupContainer>
  )
}
export default OptionalInformation

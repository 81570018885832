import React from 'react'
import { useTranslation } from 'react-i18next'

import { Contact } from '@shared/api/src'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'

export interface AdvancedSettingsProps {
  customer: Contact
}

const AdvancedSettings: React.FC<AdvancedSettingsProps> = ({ customer }) => {
  const { t } = useTranslation()

  const valueContactApiId = customer?.contact_api_id

  return (
    <FieldGroupContainer title={t('common.advanced-settings')}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('mfe-gateway.customer.customer-api-id')}
          value={valueContactApiId}
          valueTestID="contact-api-id-value"
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}

export default AdvancedSettings

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Paylinks, Tags } from '@shared/api'
import {
  FieldGroupContainerBlock,
  FieldGroupRowStacked,
  FieldGroupViewItemStacked,
  FieldGroupViewItemWithChildrenStacked,
  TagList,
} from '@shared/components'
import { useFormatBooleanAsYesNo } from '@shared/utils'

interface AdvancedSettingsProps {
  paylink: Paylinks
}

export const AdvancedSettings: FC<AdvancedSettingsProps> = ({ paylink }) => {
  const { t } = useTranslation()

  const tags = (paylink.tags as unknown as Tags[])?.map(
    (tag: Tags) => tag.title
  )
  const showBillingFields = useFormatBooleanAsYesNo(
    paylink.display_billing_fields
  )
  const paylinkApiId = paylink.paylink_api_id
  const redirectUrlOnApprove = paylink.redirect_url_on_approve
  const redirectUrlOnDecline = paylink.redirect_url_on_decline
  const redirectUrlDelay = paylink.redirect_url_delay.toString()

  return (
    <FieldGroupContainerBlock title={t('common.advanced-settings')}>
      <FieldGroupRowStacked>
        <FieldGroupViewItemStacked
          title={t('mfe-gateway.paylink.show-billing-fields')}
          value={showBillingFields}
        />
        <FieldGroupViewItemStacked
          title={t('mfe-gateway.paylink.api-id')}
          value={paylinkApiId}
        />
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <FieldGroupViewItemStacked
          title={t('mfe-gateway.paylink.approval-direct-url')}
          value={redirectUrlOnApprove}
        />
        <FieldGroupViewItemStacked
          title={t('mfe-gateway.paylink.decline-direct-url')}
          value={redirectUrlOnDecline}
        />
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <FieldGroupViewItemStacked
          title={t('mfe-gateway.paylink.redirect-delay')}
          value={redirectUrlDelay}
        />
        <FieldGroupViewItemWithChildrenStacked
          title={t('common.tags')}
          titleTestID="tags-title"
        >
          <TagList items={tags} nameSelector={(tag) => tag} wrapWords={false} />
        </FieldGroupViewItemWithChildrenStacked>
      </FieldGroupRowStacked>
    </FieldGroupContainerBlock>
  )
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Box, Grid, Typography } from '@mui/material'

import { MerchantAccount, Terminal, TransactionType } from '@shared/api'
import {
  RadioButtons,
  RadioButtonsArray,
  Input,
  SelectComponent as Select,
  Checkbox,
} from '@shared/components'
import {
  PubSubEvent,
  useEnforceLogin,
  useLocations,
  usePub,
  useSub,
} from '@shared/hooks'
import { ProcessMethod } from '@shared/types'
import {
  getDefaultProcessMethod,
  getValidTerminals,
  getDefaultPaymentDetails,
  getProcessMethodOptions,
} from '@shared/utils'

import { AchAccountInformation } from '../ach-account-information/AchAccountInformation'
import { CreditCardInformation } from '../credit-card-information/CreditCardInformation'
import {
  CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT,
  CUSTOMER_DETAILS_WALLET_CHANGE_EVENT,
} from '../customer-details/CustomerDetails'
import {
  TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT,
  TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT,
} from '../transaction-information/TransactionInformation'

export const PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT = new Event(
  'PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE'
) as PubSubEvent<ProcessMethod | null>

const useStyles = tss.withName('PaymentAccountDetails').create(({ theme }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  title: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter !important',
    fontSize: '18px !important',
    fontWeight: '500 !important',
    lineHeight: '28px !important',
  },
  button: {
    width: '100%',
    height: '44px',
  },
}))

export const PaymentAccountDetails = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { selectedLocation, locationTerminals } = useLocations()
  const { walletId } = useParams()

  const { user } = useEnforceLogin()
  const publish = usePub()

  const {
    control,
    watch,
    formState: { errors },
    reset,
    getValues,
    clearErrors,
  } = useFormContext()

  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const [selectedProcessMethod, setSelectedProcessMethod] =
    useState<ProcessMethod | null>(null)

  const [terminals, setTerminals] = useState<Terminal[]>(
    locationTerminals ?? []
  )
  const [selectedTransactionType, setSelectedTransactionType] =
    useState<TransactionType | null>(null)

  const saveAccount = watch('save_account')

  const customerId = watch('contact_id')

  // This function will be called also when the selected merchant account changes
  // therefore we need to pass the merchant account as a parameter
  // because the state might not be updated yet
  const onChangeProcessMethod = useCallback(
    (
      processMethod: ProcessMethod | null,
      merchantAccount?: MerchantAccount | null
    ) => {
      setSelectedProcessMethod(processMethod)
      const accountType =
        (merchantAccount ?? selectedMerchantAccount)?.payment_method === 'ach'
          ? 'personal'
          : null
      reset({
        ...getValues(),
        ...getDefaultPaymentDetails(
          selectedLocation,
          user,
          merchantAccount ?? selectedMerchantAccount,
          processMethod,
          accountType
        ),
      })

      publish(
        PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT,
        processMethod
      )
    },
    [selectedMerchantAccount]
  )

  // Subscribe to Selected Merchant Account
  useSub<typeof TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT>(
    TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT.type,
    ({ data: merchant }) => {
      setSelectedMerchantAccount(merchant)

      const availableProcessMethods = getProcessMethodOptions({
        location: selectedLocation,
        merchantAccount: merchant,
        user,
        transactionType: selectedTransactionType,
      })

      const defaultProcessMethod = getDefaultProcessMethod({
        user,
        location: selectedLocation,
        merchantAccount: merchant,
        transactionType: selectedTransactionType,
      })

      if (
        merchant?.payment_method === selectedMerchantAccount?.payment_method &&
        availableProcessMethods.includes(selectedProcessMethod)
      )
        return

      if (!walletId) {
        onChangeProcessMethod(defaultProcessMethod, merchant)
      }
    },
    [
      selectedLocation,
      selectedTransactionType,
      user,
      selectedProcessMethod,
      walletId,
    ]
  )

  // Subscribe to Selected Customer
  useSub<typeof CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT>(
    CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT.type,
    ({ data: customer }) => {
      if (!customer && selectedProcessMethod === 'wallet') {
        onChangeProcessMethod('manual')
      }
    },
    [selectedProcessMethod]
  )

  useSub<typeof CUSTOMER_DETAILS_WALLET_CHANGE_EVENT>(
    CUSTOMER_DETAILS_WALLET_CHANGE_EVENT.type,
    ({ data: wallet }) => {
      if (wallet) {
        onChangeProcessMethod('wallet')
      }
    }
  )

  // Subscribe to Selected Transaction Type
  useSub<typeof TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT>(
    TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT.type,
    ({ data: transactionType }) => setSelectedTransactionType(transactionType)
  )

  const paymentMethodsRadioButtonsProps = useMemo(() => {
    const availableProcessMethods = getProcessMethodOptions({
      location: selectedLocation,
      merchantAccount: selectedMerchantAccount,
      user,
      transactionType: selectedTransactionType,
    })

    return [
      {
        testId: `manual-process-method-button`,
        defaultSelected: selectedProcessMethod === 'manual',
        title: t('mfe-gateway.keyed'),
        color: 'secondary',
        onClick: () => onChangeProcessMethod('manual'),
        className: classes.button,
        guidingId:
          'virtualterminal-paymentaccountdetails-paymentmethodtype-keyed',
      },
      {
        testId: `terminal-process-method-button`,
        defaultSelected: selectedProcessMethod === 'terminal',
        title: t('mfe-gateway.terminal'),
        color: 'secondary',
        onClick: () => {
          onChangeProcessMethod('terminal')
        },
        visible:
          availableProcessMethods.includes('terminal') && terminals.length,
        className: classes.button,
        guidingId:
          'virtualterminal-paymentaccountdetails-paymentmethodtype-terminal',
      },
      {
        testId: `wallet-process-method-button`,
        visible: !!customerId && availableProcessMethods.includes('wallet'),
        defaultSelected: selectedProcessMethod === 'wallet',
        title: t('mfe-gateway.customer-wallet'),
        color: 'secondary',
        onClick: () => onChangeProcessMethod('wallet'),
        className: classes.button,
        guidingId:
          'virtualterminal-paymentaccountdetails-paymentmethodtype-wallet',
      },
    ] as RadioButtonsArray
  }, [
    selectedProcessMethod,
    onChangeProcessMethod,
    selectedMerchantAccount,
    selectedTransactionType,
    selectedLocation,
    user,
    customerId,
    terminals,
  ])

  useEffect(() => {
    if (!selectedLocation || !selectedMerchantAccount) return
    setTerminals(
      getValidTerminals(
        selectedLocation,
        selectedMerchantAccount,
        user,
        locationTerminals
      )
    )
  }, [selectedLocation, selectedMerchantAccount, locationTerminals])

  if (selectedMerchantAccount?.payment_method === 'cash' || walletId)
    return null

  return (
    <Box className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        data-testid="payment-account-details-title"
      >
        {t('mfe-gateway.virtual-terminal.payment-account-details')}
      </Typography>

      <Grid container columnGap={'8px'} flexWrap={'nowrap'}>
        {paymentMethodsRadioButtonsProps.filter(
          (button) => button.visible || button.visible === undefined
        ).length > 1 && (
          <Grid item xs={6}>
            <RadioButtons
              testId="payment-method-type-radio-buttons"
              label={t('common.payment-method-type')}
              buttons={paymentMethodsRadioButtonsProps}
            />
          </Grid>
        )}

        {selectedProcessMethod === 'terminal' && terminals?.length && (
          <Grid item xs={6}>
            <Controller
              control={control}
              name="terminal_id"
              render={({ field }) => (
                <Select
                  testId="terminal-select"
                  label={t('mfe-gateway.terminal-placeholder')}
                  options={terminals.map((terminal) => ({
                    label: terminal.title,
                    value: terminal.id,
                  }))}
                  placeholder={t('mfe-gateway.terminal')}
                  sort={true}
                  style={{
                    width: '100%',
                    maxWidth: 'unset',
                    border: 'unset',
                    height: '44px',
                  }}
                  {...field}
                  guidingId="virtualterminal-paymentaccountdetails-terminal"
                />
              )}
            />
          </Grid>
        )}
      </Grid>

      {selectedProcessMethod === 'manual' && (
        <>
          {selectedMerchantAccount?.payment_method === 'cc' && (
            <CreditCardInformation
              showCvv={selectedMerchantAccount?.vt_cvv}
              guidingId="virtualterminal-paymentaccountdetails"
            />
          )}
          <AchAccountInformation
            guidingId="virtualterminal-paymentaccountdetails"
            selectedMerchantAccount={selectedMerchantAccount}
          />
        </>
      )}

      {selectedProcessMethod !== 'wallet' &&
        !!selectedLocation?.product_token?.active && (
          <>
            <Grid container columnGap={'8px'} flexWrap={'nowrap'}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="save_account"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      testId="save-account-input"
                      checked={!!field.value}
                      label={t(
                        'mfe-gateway.virtual-terminal.save-for-future-transactions'
                      )}
                      description={t(
                        `mfe-gateway.virtual-terminal.store-card-for-future-use`
                      )}
                      onChange={(e) => {
                        if (field.value) {
                          clearErrors('save_account_title')
                        }
                        field.onChange(e)
                      }}
                      guidingId="virtualterminal-paymentaccountdetails-saveaccount"
                    />
                  )}
                />
              </Grid>

              {saveAccount && (
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="save_account_title"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        testId="save-account-title-input"
                        label={t('common.account-title')}
                        placeholder={t('common.account-title-placeholder')}
                        error={!!errors.save_account_title}
                        helperText={
                          errors.save_account_title?.message as string
                        }
                        guidingId="virtualterminal-paymentaccountdetails-saveaccounttitle"
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
    </Box>
  )
}

import { ModelUpdatedEvent, RowSelectedEvent } from 'ag-grid-community'
import { ColDef } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { debounce, set } from 'lodash'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { HelpOutline } from '@mui/icons-material'
import { Grid, Tooltip } from '@mui/material'

import { Contact, QuickInvoice, Transaction } from '@shared/api'
import { preprocessCurrencyAmount } from '@shared/api/src/utils/transactions/preprocessCurrencyAmount'
import { valueToNumber } from '@shared/api/src/utils/valueToNumber'
import {
  ActionModal,
  CurrencyInput,
  DatePicker,
  EmptyFilterPlaceholder,
  GetItemsOptions,
  Input,
} from '@shared/components'
import { useAuthorization, useEnforceLogin, useLocations } from '@shared/hooks'
import { searchCustomers } from '@shared/services/customers'
import {
  checkAllPermissions,
  currency,
  DataSource,
  filterParams,
  formatDate,
} from '@shared/utils'

import { CustomerSelector } from '@/components/customer-selector/CustomerSelector'

const useStyles = tss
  .withName('AssignTransactionsModal')
  .create(({ theme }) => ({
    modal: {
      width: '1200px',
      maxWidth: '1200px',
      maxHeight: '800px',
      height: 'auto',
    },
    agGridContainer: {
      background: '#FFF',
      padding: '10px 20px 40px',
      border: 'none',
      '& .ag-root-wrapper': {
        overflow: 'hidden',
        border: 'none',
      },
      '& .ag-header-cell-text': {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: 'normal',
        color: theme.palette['neutral-700'],
      },
      '& .ag-row': {
        backgroundColor: '#FFF',
      },
      '& .ag-row-odd': {
        backgroundColor: theme.palette['neutral-50'],
      },
      '& .ag-cell': {
        borderBottom: `1px solid ${theme.palette['neutral-200']}`,
      },
      '& .ag-cell-value': {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '400',
        color: theme.palette['neutral-700'],
      },
    },
    agGridContainerNoTopButtonBar: {
      padding: '20px 20px 40px',
    },
    agGridContainerIsInline: {
      padding: '0px !important',
    },
  }))

interface FilterTransactionsModalProps {
  quickInvoice: QuickInvoice
  open: boolean
  onClose: () => void
  onAssign: (transactions: Transaction[]) => void
}

interface AssignableTransaction
  extends Pick<
    Transaction,
    | 'id'
    | 'created_ts'
    | 'transaction_amount'
    | 'last_four'
    | 'account_holder_name'
    | 'contact_id'
    | 'contact'
  > {
  isSelected?: boolean
}

export const FilterTransactionsModal: FC<FilterTransactionsModalProps> = ({
  quickInvoice,
  open,
  onAssign,
  onClose,
}) => {
  const { t } = useTranslation()
  const { selectedLocation } = useLocations()
  const { user } = useEnforceLogin()
  const { userPermissionSet } = useAuthorization()

  const [customers, setCustomers] = useState<Contact[] | null>(null)
  const [selectedCustomer, setSelectedCustomer] = useState<Contact | null>(null)
  const [selectedTransactions, setSelectedTransactions] = useState<
    Transaction[]
  >([])

  const [filters, setFilters] = useState<Partial<AssignableTransaction>>({
    last_four: '',
    account_holder_name: '',
    transaction_amount: undefined,
  })
  const [date, setDate] = useState<string>()
  const [isFiltered, setIsFiltered] = useState(false)

  const { classes } = useStyles()

  const gridRef = useRef<AgGridReact>(null)

  const clearFilters = () => {
    setCustomers(null)
    setSelectedCustomer(null)
    setFilters({
      last_four: '',
      account_holder_name: '',
      transaction_amount: undefined,
    })
    setDate('')
    setIsFiltered(false)
  }

  const handleClose = () => {
    clearFilters()
    setSelectedTransactions([])
    onClose()
  }

  const datasource = useCallback(
    (extraFilters) => {
      const validPaymentMethods = ['cash']

      if (quickInvoice?.ach_product_transaction_id) {
        validPaymentMethods.push('ach')
      }

      if (quickInvoice?.cc_product_transaction_id) {
        validPaymentMethods.push('cc')
      }

      const filters = {
        ...extraFilters,
        'filter[location_id]': selectedLocation?.id,
        'filter[status_code]': '101,131,132,133,134,140',
        'filter[payment_method]': validPaymentMethods.join(','),
      }

      const clientFilter = (data: Transaction[]) => {
        return data.filter((transaction) => {
          if (transaction?.product_transaction?.industry_type === 'lodging') {
            return false
          }

          if (
            transaction?.payment_method === 'ach' &&
            transaction?.type_id === 50
          ) {
            return false
          }

          return true
        })
      }

      const extraParams = filterParams(filters)
      return new DataSource(
        'transactions',
        extraParams,
        undefined,
        clientFilter
      )
    },
    [selectedLocation]
  )

  const getCustomers = useCallback(
    async (options?: GetItemsOptions) => {
      if (!selectedLocation?.id) return []
      return searchCustomers(selectedLocation.id, options?.search ?? '')
    },
    [selectedLocation]
  )

  const onChangeCustomer = useCallback(
    (customer: Contact) => {
      setSelectedCustomer(customer)
      setFilters((prev) => ({
        ...prev,
        contact_id: customer ? customer.id : undefined,
      }))
    },
    [setSelectedCustomer]
  )

  const columnDefs: ColDef<AssignableTransaction>[] = [
    {
      headerName: t('common.select'),
      field: 'isSelected',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionCurrentPageOnly: true,
      width: 50,
    },
    {
      headerName: t('common.date-transaction'),
      field: 'created_ts',
      valueGetter: (params) => {
        const timestamp = params.data?.created_ts
        return formatDate(timestamp, user?.tz) || '-'
      },
    },
    {
      headerName: t('common.amount'),
      field: 'transaction_amount',
      type: 'rightAligned',
      valueGetter: (params) => currency(params.data?.transaction_amount, true),
    },
    {
      headerName: t('common.token-last-four'),
      field: 'last_four',
    },
    {
      headerName: t('common.account-holder-name'),
      field: 'account_holder_name',
    },
    {
      headerName: t('common.customer'),
      field: 'contact_id',
      valueGetter: (params) => {
        return `${params.data.contact?.first_name || ''} ${
          params.data.contact?.last_name || ''
        }`
      },
    },
  ]

  const isRowSelectable = useCallback(
    ({ data }) => {
      // If the transaction does not have a contact_id and quick invoice does, it can only be assigned if the user has the permission to edit transactions
      if (
        quickInvoice?.contact_id &&
        !data.contact_id &&
        checkAllPermissions(
          userPermissionSet,
          'v2.transactions.put',
          'v2.transactions.put.edit'
        )
      ) {
        return true
      }

      // If both the transaction and the quick invoide do not have a contact_id, it can be assigned
      if (!quickInvoice?.contact_id && !data.contact_id) {
        return true
      }

      // If the contact_id of the transaction is different from the contact_id of the quick invoice, it can not be assigned
      if (quickInvoice?.contact_id !== data.contact_id) {
        return false
      }

      // Other cases, it can be assigned
      return true
    },
    [userPermissionSet, quickInvoice]
  )

  const onGridReady = useCallback(async (params) => {
    params.api.showNoRowsOverlay()
    params.api.sizeColumnsToFit()
  }, [])

  const applyFilters = useCallback(() => {
    let parsedFilters = {}

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        let filter = filters[key]
        if (key === 'created_ts') {
          const dateTo = new Date(filters[key])
          dateTo.setDate(dateTo.getDate() + 1)

          filter = {
            $gte: filters[key] / 1000,
            $lte: dateTo.getTime() / 1000 - 1,
          }
        }
        parsedFilters = {
          ...parsedFilters,
          [`filter[${key}]`]: filter,
        }
      }
    })

    if (Object.keys(parsedFilters).length === 0) {
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.setServerSideDatasource(undefined)
      }
      setIsFiltered(false)
      return
    }

    setIsFiltered(true)
    gridRef.current.api.setServerSideDatasource(datasource(parsedFilters))
  }, [filters, datasource, gridRef])

  const debounceFilters = useCallback(debounce(applyFilters, 500), [
    applyFilters,
  ])

  useEffect(() => {
    if (!gridRef.current || Object.values(filters).length === 0) return

    debounceFilters()

    return () => {
      debounceFilters.cancel()
    }
  }, [filters])

  const onConfirm = useCallback(() => {
    if (selectedTransactions.length > 0) {
      clearFilters()
    }
    onAssign(selectedTransactions)
  }, [selectedTransactions, onAssign])

  // Ag grid does not let modify the checkbox display. So we need to manually modify the DOM to add a tooltip.
  const onModelUpdated = (params: ModelUpdatedEvent) => {
    const elements = document.querySelectorAll(
      '.ag-selection-checkbox.ag-invisible'
    )

    elements.forEach((element) => {
      element.classList.remove('ag-invisible')
      ReactDOM.render(
        <Tooltip
          title={t(
            'mfe-gateway.quick-invoice.this-transaction-can-not-be-assigned'
          )}
          placement="top"
        >
          <HelpOutline
            color="disabled"
            fontSize="medium"
            style={{
              marginLeft: '-4px',
            }}
          />
        </Tooltip>,
        element
      )
    })
  }

  const NoRowsOverlay = () => (
    <div className="ag-overlay-loading-center">
      <p className="far fa-frown">{t('common.not-available-results')}</p>
    </div>
  )

  return (
    <>
      <ActionModal
        open={open}
        title={t('mfe-gateway.quick-invoice.assign-transaction')}
        subtitle={t('mfe-gateway.quick-invoice.select-transaction-to-assign')}
        onClose={handleClose}
        buttons={[
          {
            label: t('common.cancel'),
            onClick: handleClose,
            color: 'secondary',
            guidingId: 'quickinvoice-filtertransactions-cancel',
          },
          {
            label: t('common.assign'),
            onClick: onConfirm,
            disabled: !isFiltered || selectedTransactions.length === 0,
            guidingId: 'quickinvoice-filtertransactions-assign',
          },
        ]}
        className={classes.modal}
        guidingId="quickinvoice-filtertransactions"
      >
        <Grid container spacing={2} columns={15} marginBottom="24px">
          <Grid item xs={3}>
            <DatePicker
              value={date}
              onChange={(e) => {
                setDate(e.target.value)
                setFilters((prev) => ({
                  ...prev,
                  created_ts: new Date(e.target.value).getTime(),
                }))
              }}
              label={t('common.date-transaction')}
              testId="date-transaction-input"
              guidingId="quickinvoice-filtertransactions-datetransaction"
            />
          </Grid>

          <Grid item xs={3}>
            <CurrencyInput
              label={t('common.amount-transaction')}
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  transaction_amount: preprocessCurrencyAmount(
                    valueToNumber(e.target.value)
                  ),
                }))
              }
              testId="amount-transaction-input"
              currency="USD"
              guidingId="quickinvoice-filtertransactions-amounttransaction"
            />
          </Grid>

          <Grid item xs={3}>
            <Input
              label={t('common.token-last-four')}
              value={filters?.last_four}
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  last_four: e.target.value,
                }))
              }
              placeholder={t('common.type-name')}
              testId="last-four-input"
              inputProps={{
                maxLength: 4,
              }}
              guidingId="quickinvoice-filtertransactions-lastfour"
            />
          </Grid>

          <Grid item xs={3}>
            <Input
              label={t('common.account-holder-name')}
              value={filters?.account_holder_name}
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  account_holder_name: e.target.value,
                }))
              }
              placeholder={t('common.type-name')}
              testId="account-holder-name-input"
              guidingId="quickinvoice-filtertransactions-accountholdername"
            />
          </Grid>

          <Grid item xs={3}>
            <CustomerSelector
              customers={customers ?? []}
              selectedCustomer={selectedCustomer}
              getCustomers={getCustomers}
              onChangeCustomers={setCustomers}
              onChangeSelectedCustomer={onChangeCustomer}
              label={t('common.customer')}
              placeholder={t('common.select')}
              guidingId="quickinvoice-filtertransactions"
            />
          </Grid>
        </Grid>

        {!isFiltered && (
          <EmptyFilterPlaceholder
            title={t('mfe-gateway.quick-invoice.filter-by')}
            style={{ height: '200px' }}
          />
        )}

        <div
          className={`ag-theme-alpine ${classes.agGridContainer} ${classes.agGridContainerNoTopButtonBar} ${classes.agGridContainerIsInline}`}
          style={{
            height: isFiltered ? '300px' : '0',
          }}
        >
          <AgGridReact
            getRowId={({ data }) => data.id}
            columnDefs={columnDefs}
            rowModelType="serverSide"
            noRowsOverlayComponent={NoRowsOverlay}
            onGridReady={onGridReady}
            ref={gridRef}
            paginationPageSize={10}
            maxBlocksInCache={1}
            pagination
            suppressPaginationPanel
            rowSelection="multiple"
            rowMultiSelectWithClick
            suppressLoadingOverlay
            isRowSelectable={isRowSelectable}
            onModelUpdated={onModelUpdated}
            suppressServerSideInfiniteScroll
            onRowSelected={({ data, node }: RowSelectedEvent) => {
              if (node.isSelected()) {
                setSelectedTransactions([...selectedTransactions, data])
              } else {
                setSelectedTransactions((prev) =>
                  prev.filter((transaction) => transaction.id !== data.id)
                )
              }
            }}
            data-guiding-id="quickinvoice-filtertransactions-grid"
          />
        </div>
      </ActionModal>
    </>
  )
}

import { FC, useState, useMemo, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, InputLabel, Typography } from '@mui/material'

import { MerchantAccount, RoutingNumber } from '@shared/api'
import {
  SelectComponent as Select,
  RadioButtons,
  Input,
  useInputStyles,
  GetItemsOptions,
  Typeahead,
  RadioButtonsArray,
} from '@shared/components'
import { PubSubEvent, useLocations, usePub, useSub } from '@shared/hooks'
import { AccountType } from '@shared/types'
import { getRoutingNumbersService, getSECCodes } from '@shared/utils'

import { TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT } from '../transaction-information/TransactionInformation'

export const ACH_ACCOUNT_INFO_ACH_SEC_CODE_CHANGE_EVENT = new Event(
  'ACH_ACCOUNT_INFO_ACH_SEC_CODE_CHANGE'
) as PubSubEvent<string | null>

export const ACH_ACCOUNT_INFO_ACCOUNT_TYPE_CHANGE_EVENT = new Event(
  'ACH_ACCOUNT_INFO_ACCOUNT_TYPE_FIELD_CHANGE'
) as PubSubEvent<'checking' | 'savings' | null>

export const ACH_ACCOUNT_INFO_ROUTING_NUMBER_CHANGE_EVENT = new Event(
  'ACH_ACCOUNT_INFO_ROUTING_NUMBER_CHANGE'
) as PubSubEvent<RoutingNumber | string | null>

const useStyles = tss.withName('AchAccountInformation').create(() => ({
  inputContainer: {
    paddingBottom: '20px',
  },
  label: {
    marginBottom: '.4em',
  },
  multipleInputContainer: {
    display: 'flex',
    gap: '8px',
    paddingBottom: '20px',
  },
}))

// This is a bit ugly, I know. But we need to render the component logic but not the UI part.
// This component needs to be rendered in order to receive the pubsub events.
// I expect to use this prop only on VirtualTerminal
interface AchAccountInformationProps {
  guidingId?: string
}

export const AchAccountInformation: FC<AchAccountInformationProps> = ({
  guidingId,
}) => {
  const { classes } = useStyles()
  const { classes: inputClasses, cx } = useInputStyles()
  const { t } = useTranslation()
  const { selectedLocation } = useLocations()

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext()

  const publish = usePub()

  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const [selectedRoutingNumber, setSelectedRoutingNumber] =
    useState<RoutingNumber | null>(null)

  const [accountTypeField, setAccountTypeField] =
    useState<AccountType>('personal')

  const [isACH, setIsACH] = useState<boolean>(false)

  const [achSecCodes, setAchSecCodes] = useState<
    { label: string; value: string }[]
  >([])

  const accountType = watch('account_type')

  const routingNumbersService = useMemo(() => {
    const service = getRoutingNumbersService(selectedLocation)

    if (!service) return null
    return (options: GetItemsOptions) => service(options?.search ?? '')
  }, [selectedLocation])

  const getRoutingNumberLabel = ({
    routing_number,
    bank_name,
  }: RoutingNumber) => `${routing_number} - ${bank_name}`

  const onChangeRoutingNumber = (routingNumber: RoutingNumber | null) => {
    setSelectedRoutingNumber(routingNumber)
    clearErrors('routing_number')
    setValue('routing_number', routingNumber?.routing_number ?? undefined)
    publish(ACH_ACCOUNT_INFO_ROUTING_NUMBER_CHANGE_EVENT, routingNumber)
  }

  // Subscribe to Selected Merchant Account
  useSub<typeof TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT>(
    TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT.type,
    ({ data: merchantAccount }: PubSubEvent<MerchantAccount | null>) => {
      setSelectedMerchantAccount(merchantAccount)
      setIsACH(merchantAccount?.payment_method === 'ach')
    }
  )

  useEffect(() => {
    if (!accountTypeField || !isACH) return
    const secCodes = getSECCodes(accountTypeField, selectedMerchantAccount)
    setAchSecCodes(secCodes)
    setValue('ach_sec_code', secCodes[0]?.value)
  }, [accountTypeField, selectedMerchantAccount, isACH])

  const businessPersonalAccountButtons: RadioButtonsArray = useMemo(() => {
    if (!selectedMerchantAccount) return []

    const personalCodes = getSECCodes('personal', selectedMerchantAccount)
    const businessCodes = getSECCodes('business', selectedMerchantAccount)

    const buttons: RadioButtonsArray = [
      {
        testId: 'personal-account-type-field-button',
        defaultSelected: accountTypeField === 'personal',
        title: t('common.personal'),
        color: 'secondary',
        onClick: () => setAccountTypeField('personal'),
        style: {
          width: '50%',
        },
        guidingId: `${guidingId}-ach-accounttypefield-personal`,
        visible: personalCodes.length > 0,
      },
      {
        testId: 'business-account-type-field-button',
        defaultSelected: accountTypeField === 'business',
        title: t('common.business'),
        color: 'secondary',
        onClick: () => setAccountTypeField('business'),
        style: {
          width: '50%',
        },
        guidingId: `${guidingId}-ach-accounttypefield-business`,
        visible: businessCodes.length > 0,
      },
    ]

    if (personalCodes.length === 0 && businessCodes.length > 0) {
      setAccountTypeField('business')
    }

    return buttons
  }, [selectedMerchantAccount, accountTypeField])

  if (!isACH) return null

  return (
    <Box>
      <Box className={classes.inputContainer}>
        <Controller
          name="account_holder_name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              testId="account-holder-name-input"
              guidingId={`${guidingId}-ach-accountholdername`}
              onChange={(event) => {
                field.onChange(event)
              }}
              label={t('common.account-holder-name')}
              placeholder={t('common.account-holder-name-placeholder')}
              error={!!errors.account_holder_name}
              helperText={errors.account_holder_name?.message as string}
              required
            />
          )}
        />
      </Box>

      <Box className={classes.inputContainer}>
        <Controller
          name="ach_sec_code"
          control={control}
          render={({ field }) => (
            <Box>
              <InputLabel
                test-id="select-account-label"
                variant="standard"
                htmlFor="uncontrolled-native"
                required
                className={cx(inputClasses.label, classes.label)}
                sx={{
                  '.MuiInputLabel-asterisk': {
                    color: '#ff0000',
                  },
                }}
              >
                {t('common.transaction-sec-code')}
              </InputLabel>
              <Select
                {...field}
                value={achSecCodes.length > 0 ? field.value : ''}
                testId="ach-sec-code-selector"
                options={achSecCodes}
                onChange={(event) => {
                  setValue('ach_sec_code', event.target.value)

                  publish(
                    ACH_ACCOUNT_INFO_ACH_SEC_CODE_CHANGE_EVENT,
                    event.target.value
                  )
                }}
                style={{
                  width: '100%',
                  maxWidth: 'unset',
                  border: 'unset',
                  height: '44px',
                }}
                error={!!errors.ach_sec_code}
                helperText={errors.ach_sec_code?.message as string}
                guidingId={`${guidingId}-ach-seccode`}
              />
            </Box>
          )}
        />
      </Box>

      <Box className={classes.multipleInputContainer}>
        <Box width="49%">
          <Typography className={cx(inputClasses.label, classes.label)}>
            {t('common.account-type')}
          </Typography>

          <RadioButtons
            buttons={[
              {
                testId: 'checking-account-type-button',
                defaultSelected: accountType === 'checking',
                title: t('common.checking'),
                color: 'secondary',
                onClick: () => {
                  const value = 'checking'
                  setValue('account_type', value)
                  publish(ACH_ACCOUNT_INFO_ACCOUNT_TYPE_CHANGE_EVENT, value)
                },
                style: {
                  width: '50%',
                },
                guidingId: `${guidingId}-ach-accounttype-checking`,
              },
              {
                testId: 'saving-account-type-button',
                defaultSelected: accountType === 'savings',
                title: t('common.savings'),
                color: 'secondary',
                onClick: () => {
                  const value = 'savings'
                  setValue('account_type', value)
                  publish(ACH_ACCOUNT_INFO_ACCOUNT_TYPE_CHANGE_EVENT, value)
                },
                style: {
                  width: '50%',
                },
                guidingId: `${guidingId}-ach-accounttype-savings`,
              },
            ]}
          />

          {!!errors.account_type && (
            <Typography
              className={cx(
                inputClasses.helperText,
                inputClasses.helperTextError
              )}
              variant="body2"
            >
              {errors.account_type.message}
            </Typography>
          )}
        </Box>

        <Box width="49%">
          <Typography className={cx(inputClasses.label, classes.label)}>
            {t('common.business-personal-account')}
          </Typography>

          <RadioButtons buttons={businessPersonalAccountButtons} />
        </Box>
      </Box>

      <Box display="flex" gap="8px">
        <Box width={'49%'}>
          <Controller
            name="account_number"
            control={control}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  testId="account-number-input"
                  className={inputClasses.input}
                  onChange={(event) => {
                    field.onChange(event)
                  }}
                  label={t('common.account-number')}
                  error={!!errors.account_number}
                  helperText={errors.account_number?.message as string}
                  required
                  type="number"
                  guidingId={`${guidingId}-ach-accountnumber`}
                />
              </>
            )}
          />
        </Box>

        <Box width={'49%'}>
          {!!routingNumbersService ? (
            // TODO: move routing number typeahead to a new component?
            <Typeahead<RoutingNumber>
              id="routing-number-typeahead"
              testId="routing-number-typeahead"
              label={t('common.routing-number')}
              matchItemAndValueProperty={'routing_number'}
              value={selectedRoutingNumber}
              getItems={routingNumbersService ?? undefined}
              getItemLabel={getRoutingNumberLabel}
              onChangeValue={onChangeRoutingNumber}
              noOptionsText={t('common.routing-number-search')}
              error={!!errors.routing_number}
              helperText={errors.routing_number?.message as string}
              required
              guidingId={`${guidingId}-ach-routingnumber`}
            />
          ) : (
            <Controller
              name="routing_number"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  testId="routing-number-input"
                  label={t('common.routing-number')}
                  placeholder={t('common.routing-number-placeholder')}
                  onChange={(event) => {
                    field.onChange(event)
                    setValue('routing_number', event.target.value)

                    publish(
                      ACH_ACCOUNT_INFO_ROUTING_NUMBER_CHANGE_EVENT,
                      event.target.value
                    )
                  }}
                  error={!!errors.routing_number}
                  helperText={errors.routing_number?.message as string}
                  required
                  guidingId={`${guidingId}-ach-routingnumber`}
                />
              )}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

import { MerchantAccount } from '../../../api'
import { PaymentMethodType, AccountType } from '../../../types'

export enum SECCode {
  'PPD' = 'PPD',
  'CCD' = 'CCD',
}

export const getDefaultSECCode = (
  merchantAccount: MerchantAccount | null,
  accountType: AccountType | null
): SECCode | undefined => {
  if (
    merchantAccount?.payment_method === PaymentMethodType.CC ||
    merchantAccount?.payment_method === PaymentMethodType.CASH ||
    !accountType
  )
    return undefined

  return accountType === 'personal' ? SECCode.PPD : SECCode.CCD
}

import SvgIcon from '@mui/material/SvgIcon'

export const Search = (props) => (
  <SvgIcon {...props}>
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon-search">
        <circle
          id="Oval"
          cx="14.7448"
          cy="15.3056"
          r="9.41592"
          stroke="#374151"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path"
          d="M10.972 11.8489C11.9726 10.8483 13.3297 10.2861 14.7448 10.2861C16.1599 10.2861 17.517 10.8483 18.5176 11.8489"
          stroke="#374151"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_2"
          d="M26.6711 27.2318L21.4023 21.9629"
          stroke="#374151"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  </SvgIcon>
)

import {
  EbtTransactionType,
  MerchantAccount,
  TransactionType,
  User,
} from '../../../api'
import { PaymentMethod, PaymentMethodEbt } from '../../../types'
import { getTransactionTypesForAccount } from '../get-transaction-types-for-account/getTransactionTypesForAccount'

const defaultCcTransactionType: TransactionType = 'sale'
const defaultAchTransactionType: TransactionType = 'debit'
const defaultCashTransactionType: TransactionType = 'cash.sale'

export const getDefaultTransactionType = (
  user: User,
  merchantAccount?: MerchantAccount | null
): TransactionType | EbtTransactionType => {
  if (!merchantAccount) return defaultCcTransactionType

  const allowedTransactions = getTransactionTypesForAccount(
    merchantAccount,
    user
  ).map((t) => t.value)

  let defaultTransactionType =
    merchantAccount.default_transaction_type as TransactionType

  if (!defaultTransactionType) {
    if (merchantAccount.payment_method === 'cc') {
      defaultTransactionType = defaultCcTransactionType
    }

    if (merchantAccount.payment_method === 'ach') {
      defaultTransactionType = defaultAchTransactionType
    }

    if (merchantAccount.payment_method === 'cash') {
      defaultTransactionType = defaultCashTransactionType
    }
  }

  //If the default is allowed then return it.  If nothing is allowed, fall back on the default.
  if (
    allowedTransactions.length === 0 ||
    allowedTransactions.includes(defaultTransactionType)
  ) {
    return defaultTransactionType
  }

  //If none of the defaults work, return the first allowed action.
  return allowedTransactions[0]
}

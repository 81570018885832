import { useTranslation } from 'react-i18next'

import { Status } from '@shared/components'

interface ActiveStatusProps {
  active: boolean
  customText?: {
    active: string
    inactive: string
  }
}

const ActiveStatus = ({ active, customText }: ActiveStatusProps) => {
  const { t } = useTranslation()
  const text = active
    ? customText?.active ?? t('common.active')
    : customText?.inactive ?? t('common.inactive')

  return <Status text={text} backgroundColor={active ? 'teal' : 'red'} />
}

export default ActiveStatus

import { Location, MerchantAccount, User } from '../../../api'
import { getValidTerminals } from '../get-valid-terminals/getValidTerminals'

export const getDefaultTerminalId = (
  location: Location,
  merchantAccount: MerchantAccount | null | undefined,
  user: User
): string | null | undefined => {
  const validTerminals = getValidTerminals(location, merchantAccount, user)

  if (validTerminals.length === 0) {
    return undefined
  }

  const preferredTerminal = user.ui_prefs?.default_terminal

  if (validTerminals.some((terminal) => terminal.id === preferredTerminal)) {
    return preferredTerminal
  }

  return validTerminals[0]?.id
}

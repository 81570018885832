import { useTranslation } from 'react-i18next'

import { Recurring } from '@shared/api/src'

export function useFormatIntervals(
  interval: number,
  type: Recurring['interval_type']
) {
  const { t } = useTranslation()

  const unit: string = {
    d: t('Day'),
    w: t('Week'),
    m: t('Month'),
  }[type]

  return `${interval} ${interval > 1 ? unit + 's' : unit}`
}

/**
 *
 * Transform an API field name to uppercase.
 * @param fieldPropertyName The API Field name.
 * @returns The field name formatted to uppercase. e.g. 'transaction_amount' = Transaction Amount
 */
export const apiFieldNameToUpperCase = (fieldName: string) =>
  fieldName
    .split('_')
    .map((property) => `${property[0].toUpperCase()}${property.slice(1)}`)
    .join(' ')

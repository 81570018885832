import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { api, QuickInvoice } from '@shared/api'
import { ActionModal } from '@shared/components'
import { useNotification } from '@shared/hooks'

interface ReopenQuickInvoiceProps {
  open: boolean
  quickInvoice: QuickInvoice
  onSuccess: () => void
  onClose: () => void
}

export const ReopenQuickInvoice: FC<ReopenQuickInvoiceProps> = ({
  open,
  quickInvoice,
  onSuccess,
  onClose,
}) => {
  const { t } = useTranslation()
  const { setNotification } = useNotification()

  const [isLoading, setIsLoading] = useState(false)

  const onReopen = async () => {
    try {
      setIsLoading(true)
      await api.service('quick-invoices').reopen(quickInvoice.id)
      setNotification({
        label: t('mfe-gateway.quick-invoice.invoice-reopened-successfully'),
        type: 'success',
      })
      onSuccess()
    } catch (error) {
      setNotification({
        label: error.message,
        type: 'error',
      })
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <ActionModal
      open={open}
      title={t('mfe-gateway.quick-invoice.reopen-invoice')}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: onClose,
          color: 'secondary',
          guidingId: 'quickinvoice-reopenquickinvoice-cancel',
        },
        {
          label: t('common.reopen'),
          onClick: onReopen,
          color: 'primary',
          isLoading: isLoading,
          guidingId: 'quickinvoice-reopenquickinvoice-reopen',
        },
      ]}
      onClose={onClose}
      guidingId="quickinvoice-reopenquickinvoice"
    >
      <Typography sx={{ fontSize: '14px' }} variant="body2">
        {`${t('common.modal.are-you-sure')} `}
        <strong>{t('mfe-gateway.quick-invoice.reopen-this-invoice')}?</strong>
      </Typography>
    </ActionModal>
  )
}

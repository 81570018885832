import React from 'react'

import { Grid } from '@mui/material'

export interface PageLayoutContainerMainProps {
  testID?: string
  isFullWidth?: boolean
}

export const PageLayoutContainerMain: React.FC<
  PageLayoutContainerMainProps
> = ({
  children,
  testID = 'page-layout-container-main',
  isFullWidth = false,
}) => {
  return (
    <Grid
      item
      sm={12}
      lg={isFullWidth ? 12 : 8}
      xl={isFullWidth ? 12 : 9}
      data-testid={testID}
    >
      {children}
    </Grid>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { formatDatetime } from '@shared/utils'

interface OtherInfoProps {
  createdDate: string | number | Date
  createdBy: string
  timezone?: string
  section?: string
}

const OtherInfo: FC<OtherInfoProps> = ({
  createdDate,
  createdBy,
  timezone,
  section,
}) => {
  const { t } = useTranslation()

  return (
    <FieldGroupContainer title={t('common.other-info')}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.date-created')}
          value={formatDatetime(createdDate, timezone)}
          valueTestID={`${section}-created-date-value`}
        />

        <FieldGroupViewItem
          title={t('common.created-by')}
          value={createdBy}
          valueTestID={`${section}-created-by-value`}
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}

export default OtherInfo

import { omit } from 'lodash'
import { DateTime } from 'luxon'
import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { QuickInvoiceSetting } from '@shared/api'
import {
  Checkbox,
  CurrencyInput,
  FieldGroupContainer,
  FieldGroupRow,
  FileUpload,
  Input,
  PageLayoutDivider,
  SelectComponent,
} from '@shared/components'
import { useAuthorization, useLocations } from '@shared/hooks'
import { checkPermission } from '@shared/utils'

import { QuickInvoiceFormData } from '../../quick-invoice-add/QuickInvoiceAdd'

interface OptionalSettingsProps {
  isEditable?: boolean
  defaultSettings?: QuickInvoiceSetting
}

export const OptionalSettings: FC<OptionalSettingsProps> = ({
  isEditable = true,
  defaultSettings,
}) => {
  const { t } = useTranslation()
  const { userPermissionSet } = useAuthorization()
  const methods = useFormContext<QuickInvoiceFormData>()
  const { selectedLocation, hasFileService } = useLocations()
  const hasFiles = methods.watch('files')?.length > 0
  const guidingId = 'quickinvoice-optionalsettings'

  const [notificationBeforeDueDate, setNotificationBeforeDueDate] = useState(
    defaultSettings?.default_notification_days_before_due_date > 0 ||
      methods.getValues('notification_days_before_due_date') > 0
  )
  const [notificationAfterDueDate, setNotificationAfterDueDate] = useState(
    defaultSettings?.default_notification_days_after_due_date > 0 ||
      methods.getValues('notification_days_after_due_date') > 0
  )

  const [isDueDateTodayOrPast, setIsDueDateTodayOrPast] = useState(false)
  const [isDueDateTomorrow, setIsDueDateTomorrow] = useState(false)

  const dueDate = methods.watch('due_date')

  useEffect(() => {
    if (!dueDate) {
      setIsDueDateTodayOrPast(false)
      setIsDueDateTomorrow(false)
      return
    }

    const expectedFormat = 'MM/dd/yyyy'
    const parsedDueDate = DateTime.fromFormat(dueDate, expectedFormat).startOf(
      'day'
    )
    if (methods?.formState?.isSubmitting || dueDate.includes('_')) {
      return
    }
    if (!parsedDueDate.isValid) {
      setIsDueDateTodayOrPast(true)
      setIsDueDateTomorrow(false)
      methods.setValue('notification_on_due_date', false)
      methods.setValue('notification_days_before_due_date', 0)
      return
    }
    const now = DateTime.local().startOf('day')
    const isTodayOrPast = parsedDueDate <= now
    const isTomorrow = parsedDueDate.equals(now.plus({ days: 1 }))

    setIsDueDateTodayOrPast(isTodayOrPast)
    setIsDueDateTomorrow(isTomorrow)

    if (isTodayOrPast) {
      methods.setValue('notification_on_due_date', false)
      methods.setValue('notification_days_before_due_date', 0)
      setNotificationBeforeDueDate(false)
    } else if (isTomorrow) {
      methods.setValue('notification_days_before_due_date', 0)
      setNotificationBeforeDueDate(false)
    } else {
      const defaultOnDueDate =
        defaultSettings?.default_notification_on_due_date ?? false
      const defaultDaysBefore =
        defaultSettings?.default_notification_days_before_due_date ?? 0

      methods.setValue('notification_on_due_date', defaultOnDueDate)
      if (methods.getValues('notification_days_before_due_date') === 0) {
        methods.setValue('notification_days_before_due_date', defaultDaysBefore)
      }
      setNotificationBeforeDueDate(defaultDaysBefore > 0)
    }
  }, [dueDate, defaultSettings, methods])

  const allowOverpayment = methods.watch('allow_overpayment')
  const allowPartialPayment = methods.watch('allow_partial_pay')

  const tagsOptions = useMemo(() => {
    if (!selectedLocation?.tags) return []
    return selectedLocation.tags.map((tag) => ({
      label: tag.title,
      value: tag.title,
    }))
  }, [selectedLocation])

  const handleFileUpload = (
    files: { file_name: string; file?: File; content?: string }[]
  ) => {
    methods.setValue('files', files)
  }

  const showFileOption =
    hasFileService &&
    selectedLocation?.product_file &&
    selectedLocation.product_file.active &&
    (checkPermission(userPermissionSet, 'v2.quickinvoices.post.files') ||
      checkPermission(userPermissionSet, 'v2.quickinvoices.delete.files') ||
      checkPermission(userPermissionSet, 'v2.quickinvoices.get.files'))

  return (
    <FormProvider {...methods}>
      <FieldGroupContainer
        title={t('mfe-gateway.quick-invoice.optional-settings')}
        showContent
        showToggle
        titleTestID="quick-invoice-optional-settings"
        guidingId={guidingId}
      >
        <FieldGroupRow>
          <Grid item xs={12}>
            <Controller
              name="notification_on_due_date"
              control={methods.control}
              render={({ field }) => (
                <Checkbox
                  {...omit(field, 'ref')}
                  checked={!!field.value}
                  disabled={!isEditable || isDueDateTodayOrPast}
                  label={t('mfe-gateway.quick-invoice.reminder-on-due-date')}
                  description={
                    isDueDateTodayOrPast
                      ? t(
                          'mfe-gateway.quick-invoice.setting-unavailable-due-date'
                        )
                      : t(
                          'mfe-gateway.quick-invoice.reminder-on-due-date-description'
                        )
                  }
                  guidingId={`${guidingId}-notificationonduedate`}
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow
          columnSpacing="12px"
          alignItems="center"
          marginBottom="20px"
        >
          <Grid item xs={12} sm={8}>
            <Controller
              name="notification_days_before_due_date"
              control={methods.control}
              render={({ field }) => (
                <Checkbox
                  {...omit(field, 'ref')}
                  checked={!!field.value}
                  label={t(
                    'mfe-gateway.quick-invoice.reminder-before-due-date'
                  )}
                  description={
                    isDueDateTodayOrPast || isDueDateTomorrow
                      ? t(
                          'mfe-gateway.quick-invoice.setting-unavailable-due-date'
                        )
                      : t(
                          'mfe-gateway.quick-invoice.reminder-before-due-date-description'
                        )
                  }
                  disabled={
                    !isEditable || isDueDateTodayOrPast || isDueDateTomorrow
                  }
                  guidingId={`${guidingId}-notificationdaysbeforeduedate`}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Controller
              name="notification_days_before_due_date"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  testId="notification_days-input"
                  label={t(
                    'mfe-gateway.quick-invoice.reminder-before-due-date'
                  )}
                  type="number"
                  inputProps={{ min: 0, max: 365 }}
                  endAdornmentText={t('common.days')}
                  placeholder="0"
                  error={
                    !!methods.formState.errors.notification_days_before_due_date
                  }
                  helperText={methods.formState.errors.notification_days_before_due_date?.message?.toString()}
                  disabled={
                    !isEditable || isDueDateTodayOrPast || isDueDateTomorrow
                  }
                  onChange={(event) => {
                    let val = event?.target?.value?.replace(/\D/g, '')
                    field.onChange(val)
                  }}
                  guidingId={`${guidingId}-notificationday`}
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow
          columnSpacing="12px"
          alignItems="center"
          marginBottom="20px"
        >
          <Grid item xs={12} sm={8}>
            <Checkbox
              checked={notificationAfterDueDate}
              onChange={() =>
                setNotificationAfterDueDate((prev) => {
                  if (prev) {
                    methods.setValue('notification_days_after_due_date', 0)
                    methods.clearErrors('notification_days_after_due_date')
                  }
                  return !prev
                })
              }
              label={t('mfe-gateway.quick-invoice.reminder-after-due-date')}
              description={t(
                'mfe-gateway.quick-invoice.reminder-after-due-date-description'
              )}
              disabled={!isEditable}
              guidingId={`${guidingId}-notificationdaysafterduedate`}
            />
          </Grid>
          {notificationAfterDueDate && (
            <Grid item xs={12} sm={4}>
              <Controller
                name="notification_days_after_due_date"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t(
                      'mfe-gateway.quick-invoice.reminder-after-due-date'
                    )}
                    type="number"
                    inputProps={{ min: 0, max: 365 }}
                    endAdornmentText={t('common.days')}
                    placeholder="0"
                    error={
                      !!methods.formState.errors
                        .notification_days_after_due_date
                    }
                    helperText={methods.formState.errors.notification_days_after_due_date?.message?.toString()}
                    disabled={!isEditable}
                    onChange={(event) => {
                      let val = event?.target?.value?.replace(/\D/g, '')
                      field.onChange(val)
                    }}
                    guidingId={`${guidingId}-notificationafterduedate`}
                  />
                )}
              />
            </Grid>
          )}
        </FieldGroupRow>

        <FieldGroupRow
          columnSpacing="12px"
          alignItems="center"
          marginBottom="20px"
        >
          <Grid item xs={12} sm={8}>
            <Controller
              name="allow_partial_pay"
              control={methods.control}
              render={({ field }) => (
                <Checkbox
                  {...omit(field, 'ref')}
                  checked={!!field.value}
                  label={t('mfe-gateway.quick-invoice.allow-partial-payment')}
                  description={t(
                    'mfe-gateway.quick-invoice.allow-partial-payment-description'
                  )}
                  disabled={!isEditable}
                  onChange={(e) => {
                    field.onChange(e)
                    if (!field.value) {
                      methods.setValue('single_payment_min_amount', undefined)
                      methods.clearErrors('single_payment_min_amount')
                    }
                  }}
                  guidingId={`${guidingId}-allowpartialpayment`}
                />
              )}
            />
          </Grid>

          {allowPartialPayment && (
            <Grid item xs={12} sm={4}>
              <Controller
                control={methods.control}
                name="single_payment_min_amount"
                render={({ field }) => (
                  <CurrencyInput
                    {...field}
                    label={t('mfe-gateway.quick-invoice.min-amount')}
                    currency="USD"
                    showDollarSign
                    allowDecimal
                    error={!!methods.formState.errors.single_payment_min_amount}
                    helperText={methods.formState.errors.single_payment_min_amount?.message?.toString()}
                    disabled={!isEditable}
                    guidingId={`${guidingId}-minamount`}
                  />
                )}
              />
            </Grid>
          )}
        </FieldGroupRow>

        <FieldGroupRow
          columnSpacing="12px"
          alignItems="center"
          marginBottom="20px"
        >
          <Grid item xs={12} sm={8}>
            <Controller
              name="allow_overpayment"
              control={methods.control}
              render={({ field }) => (
                <Checkbox
                  {...omit(field, 'ref')}
                  checked={!!field.value}
                  label={t('mfe-gateway.quick-invoice.allow-overpayment')}
                  description={t(
                    'mfe-gateway.quick-invoice.allow-over-payment-description'
                  )}
                  disabled={!isEditable}
                  onChange={(e) => {
                    field.onChange(e)
                    if (!field.value) {
                      methods.setValue('single_payment_max_amount', undefined)
                      methods.clearErrors('single_payment_max_amount')
                    }
                  }}
                  guidingId={`${guidingId}-allowoverpayment`}
                />
              )}
            />
          </Grid>
          {allowOverpayment && (
            <Grid item xs={12} sm={4}>
              <Controller
                control={methods.control}
                name="single_payment_max_amount"
                render={({ field }) => (
                  <CurrencyInput
                    {...field}
                    label={t('mfe-gateway.quick-invoice.max-amount')}
                    currency="USD"
                    showDollarSign
                    allowDecimal
                    error={!!methods.formState.errors.single_payment_max_amount}
                    helperText={methods.formState.errors.single_payment_max_amount?.message?.toString()}
                    disabled={!isEditable}
                    guidingId={`${guidingId}-maxamount`}
                  />
                )}
              />
            </Grid>
          )}
        </FieldGroupRow>

        <FieldGroupRow>
          <Grid item xs={12}>
            <Controller
              name="bank_funded_only_override"
              control={methods.control}
              render={({ field }) => (
                <SelectComponent
                  {...field}
                  label={t('mfe-gateway.quick-invoice.bank-funded')}
                  value={field.value}
                  options={[
                    {
                      label: t(
                        'mfe-gateway.quick-invoice.follow-deposit-account-setting'
                      ),
                      value: null,
                    },
                    { label: t('common.no'), value: '0' },
                    { label: t('common.yes'), value: '1' },
                  ]}
                  placeholder={t(
                    'mfe-gateway.quick-invoice.follow-deposit-account-setting'
                  )}
                  style={{
                    width: '100%',
                    maxWidth: 'unset',
                    border: 'unset',
                    height: '44px',
                  }}
                  error={!!methods.formState.errors.bank_funded_only_override}
                  helperText={methods.formState.errors.bank_funded_only_override?.message?.toString()}
                  disabled={!isEditable}
                  guidingId={`${guidingId}-bankfunded`}
                />
              )}
            />
          </Grid>
        </FieldGroupRow>
        {showFileOption ? (
          <>
            <FieldGroupRow>
              <Grid item xs={12}>
                <Controller
                  name="files"
                  control={methods.control}
                  render={({ field }) => (
                    <FileUpload
                      {...field}
                      maxFiles={4}
                      initialFiles={field.value?.map(
                        (file: { file_name: string }) => ({
                          file_name: file.file_name,
                        })
                      )}
                      onFileChange={(files) => {
                        handleFileUpload(files)
                      }}
                      disabled={!isEditable}
                      error={!!methods.formState.errors.files}
                      helperText={
                        methods.formState.errors.files?.message
                          ? methods.formState.errors.files.message.toString()
                          : ''
                      }
                      auxHelperText={t(
                        'mfe-gateway.quick-invoice.maximum-four-files-can-be-attached'
                      )}
                      showAddOption={checkPermission(
                        userPermissionSet,
                        'v2.quickinvoices.post.files'
                      )}
                      showDeleteOption={checkPermission(
                        userPermissionSet,
                        'v2.quickinvoices.delete.files'
                      )}
                      guidingId={`${guidingId}-uploadfile`}
                      successMessage={t(
                        'mfe-gateway.quick-invoice.file-added-quick-invoice-successfully'
                      )}
                    />
                  )}
                />
              </Grid>
            </FieldGroupRow>

            {hasFiles && (
              <FieldGroupRow>
                <Grid item xs={12}>
                  <Controller
                    name="attach_files_to_email"
                    control={methods.control}
                    render={({ field }) => (
                      <Checkbox
                        {...omit(field, 'ref')}
                        label={t(
                          'mfe-gateway.quick-invoice.attach-files-email'
                        )}
                        description={t(
                          'mfe-gateway.quick-invoice.attach-files-related-invoice-email-reminders'
                        )}
                        checked={!!field.value}
                        onChange={field.onChange}
                        style={{ marginLeft: '11px', marginTop: '12px' }}
                        guidingId={`${guidingId}-attachfilestoemail`}
                      />
                    )}
                  />
                </Grid>
              </FieldGroupRow>
            )}

            <PageLayoutDivider />
          </>
        ) : null}

        {tagsOptions.length > 0 && (
          <FieldGroupRow>
            <Grid item xs={12}>
              <Controller
                name="tags"
                control={methods.control}
                render={({ field }) => (
                  <SelectComponent
                    multiple
                    onChange={field.onChange}
                    value={field.value}
                    options={tagsOptions}
                    label={t('common.tags')}
                    placeholder={t('common.tags-select')}
                    style={{
                      width: '100%',
                      maxWidth: 'unset',
                      border: 'unset',
                      height: '44px',
                    }}
                    error={!!methods.formState.errors.tags}
                    helperText={methods.formState.errors.tags?.message?.toString()}
                    disabled={!isEditable}
                    guidingId={`${guidingId}-tags`}
                  />
                )}
              />
            </Grid>
          </FieldGroupRow>
        )}

        <FieldGroupRow>
          <Grid item xs={12}>
            <Controller
              name="note"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(event) => {
                    field.onChange(event)
                  }}
                  label={t('mfe-gateway.quick-invoice.notes')}
                  placeholder={t('mfe-gateway.quick-invoice.notes-placeholder')}
                  error={!!methods.formState.errors.note}
                  helperText={methods.formState.errors.note?.message?.toString()}
                  disabled={!isEditable}
                  guidingId={`${guidingId}-notes`}
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow>
          <Grid item xs={12}>
            <Controller
              name="quick_invoice_c1"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('common.custom-1')}
                  placeholder={t('mfe-gateway.custom-1-placeholder')}
                  error={!!methods.formState.errors.quick_invoice_c1}
                  helperText={methods.formState.errors.quick_invoice_c1?.message?.toString()}
                  disabled={!isEditable}
                  guidingId={`${guidingId}-customfield1`}
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow>
          <Grid item xs={12}>
            <Controller
              name="quick_invoice_c2"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('common.custom-2')}
                  placeholder={t('mfe-gateway.custom-2-placeholder')}
                  error={!!methods.formState.errors.quick_invoice_c2}
                  helperText={methods.formState.errors.quick_invoice_c2?.message?.toString()}
                  disabled={!isEditable}
                  guidingId={`${guidingId}-customfield2`}
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow>
          <Grid item xs={12}>
            <Controller
              name="quick_invoice_c3"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('common.custom-3')}
                  placeholder={t('mfe-gateway.custom-3-placeholder')}
                  error={!!methods.formState.errors.quick_invoice_c3}
                  helperText={methods.formState.errors.quick_invoice_c3?.message?.toString()}
                  disabled={!isEditable}
                  guidingId={`${guidingId}-customfield3`}
                />
              )}
            />
          </Grid>
        </FieldGroupRow>
      </FieldGroupContainer>
    </FormProvider>
  )
}

import { useTranslation } from 'react-i18next'

import { Recurring } from '@shared/api'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'

export type AdvancedSettingsProps = {
  recurringData: Recurring | null
}
function AdvancedSettings({ recurringData }: AdvancedSettingsProps) {
  const { t } = useTranslation()

  const value_recurring_api_id = recurringData?.token_api_id

  return (
    <FieldGroupContainer title={t('common.advanced-settings')}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.recurring-billing.recurring-api-id')}
          value={value_recurring_api_id}
          valueTestID="recurring-billing-api-id-value"
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}
export default AdvancedSettings

import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid, SelectChangeEvent } from '@mui/material'

import { Contact, MerchantAccount } from '@shared/api'
import {
  DatePicker,
  FieldGroupContainer,
  FieldGroupRow,
  Input,
  InputPhone,
  SelectComponent,
  SelectOption,
} from '@shared/components'
import { useSub } from '@shared/hooks'

import { CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT } from '@/components/customer-details/CustomerDetails'

import {
  QUICK_INVOICE_MERCHANT_ACCOUNT_ACH_CHANGE_EVENT,
  QUICK_INVOICE_MERCHANT_ACCOUNT_CC_CHANGE_EVENT,
} from '../merchant-selector/MerchantSelector'

type DeliveryMethod = 'email' | 'sms' | 'both' | 'none'

interface InvoiceDetailsProps {
  isEditable?: boolean
  ACHMerchantAccount: MerchantAccount | null
  CCMerchantAccount: MerchantAccount | null
}

export const InvoiceDetails: FC<InvoiceDetailsProps> = ({
  isEditable = true,
  ACHMerchantAccount,
  CCMerchantAccount,
}) => {
  const { t } = useTranslation()
  const methods = useFormContext()

  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>('none')
  const [customer, setCustomer] = useState<Contact | null>(null)

  const deliveryOptions = useMemo<SelectOption<DeliveryMethod>[]>(() => {
    const options: SelectOption<DeliveryMethod>[] = [
      { value: 'none', label: t('mfe-gateway.quick-invoice.none') },
      { value: 'email', label: t('common.email') },
    ]

    if (ACHMerchantAccount?.sms_enable || CCMerchantAccount?.sms_enable) {
      options.push({ value: 'sms', label: 'SMS' })
      options.push({
        value: 'both',
        label: t('mfe-gateway.quick-invoice.email-and-sms'),
      })
    }

    return options
  }, [ACHMerchantAccount, CCMerchantAccount])

  const onChangeDeliveryMethod = (event: SelectChangeEvent) => {
    const value = event.target.value as DeliveryMethod
    setDeliveryMethod(value)

    if (value === 'sms' || value === 'both') {
      methods.setValue('send_text_to_pay', true)
      methods.setValue(
        'cell_phone',
        methods.getValues('cell_phone') || customer?.cell_phone || null
      )
      if (value === 'sms') {
        methods.setValue('send_email', false)
        methods.setValue('email', null)
      }
    }

    if (value === 'email' || value === 'both') {
      methods.setValue('send_email', true)
      methods.setValue(
        'email',
        methods.getValues('email') || customer?.email || null
      )
      if (value === 'email') {
        methods.setValue('send_text_to_pay', false)
        methods.setValue('cell_phone', null)
      }
    }

    if (value === 'none') {
      methods.setValue('send_text_to_pay', false)
      methods.setValue('send_email', false)
      methods.setValue('email', null)
      methods.setValue('cell_phone', null)
    }
  }

  useSub<typeof CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT>(
    CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT.type,
    ({ data: customer }) => {
      setCustomer(customer)
      if (!customer) return

      if (deliveryMethod === 'email' || deliveryMethod === 'both') {
        methods.setValue('email', customer.email)
      }
      if (deliveryMethod === 'sms' || deliveryMethod === 'both') {
        methods.setValue('cell_phone', customer.cell_phone)
      }
    },
    [deliveryMethod]
  )

  useEffect(() => {
    if (deliveryOptions.length === 0) return

    const email = methods.getValues('email')
    const cell_phone = methods.getValues('cell_phone')

    if (
      email &&
      cell_phone &&
      deliveryOptions.some((option) => option.value === 'both')
    ) {
      setDeliveryMethod('both')
      return
    }

    if (email && deliveryOptions.some((option) => option.value === 'email')) {
      setDeliveryMethod('email')
      return
    }

    if (
      cell_phone &&
      deliveryOptions.some((option) => option.value === 'sms')
    ) {
      setDeliveryMethod('sms')
      return
    }
  }, [deliveryOptions])

  return (
    <FormProvider {...methods}>
      <FieldGroupContainer
        title={t('mfe-gateway.quick-invoice.invoice-details')}
        titleTestID="quick-invoice-invoice-details"
      >
        <FieldGroupRow columnSpacing="12px">
          <Grid item xs={6}>
            <Controller
              name="title"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(event) => {
                    field.onChange(event)
                  }}
                  label={t('common.title')}
                  required
                  placeholder={t('mfe-gateway.quick-invoice.title-placeholder')}
                  error={!!methods.formState.errors.title}
                  helperText={methods.formState.errors.title?.message.toString()}
                  style={{
                    width: '100%',
                  }}
                  disabled={!isEditable}
                  testId="invoice-title"
                  guidingId="quickinvoice-invoicedetails-title"
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="invoice_number"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(event) => {
                    field.onChange(event)
                  }}
                  label={t('common.invoice-number')}
                  placeholder={t('common.invoice-number')}
                  error={!!methods.formState.errors.invoice_number}
                  helperText={methods.formState.errors.invoice_number?.message.toString()}
                  style={{
                    width: '100%',
                  }}
                  disabled={!isEditable}
                  guidingId="quickinvoice-invoicedetails-invoicenumber"
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow columnSpacing="12px">
          <Grid item xs={6}>
            <Controller
              name="due_date"
              control={methods.control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label={t('common.date-due')}
                  style={{
                    width: '100%',
                  }}
                  required
                  error={!!methods.formState.errors.due_date}
                  helperText={methods.formState.errors.due_date?.message.toString()}
                  disabled={!isEditable}
                  guidingId="quickinvoice-invoicedetails-duedate"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="expire_date"
              control={methods.control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label={t('common.date-expiration')}
                  style={{
                    width: '100%',
                  }}
                  error={!!methods.formState.errors.expire_date}
                  helperText={methods.formState.errors.expire_date?.message.toString()}
                  disablePast
                  disabled={!isEditable}
                  guidingId="quickinvoice-invoicedetails-expiredate"
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow>
          <Grid item xs={12}>
            <SelectComponent
              label={t('mfe-gateway.quick-invoice.delivery-method')}
              value={deliveryMethod}
              onChange={onChangeDeliveryMethod}
              options={deliveryOptions}
              style={{
                width: '100%',
                maxWidth: 'unset',
                border: 'unset',
                height: '44px',
              }}
              disabled={!isEditable}
              guidingId="quickinvoice-invoicedetails-deliverymethod"
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow columnSpacing="12px">
          {(deliveryMethod === 'both' || deliveryMethod === 'email') && (
            <Grid item xs={6}>
              <Controller
                name="email"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={(event) => {
                      field.onChange(event)
                    }}
                    label={t('common.email')}
                    placeholder={t('common.email-placeholder')}
                    error={!!methods.formState.errors.email}
                    helperText={methods.formState.errors.email?.message.toString()}
                    style={{
                      width: '100%',
                    }}
                    disabled={!isEditable}
                    guidingId="quickinvoice-invoicedetails-email"
                  />
                )}
              />
            </Grid>
          )}

          {(deliveryMethod === 'both' || deliveryMethod === 'sms') && (
            <Grid item xs={6}>
              <Controller
                name="cell_phone"
                control={methods.control}
                render={({ field }) => (
                  <InputPhone
                    {...field}
                    onChange={(event) => {
                      field.onChange(event)
                    }}
                    label={t('common.phone-number')}
                    error={!!methods.formState.errors.cell_phone}
                    helperText={methods.formState.errors.cell_phone?.message.toString()}
                    style={{
                      width: '100%',
                    }}
                    disabled={!isEditable}
                    guidingId="quickinvoice-invoicedetails-cellphone"
                  />
                )}
              />
            </Grid>
          )}
        </FieldGroupRow>
      </FieldGroupContainer>
    </FormProvider>
  )
}

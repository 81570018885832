import { useTranslation } from 'react-i18next'

import { Recurring, Tags } from '@shared/api'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItemWithChildren,
  FieldGroupViewItem,
  TagList,
} from '@shared/components'

export type OptionalInformationProps = {
  recurringData: Recurring | null
}
function OptionalInformation({ recurringData }: OptionalInformationProps) {
  const { t } = useTranslation()

  const value_description = recurringData?.description

  return (
    <FieldGroupContainer title={t('common.optional-information')}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.description')}
          value={value_description}
          valueTestID="recurring-billing-description-value"
        />
        <FieldGroupViewItemWithChildren title={t('common.tags')}>
          <TagList
            items={(recurringData.tags as Tags[]) ?? []}
            nameSelector={(tag: Tags) => tag.title}
            wrapWords={false}
          />
        </FieldGroupViewItemWithChildren>
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}
export default OptionalInformation

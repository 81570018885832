import {
  EbtTransactionType,
  Location,
  MerchantAccount,
  TransactionType,
  User,
} from '../../../api'
import { ProcessMethod } from '../../../types'
import { getProcessMethodOptions } from '../get-process-method-options/getProcessMethodOptions'

type GetDefaultProcessMethodData = {
  user: User
  location: Location
  merchantAccount: MerchantAccount | undefined
  transactionType: TransactionType | EbtTransactionType
}

export const getDefaultProcessMethod = ({
  location,
  merchantAccount,
  user,
  transactionType,
}: GetDefaultProcessMethodData): ProcessMethod => {
  if (!user) {
    return 'manual'
  }
  const availableOptions = getProcessMethodOptions({
    location,
    merchantAccount,
    user,
    transactionType,
  })

  if (
    user.ui_prefs?.process_method === 'physical_terminal' &&
    availableOptions.includes('terminal')
  ) {
    return 'terminal'
  }

  return 'manual'
}

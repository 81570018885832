import { useTranslation } from 'react-i18next'

import { Token } from '@shared/api'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { secCodeToAccountType } from '@shared/types'

interface ACHDetailsViewProps {
  wallet: Token
  hideEditableFields?: boolean
}

const ACHDetailsView = ({
  wallet,
  hideEditableFields = false,
}: ACHDetailsViewProps) => {
  const { t } = useTranslation()

  const valueTitle = wallet?.title
  const valueSeccode = wallet?.ach_sec_code
  const valueAccountholdername = wallet?.account_holder_name
  const valueAccounttype = wallet?.account_type
  const valueBusinesspersonal = secCodeToAccountType(wallet.ach_sec_code)
  const valueRouting = wallet?.routing_number
  const valueLastfour = wallet?.last_four

  return (
    <>
      <FieldGroupContainer
        title={
          hideEditableFields ? null : t('mfe-gateway.token-ach.account-details')
        }
      >
        {!hideEditableFields && (
          <FieldGroupRow>
            <FieldGroupViewItem title={t('common.title')} value={valueTitle} />
            <FieldGroupViewItem
              title={t('common.account-holder-name')}
              value={valueAccountholdername}
            />
          </FieldGroupRow>
        )}
        <FieldGroupRow>
          <FieldGroupViewItem
            title={t('common.transaction-sec-code')}
            value={valueSeccode}
          />
        </FieldGroupRow>
        <FieldGroupRow>
          <FieldGroupViewItem
            title={t('common.account-option')}
            value={valueAccounttype}
          />
          <FieldGroupViewItem
            title={t('common.account-type')}
            value={valueBusinesspersonal}
          />
        </FieldGroupRow>
        <FieldGroupRow>
          <FieldGroupViewItem
            title={t('common.account-last-four')}
            value={valueLastfour}
          />
          <FieldGroupViewItem
            title={t('common.routing-number')}
            value={valueRouting}
          />
        </FieldGroupRow>
      </FieldGroupContainer>
    </>
  )
}

export default ACHDetailsView

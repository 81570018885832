import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'
import * as yup from 'yup'

import { AppBar } from '@mui/material'

import { Contact, api } from '@shared/api/src'
import {
  ButtonBarEnd,
  ButtonBar,
  Button,
  Notification,
  Loading,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutDivider,
} from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useLocations,
  useNotification,
} from '@shared/hooks'
import {
  formatDate,
  formatRawDate,
  processPhone,
  toArrayFieldErrors,
} from '@shared/utils'

import { BillingInformation } from '@/components/billing-information/BillingInformation'

import {
  AdvancedSettings,
  CustomerDetails,
  ContactInformation,
} from '../components'

interface FormData {
  location_id: string
  address: {
    country: 'US' | 'CA'
    street: string
    city: string
    state: string
    postal_code: string
  }
  first_name: string
  last_name: string
  email: string
  home_phone: string
  cell_phone: string
  account_number: string
  date_of_birth: string
  contact_api_id: string
}

const buildSchema = (t: (key: string) => string) =>
  yup.object().shape({
    location_id: yup.string(),
    first_name: yup.string(),
    last_name: yup
      .string()
      .required(t('mfe-gateway.validations.customer.last-name-required')),
    email: yup
      .string()
      .email(t('common.validations.invalid-email'))
      .test('valid-email', 'common.validations.invalid-email', (value) => {
        if (!value) return true
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
      }),
    address: yup.object().shape({
      state: yup.string(),
      postal_code: yup.string(),
      country: yup.string(),
      street: yup.string(),
      city: yup.string(),
    }),
    home_phone: yup.string(),
    cell_phone: yup.string(),
    account_number: yup.string(),
    date_of_birth: yup
      .string()
      .test(
        'valid-date',
        t('mfe-gateway.validations.customer.invalid-date-format'),
        (value) => {
          if (!value) return true
          return /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(
            value
          )
        }
      ),
    contact_api_id: yup.string(),
  })

export default function CustomerEdit() {
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { selectedLocation } = useLocations()
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { id } = useParams()

  const [isLoadingCustomer, setIsLoadingCustomer] = useState<boolean>(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)

  const [customer, setCustomer] = useState<Contact>()

  const returnToUrl = state?.backUrl || '/merchant/gateway/customer'

  const schema = buildSchema(t)

  const methods = useForm({
    defaultValues: {
      location_id: selectedLocation?.id,
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.customer.edit-customer'),
      null,
      [
        t('common.customers'),
        `${customer?.first_name || ''} ${customer?.last_name || ''}`,
      ],
      returnToUrl
    )
  }, [customer?.first_name, customer?.last_name])

  useEffect(() => {
    //Check for a location ID to ensure the tokens are loaded
    if (!selectedLocation.id || customer) {
      return
    }

    getCustomer()
  }, [selectedLocation.id])

  const getCustomer = async () => {
    try {
      setIsLoadingCustomer(true)

      await api.service('contacts').get(id).then(setCustomer)

      setIsLoadingCustomer(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!customer) {
      return
    }

    methods.setValue('first_name', customer.first_name || undefined)
    methods.setValue('last_name', customer.last_name || undefined)
    methods.setValue('account_number', customer.account_number || undefined)
    methods.setValue(
      'date_of_birth',
      customer?.date_of_birth
        ? formatDate(customer?.date_of_birth, 'UTC')
        : undefined
    )
    methods.setValue('email', customer.email || undefined)
    methods.setValue('home_phone', customer.home_phone || undefined)
    methods.setValue('cell_phone', customer.cell_phone || undefined)
    methods.setValue('address.street', customer.address.street || undefined)
    methods.setValue('address.city', customer.address.city || undefined)
    methods.setValue('address.country', customer.address.country || undefined)
    methods.setValue('address.state', customer.address.state || undefined)
    methods.setValue(
      'address.postal_code',
      customer.address.postal_code || undefined
    )
    methods.setValue('contact_api_id', customer.contact_api_id || undefined)
  }, [customer])

  const onSubmit: SubmitHandler<FormData> = async ({
    home_phone,
    cell_phone,
    date_of_birth,
    account_number,
    contact_api_id,
    email,
    first_name,
    last_name,
    location_id,
    address,
  }) => {
    const payload = {
      location_id,
      address: {
        city: !!address.city ? address.city : null,
        country: !!address.country ? address.country : null,
        postal_code: !!address.postal_code ? address.postal_code : null,
        state: !!address.state ? address.state : null,
        street: !!address.street ? address.street : null,
      },
      contact_api_id: !!contact_api_id ? contact_api_id : null,
      account_number: !!account_number ? account_number : null,
      email: !!email ? email : null,
      first_name: !!first_name ? first_name : null,
      last_name: !!last_name ? last_name : null,
      home_phone: home_phone ? processPhone(home_phone) : null,
      date_of_birth: date_of_birth
        ? formatRawDate(date_of_birth, 'yyyy-MM-dd', 'UTC')
        : null,
      cell_phone: cell_phone ? processPhone(cell_phone) : null,
    }

    setIsLoadingSubmit(true)
    try {
      const result = await api.service('contacts').update(customer.id, payload)

      navigate(`/merchant/gateway/customer/${result.id}/view`)
    } catch (error) {
      setNotification({
        label: t('common.please-review-information'),
        type: 'error',
      })

      const errorsFormatted = toArrayFieldErrors(error)

      errorsFormatted.forEach((e) => {
        methods.setError(e.field, {
          type: 'manual',
          message: e.message,
        })
      })

      methods.setFocus(errorsFormatted[0]?.field)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  return (
    <>
      {isLoadingCustomer ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <PageLayoutContainer isButtonBarAtBottom>
              <PageLayoutContainerMain>
                <CustomerDetails />

                <PageLayoutDivider />

                <ContactInformation />

                <PageLayoutDivider />

                <BillingInformation
                  basePath="address"
                  type="address"
                  showPhone={false}
                  showCountryAllOptions={false}
                />

                <PageLayoutDivider />

                <AdvancedSettings />
              </PageLayoutContainerMain>

              <AppBar
                sx={{
                  bottom: 0,
                  top: 'auto',
                  position: 'fixed',
                  boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
                }}
              >
                <ButtonBar style={{ marginBottom: '0 !important' }}>
                  <ButtonBarEnd>
                    <Button
                      label={t('common.cancel')}
                      color="secondary"
                      style={{ width: '101px' }}
                      testId="cancel-button"
                      onClick={() => {
                        navigateToUrl(returnToUrl)
                      }}
                      guidingId="edit-customers-cancel"
                    />
                    <Button
                      type="submit"
                      label={t('common.save')}
                      isLoading={isLoadingSubmit}
                      style={{ marginLeft: '8px', width: '86px' }}
                      testId="save-button"
                      guidingId="edit-customers-save"
                    />
                  </ButtonBarEnd>
                </ButtonBar>
              </AppBar>
            </PageLayoutContainer>
          </form>
        </FormProvider>
      )}
    </>
  )
}

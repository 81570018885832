import React from 'react'
import { useTranslation } from 'react-i18next'

import { QuickInvoice } from '@shared/api/src'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { formatDate, formatPhoneNumber } from '@shared/utils'

interface QuickInvoiceDetailsProps {
  invoice: QuickInvoice
}

export const QuickInvoiceDetails: React.FC<QuickInvoiceDetailsProps> = ({
  invoice,
}) => {
  const { t } = useTranslation()

  const getDeliveryMethod = (email?: string, cell_phone?: string): string => {
    if (email && cell_phone) return t('mfe-gateway.quick-invoice.email-and-sms')
    if (email) return t('mfe-gateway.quick-invoice.email-only')
    if (cell_phone) return t('mfe-gateway.quick-invoice.sms-only')
    return t('mfe-gateway.quick-invoice.none')
  }

  const valueQuickInvoiceTitle = invoice?.title
  const valueQuickInvoiceInvoiceNumber = invoice?.invoice_number
  const valueQuickInvoiceDueDate = formatDate(invoice?.due_date, 'UTC')
  const valueQuickInvoiceExpireDate = formatDate(invoice?.expire_date, 'UTC')

  const valueQuickInvoiceDeliveryMethod = getDeliveryMethod(
    invoice.email,
    invoice.cell_phone
  )
  const valueQuickInvoiceEmail = invoice?.email
  const valueQuickInvoiceCellPhone = formatPhoneNumber(invoice?.cell_phone)

  return (
    <FieldGroupContainer title={t('mfe-gateway.quick-invoice.invoice-details')}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.title')}
          value={valueQuickInvoiceTitle}
          valueTestID="quick-invoice-title-value"
        />

        <FieldGroupViewItem
          title={t('common.invoice-number')}
          value={valueQuickInvoiceInvoiceNumber}
          valueTestID="quick-invoice-invoice-number-value"
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.due-date')}
          value={valueQuickInvoiceDueDate}
          valueTestID="quick-invoice-due-date-value"
        />

        <FieldGroupViewItem
          title={t('common.expiration-date')}
          value={valueQuickInvoiceExpireDate}
          valueTestID="quick-invoice-expire-date-value"
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('mfe-gateway.quick-invoice.delivery-method')}
          value={valueQuickInvoiceDeliveryMethod}
          valueTestID="quick-invoice-delivery-method-value"
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.email')}
          value={valueQuickInvoiceEmail}
          valueTestID="quick-invoice-email-value"
        />

        <FieldGroupViewItem
          title={t('common.phone-cell')}
          value={valueQuickInvoiceCellPhone}
          valueTestID="quick-invoice-cell-phone-value"
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}

import { useTranslation } from 'react-i18next'

export const useFormatBooleanAsYesNo = (value: boolean) => {
  const { t } = useTranslation()

  const yes = t('common.yes')
  const no = t('common.no')

  return value ? yes : no
}

import { useTranslation } from 'react-i18next'

import { Recurring } from '@shared/api/src'
import { Status, StatusProps } from '@shared/components'
import { recurringStatusCodes, RecurringStatusType } from '@shared/types'

interface RecurringStatusProps {
  statusCode: Recurring['status']
}

const formatRecurringStatus = (status: string) => {
  return recurringStatusCodes[status]
}

const RecurringStatus = ({ statusCode }: RecurringStatusProps) => {
  const { t } = useTranslation()
  const getStatus = (status: RecurringStatusType): StatusProps => {
    let statusData: StatusProps = {
      text: undefined,
      backgroundColor: undefined,
    }

    switch (status) {
      case RecurringStatusType.PAUSED:
        statusData.text = t('common.paused')
        statusData.backgroundColor = 'yellow'
        break
      case RecurringStatusType.ACTIVE:
        statusData.text = t('common.active')
        statusData.backgroundColor = 'teal'
        break
      case RecurringStatusType.ENDED:
        statusData.text = t('common.ended')
        statusData.backgroundColor = 'red'
        break
      default:
        statusData.text = t('common.unknown')
        statusData.backgroundColor = 'gray'
        break
    }

    return statusData
  }

  const { text, backgroundColor } = getStatus(formatRecurringStatus(statusCode))

  return <Status text={text} backgroundColor={backgroundColor} />
}

export default RecurringStatus

import { Location, MerchantAccount, Terminal, User } from '../../../api/src'
import { getUserPermissionsMap } from '../../permissions/permissions'

export const getValidTerminals = (
  location: Location,
  merchantAccount: MerchantAccount | undefined | null,
  user: User,
  locationTerminals?: Terminal[]
): Terminal[] => {
  if (!user || !location) return []

  const permissions = getUserPermissionsMap(user)

  if (
    !(
      permissions['v2.routertransactions.post'] ||
      permissions['v2.terminaltransactions.post'] ||
      permissions['*']
    ) ||
    merchantAccount?.industry_type === 'moto' ||
    merchantAccount?.industry_type === 'ecommerce' ||
    merchantAccount?.payment_method !== 'cc' ||
    merchantAccount?.surcharge?.compliant
  ) {
    return []
  }

  let filterTerminals = locationTerminals ?? location.terminals
  return (
    filterTerminals?.filter((t) => {
      return (
        t?.terminal_manufacturer_code?.toString() !== '4' &&
        (process.env.API_ROOT?.includes('api.fortis.tech')
          ? t?.terminal_manufacturer_code?.toString() !== '100'
          : true)
      )
    }) ?? []
  )
}

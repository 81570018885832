import { omit } from 'lodash'

import { api, Contact } from '../api'

/**
 * Provides a layer of abstraction for customer fetching to keep the logic separate from the UI.
 */
export const getCustomerById = async (id = '') => {
  const customer = await api.service('contacts').get(id)

  return customer
}

export const searchCustomers = async (locationId: string, filter: string) => {
  const results = await api.service('contact-searches').find({
    query: {
      location_id: locationId,
      keyword: `"${filter}"`,
    },
  })

  return results
}

export const deactivateCustomer = async (id: string) =>
  await api.service('contacts').remove(id)

export const activateCustomer = async (id: string) =>
  await api.service('contacts').activate(id)

export const updateCustomer = async (customer: Contact) =>
  api.service('contacts').update(
    customer.id!,
    omit(customer, [
      //These fields are not accepted by the API
      'id',
      'created_ts',
      'modified_ts',
      'created_user_id',
      'created_user',
      'header_message_type_id',
      'active',
    ]) as Contact
  )

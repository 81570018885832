import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Token } from '@shared/api'
import { ActionModal, OtherInfo, PageLayoutDivider } from '@shared/components'
import { useEnforceLogin } from '@shared/hooks'
import { PaymentMethodType } from '@shared/types'

import ACHDetailsView from '../ach-details-view/ACHDetailsView'
import BillingInformationView from '../billing-information-view/BillingInformationView'
import CCDetailsView from '../cc-details-view/CCDetailsView'

interface WalletViewProps {
  open: boolean
  wallet: Token
  onClose: () => void
}

const useStyles = tss.withName('WalletView').create(({ theme }) => ({
  modal: {
    minWidth: '640px',
  },
}))

const WalletView = ({ open, wallet, onClose }: WalletViewProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { user } = useEnforceLogin()
  return (
    <ActionModal
      open={open}
      title={t('mfe-gateway.token.view-wallet')}
      onClose={onClose}
      className={classes.modal}
      isScrollableContent
    >
      {wallet.payment_method === PaymentMethodType.CC && (
        <CCDetailsView wallet={wallet} />
      )}
      {wallet.payment_method === PaymentMethodType.ACH && (
        <ACHDetailsView wallet={wallet} />
      )}

      <PageLayoutDivider />
      <BillingInformationView billing_information={wallet.billing_address} />
      <PageLayoutDivider />
      <OtherInfo
        createdDate={wallet.created_ts}
        createdBy={wallet.created_user?.email}
        timezone={user?.tz}
      />
    </ActionModal>
  )
}

export default WalletView

import { omit } from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Checkbox, Input, FieldGroupContainer } from '@shared/components'

interface AdvancedSettingsAddProps {
  showRunAvs?: boolean
}

const AdvancedSettingsAdd = ({
  showRunAvs = true,
}: AdvancedSettingsAddProps) => {
  const { t } = useTranslation()
  const methods = useFormContext()
  return (
    <FieldGroupContainer
      title={t('common.advanced-settings')}
      showToggle={true}
      showContent={false}
      toggleTestID="wallet-advanced-settings-toggle"
      guidingId="wallet-advancedsettings"
    >
      {showRunAvs && (
        <Controller
          name="run_avs"
          control={methods.control}
          render={({ field }) => (
            <Checkbox
              {...omit(field, 'ref')}
              label={t('mfe-gateway.token-cc.run-avs')}
              description={t('mfe-gateway.token-cc.run-avs-description')}
              checked={field.value}
              onChange={field.onChange}
              style={{
                marginLeft: '11px',
                marginBottom: '24px',
              }}
              guidingId="token-runavs"
            />
          )}
        />
      )}
      <Controller
        name="token_api_id"
        control={methods.control}
        render={({ field }) => (
          <Input
            {...field}
            onChange={(event) => {
              field.onChange(event)
            }}
            label={t('mfe-gateway.token.wallet-api-id-optional')}
            placeholder={t('mfe-gateway.token.wallet-api-id-placeholder')}
            helperText={t('common.leave-this-field-blank')}
            guidingId="token-apiid"
          />
        )}
      />
    </FieldGroupContainer>
  )
}

export default AdvancedSettingsAdd

import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { Token } from '@shared/api'
import {
  AccountType,
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
  FieldGroupViewItemWithChildren,
} from '@shared/components'
import { formatExpDate } from '@shared/utils'

interface CCDetailsViewProps {
  wallet: Token
}

const useStyles = tss.withName('CCDetailsView').create(({ theme }) => ({
  value: {
    color: theme.palette['neutral-900'],
    fontWeight: '500',
    lineHeight: '20px',
    fontSize: '14px',
  },
  accountTypeContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
}))

const CCDetailsView = ({ wallet }: CCDetailsViewProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const valueTitle = wallet?.title
  const valueAccountholdername = wallet?.account_holder_name
  const valueExpdate = formatExpDate(wallet?.exp_date)

  return (
    <>
      <FieldGroupContainer title={t('mfe-gateway.token-cc.card-details')}>
        <FieldGroupRow>
          <FieldGroupViewItem title={t('common.title')} value={valueTitle} />
          <FieldGroupViewItem
            title={t('common.card-holder-name')}
            value={valueAccountholdername}
          />
        </FieldGroupRow>
        <FieldGroupRow>
          <FieldGroupViewItemWithChildren title={t('common.account-type')}>
            <Box className={classes.accountTypeContainer}>
              <AccountType showLabel={false} type={wallet.account_type} />
              <Typography className={classes.value}>
                • {wallet.last_four}
              </Typography>
            </Box>
          </FieldGroupViewItemWithChildren>
          <FieldGroupViewItem
            title={t('common.expire-date')}
            value={valueExpdate}
          />
        </FieldGroupRow>
      </FieldGroupContainer>
    </>
  )
}

export default CCDetailsView

import { cloneDeep } from 'lodash'

import { Transaction } from '../../../api/src'
import { preprocessCurrencyAmount } from '../../../api/src/utils/transactions/preprocessCurrencyAmount'
import clearBlankFields from '../../clear-blank-fields/clearBlankFields'
import { processPhone } from '../../phone-number-format/phoneNumberFormat'

/**
 * Type used to ensure that this works with different transactionData types
 */
interface ProcessableTransaction {
  subtotal_amount?: number | null
  tip_amount?: number | null
  tax?: number | null
  surcharge_amount?: number | null
  transaction_amount?: number | null
  account_number?: string | null
  exp_date?: string | null
  cvv?: string | null
  billing_address?: Transaction['billing_address']
  save_account_title?: string | null
  save_account?: boolean | null
}

/**
 * Cleans up transactions so that they can be accepted by the API, if possible.
 * Done here rather than as an API hook so that other consumers of the API don't have to conform to the same string formatting
 */
export const preprocessTransactionData = <T extends ProcessableTransaction>(
  transactionData: T
): T => {
  const processedTransaction = {
    ...cloneDeep(transactionData),
    subtotal_amount: preprocessCurrencyAmount(transactionData.subtotal_amount),
    tip_amount: preprocessCurrencyAmount(transactionData.tip_amount),
    tax: preprocessCurrencyAmount(transactionData.tax),
    surcharge_amount: preprocessCurrencyAmount(
      transactionData.surcharge_amount
    ),
    transaction_amount:
      preprocessCurrencyAmount(transactionData.transaction_amount) ?? 0,
    exp_date: transactionData.exp_date?.replace('/', ''),
    account_number: transactionData.account_number?.replace(/\s/g, ''),
    billing_address: {
      ...transactionData.billing_address,
      phone: processPhone(transactionData.billing_address?.phone as string),
    },
    save_account_title: transactionData.save_account
      ? transactionData.save_account_title
      : undefined,
  }

  return clearBlankFields(processedTransaction)
}

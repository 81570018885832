import { MerchantAccount } from '@shared/api/src/schemas/types'
import { recurringTypes, recurringStatusCodes } from '@shared/types'
import { sortMerchantAccounts } from '@shared/utils'

export const mapRecurringTypeId = (type_id: string | undefined) => {
  return type_id ? recurringTypes[type_id.toString()] : ''
}

export const filterRecurringTypeValues = Object.entries(recurringTypes).map(
  ([key, value]) => ({ value: key, label: value })
)

export const filterRecurringStatusValues = Object.entries(
  recurringStatusCodes
).map(([key, value]) => ({ value: key, label: value }))

export const filterMerchantAccount = (MerchantAccounts: MerchantAccount[]) => {
  if (!MerchantAccounts) return []
  const sortedMerchantAccounts = sortMerchantAccounts(MerchantAccounts)
  return Object.entries(sortedMerchantAccounts).map(([key, value]) => ({
    value: value.id,
    label: value.title,
  }))
}

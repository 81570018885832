import { MerchantAccount } from '../../../api/src/schemas/types'

/**
 * Alphabetically sorts merchant accounts, applies optional concatenations,
 * and groups by active/inactive status, with inactive accounts listed last.
 *
 * @param {MerchantAccount[]} merchantAccounts Array of merchant accounts to sort.
 * @param {boolean} concatInactiveTag Whether to append "(Inactive)" to inactive accounts.
 * @param {boolean} concatPaymentMethod Whether to append the payment method to the title.
 * @returns {MerchantAccount[]} Sorted and processed merchant accounts.
 */
export const sortMerchantAccounts = (
  merchantAccounts: MerchantAccount[],
  concatInactiveTag = true,
  concatPaymentMethod = false
): MerchantAccount[] => {
  if (merchantAccounts?.length) {
    // First, sort all accounts alphabetically by title.
    merchantAccounts?.sort((a, b) => a.title.localeCompare(b.title))

    // Process accounts for optional tags and payment method.
    const processedAccounts = merchantAccounts?.map((account) => {
      let { title, payment_method, active } = account

      // Concatenate payment method if required, but ensure it's not already present.
      if (concatPaymentMethod && payment_method) {
        const paymentMethodFormatted = ` - ${payment_method.toUpperCase()}`
        if (!title.includes(paymentMethodFormatted)) {
          title += paymentMethodFormatted
        }
      }

      // Apply inactive tag if required and the account is inactive.
      if (concatInactiveTag && !active) {
        if (!title.includes('(Inactive)')) {
          title += ' (Inactive)'
        }
      }

      return {
        ...account,
        title,
      }
    })

    // Separate active and inactive accounts now that all titles are finalized.
    const activeAccounts = processedAccounts?.filter(
      (account) => account.active
    )
    const inactiveAccounts = processedAccounts?.filter(
      (account) => !account.active
    )

    // Since accounts were already sorted alphabetically, simply concatenate active and inactive arrays.
    return [...activeAccounts, ...inactiveAccounts]
  } else {
    return []
  }
}

export const sortMerchantAccountsOnGridFilter = (
  firstId: string,
  secondId: string,
  allMerchants: MerchantAccount[]
) => {
  const aOption = allMerchants.find((merchant) => merchant.id === firstId)

  const bOption = allMerchants.find((merchant) => merchant.id === secondId)

  if (aOption.active && !bOption.active) {
    return -1
  } else if (!aOption.active && bOption.active) {
    return 1
  }

  return aOption?.title.localeCompare(bOption?.title)
}

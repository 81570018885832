import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { FieldGroupContainer, Input, InputPhone } from '@shared/components'

interface FormData {
  email: string
  home_phone: string
  cell_phone: string
}

const ContactInformation = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>()

  return (
    <FieldGroupContainer title={t('mfe-gateway.contact-information')}>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item sm={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="email-input"
                label={t('common.email')}
                placeholder={t('common.email-placeholder')}
                error={!!errors.email}
                helperText={errors.email?.message}
                guidingId={'customers-email'}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Controller
            name="home_phone"
            control={control}
            render={({ field }) => (
              <InputPhone
                {...field}
                testId="home-phone-input"
                label={t('common.phone-home')}
                error={!!errors.home_phone}
                helperText={errors.home_phone?.message}
                guidingId={'customers-homephone'}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Controller
            name="cell_phone"
            control={control}
            render={({ field }) => (
              <InputPhone
                {...field}
                testId="cell-phone-input"
                label={t('common.phone-cell')}
                error={!!errors.cell_phone}
                helperText={errors.cell_phone?.message}
                guidingId={'customers-cellphone'}
              />
            )}
          />
        </Grid>
      </Grid>
    </FieldGroupContainer>
  )
}

export default ContactInformation

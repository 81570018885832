import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Box, Grid, Typography } from '@mui/material'

import {
  EbtTransactionType,
  MerchantAccount,
  TransactionType,
} from '@shared/api'
import {
  SelectComponent as Select,
  Input,
  RadioButtons,
  RadioButtonsArray,
  CurrencyInput,
} from '@shared/components'
import {
  PubSubEvent,
  useAuthorization,
  useEnforceLogin,
  useLocations,
  usePub,
  useSub,
} from '@shared/hooks'
import {
  PaymentMethod,
  ProcessMethod,
  SelectOption,
  PaymentMethodEbt,
} from '@shared/types'
import {
  getTransactionTypesForAccount,
  getTransactionTypesForPaymentMethod,
  getVirtualTerminalMerchantAccounts,
  getDefaultMerchantAccount,
  getDefaultTransactionType,
  checkPermission,
  getValidTerminals,
} from '@shared/utils'

import {
  CUSTOMER_DETAILS_WALLET_CHANGE_EVENT,
  CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT,
} from '../customer-details/CustomerDetails'
import { PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT } from '../payment-account-details/PaymentAccountDetails'

export const TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT = new Event(
  'TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE'
) as PubSubEvent<MerchantAccount | null>

export const TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT = new Event(
  'TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE'
) as PubSubEvent<TransactionType | EbtTransactionType | null>

const useStyles = tss
  .withName('TransactionInformation')
  .create(({ theme }) => ({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    title: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter !important',
      fontSize: '18px !important',
      fontWeight: '500 !important',
      lineHeight: '28px !important',
    },
    multipleInputContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: '3px',
      rowGap: '18px',
      justifyContent: 'space-between',
    },
    button: {
      width: '100%',
      height: '44px',
    },
    multipleInput: {
      width: '49%',
    },
  }))

export const TransactionInformation = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { selectedLocation, locationTerminals } = useLocations()
  const { user } = useEnforceLogin()
  const { userPermissionSet } = useAuthorization()
  const { walletId } = useParams()
  const publish = usePub()

  const {
    control,
    setValue,
    formState: { errors },
    clearErrors,
    resetField,
  } = useFormContext()

  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethod | PaymentMethodEbt | null
  >(null)

  const [selectedTransactionType, setSelectedTransactionType] = useState<
    TransactionType | EbtTransactionType | null
  >(null)

  const [processMethod, setProcessMethod] = useState<ProcessMethod>()

  const hasSubtotal = useMemo(() => {
    if (!selectedMerchantAccount) return undefined

    if (selectedPaymentMethod?.includes('ebt')) {
      return !!selectedMerchantAccount?.vt_enable_sales_tax
    }

    return (
      !!selectedMerchantAccount?.surcharge ||
      !!selectedMerchantAccount?.vt_enable_sales_tax ||
      !!selectedMerchantAccount?.vt_enable_tip
    )
  }, [selectedMerchantAccount, selectedPaymentMethod])

  useSub<typeof PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT>(
    PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT.type,
    ({ data: processMethod }) => {
      if (processMethod === 'terminal' && selectedTransactionType === 'force') {
        onChangeTransactionType('sale')
      }
      setProcessMethod(processMethod)
    },
    [selectedTransactionType]
  )

  useSub<typeof CUSTOMER_DETAILS_WALLET_CHANGE_EVENT>(
    CUSTOMER_DETAILS_WALLET_CHANGE_EVENT.type,
    ({ data: wallet }) => {
      if (!wallet) {
        return
      }
      if (
        selectedMerchantAccount &&
        merchantAccountsRecord[wallet.payment_method].length >= 1 &&
        wallet?.payment_method !== selectedMerchantAccount?.payment_method
      ) {
        onChangePaymentMethod(wallet.payment_method)
        onChangeMerchantAccount(
          getDefaultMerchantAccount(selectedLocation, wallet.payment_method)
        )
      }
    },
    [selectedMerchantAccount, walletId]
  )

  useSub<typeof CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT>(
    CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT.type,
    ({ data: customer }) => {
      // Hacky way to trigger the merchant account change event on scenarios where customer details load later than the transaction information
      // I don't like this, but it's the only way to make it work for now
      if (customer && selectedMerchantAccount) {
        publish(
          TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT,
          selectedMerchantAccount
        )
      }
    },
    [selectedMerchantAccount]
  )

  const onChangePaymentMethod = useCallback(
    (paymentMethod: PaymentMethod | PaymentMethodEbt | null) => {
      // No need to publish the Payment Method
      // as its known through the selectedMerchantAccount.payment_method
      clearErrors()
      setSelectedPaymentMethod(paymentMethod)
    },
    []
  )

  const onChangeTransactionType = useCallback(
    (transactionType: TransactionType | EbtTransactionType | null) => {
      clearErrors()
      if (
        transactionType !== 'force' ||
        !transactionType?.includes('voucher')
      ) {
        setValue('auth_code', undefined)
      }
      if (!transactionType?.includes('voucher')) {
        setValue('voucher_number', undefined)
      }
      setSelectedTransactionType(transactionType)
    },
    [selectedMerchantAccount]
  )

  const onChangeMerchantAccount = useCallback(
    (
      merchantAccount: MerchantAccount | null,
      paymentMethod?: PaymentMethod | PaymentMethodEbt
    ) => {
      if (!merchantAccount) {
        return
      }
      let currentPaymentMethod = paymentMethod || selectedPaymentMethod
      transactionTypeOptions = getTransactionTypesForAccount(
        selectedMerchantAccount,
        user,
        processMethod,
        selectedLocation,
        locationTerminals,
        currentPaymentMethod
      )

      setSelectedMerchantAccount(merchantAccount)
      setValue('product_transaction_id', merchantAccount?.id ?? undefined)
      let defaultType = getDefaultTransactionType(user, merchantAccount)
      if (defaultType === 'sale' && currentPaymentMethod === 'ebt') {
        if (
          transactionTypeOptions?.filter(
            (option) => option.value === 'ebt.sale'
          )?.length
        ) {
          defaultType = 'ebt.sale'
        }
      }
      if (
        !defaultType.includes('ebt') &&
        currentPaymentMethod === 'ebt' &&
        transactionTypeOptions?.length
      ) {
        defaultType = transactionTypeOptions[0].value
      }
      onChangeTransactionType(defaultType)
      if (!selectedPaymentMethod) {
        onChangePaymentMethod(merchantAccount?.payment_method ?? null)
      }
    },
    [
      user,
      onChangeTransactionType,
      selectedPaymentMethod,
      selectedTransactionType,
      selectedLocation,
      processMethod,
      locationTerminals,
    ]
  )

  // Merchant Accounts Record were they're classified by payment method
  const merchantAccountsRecord: Record<
    PaymentMethod | PaymentMethodEbt,
    MerchantAccount[]
  > = useMemo(() => {
    const record: Record<PaymentMethod | PaymentMethodEbt, MerchantAccount[]> =
      {
        cc: [],
        ach: [],
        cash: [],
        ebt: [],
      }

    if (!selectedLocation?.id) {
      return record
    }

    const merchantAccounts =
      getVirtualTerminalMerchantAccounts(selectedLocation)

    const filterByPaymentMethod = (
      paymentMethod: PaymentMethod | PaymentMethodEbt
    ) =>
      merchantAccounts.filter((merchantAccount) => {
        if (paymentMethod === 'ebt') {
          if (
            merchantAccount.allow_ebt_cash_benefit ||
            merchantAccount.allow_ebt_food_stamp
          ) {
            return merchantAccount
          }
        } else {
          if (merchantAccount.payment_method === paymentMethod) {
            return merchantAccount
          }
        }
      })

    record.ebt = filterByPaymentMethod('ebt')
    record.cc = filterByPaymentMethod('cc')
    record.ach = filterByPaymentMethod('ach')
    record.cash = filterByPaymentMethod('cash')

    return record
  }, [selectedLocation, selectedTransactionType, selectedMerchantAccount])

  // Merchant Accounts Options for the Select Dropdown
  const merchantAccountOptions = useMemo(() => {
    if (!merchantAccountsRecord || !selectedPaymentMethod) {
      return []
    }
    let merchantAccounts
    if (selectedPaymentMethod === 'ebt') {
      merchantAccounts = merchantAccountsRecord['cc'].filter(
        (record) => record.allow_ebt_cash_benefit || record.allow_ebt_food_stamp
      )
    } else {
      merchantAccounts = merchantAccountsRecord[selectedPaymentMethod]
    }

    return merchantAccounts.map((merchantAccount) => ({
      label: merchantAccount.title,
      value: merchantAccount,
    }))
  }, [merchantAccountsRecord, selectedPaymentMethod, selectedTransactionType])

  // Available Payment Method Options.

  const paymentMethodOptions = useMemo(() => {
    const options: SelectOption<PaymentMethod | PaymentMethodEbt>[] = [
      { label: 'CC', value: 'cc' },
      { label: 'ACH', value: 'ach' },
      { label: 'Cash', value: 'cash' },
      { label: 'EBT', value: 'ebt' },
    ]

    return options.filter((paymentMethod) => {
      if (!merchantAccountsRecord) return false

      const transactionTypeOptions =
        getTransactionTypesForPaymentMethod(paymentMethod)

      const hasTransactionTypesPermissions = !!transactionTypeOptions.filter(
        ({ value: transactionType }) => {
          let permission = transactionType.toString()
          if (permission?.includes('ebt')) {
            permission = transactionType.split('.')[1]
          }
          return checkPermission(
            userPermissionSet,
            `v2.transactions.post.${permission}`
          )
        }
      ).length

      if (!hasTransactionTypesPermissions) return false
      return merchantAccountsRecord[paymentMethod.value].length >= 1
    })
  }, [
    merchantAccountsRecord,
    userPermissionSet,
    selectedMerchantAccount,
    selectedTransactionType,
  ])

  // There the Available Payment Method Options are
  // mapped into the desired Radio Buttons Props.

  const getTranslatedMethod = (value) => {
    switch (value.label) {
      case 'Cash':
        return t('common.cash')
      default:
        return value.label
    }
  }

  const paymentMethodsRadioButtonsProps: RadioButtonsArray = useMemo(() => {
    if (selectedMerchantAccount?.card_type_ebt) {
      if (
        !paymentMethodOptions.filter((option) => option.value === 'ebt').length
      ) {
        paymentMethodOptions.push({ label: 'EBT', value: 'ebt' })
      }
    }
    return paymentMethodOptions.map((paymentMethod) => ({
      testId: `${paymentMethod.value}-payment-method-button`,
      defaultSelected: selectedPaymentMethod === paymentMethod.value,
      title: getTranslatedMethod(paymentMethod),
      color: 'secondary',
      onClick: () => {
        onChangePaymentMethod(paymentMethod.value)
        onChangeMerchantAccount(
          getDefaultMerchantAccount(selectedLocation, paymentMethod.value),
          paymentMethod.value
        )
      },
      className: classes.button,
      guidingId: `virtualterminal-transactioninformation-paymentmethod-${paymentMethod}`,
    }))
  }, [
    paymentMethodOptions,
    selectedPaymentMethod,
    onChangePaymentMethod,
    selectedMerchantAccount,
    selectedTransactionType,
    selectedLocation,
  ])

  // Available Transaction Type Options.
  const getTranslatedType = (value) => {
    let label = ''
    if (value === 'sale' || value === 'cash.sale' || value === 'ebt.sale') {
      label = t('common.sale')
    } else if (value === 'authonly') {
      label = t('mfe-gateway.authonly')
    } else if (value === 'avsonly') {
      label = t('common.avsonly')
    } else if (
      value === 'refund' ||
      value === 'cash.refund' ||
      value === 'ebt.refund'
    ) {
      label = t('common.refund')
    } else if (value === 'force') {
      label = t('mfe-gateway.force')
    } else if (value === 'debit') {
      label = t('common.debit')
    } else if (value === 'credit') {
      label = t('mfe-gateway.credit')
    } else if (value === 'ebt.voucherclearsale') {
      label = t('common.voucher-clear-sale')
    } else if (value === 'ebt.voucherclearrefund') {
      label = t('common.voucher-clear-refund')
    } else if (value === 'ebt.balanceinquiry') {
      label = t('common.balance-inquiry')
    }
    return label
  }

  let transactionTypeOptions = useMemo(() => {
    if (!selectedMerchantAccount) return []
    return getTransactionTypesForAccount(
      selectedMerchantAccount,
      user,
      processMethod,
      selectedLocation,
      locationTerminals,
      selectedPaymentMethod
    ).map((option) => ({
      ...option,
      label: getTranslatedType(option.value),
    }))
  }, [
    selectedMerchantAccount,
    user,
    processMethod,
    selectedPaymentMethod,
    selectedLocation,
    locationTerminals,
  ]) // eslint-disable-line

  // There the Available Transaction Type Options are
  // mapped into the desired Radio Buttons Props.
  if (
    selectedPaymentMethod !== 'ebt' &&
    selectedTransactionType?.includes('ebt')
  ) {
    setSelectedTransactionType(transactionTypeOptions[0].value)
  }
  const transactionTypesRadioButtonsProps: RadioButtonsArray = useMemo(
    () =>
      transactionTypeOptions.map(({ value: transactionType, label }) => ({
        testId: `${transactionType}-transaction-type-button`,
        defaultSelected: selectedTransactionType === transactionType,
        title: label,
        color: 'secondary',
        onClick: () => onChangeTransactionType(transactionType),
        className: classes.button,
        guidingId: `virtualterminal-transactioninformation-transactiontype-${transactionType}`,
      })),
    [
      transactionTypeOptions,
      selectedTransactionType,
      onChangeTransactionType,
      selectedPaymentMethod,
    ]
  )

  // On Mount set the Default Merchant Account,
  // it has to be set again if the Selected Location changes.
  useEffect(() => {
    let defaultMerchantAccount = getDefaultMerchantAccount(
      selectedLocation,
      selectedPaymentMethod
    )
    if (!defaultMerchantAccount) {
      setSelectedPaymentMethod('cc')
      defaultMerchantAccount = getDefaultMerchantAccount(selectedLocation, 'cc')
    }
    if (!defaultMerchantAccount) {
      setSelectedPaymentMethod('ach')
      defaultMerchantAccount = getDefaultMerchantAccount(
        selectedLocation,
        'ach'
      )
    }
    onChangeMerchantAccount(defaultMerchantAccount)
  }, [selectedLocation])

  // TODO: maybe a good workaround?, when setting the default merchant account in
  // the useEffect above the components/PaymentAccountDetails.tsx isn't yet mounted sometimes,
  // so it doesn't receive that default merchant account and doesn't show in the UI,
  // this fixes that, but can cause to publish more than necessary to care about?
  useEffect(() => {
    setTimeout(() => {
      publish(
        TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT,
        selectedMerchantAccount
      )
    }, 200)
  }, [selectedMerchantAccount?.id])

  // TODO: same as explained above, but with the pages/transaction/VirtualTerminal.tsx,
  // the transaction type sometimes wasn't defined when submitting a transaction,
  // causing an API Error.
  useEffect(() => {
    publish(
      TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT,
      selectedTransactionType
    )
    if (selectedTransactionType?.includes('ebt')) {
      if (selectedTransactionType?.includes('voucher')) {
        setProcessMethod('manual')
        publish(PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT, 'manual')
      } else {
        setProcessMethod('terminal')
        publish(PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT, 'terminal')
      }
    }
  }, [selectedTransactionType])

  useEffect(() => {
    if (
      selectedPaymentMethod === 'ebt' &&
      !selectedTransactionType?.includes('ebt')
    ) {
      if (selectedMerchantAccount.default_transaction_type === 'sale') {
        const validTerminals = getValidTerminals(
          selectedLocation,
          selectedMerchantAccount,
          user,
          locationTerminals
        )
        if (validTerminals.length) {
          setSelectedTransactionType('ebt.sale')
        }
      } else {
        setSelectedTransactionType(transactionTypeOptions[0].value)
      }
    }
  }, [
    selectedPaymentMethod,
    selectedLocation,
    locationTerminals,
    selectedMerchantAccount,
  ])

  useEffect(() => {
    if (transactionTypeOptions?.length) {
      let hasOption = false
      transactionTypeOptions.forEach((option) => {
        if (option.value === selectedTransactionType) {
          hasOption = true
        }
      })
      if (!hasOption) {
        setSelectedTransactionType(transactionTypeOptions[0].value)
      }
    }
  }, [transactionTypeOptions, selectedTransactionType])

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="h6">
        {t('common.transaction-information')}
      </Typography>

      <Grid
        container
        columnGap={'8px'}
        rowGap={'18px'}
        wrap="wrap"
        flexWrap="wrap"
      >
        {!!merchantAccountOptions?.length && (
          <>
            {paymentMethodsRadioButtonsProps.length > 1 && !walletId && (
              <Grid item xs={6}>
                <RadioButtons
                  testId="payment-method-radio-buttons"
                  label={t('common.payment-method')}
                  buttons={paymentMethodsRadioButtonsProps}
                  guidingId="virtualterminal-transactioninformation-paymentmethod"
                />
              </Grid>
            )}
          </>
        )}

        {selectedPaymentMethod !== 'ebt' && (
          <Grid item xs={12}>
            <RadioButtons
              testId="transaction-type-radio-buttons"
              label={t('common.transaction-type')}
              buttons={transactionTypesRadioButtonsProps}
            />
          </Grid>
        )}
        {selectedPaymentMethod === 'ebt' && (
          <Grid item xs={12}>
            <Select
              required
              testId="transaction-type-select"
              guidingId="virtualterminal-transactioninformation-transaction-type"
              label={t('common.transaction-type')}
              options={
                transactionTypeOptions as unknown as SelectOption<string>[]
              }
              sort={true}
              value={selectedTransactionType}
              onChange={(event) =>
                onChangeTransactionType(
                  event.target.value as EbtTransactionType
                )
              }
              style={{
                width: '100%',
                maxWidth: 'unset',
                border: 'unset',
                height: '44px',
              }}
              error={!!errors.transactionType}
              helperText={errors.transactionType?.message as string}
            />
          </Grid>
        )}
      </Grid>

      <Box className={classes.multipleInputContainer}>
        {merchantAccountOptions.length >= 2 && (
          <Box className={classes.multipleInput}>
            <Select
              required
              testId="merchant-account-select"
              guidingId="virtualterminal-transactioninformation-merchantaccount"
              label={t('common.merchant-account-placeholder')}
              // TODO: use correct types there when the Select generics get fixed
              options={
                merchantAccountOptions as unknown as SelectOption<string>[]
              }
              sort={true}
              value={selectedMerchantAccount}
              onChange={(event) =>
                onChangeMerchantAccount(
                  event.target.value as unknown as MerchantAccount
                )
              }
              style={{
                width: '100%',
                maxWidth: 'unset',
                border: 'unset',
                height: '44px',
              }}
            />
          </Box>
        )}
        {selectedTransactionType !== 'avsonly' &&
          selectedTransactionType !== 'ebt.balanceinquiry' &&
          hasSubtotal !== undefined && (
            <>
              <Box className={classes.multipleInput}>
                <Controller
                  control={control}
                  name={'subtotal_amount'}
                  render={({ field }) => (
                    <CurrencyInput
                      required
                      testId="subtotal-amount-input"
                      label={
                        hasSubtotal
                          ? t('common.amount-subtotal')
                          : t('common.amount-transaction')
                      }
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      currency={
                        selectedMerchantAccount?.vt_show_currency
                          ? 'USD'
                          : undefined
                      }
                      error={
                        !!errors.subtotal_amount || !!errors.transaction_amount
                      }
                      helperText={
                        (errors.subtotal_amount?.message ||
                          errors.transaction_amount?.message) as string
                      }
                      guidingId={`virtualterminal-transactioninformation-${
                        hasSubtotal ? 'subtotal' : 'transaction-amount'
                      }`}
                    />
                  )}
                />
              </Box>

              {!!selectedMerchantAccount?.vt_enable_tip &&
                !selectedPaymentMethod?.includes('ebt') && (
                  <Box className={classes.multipleInput}>
                    <Controller
                      control={control}
                      name="tip_amount"
                      render={({ field }) => (
                        <CurrencyInput
                          {...field}
                          label={t('common.tip')}
                          testId="tip-input"
                          currency={
                            selectedMerchantAccount?.vt_show_currency
                              ? 'USD'
                              : undefined
                          }
                          error={!!errors.tip}
                          helperText={errors.tip?.message as string}
                          guidingId="virtualterminal-transactioninformation-tip"
                        />
                      )}
                      shouldUnregister
                    />
                  </Box>
                )}

              {!!selectedMerchantAccount?.vt_enable_sales_tax &&
                !!selectedMerchantAccount?.vt_override_sales_tax_allowed && (
                  <Box className={classes.multipleInput}>
                    <Controller
                      control={control}
                      name="tax"
                      render={({ field }) => (
                        <CurrencyInput
                          {...field}
                          value={field.value || ''}
                          label={t('common.tax')}
                          testId="tax-input"
                          currency={
                            selectedMerchantAccount?.vt_show_currency
                              ? 'USD'
                              : undefined
                          }
                          onChange={(event) => {
                            field.onChange(event)
                            if (event.target.value.length === 0) {
                              resetField('tax')
                            }
                          }}
                          error={!!errors.tax}
                          helperText={errors.tax?.message as string}
                          guidingId="virtualterminal-transactioninformation-tax"
                        />
                      )}
                      shouldUnregister
                    />
                  </Box>
                )}
            </>
          )}
      </Box>

      {selectedTransactionType === 'force' && (
        <Box>
          <Controller
            control={control}
            name="auth_code"
            render={({ field }) => (
              <Input
                {...field}
                testId="auth-code-input"
                label={t('common.auth-code')}
                placeholder={t('mfe-gateway.auth-code-placeholder')}
                onChange={(event) => {
                  field.onChange(event)
                  setValue('auth_code', event.target.value)
                }}
                error={!!errors.auth_code}
                helperText={errors.auth_code?.message as string}
                required
                guidingId="virtualterminal-transactioninformation-authcode"
              />
            )}
          />
        </Box>
      )}
    </Box>
  )
}

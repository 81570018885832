type CountryMapping = {
  US: string
  CA: string
}

const countryMapping: CountryMapping = {
  US: 'USA',
  CA: 'CAN',
}

const mapCountryCode = (countryCode: string): string => {
  return countryMapping[countryCode as keyof CountryMapping] || countryCode
}

export const getDefaultCountry = (
  selectedCustomerCountry?: string,
  selectedWalletCountry?: string,
  selectedLocationCountry?: string
): string => {
  if (selectedWalletCountry) {
    return mapCountryCode(selectedWalletCountry)
  }

  if (selectedCustomerCountry) {
    return mapCountryCode(selectedCustomerCountry)
  }

  if (selectedLocationCountry) {
    return mapCountryCode(selectedLocationCountry)
  }

  return ''
}

import { Grid } from '@mui/material'

import Button from '../buttons/Button'
import Input, { InputProps } from '../form-fields/inputs/Input'
import { Typeahead, TypeaheadProps } from '../form-fields/typeahead/Typeahead'
import TagList from '../merchant/tag-list/TagList'

interface ListTypeaheadProps<T> {
  typeaheadProps?: TypeaheadProps<T>
  inputProps?: InputProps
  buttonLabel: string
  buttonGuidingId?: string
  onButtonClick: () => void
  isButtonLoading?: boolean
  isButtonEnabled?: boolean
  tagsItems: T[]
  tagsNameSelector: (value: T) => string
  onDeleteTag?: (value: T) => void
  tagBackgroundColor?: string
  tagTextColor?: string
}

export const ListTypeahead = <T extends unknown>({
  buttonLabel,
  buttonGuidingId,
  onButtonClick,
  isButtonLoading = false,
  isButtonEnabled = true,
  typeaheadProps,
  inputProps,
  tagsItems,
  tagsNameSelector,
  onDeleteTag,
  tagTextColor,
  tagBackgroundColor,
}: ListTypeaheadProps<T>) => {
  return (
    <>
      <Grid container marginBottom="24px" spacing={2} width="98%">
        <Grid item xs={9}>
          {typeaheadProps ? (
            <Typeahead {...typeaheadProps} />
          ) : (
            <>
              <Input {...inputProps} />
            </>
          )}
        </Grid>
        <Grid item xs={3} position="relative">
          <Button
            color="secondary"
            label={buttonLabel}
            onClick={onButtonClick}
            style={{
              width: '100%',
              position: 'absolute',
              bottom: '0',
            }}
            isLoading={isButtonLoading}
            disabled={!isButtonEnabled}
            buttonStyle={{
              height: '44px',
            }}
            guidingId={buttonGuidingId}
          />
        </Grid>
      </Grid>
      <TagList<T>
        items={tagsItems}
        nameSelector={tagsNameSelector}
        onDelete={onDeleteTag}
        backgroundColor={tagBackgroundColor}
        textColor={tagTextColor}
      />
    </>
  )
}

import { debounce, isNil } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, CircularProgress, Divider, Typography } from '@mui/material'

import {
  MerchantAccount,
  Token,
  TransactionData,
  TransactionType,
} from '@shared/api'
import { valueToNumber } from '@shared/api/src/utils/valueToNumber'
import { SurchargeDisclosure } from '@shared/components'
import { useEnforceLogin, useLocations, useSub } from '@shared/hooks'
import { ProcessMethod } from '@shared/types'
import {
  currency,
  calculateTransactionTotals,
  calculateSurcharge,
  getSalesTaxByZipCode,
  calculateTax,
} from '@shared/utils'

import {
  TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT,
  TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT,
} from '@/components/transaction-information/TransactionInformation'

import { CUSTOMER_DETAILS_WALLET_CHANGE_EVENT } from '../customer-details/CustomerDetails'
import { PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT } from '../payment-account-details/PaymentAccountDetails'

const useStyles = tss.withName('TransactionSummary').create(({ theme }) => ({
  root: {
    marginTop: '16px',
    padding: '1em',
    backgroundColor: 'rgb(243 244 246 / 1)',
    border: '1px solid rgb(229 231 235 / 1)',
    borderRadius: '.5em',
  },
  title: {
    marginBottom: '24px',
    color: theme.palette['neutral-900'],
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
  },
  fieldContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1em 0',
  },
  fieldSubtitle: {
    fontSize: '14px',
    fontWeight: '500',
  },
  fieldLabel: {
    color: theme.palette['gray-700'],
    fontWeight: 500,
  },
  divider: {
    margin: '16px 0',
  },
  totalAmount: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    color: theme.palette['neutral-900'],
  },
}))

export const TransactionSummary = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { selectedLocation } = useLocations()
  const { user } = useEnforceLogin()

  const { watch, setValue, getFieldState } = useFormContext<TransactionData>()
  const [taxRate, setTaxRate] = useState<number | undefined>(undefined)
  const [loadingSurcharge, setLoadingSurcharge] = useState(false)
  const [loadingTax, setLoadingTax] = useState(false)

  const [isCompliantSurchargeApplied, setIsCompliantSurchargeApplied] =
    useState(false)

  const [selectedToken, setSelectedToken] = useState<Token | null>(null)

  const tokenId = watch('token_id') as string

  const {
    subtotal_amount,
    tip_amount,
    tax,
    surcharge_amount,
    transaction_amount,
    account_number,
    billing_address: { postal_code, country } = {
      postal_code: '',
      country: '',
    },
  } = watch()

  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const [selectedTransactionType, setSelectedTransactionType] =
    useState<TransactionType | null>(null)

  const [taxFromApi, setTaxFromApi] = useState(false)

  const [processMethod, setProcessMethod] = useState<ProcessMethod>()

  const showTip = useMemo(
    () => selectedMerchantAccount?.vt_enable_tip ?? false,
    [selectedMerchantAccount]
  )

  const showSalesTax = useMemo(
    () => selectedMerchantAccount?.vt_enable_sales_tax ?? false,
    [selectedMerchantAccount]
  )

  const showSurcharge = useMemo(
    () =>
      !isNil(selectedMerchantAccount?.surcharge) &&
      (isNil(selectedMerchantAccount?.surcharge?.apply_to_user_type_id) ||
        selectedMerchantAccount?.surcharge.apply_to_user_type_id ===
          user.user_type_id) &&
      (selectedTransactionType !== 'refund' ||
        selectedMerchantAccount?.surcharge?.refund_surcharges),
    [user, selectedMerchantAccount, selectedTransactionType]
  )

  const showSubtotal = useMemo(
    () => showTip || showSalesTax || showSurcharge,
    [showTip, showSalesTax, showSurcharge]
  )

  // Subscribe to Selected Merchant Account
  useSub<typeof TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT>(
    TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT.type,
    ({ data: merchantAccount }) => setSelectedMerchantAccount(merchantAccount)
  )

  // Subscribe to Selected Transaction Type
  useSub<typeof TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT>(
    TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT.type,
    ({ data: transactionType }) => {
      // Manually clear the values when the transaction type is changed to AVS Only to avoid calculations
      if (transactionType === 'avsonly') {
        setValue('subtotal_amount', undefined)
        setValue('tip_amount', undefined)
        setValue('tax', undefined)
        setValue('surcharge_amount', undefined)
        setValue('transaction_amount', undefined)
      }
      setSelectedTransactionType(transactionType)
    }
  )

  useSub<typeof PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT>(
    PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT.type,
    ({ data: processMethod }) => {
      setProcessMethod(processMethod)
    }
  )

  useSub<typeof CUSTOMER_DETAILS_WALLET_CHANGE_EVENT>(
    CUSTOMER_DETAILS_WALLET_CHANGE_EVENT.type,
    ({ data: token }) => {
      setSelectedToken(token)
    }
  )

  const calculate = useCallback(async () => {
    try {
      setLoadingSurcharge(true)
      let calculatedTax = tax

      let number = account_number
        ? account_number.replace(/\s/g, '')
        : undefined
      let postal = postal_code || undefined
      if (processMethod === 'terminal') {
        number = undefined
      }
      if (
        selectedMerchantAccount.vt_enable_sales_tax &&
        selectedMerchantAccount.tax_surcharge_config === 3
      ) {
        if (!getFieldState('tax').isDirty && postal) {
          setLoadingTax(true)
          const taxRate = await getSalesTax()
          calculatedTax = calculateTax(valueToNumber(subtotal_amount), taxRate)
        }
      }

      const amounts = await calculateSurcharge(
        selectedMerchantAccount,
        subtotal_amount,
        calculatedTax,
        tip_amount,
        tokenId,
        postal,
        number
      )

      setIsCompliantSurchargeApplied(
        amounts?.surcharge_applied &&
          !!selectedMerchantAccount.surcharge?.compliant
      )

      if (!amounts?.surcharge_applied) {
        // Throwing error to be handled by the catch block
        throw new Error('Surcharge not applied')
      }

      setValue('surcharge_amount', amounts.surcharge_amount / 100)

      let newTax = amounts.tax ? amounts.tax / 100 : 0
      let total = amounts.transaction_amount / 100
      if (
        selectedMerchantAccount.vt_enable_sales_tax &&
        selectedMerchantAccount.tax_surcharge_config === 2
      ) {
        if (getFieldState('tax').isDirty || !postal) {
          newTax = amounts.tax / 100
        } else {
          setLoadingTax(true)
          const taxRate = await getSalesTax()
          newTax = calculateTax(
            (amounts.subtotal_amount + amounts.surcharge_amount) / 100,
            taxRate
          )
          total = amounts.transaction_amount / 100 + newTax
        }
      }

      setValue('transaction_amount', total)

      if (
        selectedMerchantAccount?.vt_enable_sales_tax &&
        (tax ?? 0) !== newTax
      ) {
        setTaxFromApi(true)
        setValue('tax', newTax)
      }
    } catch (error) {
      setIsCompliantSurchargeApplied(false)
      setValue('transaction_amount', valueToNumber(subtotal_amount))
      setValue('surcharge_amount', 0)
    } finally {
      setLoadingTax(false)
      setLoadingSurcharge(false)
    }
  }, [
    selectedLocation,
    selectedMerchantAccount,
    account_number,
    postal_code,
    subtotal_amount,
    tax,
    tip_amount,
    processMethod,
    tokenId,
  ])

  const debounceCalculation = useCallback(debounce(calculate, 500), [calculate])

  const getSalesTax = useCallback(
    () => getSalesTaxByZipCode(postal_code, selectedLocation.id, country),
    [country, postal_code, selectedMerchantAccount, selectedLocation]
  )

  const debounceSalesTax = useCallback(
    debounce(() => {
      setLoadingTax(true)
      getSalesTax()
        .then((taxRate) => {
          setTaxRate(taxRate)
        })
        .catch(() => {
          setTaxRate(undefined)
        })
        .finally(() => {
          setLoadingTax(false)
        })
    }, 500),
    [getSalesTax]
  )

  useEffect(() => {
    if (!selectedMerchantAccount || selectedTransactionType === 'avsonly')
      return

    let hasInvalidPostalCode =
      !postal_code || postal_code?.length < 3 || postal_code?.length > 10

    // Manual evaluation if no postal code but wallet has postal code.
    if (
      !hasInvalidPostalCode &&
      processMethod === 'wallet' &&
      selectedToken?.billing_address?.postal_code
    ) {
      hasInvalidPostalCode = false
    }

    const invalidManual =
      (!account_number || account_number?.length < 6) &&
      processMethod === 'manual'

    const invalidWallet = processMethod === 'wallet' && !selectedToken

    const disableSurchargeCalculation =
      invalidManual ||
      invalidWallet ||
      subtotal_amount === 0 ||
      (!!selectedMerchantAccount?.surcharge?.state_exception_check &&
        hasInvalidPostalCode) ||
      !selectedMerchantAccount?.surcharge

    // TODO: Fix this, tax change should call calculation function, but this also change tax and call it twice.
    if (taxFromApi) {
      setTaxFromApi(false)
      return
    }

    const newTotals = calculateTransactionTotals({
      merchantAccount: selectedMerchantAccount,
      subtotal: subtotal_amount ?? 0,
      taxRate,
      taxOverride: getFieldState('tax').isDirty ? tax : undefined,
      tip: tip_amount ?? 0,
    })

    if (selectedMerchantAccount.vt_enable_sales_tax) {
      setValue('tax', newTotals.tax)
    } else {
      setValue('tax', undefined)
    }

    setValue('transaction_amount', newTotals.transaction_amount)

    if (disableSurchargeCalculation) {
      setValue('surcharge_amount', 0)
      setIsCompliantSurchargeApplied(false)
      return
    }

    debounceCalculation()

    return () => {
      debounceCalculation.cancel()
    }
  }, [
    subtotal_amount,
    account_number,
    postal_code,
    tax,
    taxRate,
    selectedMerchantAccount,
    tip_amount,
    processMethod,
    selectedToken,
  ])

  useEffect(() => {
    if (
      !selectedMerchantAccount ||
      !selectedLocation ||
      !postal_code ||
      postal_code.length < 3
    ) {
      setTaxRate(undefined)
      return
    }

    if (!selectedMerchantAccount.vt_enable_sales_tax) return
    if (!!selectedMerchantAccount.surcharge) return

    debounceSalesTax()

    return () => {
      debounceSalesTax.cancel()
    }
  }, [country, postal_code, selectedMerchantAccount, selectedLocation])

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="h6">
        {t('mfe-gateway.virtual-terminal.transaction-summary')}
      </Typography>

      <Box>
        {!!showSubtotal && (
          <>
            <Box className={classes.fieldContainer}>
              <Typography variant="body2" className={classes.fieldSubtitle}>
                {t('common.amount-subtotal')}
              </Typography>

              <Typography variant="body2" data-testid="subtotal-amount-value">
                {currency(valueToNumber(subtotal_amount))}
              </Typography>
            </Box>

            <Divider />
          </>
        )}

        {!!showTip && (
          <Box className={classes.fieldContainer}>
            <Typography variant="body2" className={classes.fieldSubtitle}>
              {t('common.tip')}
            </Typography>

            <Typography variant="body2" data-testid="tip-amount-value">
              {currency(valueToNumber(tip_amount))}
            </Typography>
          </Box>
        )}

        {!!showSalesTax && (
          <Box className={classes.fieldContainer}>
            <Typography variant="body2" className={classes.fieldSubtitle}>
              {t('common.tax')}
            </Typography>

            <Typography variant="body2" data-testid="tax-amount-value">
              {loadingTax ? (
                <CircularProgress
                  style={{
                    height: '20px',
                    width: '20px',
                  }}
                />
              ) : (
                currency(valueToNumber(tax))
              )}
            </Typography>
          </Box>
        )}

        {!!showSurcharge && (
          <Box className={classes.fieldContainer}>
            <Typography variant="body2" className={classes.fieldSubtitle}>
              {selectedMerchantAccount?.surcharge?.surcharge_label ??
                t('common.amount-surcharge')}
            </Typography>

            <Typography variant="body2" data-testid="surcharge-amount-value">
              {loadingSurcharge ? (
                <CircularProgress
                  style={{
                    height: '20px',
                    width: '20px',
                  }}
                />
              ) : (
                currency(valueToNumber(surcharge_amount))
              )}
            </Typography>
          </Box>
        )}

        {!!showSubtotal && <Divider className={classes.divider} />}
      </Box>

      <Box className={classes.fieldContainer} height={'60px'}>
        <Typography variant="body2" className={classes.fieldLabel}>
          {t('common.total-amount')}
        </Typography>

        <Typography
          variant="body2"
          className={classes.totalAmount}
          data-testid="total-amount-value"
        >
          {loadingSurcharge ? (
            <CircularProgress
              style={{
                height: '20px',
                width: '20px',
              }}
            />
          ) : (
            currency(valueToNumber(transaction_amount))
          )}
        </Typography>
      </Box>

      {isCompliantSurchargeApplied && (
        <SurchargeDisclosure
          merchantAccount={selectedMerchantAccount}
          isCustomerWalletTransaction={!!tokenId}
        />
      )}
    </Box>
  )
}

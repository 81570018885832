import { LicenseManager } from 'ag-grid-enterprise'
import { useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Routes, useNavigate } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import packageJSON from '@/../package.json'

import AddressablePage from '@shared/addressable-page-manager/src/AddressablePage'
import AddressablePageManager from '@shared/addressable-page-manager/src/AddressablePageManager'
import { User, api } from '@shared/api/src'
import { ErrorHandler } from '@shared/components'
import AppTheme from '@shared/design'
import {
  EnforceLoginProvider,
  AuthorizationProvider,
  LocationProvider,
  FtpPortalHubCommunicationProvider,
  useFtpPortalHubCommunication,
  ReportFiltersProvider,
  useLocations,
  NotificationProvider,
} from '@shared/hooks'

import i18n from './i18n'
import CustomerAdd from './pages/customer/customer-add/CustomerAdd'
import CustomerCharge from './pages/customer/customer-charge/CustomerCharge'
import CustomerEdit from './pages/customer/customer-edit/CustomerEdit'
import CustomerGrid from './pages/customer/customer-grid/CustomerGrid'
import CustomerView from './pages/customer/customer-view/CustomerView'
import PaylinkAdd from './pages/paylinks/paylink-add/PaylinkAdd'
import PaylinkEdit from './pages/paylinks/paylink-edit/PaylinkEdit'
import PaylinkView from './pages/paylinks/paylink-view/PaylinkView'
import PaylinkGrid from './pages/paylinks/paylinks-grid/PaylinkGrid'
import QuickInvoiceAdd from './pages/quick-invoice/quick-invoice-add/QuickInvoiceAdd'
import QuickInvoiceEdit from './pages/quick-invoice/quick-invoice-edit/QuickInvoiceEdit'
import QuickInvoiceGrid from './pages/quick-invoice/quick-invoice-grid/QuickInvoiceGrid'
import QuickInvoiceView from './pages/quick-invoice/quick-invoice-view/QuickInvoiceView'
import RecurringBillingAdd from './pages/recurring-billing/recurring-billing-add/RecurringBillingAdd'
import RecurringBillingConfirmation from './pages/recurring-billing/recurring-billing-confirmation/RecurringBillingConfirmation'
import RecurringBillingEdit from './pages/recurring-billing/recurring-billing-edit/RecurringBillingEdit'
import RecurringBillingGrid from './pages/recurring-billing/recurring-billing-grid/RecurringBillingGrid'
import RecurringBillingView from './pages/recurring-billing/recurring-billing-view/RecurringBillingView'
import TransactionConfirmation from './pages/transaction/components/TransactionConfirmation'
import Transaction from './pages/transaction/Transaction'

LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE)

export const LOCAL_STORAGE_SESSION_TOKEN_KEY = 'token'

const GatewayApp = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState<User | null>(null)
  const [addressablePageManager, setAddressablePageManager] =
    useState<AddressablePageManager>()

  const { setPortalVersion } = useFtpPortalHubCommunication()

  const { setSelectedLocation } = useLocations()

  const pages = useMemo(() => {
    const root_url = `${
      window?.location?.pathname?.split('/gateway')[0]
    }/gateway`

    return [
      new AddressablePage(
        'Virtual Terminal',
        <Transaction />,
        [],
        `${root_url}/virtual-terminal`,
        undefined,
        true
      ).addNestedPage(
        new AddressablePage(
          'Confirmation',
          <TransactionConfirmation />,
          [],
          `${root_url}/virtual-terminal/confirmation/:transactionId`,
          undefined,
          true
        )
      ),
      new AddressablePage(
        'Recurring Billing',
        <RecurringBillingGrid />,
        [],
        `${root_url}/recurring-billing`,
        undefined,
        true
      ),
      new AddressablePage(
        '',
        <QuickInvoiceGrid />,
        [],
        `${root_url}/quick-invoice`,
        undefined,
        true
      ),
      new AddressablePage(
        '',
        <CustomerGrid />,
        [],
        `${root_url}/customer`,
        undefined,
        true
      ),
      // TODO: Uncomment when paylinks are implemented
      // new AddressablePage(
      //   '',
      //   <PaylinkGrid />,
      //   [],
      //   `${root_url}/paylinks`,
      //   undefined,
      //   true
      // ),

      //////////     recurring billing    //////////
      new AddressablePage(
        'Add Recurring Billing',
        <RecurringBillingAdd />,
        [],
        `${root_url}/recurring-billing/add`,
        undefined,
        true
      ),
      new AddressablePage(
        'Edit Recurring Billing',
        <RecurringBillingEdit />,
        [],
        `${root_url}/recurring-billing/:id/edit`,
        undefined,
        true
      ),
      new AddressablePage(
        'View Recurring Billing',
        <RecurringBillingView />,
        [],
        `${root_url}/recurring-billing/:id/view`,
        undefined,
        true
      ),
      new AddressablePage(
        'New Recurring Billing Confirmation',
        <RecurringBillingConfirmation />,
        [],
        `${root_url}/recurring-billing/:id/confirmation`,
        undefined,
        true
      ),
      //////////     quick invoice    //////////
      new AddressablePage(
        'Add Quick Invoice',
        <QuickInvoiceAdd />,
        [],
        `${root_url}/quick-invoice/add`,
        undefined,
        true
      ),
      new AddressablePage(
        'Edit Quick Invoice',
        <QuickInvoiceEdit />,
        [],
        `${root_url}/quick-invoice/:id/edit`,
        undefined,
        true
      ),
      new AddressablePage(
        'View Quick Invoice',
        <QuickInvoiceView />,
        [],
        `${root_url}/quick-invoice/:id/view`,
        undefined,
        true
      ),
      //////////     customer    //////////
      new AddressablePage(
        'Add Customer',
        <CustomerAdd />,
        [],
        `${root_url}/customer/add`,
        undefined,
        true
      ),
      new AddressablePage(
        'Edit Customer',
        <CustomerEdit />,
        [],
        `${root_url}/customer/:id/edit`,
        undefined,
        true
      ),
      new AddressablePage(
        'View Customer',
        <CustomerView />,
        [],
        `${root_url}/customer/:id/view`,
        undefined,
        true
      ),
      new AddressablePage(
        'Charge Customer',
        <CustomerCharge />,
        [],
        `${root_url}/customer/:customerId/charge`,
        undefined,
        true
      ).addNestedPage(
        new AddressablePage(
          'Confirmation',
          <TransactionConfirmation />,
          [],
          'confirmation/:transactionId',
          undefined,
          true
        )
      ),
      new AddressablePage(
        'Charge Customer',
        <CustomerCharge />,
        [],
        `${root_url}/customer/:customerId/charge/:walletId`,
        undefined,
        true
      ).addNestedPage(
        new AddressablePage(
          'Confirmation',
          <TransactionConfirmation />,
          [],
          'confirmation/:transactionId',
          undefined,
          true
        )
      ),
      //////////     paylinks    //////////
      // TODO: Uncomment when paylinks are implemented
      // new AddressablePage(
      //   'Add Paylink',
      //   <PaylinkAdd />,
      //   [],
      //   `${root_url}/paylinks/add`,
      //   undefined,
      //   true
      // ),
      // new AddressablePage(
      //   'Edit Paylink',
      //   <PaylinkEdit />,
      //   [],
      //   `${root_url}/paylinks/:id/edit`,
      //   undefined,
      //   true
      // ),
      // new AddressablePage(
      //   'View Paylink',
      //   <PaylinkView />,
      //   [],
      //   `${root_url}/paylinks/:id/view`,
      //   undefined,
      //   true
      // ),
    ]
  }, [user])

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const fetchedUser: any = await getUser()
        setUser(fetchedUser)
      } catch (error) {
        console.error('ERROR GETTING/SETTING USER DATA:', error.toString())
      }
    }

    fetchUser()
    setPortalVersion({ version: packageJSON?.version })
  }, [])

  useEffect(() => {
    if (!user) return
    setAddressablePageManager(new AddressablePageManager(pages, user))
  }, [user])

  useEffect(() => {
    if (!addressablePageManager) return

    const handleLocationChange = async (event) => {
      await setSelectedLocation(event.detail.location)

      const newRoute = addressablePageManager.handleLocationChange(
        window.location.pathname
      )

      newRoute ? navigate(newRoute) : null
    }

    window.addEventListener(
      'FTP_NAVIGATOR_LOCATION_CHANGED',
      handleLocationChange
    )

    return () => {
      window.removeEventListener(
        'FTP_NAVIGATOR_LOCATION_CHANGED',
        handleLocationChange
      )
    }
  }, [addressablePageManager])

  async function getUser() {
    const user = await api.service('users').authorize()

    setUser(user)
    return user
  }

  if (!addressablePageManager) return null

  return (
    <AuthorizationProvider user={user}>
      <ThemeProvider theme={AppTheme}>
        <Routes>{addressablePageManager.renderAllAddressablePages()}</Routes>
      </ThemeProvider>
    </AuthorizationProvider>
  )
}
const Gateway: React.FC = () => {
  return (
    <FtpPortalHubCommunicationProvider>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <EnforceLoginProvider redirectToLogin>
          <ReportFiltersProvider>
            <LocationProvider>
              <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                  <NotificationProvider>
                    <GatewayApp />
                  </NotificationProvider>
                </BrowserRouter>
              </I18nextProvider>
            </LocationProvider>
          </ReportFiltersProvider>
        </EnforceLoginProvider>
      </ErrorBoundary>
    </FtpPortalHubCommunicationProvider>
  )
}

export default Gateway

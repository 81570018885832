import { ChangeEvent, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Grid, Typography, Box, IconButton, Button } from '@mui/material'

import { preprocessCurrencyAmount } from '@shared/api/src/utils/transactions/preprocessCurrencyAmount'
import { valueToNumber } from '@shared/api/src/utils/valueToNumber'
import {
  FieldGroupContainer,
  Input,
  InputAmount,
  useInputStyles,
} from '@shared/components'
import { Plus, TrashCan } from '@shared/icons'
import { currency } from '@shared/utils'

import QuickInvoiceItemsList from './quick-invoice-items-list/QuickInvoiceItemsList'
import { QuickInvoiceFormData } from '../../quick-invoice-add/QuickInvoiceAdd'

const useStyles = tss.withName('QuickInvoiceItems').create(({ theme }) => ({
  label: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  deleteIcon: {
    padding: 0,
    marginLeft: '12px',
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  addItemButton: {
    '&:hover': {
      backgroundColor: 'inherit',
      boxShadow: 'none',
    },
  },
  bottomLabel: {
    color: theme.palette['neutral-900'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  bottomLabelDisabled: {
    color: theme.palette['neutral-400'],
  },
  totalAmount: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    marginLeft: '8px',
  },
}))

export interface QuickInvoiceItem {
  name: string
  amount: number
}

interface QuickInvoiceItemsProps {
  readOnly?: boolean
}

const QuickInvoiceItems = ({ readOnly = false }: QuickInvoiceItemsProps) => {
  const { classes } = useStyles()
  const { classes: inputClasses, cx } = useInputStyles()

  const { t } = useTranslation()

  const {
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext<QuickInvoiceFormData>()

  const [items, setItems] = useState(
    getValues('item_list')?.map((item) => ({
      name: item.name,
      amount: item.amount / 100,
    })) || []
  )

  const [isIncluding, setIsIncluding] = useState<boolean>(items.length === 0)

  const [newItemName, setNewItemName] = useState<string>('')
  const [newItemAmount, setNewItemAmount] = useState<number>(null)

  useEffect(() => {
    if (items.length === 0) {
      setIsIncluding(true)
    }

    const itemsFormatted = items.map((item) => ({
      name: item.name,
      amount: preprocessCurrencyAmount(item.amount),
    }))

    setValue('item_list', itemsFormatted)
  }, [items])

  const onChangeItem = (newItem: QuickInvoiceItem, index: number) => {
    const newArray = items.map((item, i) => (i === index ? newItem : item))

    setItems(newArray)
  }

  const removeItem = (indexToRemove: number) => {
    setItems(items.filter((_, index) => index !== indexToRemove))
  }

  const addItem = () => {
    if (newItemName === '' || newItemAmount <= 0) return

    const newArray = [...items, { name: newItemName, amount: newItemAmount }]
    setItems(newArray)
    setIsIncluding(false)
    setNewItemName('')
    setNewItemAmount(null)
    setError('item_list', { type: 'manual', message: '' })
  }

  return (
    <>
      {readOnly ? (
        <QuickInvoiceItemsList list={items} />
      ) : (
        <FieldGroupContainer
          title={t('mfe-gateway.quick-invoice.add-invoice-items')}
          titleTestID="quick-invoice-items"
        >
          <Grid container>
            <Grid item xs={12} md={8} paddingRight={'12px'}>
              <Typography className={classes.label}>
                {t('mfe-gateway.quick-invoice.item-service-name')}
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography className={classes.label}>
                {t('common.amount')}
              </Typography>
            </Grid>
          </Grid>

          {items?.map((item, index) => (
            <Grid container marginTop={'8px'} key={index}>
              <Grid item xs={12} md={8} paddingRight={'12px'}>
                <Input
                  testId={`item-service-name-input-${index}`}
                  placeholder={t(
                    'mfe-gateway.quick-invoice.enter-item-service-name'
                  )}
                  value={item?.name}
                  onChange={(
                    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) =>
                    onChangeItem(
                      { name: e.target.value, amount: item.amount },
                      index
                    )
                  }
                  guidingId={`quickinvoice-quickinvoiceitems-item-${index}`}
                />
              </Grid>

              <Grid item xs={12} md={4} display={'flex'}>
                <InputAmount
                  style={{ width: '100%' }}
                  testId={`item-service-amount-input-${index}`}
                  placeholder="0.00"
                  value={item?.amount}
                  onChange={(e: any) => {
                    onChangeItem(
                      { name: item.name, amount: e.target.value },
                      index
                    )
                  }}
                  guidingId={`quickinvoice-quickinvoiceitems-amount-${index}`}
                />

                {item.name !== '' && item?.amount !== null && (
                  <IconButton
                    disableRipple
                    onClick={() => removeItem(index)}
                    className={classes.deleteIcon}
                    data-testid={`delete-service-button-${index}`}
                    data-guiding-id={`quickinvoice-quickinvoiceitems-remove-${index}`}
                  >
                    <TrashCan />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}

          {isIncluding && (
            <Grid container marginTop={'8px'}>
              <Grid item xs={12} md={8} paddingRight={'12px'}>
                <Input
                  testId={`item-service-name-input-new`}
                  placeholder={t(
                    'mfe-gateway.quick-invoice.enter-item-service-name'
                  )}
                  value={newItemName}
                  onChange={(
                    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setNewItemName(e.target.value)}
                  onBlur={() => addItem()}
                  data-guiding-id="quickinvoice-quickinvoiceitems-itemservicename"
                />
              </Grid>

              <Grid item xs={12} md={4} display={'flex'}>
                <InputAmount
                  style={{ width: '100%' }}
                  testId={`item-service-amount-input-new`}
                  placeholder="0.00"
                  value={newItemAmount}
                  onChange={(e: any) => setNewItemAmount(e.target.value)}
                  onBlur={() => addItem()}
                  data-guiding-id="quickinvoice-quickinvoiceitems-amount"
                />
              </Grid>
            </Grid>
          )}

          <Box className={classes.bottomContainer}>
            <Button
              startIcon={<Plus />}
              className={classes.addItemButton}
              disableRipple
              onClick={() => setIsIncluding(true)}
              disabled={isIncluding}
              data-testid={`add-item-button`}
              data-guiding-id="quickinvoice-quickinvoiceitems-add"
            >
              <Typography
                className={`${classes.bottomLabel} ${
                  isIncluding ? classes.bottomLabelDisabled : ''
                }`}
              >
                {t('common.add-item')}
              </Typography>
            </Button>

            <Box display={'flex'}>
              <Typography className={classes.bottomLabel}>
                {t('common.total')}
              </Typography>
              <Typography
                className={classes.totalAmount}
                data-testid={`total-amount`}
              >
                {currency(
                  items.reduce(
                    (sum, item) => sum + valueToNumber(item.amount),
                    0
                  )
                )}
              </Typography>
            </Box>
          </Box>
          {!!errors.item_list && (
            <Typography
              className={cx(
                inputClasses.helperText,
                inputClasses.helperTextError
              )}
              variant="body2"
            >
              {errors.item_list.message}
            </Typography>
          )}
        </FieldGroupContainer>
      )}
    </>
  )
}

export default QuickInvoiceItems

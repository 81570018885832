import { AxisTickProps } from '@nivo/axes'
import { DatumValue, Point, useTheme } from '@nivo/core'
import { LineSvgProps, ResponsiveLine } from '@nivo/line'
import React from 'react'

import { currency } from '@shared/utils'

export type LineChartPoint = Point & {
  data: {
    date?: number
    x: DatumValue
    xFormatted: string | number
    y: DatumValue
    yFormatted: string | number
    yStacked?: number
    transactions?: number
    code?: number
    id?: string
    name?: string
  }
}

interface LineChartProps extends LineSvgProps {
  skipTicks?: number
  tooltipContent?: React.ReactNode
  splitDateTime?: boolean
}

const formatTimeTo12Hour = (time24: string) => {
  const [hours, minutes] = time24.split(':').map(Number)
  const suffix = hours >= 12 ? 'PM' : 'AM'
  const hours12 = hours % 12 || 12
  return `${hours12}:${minutes < 10 ? '0' : ''}${minutes} ${suffix}`
}

const LineChart = ({
  skipTicks = 1,
  splitDateTime = false,
  ...rest
}: LineChartProps) => {
  const CustomTicker = (props: AxisTickProps<string>) => {
    const theme = useTheme()
    const [date, time] = splitDateTime
      ? props.value.split(' ')
      : [props.value, '']

    const formattedTime = time ? formatTimeTo12Hour(time) : ''

    return (
      <g
        transform={`translate(${props.x},${props.y})`}
        style={{
          opacity: props.opacity,
          height: '90%',
        }}
      >
        {splitDateTime && props.tickIndex % skipTicks === 0 && (
          <text
            alignmentBaseline="hanging"
            style={{
              ...theme.axis.ticks.text,
              fontSize: 11,
            }}
            textAnchor={props.textAnchor}
            transform={`translate(${props.textX},${
              props.textY - (splitDateTime ? 10 : 20)
            })`}
          >
            {formattedTime}
          </text>
        )}
        {props.tickIndex % skipTicks === 0 && (
          <text
            alignmentBaseline="hanging"
            style={{
              ...theme.axis.ticks.text,
              fontSize: 11,
            }}
            textAnchor={props.textAnchor}
            transform={`translate(${props.textX},${props.textY})`}
          >
            {date}
          </text>
        )}
      </g>
    )
  }

  return (
    <ResponsiveLine
      useMesh={true}
      animate={false}
      curve="linear"
      margin={{ top: 40, bottom: 70, right: 30, left: 80 }}
      layers={['axes', 'lines', 'areas', 'slices', 'points', 'mesh', 'legends']}
      legends={[
        {
          anchor: 'top-left',
          direction: 'row',
          translateX: -60,
          translateY: -30,
          itemsSpacing: 20,
          itemDirection: 'left-to-right',
          itemWidth: 100,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 8,
          symbolShape: 'circle',
        },
      ]}
      axisBottom={{
        tickPadding: 15,
        renderTick: CustomTicker,
      }}
      axisLeft={{
        tickPadding: 15,
        format: (value) => currency(value),
      }}
      onMouseEnter={(_datum, event: any) => {
        event.currentTarget.style.cursor = 'pointer' // Hacky way, otherwise nivo doesn't allow to change cursor
      }}
      {...rest}
    />
  )
}

export default LineChart

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Contact } from '@shared/api/src'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { formatDate } from '@shared/utils'

export interface CustomerDetailsProps {
  customer: Contact
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({ customer }) => {
  const { t } = useTranslation()

  const valueFirstName = customer?.first_name
  const valueLastName = customer?.last_name
  const valueAccountNumber = customer?.account_number
  const valueDateOfBirth = customer?.date_of_birth
    ? formatDate(customer?.date_of_birth, 'UTC')
    : ''

  return (
    <FieldGroupContainer title={t('mfe-gateway.customer-details')}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.name-first')}
          value={valueFirstName}
          valueTestID="first-name-value"
        />
        <FieldGroupViewItem
          title={t('common.name-last')}
          value={valueLastName}
          valueTestID="last-name-value"
        />
      </FieldGroupRow>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('mfe-gateway.customer-number')}
          value={valueAccountNumber}
          valueTestID="customer-number-value"
        />
        <FieldGroupViewItem
          title={t('mfe-gateway.date-of-birth')}
          value={valueDateOfBirth}
          valueTestID="dob-value"
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}

export default CustomerDetails

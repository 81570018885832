import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useFtpPortalHubCommunication } from '@shared/hooks'

import VirtualTerminal from '@/components/virtual-terminal/VirtualTerminal'

export default function Transaction() {
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { t } = useTranslation()

  useEffect(() => {
    setAppBarTitle(t('common.virtual-terminal'), null, t('common.gateway'))
  }, [])

  return <VirtualTerminal />
}

import { useCallback } from 'react'

export type PubSubEvent<T> = Event & { data: T }

/**
 * Custom Hook that returns a Function to be used for Publishing Events.
 */
export const usePub = () => {
  return useCallback(
    <T extends PubSubEvent<unknown>>(event: T, data?: T['data']) => {
      event.data = data
      window.dispatchEvent(event)
    },
    []
  )
}

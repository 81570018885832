import { cloneDeep } from 'lodash'

const clearBlankFieldsRecursively = <T extends object>(
  obj: T,
  ignoreKeys: string[] = []
) => {
  obj = cloneDeep(obj)

  //This is used to track whether or not an object has any fields that aren't empty strings.
  //If not, the entire object can be trimmed.
  let hasValidField = false

  for (const [key, value] of Object.entries(obj)) {
    if (ignoreKeys.includes(key)) continue
    if (
      (typeof value === 'string' && value === '') ||
      value === null ||
      value === undefined
    ) {
      delete obj[key as keyof T]
    } else if (typeof value === 'object') {
      const { result, valid } = clearBlankFieldsRecursively(value, ignoreKeys)

      obj[key as keyof T] = result
      //If the child has fields, its parent should not be removed.
      if (valid) {
        hasValidField = true
      } else {
        delete obj[key as keyof T]
      }
    } else {
      hasValidField = true
    }
  }

  return { result: obj, valid: hasValidField }
}

/**
 * Given an object, recursively clears empty strings from its properties and the properties of its children.
 *
 * @param obj The object to be cleared
 * @param ignoreKeys An array of keys to ignore when clearing blank fields
 * @returns A deep clone of the original object with all blank fields removed.
 */
export const clearBlankFields = <T extends object>(
  obj: T,
  ignoreKeys?: string[]
) =>
  //A recursive version of this is run using an extra boolean flag in order to provide extra info for recursive calls
  clearBlankFieldsRecursively(obj, ignoreKeys).result

export default clearBlankFields

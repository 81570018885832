import { MerchantAccount } from '../../../api'
import { PaymentMethodType, AccountType } from '../../../types'
import { getSECCodes } from '../../sec-codes/secCodes'

export enum SECCode {
  'PPD' = 'PPD',
  'CCD' = 'CCD',
}

export const getDefaultSECCode = (
  merchantAccount: MerchantAccount | null,
  accountType: AccountType | null
): SECCode | undefined => {
  if (
    !merchantAccount ||
    merchantAccount?.payment_method === PaymentMethodType.CC ||
    merchantAccount?.payment_method === PaymentMethodType.CASH ||
    !accountType
  )
    return undefined

  const personalCodes = getSECCodes('personal', merchantAccount)
  const businessCodes = getSECCodes('business', merchantAccount)

  if (accountType === 'personal' && personalCodes.length > 0) {
    return personalCodes[0]?.value as SECCode
  } else {
    return businessCodes[0]?.value as SECCode
  }
}

import { FC, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Contact } from '@shared/api'
import { Typeahead, TypeaheadProps } from '@shared/components'

export interface CustomerSelectorProps {
  customers: Contact[]
  selectedCustomer: Contact | null
  getCustomers?: TypeaheadProps<Contact>['getItems']
  onChangeCustomers: (customers: Contact[]) => void
  onChangeSelectedCustomer: (wallet: Contact | null) => void
  getCustomersOnMount?: boolean
  loading?: boolean
  required?: boolean
  disabled?: boolean
  label?: string
  placeholder?: string
  guidingId?: string
}

export const CustomerSelector: FC<CustomerSelectorProps> = ({
  customers,
  selectedCustomer,
  getCustomers,
  onChangeCustomers,
  onChangeSelectedCustomer,
  getCustomersOnMount,
  loading,
  required,
  disabled,
  label,
  placeholder,
  guidingId,
}) => {
  const { t } = useTranslation()

  const methods = useFormContext()

  const getCustomerLabel = useCallback(
    (customer: Contact) =>
      `${customer?.first_name ?? ''} ${customer?.last_name} ${
        customer?.account_number ? '• ' + customer.account_number : ''
      }`,
    []
  )

  return (
    <Typeahead<Contact>
      id="customer-selector"
      testId="customer-selector-input"
      autoCompleteTestId="customer-selector-autocomplete"
      debounceMs={300}
      label={label ?? t('mfe-gateway.customer.select')}
      noOptionsText={t('mfe-gateway.customer.select-prompt')}
      value={selectedCustomer}
      items={customers}
      matchItemAndValueProperty={'id'}
      getItemLabel={getCustomerLabel}
      getItems={getCustomers}
      onChangeItems={onChangeCustomers}
      onChangeValue={onChangeSelectedCustomer}
      getItemsOnMount={!!getCustomersOnMount}
      localFiltering={!getCustomers}
      loading={!!loading}
      disabled={!!disabled}
      required={!!required}
      error={!!methods?.formState?.errors.contact_id}
      helperText={methods?.formState?.errors.contact_id?.message as string}
      placeholder={placeholder}
      guidingId={`${guidingId}-customerselector`}
    />
  )
}
